.image0 {
    background-image: url("../../assets/images/ProgressReport/1.png");
}

.image1 {
    background-image: url("../../assets/images/ProgressReport/2.png");
}

.image2 {
    background-image: url("../../assets/images/ProgressReport/3.png");
}

.image3 {
    background-image: url("../../assets/images/ProgressReport/4.png");
}

.image4 {
    background-image: url("../../assets/images/ProgressReport/5.png");
}

.image5 {
    background-image: url("../../assets/images/ProgressReport/6.png");
}

.image6 {
    background-image: url("../../assets/images/ProgressReport/7.png");
}

.image7 {
    background-image: url("../../assets/images/ProgressReport/8.png");
}

.image8 {
    background-image: url("../../assets/images/ProgressReport/9.png");
}

.image9 {
    background-image: url("../../assets/images/ProgressReport/10.png");
}

