.chat-window-container{
    flex: 1;
    overflow: hidden;
}
.chat-window-container .chat-body-attachfiles{
    height: 48vh;
    overflow: auto;
    padding: 10px 30px;
}
.chat-window-container .chat-body{
    height: 61vh;
    overflow: auto;
    padding: 10px 30px;
}

.chat-window-container .header{
    border-bottom: none;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .10) !important;
    height: 60px;
}
.chat-window-container .header .header-toolbar{
    padding: 0px 30px;
}
.chat-window-container .footer{
    border-bottom: none;
    box-shadow: 0 0 black;
}
.chat-window-container .footer .attach-button{
    position: absolute;
    margin-left: 44px;
}
.chat-window-container .footer .attach-button>div>button{
    height: 45px;
    width: 45px;
}
.chat-window-container .footer .attach-section{
    display:flex;
    flex:1
}
.chat-window-container .footer .attach-section .item-container{
    position: relative;
}
.chat-window-container .footer .attach-section .item-container .item{
    border-radius: 5px;
    margin: 4px;
}
.chat-window-container .footer .attach-section .item-container .item-icon{
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    color: #fff;
}


.chat-window-container .footer .attach-section .chat-input{
  
}
.chat-window-container .header .iconbutton{
    background: rgba(0,0,0,0.05);
    padding: 8px;
    margin: 4px;
}
.chat-window-container .iconbutton{
    background: rgba(0,0,0,0.05);
    padding: 9px;
    /* margin: 4px; */
}
.chat-window-container .footer .chat-input-box{
    display: flex;
    position: relative;
    width: 100%;
    min-width: 200px;
    margin-left: 60px;
    margin-right: 20px;
}
.chat-window-container .footer .chat-input-box .chat-input{
    border: 1px solid #ccc;
    border-radius: 20px;
    height: 44px;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    padding: 9px 100px 0px 24px;
    min-height: 44px;
    resize: none;
}:focus{
    outline: none;
}
.chat-window-container .footer .chat-input-box .button{
    color: blue;
    position: absolute;
    right: 10px;
    top: -4px;
    bottom: 0;
}
