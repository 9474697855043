.student-dashboard .main-header {
    background: rgb(41, 185, 187);
    margin: -24px;
    height: 110px;
}

.student-dashboard .header-content {
    padding: 35px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.student-dashboard .header-content1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.student-dashboard .back {
    border-right: 1px solid white;
    margin-right: 20px;
    padding-right: 20px;
    font-size: 20px;
}

.student-dashboard .content {
    margin-top: 40px;
}

.student-dashboard .ant-list-item:hover {
    background: none;
}

.add-student-menu {
    width: 375px;
}

.add-student-menu .header-menu {
    background: rgb(84, 99, 214);
    padding-top: 30px;
    margin-top: -4px;
}

.add-student-menu .grid-menu {
    padding: 0 25px;
}

.add-student-menu .active {
    color: white;
    border-bottom: 2px solid black;
    text-align: center;
}

.add-student-menu .inactive {
    color: hsla(0,0%,100%,.5);
    text-align: center;
}

.add-student-menu .student-button {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

.ant-modal-header {
    padding: 25px 24px;
    background: #5463d6;
}

.ant-modal-title {
    font-size: 22px;
    color: #fff;
}

.ant-modal-close-x {
    color: #fff;
    font-size: 20px;
}