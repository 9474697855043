.report-group-list .container {
  overflow-x: auto !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-top: 5px !important;
  padding: 5px;
  width: 100%;
}

.report-group-list .w-100-p {
  width: 100%;
}

/*.report-group-list tr {*/
.progress-report-table tr {
  height: 40px !important;
}

/*.report-group-list td {*/
.progress-report-table td {
  height: 40px !important;
  padding: 5px !important;
  text-align: center !important;
}

.report-group-list .button-md {
  width: 30%;
}
.report-group-list .moduleName {
  width: 40%;
}

@media only screen and (max-width: 600px) {
  /*.report-group-list td {*/
  .progress-report-table td {
    height: 40px !important;
    padding: 5px !important;
    text-align: center !important;
    min-width: 100px !important;
  }
  /*.report-group-list td.moduleName {*/
  .progress-report-table td.moduleName {
    min-width: 180px !important;
  }
  /*.report-group-list td.avt {*/
  .progress-report-table td.avt {
    max-width: 10px !important;
  }
}
/*.report-group-list td {*/
.progress-report-table td {
  min-width: 100px !important;
}
/*.report-group-list td.moduleName {*/
.progress-report-table td.moduleName {
  min-width: 180px !important;
}

.report-group-list .group-list .main-header {
  background: #934082;
  margin: -24px;
  height: 110px;
}

.report-group-list .group-list .header-content {
  padding: 35px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.report-group-list .group-list .header-content1 {
  width: 100%;
  display: flex;
  align-items: center;
}

.report-group-list .group-list .back {
  border-right: 1px solid white;
  margin-right: 20px;
  padding-right: 20px;
  font-size: 20px;
}

.report-group-list .group-list .content {
  margin-top: 40px;
}

.report-group-list .group-list .div-content {
  margin-left: 45px;
  margin-right: 45px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}