.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

td,
th {
	border: 1px black solid;
	cursor: move;
}

select {
	appearance: none;
	width: 80%;
	margin: auto;
	height: 80%;
	border: 0px;
	outline: 0px;
	text-align: center;
	text-align-last: center;
	border-radius: 1%;
}

table {
	width: 100%;
}

/* option {
  background-color: blue;
} */

th > div {
	width: 60%;
}

.new-card-main {
	background-color: #fff;
	border-radius: 1%;
}

p {
	width: 80%;
	height: 80%;
	margin: auto;
}

/* * {
	background-color: #026aa7;
} */

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	/* box-shadow: inset 0 0 3px grey; */
	border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: lightgray;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: grey;
}

textarea {
	border: none;
	background-color: inherit;
	resize: none;
	outline: none;
}



.dropdown-icon {
  position: absolute;
  right: 2%;
  /* bottom: 5%; */
  border: none;
  background: none;
  width: 1.2rem;
}