.report-student-list .container {
  margin: 10px;
  overflow-x: auto;
  margin-right: 1px;
  margin-left: 1px;
  margin-top: 50px;
}
.report-student-list .search-xs {
  opacity: 1 !important;
  background-color: #e3e3e3 !important;
}
.report-student-list .title {
  margin-top: 20px;
  text-align: center;
}

/*.report-student-table .MuiTableHead-root tr {*/
.progress-report-table tr {
  height: 40px !important;
}
.report-student-list .text-l {
  text-align: left !important;
}

/*.report-student-table .MuiTableHead-root .MuiTableCell-root th {*/
.progress-report-table th {
  /*display: flex;*/
  align-items: center;
  max-width: 125px;
  /* min-width: 125px; */
  padding: 0 !important;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center !important;
}
.report-student-list .MuiPaper-elevation1 {
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12) !important;
}
/*.report-student-list td {*/
.progress-report-table td {
  height: 40px !important;
  padding: 5px !important;
  text-align: center !important;
  /* min-width: 100px; */
}
.report-student-list main {
  overflow-x: auto;
}
.report-student-list .publishBtn {
  background-color: transparent !important;
  padding: 0px !important;
  color: #3f51b5 !important;
  box-shadow: none !important;
}
.report-student-list .publishBtn:hover {
  background-color: transparent !important;
}
.report-student-list .publishBtn:active {
  background-color: transparent !important;
}
.report-student-list .MuiFormGroup-root > .MuiTypography-body1 {
  font-size: 0.85rem;
}
.report-student-list .MuiFormGroup-root > .MuiFormControlLabel-root > .MuiTypography-body1 {
  font-size: 0.85rem !important;
}
.report-student-list .MuiFormControlLabel-root {
  margin: 0;
  max-height: 28px;
}
.report-student-list .search-xs {
  width: 90% !important;
}
.report-student-list .makeStyles-selectGroup-7299 {
  height: 100%;
}
.report-student-list .makeStyles-selectGroup-579 {
  height: 100%;
}
.report-student-list .MuiFormControl-root.makeStyles-selectGroup-576 {
  height: 100% !important;
}
.report-student-list .MuiFilledInput-underline:before {
  border-bottom: none !important;
}
.report-student-list .MuiInputBase-formControl {
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px #00000024,
    0px 2px 1px -1px rgba(0, 0, 0, 0.02);
  /* max-height: 46px; */
}
div[class^='makeStyles-selectGroup'],
div[class*=' makeStyles-selectGroup'] {
  height: 100%;
}
/* [class^='makeStyles-notCurrentSemesterHeader'],
[class*=' makeStyles-notCurrentSemesterHeader'] {
  background-color: red !important;
} */

[class^='makeStyles-currentSemesterHeader'],
[class*=' makeStyles-currentSemesterHeader'] {
  background: #5D7EB0 0% 0% no-repeat padding-box !important;
  color: white !important;
}

[class^='makeStyles-completedSemesterHeader'],
[class*=' makeStyles-completedSemesterHeader'] {
  background: black 0% 0% no-repeat padding-box !important;
  color: white !important;
}

[class^='makeStyles-pubishedSemesterHeader'],
[class*='makeStyles-pubishedSemesterHeader'] {
  background: black 0% 0% no-repeat padding-box !important;
  color: white !important;
}

[class^='makeStyles-currentSemesterContainer'],
[class*='makeStyles-currentSemesterContainer'] {
  color: black !important;
  background-color: #fff !important;
}


[class^='makeStyles-publishedSemesterContainer'],
[class*='makeStyles-publishedSemesterContainer'] {
  color: white !important;
  background-color: #cce6f1 !important;
}

[class^='makeStyles-CommentContainer'],
[class*='makeStyles-CommentContainer'] {
  color: white !important;
  background-color: white !important;
}

[class^='makeStyles-completedSemesterContainer'],
[class*='makeStyles-completedSemesterContainer'] {
  color: white !important;
  background-color: #cce6f1 !important;
}

[class^='amakeStyles-currentSemesterHeader'],
[class*='amakeStyles-currentSemesterHeader'] {
  background: #eeeeee 0% 0% no-repeat padding-box !important;
  /* color: rgb(0, 0, 0) !important; */
}

[class^='makeStyles-currentSemesterHeader'],
[class*=' makeStyles-currentSemesterHeader'] {
  background: #5D7EB0 0% 0% no-repeat padding-box !important;
  color: white !important;
}

[class^='amakeStyles-currentSemesterHeader'],
[class*='amakeStyles-currentSemesterHeader'] {
  background: #eeeeee 0% 0% no-repeat padding-box !important;
  /* color: rgb(0, 0, 0) !important; */
}

/* [class^='makeStyles-inProgressHeader'],
[class*=' makeStyles-inProgressHeader'] {
  color: black !important;
} */

.report-student-list .MuiSelect-select:focus {
  background-color: transparent !important;
}
@media only screen and (max-width: 959px) {
  .report-student-list .MuiFormGroup-root {
    display: block !important;
  }
  .report-student-list .radio-container-xs {
    justify-content: flex-start !important;
  }
  .report-student-list .MuiFormControlLabel-root {
    margin-left: unset;
  }
  .report-student-list .MuiFormGroup-row {
    flex-direction: column !important;
    align-items: stretch;
    display: flex !important;
    margin-top: 5px !important;
  }
  .report-student-list .search-xs {
    width: 100% !important;
  }
  .report-student-list .f-start {
    justify-content: flex-start !important;
  }
  .report-student-list .af-start {
    align-items: flex-start !important;
  }
  .report-student-list .container {
    margin-top: 0px;
  }
  .report-student-list .mb-10-xs {
    margin-bottom: 10px !important;
  }
  .report-student-list .d-column-xs {
    display: flex;
    flex-direction: column;
  }
}
.report-student-list .teacher {
  padding-left: 13px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.report-student-list .group-student .main-header {
  background: #934082;
  margin: -24px;
  height: 110px;
}

.report-student-list .group-student .header-content {
  padding: 35px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.report-student-list .group-student .header-content1 {
  width: 100%;
  display: flex;
  align-items: center;
}

.report-student-list .group-student .back {
  border-right: 1px solid white;
  margin-right: 20px;
  padding-right: 20px;
  font-size: 20px;
}

.report-student-list .group-student .content {
  margin-top: 40px;
}

.report-student-list .group-student .div-content {
  margin-left: 45px;
  margin-right: 45px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}