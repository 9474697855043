.group-dashboard .main-header {
    background: rgb(84, 99, 214);
    margin: -24px;
    height: 110px;
}

.group-dashboard .header-content {
    padding: 35px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.group-dashboard .header-content1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.group-dashboard .back {
    border-right: 1px solid white;
    margin-right: 20px;
    padding-right: 20px;
    font-size: 20px;
}

.group-dashboard .content {
    margin-top: 40px;
}

.group-dashboard .div-content {
    margin-left: 45px;
    margin-right: 45px;
    justify-content: space-between;
    align-items: center;
    display: flex;
}

.class-profile .main-header {
    background: rgb(84, 99, 214);
    margin: -24px;
    height: 110px;
}

.class-profile .header-content {
    padding: 35px 25px;
    display: flex;
    align-items: center;
}

.class-profile .header-content1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.class-profile .back {
    border-right: 1px solid white;
    margin-right: 20px;
    padding-right: 20px;
    font-size: 20px;
}

.class-profile .content {
    margin-top: 24px;
}

.class-profile .student-content {
    padding: 20px;
}

.class-profile .staff-content {
    padding: 20px;
}

.class-profile .parent-content {
    padding: 20px;
}

.class-profile .data-table-header {
    padding: 0 24px;
    background: #ebeff4;
}

.group-list-popup-menu {
    max-height: 250px;
    overflow: auto;
}

.add-staff-student-menu {
    width: 250px;
}

.add-staff-student-menu .header-menu {
    background: rgb(84, 99, 214);
    padding-top: 30px;
    margin-top: -4px;
}

.add-staff-student-menu .grid-menu {
    padding: 0 25px;
}

.add-staff-student-menu .grid-textfield {
    margin-top: 40px;
    padding: 0 25px;
}

.add-staff-student-menu .child-button {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
}

.add-staff-student-menu .student-button {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

.add-class-menu {
    width: 250px;
}

.add-class-menu .header-menu {
    background: rgb(84, 99, 214);
    padding-top: 30px;
    margin-top: -4px;
}

.add-class-menu .grid-menu {
    padding: 0 25px;
}

.add-class-menu .textfield {
    padding: 30px 15px 15px 15px;
}

.add-class-menu .create-class {
    text-align: center;
    margin: 15px 0;
}

.add-to-group .ant-avatar {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.add-to-group .ant-list-item {
    padding: 5px 0;
}

.add-to-group .ant-list-item-meta-title {
    margin-bottom: 0;
}

.add-to-group .ant-list-item-meta-avatar {
    margin-right: 10px;
}

.container{

    display:flex;
    width:100%;
    flex-wrap:wrap;

}
.calendarContainer{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.calendarDay{
    display: flex;
    border: 1px solid gray;
    padding: 20px;
    width: calc(100% / 7);
    height: 100px;
}

.class-schedule .ant-table-thead > tr > th {
    text-align: center;
}

.class-schedule .ant-avatar {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.class-schedule .align-center {
    align-items: center;
}

.class-schedule .ml-10 {
    margin-left: 10px !important;
}

.class-schedule .schedule-box {
    background: rgb(203 208 243);
    padding: 5px;
    border-radius: 5px;
    height: 40px;
}

.class-schedule .ant-table-tbody > tr > td {
    padding: 2px;
    height: 40px;
}
