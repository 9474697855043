.progress-report-detail .main-header {
    background: #934082;
    margin: -24px;
    height: 100px;
}

.progress-report-detail .header-content {
    padding: 30px 25px;
}

.progress-report-detail .header-content1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.progress-report-detail .back {
    border-right: 1px solid white;
    margin-right: 20px;
    padding-right: 20px;
    font-size: 20px;
}

.progress-report-detail .content {
    margin-top: 40px;
}

.progress-report-detail #container {
    width:100%;
}

.progress-report-detail #left {
    float:left;
    max-width: 310px;
}

.progress-report-detail #right {
    float:right;
    max-width: 305px;
    text-align: end;
}

.progress-report-detail #center {
    margin:0 auto;
    /*width:100px;*/
    text-align: center;
}

.progress-report-table td {
    height: 40px!important;
    padding: 5px!important;
    text-align: center!important;
}