.staff-dashboard .main-header {
    background: #934082;
    margin: -24px;
    height: 110px;
}

.staff-dashboard .header-content {
    padding: 35px 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.staff-dashboard .header-content1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.staff-dashboard .back {
    border-right: 1px solid white;
    margin-right: 20px;
    padding-right: 20px;
    font-size: 20px;
}

.staff-dashboard .content {
    margin-top: 40px;
}

.user-profile .main-header {
    background: #934082;
    margin: -24px;
    height: 110px;
}

.user-profile .header-content {
    padding: 35px 25px;
    display: flex;
    align-items: center;
}

.user-profile .header-content1 {
    width: 100%;
    display: flex;
    align-items: center;
}

.user-profile .back {
    border-right: 1px solid white;
    margin-right: 20px;
    padding-right: 20px;
    font-size: 20px;
}

.user-profile .content {
    margin-top: 35px;
}