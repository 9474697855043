/*@tailwind preflight;*/

.list-reset {
    list-style: none;
    padding: 0;
}

.appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.bg-fixed {
    background-attachment: fixed;
}

.bg-local {
    background-attachment: local;
}

.bg-scroll {
    background-attachment: scroll;
}

.bg-transparent {
    background-color: transparent;
}

.bg-black {
    background-color: #22292f;
}

.bg-grey-darkest {
    background-color: #3d4852;
}

.bg-grey-darker {
    background-color: #606f7b;
}

.bg-grey-dark {
    background-color: #8795a1;
}

.bg-grey {
    background-color: #b8c2cc;
}

.bg-grey-light {
    background-color: #dae1e7;
}

.bg-grey-lighter {
    background-color: #f1f5f8;
}

.bg-grey-lightest {
    background-color: #f8fafc;
}

.bg-white {
    background-color: #fff;
}

.bg-red-darkest {
    background-color: #3b0d0c;
}

.bg-red-darker {
    background-color: #621b18;
}

.bg-red-dark {
    background-color: #cc1f1a;
}

.bg-red {
    background-color: #e3342f;
}

.bg-red-light {
    background-color: #ef5753;
}

.bg-red-lighter {
    background-color: #f9acaa;
}

.bg-red-lightest {
    background-color: #fcebea;
}

.bg-orange-darkest {
    background-color: #462a16;
}

.bg-orange-darker {
    background-color: #613b1f;
}

.bg-orange-dark {
    background-color: #de751f;
}

.bg-orange {
    background-color: #f6993f;
}

.bg-orange-light {
    background-color: #faad63;
}

.bg-orange-lighter {
    background-color: #fcd9b6;
}

.bg-orange-lightest {
    background-color: #fff5eb;
}

.bg-yellow-darkest {
    background-color: #453411;
}

.bg-yellow-darker {
    background-color: #684f1d;
}

.bg-yellow-dark {
    background-color: #f2d024;
}

.bg-yellow {
    background-color: #ffed4a;
}

.bg-yellow-light {
    background-color: #fff382;
}

.bg-yellow-lighter {
    background-color: #fff9c2;
}

.bg-yellow-lightest {
    background-color: #fcfbeb;
}

.bg-green-darkest {
    background-color: #0f2f21;
}

.bg-green-darker {
    background-color: #1a4731;
}

.bg-green-dark {
    background-color: #1f9d55;
}

.bg-green {
    background-color: #38c172;
}

.bg-green-light {
    background-color: #51d88a;
}

.bg-green-lighter {
    background-color: #a2f5bf;
}

.bg-green-lightest {
    background-color: #e3fcec;
}

.bg-teal-darkest {
    background-color: #0d3331;
}

.bg-teal-darker {
    background-color: #20504f;
}

.bg-teal-dark {
    background-color: #38a89d;
}

.bg-teal {
    background-color: #4dc0b5;
}

.bg-teal-light {
    background-color: #64d5ca;
}

.bg-teal-lighter {
    background-color: #a0f0ed;
}

.bg-teal-lightest {
    background-color: #e8fffe;
}

.bg-blue-darkest {
    background-color: #12283a;
}

.bg-blue-darker {
    background-color: #1c3d5a;
}

.bg-blue-dark {
    background-color: #2779bd;
}

.bg-blue {
    background-color: #3490dc;
}

.bg-blue-light {
    background-color: #6cb2eb;
}

.bg-blue-lighter {
    background-color: #bcdefa;
}

.bg-blue-lightest {
    background-color: #eff8ff;
}

.bg-indigo-darkest {
    background-color: #191e38;
}

.bg-indigo-darker {
    background-color: #2f365f;
}

.bg-indigo-dark {
    background-color: #5661b3;
}

.bg-indigo {
    background-color: #6574cd;
}

.bg-indigo-light {
    background-color: #7886d7;
}

.bg-indigo-lighter {
    background-color: #b2b7ff;
}

.bg-indigo-lightest {
    background-color: #e6e8ff;
}

.bg-purple-darkest {
    background-color: #21183c;
}

.bg-purple-darker {
    background-color: #382b5f;
}

.bg-purple-dark {
    background-color: #794acf;
}

.bg-purple {
    background-color: #9561e2;
}

.bg-purple-light {
    background-color: #a779e9;
}

.bg-purple-lighter {
    background-color: #d6bbfc;
}

.bg-purple-lightest {
    background-color: #f3ebff;
}

.bg-pink-darkest {
    background-color: #451225;
}

.bg-pink-darker {
    background-color: #6f213f;
}

.bg-pink-dark {
    background-color: #eb5286;
}

.bg-pink {
    background-color: #f66d9b;
}

.bg-pink-light {
    background-color: #fa7ea8;
}

.bg-pink-lighter {
    background-color: #ffbbca;
}

.bg-pink-lightest {
    background-color: #ffebef;
}

.hover\:bg-transparent:hover {
    background-color: transparent;
}

.hover\:bg-black:hover {
    background-color: #22292f;
}

.hover\:bg-grey-darkest:hover {
    background-color: #3d4852;
}

.hover\:bg-grey-darker:hover {
    background-color: #606f7b;
}

.hover\:bg-grey-dark:hover {
    background-color: #8795a1;
}

.hover\:bg-grey:hover {
    background-color: #b8c2cc;
}

.hover\:bg-grey-light:hover {
    background-color: #dae1e7;
}

.hover\:bg-grey-lighter:hover {
    background-color: #f1f5f8;
}

.hover\:bg-grey-lightest:hover {
    background-color: #f8fafc;
}

.hover\:bg-white:hover {
    background-color: #fff;
}

.hover\:bg-red-darkest:hover {
    background-color: #3b0d0c;
}

.hover\:bg-red-darker:hover {
    background-color: #621b18;
}

.hover\:bg-red-dark:hover {
    background-color: #cc1f1a;
}

.hover\:bg-red:hover {
    background-color: #e3342f;
}

.hover\:bg-red-light:hover {
    background-color: #ef5753;
}

.hover\:bg-red-lighter:hover {
    background-color: #f9acaa;
}

.hover\:bg-red-lightest:hover {
    background-color: #fcebea;
}

.hover\:bg-orange-darkest:hover {
    background-color: #462a16;
}

.hover\:bg-orange-darker:hover {
    background-color: #613b1f;
}

.hover\:bg-orange-dark:hover {
    background-color: #de751f;
}

.hover\:bg-orange:hover {
    background-color: #f6993f;
}

.hover\:bg-orange-light:hover {
    background-color: #faad63;
}

.hover\:bg-orange-lighter:hover {
    background-color: #fcd9b6;
}

.hover\:bg-orange-lightest:hover {
    background-color: #fff5eb;
}

.hover\:bg-yellow-darkest:hover {
    background-color: #453411;
}

.hover\:bg-yellow-darker:hover {
    background-color: #684f1d;
}

.hover\:bg-yellow-dark:hover {
    background-color: #f2d024;
}

.hover\:bg-yellow:hover {
    background-color: #ffed4a;
}

.hover\:bg-yellow-light:hover {
    background-color: #fff382;
}

.hover\:bg-yellow-lighter:hover {
    background-color: #fff9c2;
}

.hover\:bg-yellow-lightest:hover {
    background-color: #fcfbeb;
}

.hover\:bg-green-darkest:hover {
    background-color: #0f2f21;
}

.hover\:bg-green-darker:hover {
    background-color: #1a4731;
}

.hover\:bg-green-dark:hover {
    background-color: #1f9d55;
}

.hover\:bg-green:hover {
    background-color: #38c172;
}

.hover\:bg-green-light:hover {
    background-color: #51d88a;
}

.hover\:bg-green-lighter:hover {
    background-color: #a2f5bf;
}

.hover\:bg-green-lightest:hover {
    background-color: #e3fcec;
}

.hover\:bg-teal-darkest:hover {
    background-color: #0d3331;
}

.hover\:bg-teal-darker:hover {
    background-color: #20504f;
}

.hover\:bg-teal-dark:hover {
    background-color: #38a89d;
}

.hover\:bg-teal:hover {
    background-color: #4dc0b5;
}

.hover\:bg-teal-light:hover {
    background-color: #64d5ca;
}

.hover\:bg-teal-lighter:hover {
    background-color: #a0f0ed;
}

.hover\:bg-teal-lightest:hover {
    background-color: #e8fffe;
}

.hover\:bg-blue-darkest:hover {
    background-color: #12283a;
}

.hover\:bg-blue-darker:hover {
    background-color: #1c3d5a;
}

.hover\:bg-blue-dark:hover {
    background-color: #2779bd;
}

.hover\:bg-blue:hover {
    background-color: #3490dc;
}

.hover\:bg-blue-light:hover {
    background-color: #6cb2eb;
}

.hover\:bg-blue-lighter:hover {
    background-color: #bcdefa;
}

.hover\:bg-blue-lightest:hover {
    background-color: #eff8ff;
}

.hover\:bg-indigo-darkest:hover {
    background-color: #191e38;
}

.hover\:bg-indigo-darker:hover {
    background-color: #2f365f;
}

.hover\:bg-indigo-dark:hover {
    background-color: #5661b3;
}

.hover\:bg-indigo:hover {
    background-color: #6574cd;
}

.hover\:bg-indigo-light:hover {
    background-color: #7886d7;
}

.hover\:bg-indigo-lighter:hover {
    background-color: #b2b7ff;
}

.hover\:bg-indigo-lightest:hover {
    background-color: #e6e8ff;
}

.hover\:bg-purple-darkest:hover {
    background-color: #21183c;
}

.hover\:bg-purple-darker:hover {
    background-color: #382b5f;
}

.hover\:bg-purple-dark:hover {
    background-color: #794acf;
}

.hover\:bg-purple:hover {
    background-color: #9561e2;
}

.hover\:bg-purple-light:hover {
    background-color: #a779e9;
}

.hover\:bg-purple-lighter:hover {
    background-color: #d6bbfc;
}

.hover\:bg-purple-lightest:hover {
    background-color: #f3ebff;
}

.hover\:bg-pink-darkest:hover {
    background-color: #451225;
}

.hover\:bg-pink-darker:hover {
    background-color: #6f213f;
}

.hover\:bg-pink-dark:hover {
    background-color: #eb5286;
}

.hover\:bg-pink:hover {
    background-color: #f66d9b;
}

.hover\:bg-pink-light:hover {
    background-color: #fa7ea8;
}

.hover\:bg-pink-lighter:hover {
    background-color: #ffbbca;
}

.hover\:bg-pink-lightest:hover {
    background-color: #ffebef;
}

.bg-bottom {
    background-position: bottom;
}

.bg-center {
    background-position: center;
}

.bg-left {
    background-position: left;
}

.bg-left-bottom {
    background-position: left bottom;
}

.bg-left-top {
    background-position: left top;
}

.bg-right {
    background-position: right;
}

.bg-right-bottom {
    background-position: right bottom;
}

.bg-right-top {
    background-position: right top;
}

.bg-top {
    background-position: top;
}

.bg-repeat {
    background-repeat: repeat;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-repeat-x {
    background-repeat: repeat-x;
}

.bg-repeat-y {
    background-repeat: repeat-y;
}

.bg-auto {
    background-size: auto;
}

.bg-cover {
    background-size: cover;
}

.bg-contain {
    background-size: contain;
}

.border-collapse {
    border-collapse: collapse;
}

.border-separate {
    border-collapse: separate;
}

.border-transparent {
    border-color: transparent;
}

.border-black {
    border-color: #22292f;
}

.border-grey-darkest {
    border-color: #3d4852;
}

.border-grey-darker {
    border-color: #606f7b;
}

.border-grey-dark {
    border-color: #8795a1;
}

.border-grey {
    border-color: #b8c2cc;
}

.border-grey-light {
    border-color: #dae1e7;
}

.border-grey-lighter {
    border-color: #f1f5f8;
}

.border-grey-lightest {
    border-color: #f8fafc;
}

.border-white {
    border-color: #fff;
}

.border-red-darkest {
    border-color: #3b0d0c;
}

.border-red-darker {
    border-color: #621b18;
}

.border-red-dark {
    border-color: #cc1f1a;
}

.border-red {
    border-color: #e3342f;
}

.border-red-light {
    border-color: #ef5753;
}

.border-red-lighter {
    border-color: #f9acaa;
}

.border-red-lightest {
    border-color: #fcebea;
}

.border-orange-darkest {
    border-color: #462a16;
}

.border-orange-darker {
    border-color: #613b1f;
}

.border-orange-dark {
    border-color: #de751f;
}

.border-orange {
    border-color: #f6993f;
}

.border-orange-light {
    border-color: #faad63;
}

.border-orange-lighter {
    border-color: #fcd9b6;
}

.border-orange-lightest {
    border-color: #fff5eb;
}

.border-yellow-darkest {
    border-color: #453411;
}

.border-yellow-darker {
    border-color: #684f1d;
}

.border-yellow-dark {
    border-color: #f2d024;
}

.border-yellow {
    border-color: #ffed4a;
}

.border-yellow-light {
    border-color: #fff382;
}

.border-yellow-lighter {
    border-color: #fff9c2;
}

.border-yellow-lightest {
    border-color: #fcfbeb;
}

.border-green-darkest {
    border-color: #0f2f21;
}

.border-green-darker {
    border-color: #1a4731;
}

.border-green-dark {
    border-color: #1f9d55;
}

.border-green {
    border-color: #38c172;
}

.border-green-light {
    border-color: #51d88a;
}

.border-green-lighter {
    border-color: #a2f5bf;
}

.border-green-lightest {
    border-color: #e3fcec;
}

.border-teal-darkest {
    border-color: #0d3331;
}

.border-teal-darker {
    border-color: #20504f;
}

.border-teal-dark {
    border-color: #38a89d;
}

.border-teal {
    border-color: #4dc0b5;
}

.border-teal-light {
    border-color: #64d5ca;
}

.border-teal-lighter {
    border-color: #a0f0ed;
}

.border-teal-lightest {
    border-color: #e8fffe;
}

.border-blue-darkest {
    border-color: #12283a;
}

.border-blue-darker {
    border-color: #1c3d5a;
}

.border-blue-dark {
    border-color: #2779bd;
}

.border-blue {
    border-color: #3490dc;
}

.border-blue-light {
    border-color: #6cb2eb;
}

.border-blue-lighter {
    border-color: #bcdefa;
}

.border-blue-lightest {
    border-color: #eff8ff;
}

.border-indigo-darkest {
    border-color: #191e38;
}

.border-indigo-darker {
    border-color: #2f365f;
}

.border-indigo-dark {
    border-color: #5661b3;
}

.border-indigo {
    border-color: #6574cd;
}

.border-indigo-light {
    border-color: #7886d7;
}

.border-indigo-lighter {
    border-color: #b2b7ff;
}

.border-indigo-lightest {
    border-color: #e6e8ff;
}

.border-purple-darkest {
    border-color: #21183c;
}

.border-purple-darker {
    border-color: #382b5f;
}

.border-purple-dark {
    border-color: #794acf;
}

.border-purple {
    border-color: #9561e2;
}

.border-purple-light {
    border-color: #a779e9;
}

.border-purple-lighter {
    border-color: #d6bbfc;
}

.border-purple-lightest {
    border-color: #f3ebff;
}

.border-pink-darkest {
    border-color: #451225;
}

.border-pink-darker {
    border-color: #6f213f;
}

.border-pink-dark {
    border-color: #eb5286;
}

.border-pink {
    border-color: #f66d9b;
}

.border-pink-light {
    border-color: #fa7ea8;
}

.border-pink-lighter {
    border-color: #ffbbca;
}

.border-pink-lightest {
    border-color: #ffebef;
}

.hover\:border-transparent:hover {
    border-color: transparent;
}

.hover\:border-black:hover {
    border-color: #22292f;
}

.hover\:border-grey-darkest:hover {
    border-color: #3d4852;
}

.hover\:border-grey-darker:hover {
    border-color: #606f7b;
}

.hover\:border-grey-dark:hover {
    border-color: #8795a1;
}

.hover\:border-grey:hover {
    border-color: #b8c2cc;
}

.hover\:border-grey-light:hover {
    border-color: #dae1e7;
}

.hover\:border-grey-lighter:hover {
    border-color: #f1f5f8;
}

.hover\:border-grey-lightest:hover {
    border-color: #f8fafc;
}

.hover\:border-white:hover {
    border-color: #fff;
}

.hover\:border-red-darkest:hover {
    border-color: #3b0d0c;
}

.hover\:border-red-darker:hover {
    border-color: #621b18;
}

.hover\:border-red-dark:hover {
    border-color: #cc1f1a;
}

.hover\:border-red:hover {
    border-color: #e3342f;
}

.hover\:border-red-light:hover {
    border-color: #ef5753;
}

.hover\:border-red-lighter:hover {
    border-color: #f9acaa;
}

.hover\:border-red-lightest:hover {
    border-color: #fcebea;
}

.hover\:border-orange-darkest:hover {
    border-color: #462a16;
}

.hover\:border-orange-darker:hover {
    border-color: #613b1f;
}

.hover\:border-orange-dark:hover {
    border-color: #de751f;
}

.hover\:border-orange:hover {
    border-color: #f6993f;
}

.hover\:border-orange-light:hover {
    border-color: #faad63;
}

.hover\:border-orange-lighter:hover {
    border-color: #fcd9b6;
}

.hover\:border-orange-lightest:hover {
    border-color: #fff5eb;
}

.hover\:border-yellow-darkest:hover {
    border-color: #453411;
}

.hover\:border-yellow-darker:hover {
    border-color: #684f1d;
}

.hover\:border-yellow-dark:hover {
    border-color: #f2d024;
}

.hover\:border-yellow:hover {
    border-color: #ffed4a;
}

.hover\:border-yellow-light:hover {
    border-color: #fff382;
}

.hover\:border-yellow-lighter:hover {
    border-color: #fff9c2;
}

.hover\:border-yellow-lightest:hover {
    border-color: #fcfbeb;
}

.hover\:border-green-darkest:hover {
    border-color: #0f2f21;
}

.hover\:border-green-darker:hover {
    border-color: #1a4731;
}

.hover\:border-green-dark:hover {
    border-color: #1f9d55;
}

.hover\:border-green:hover {
    border-color: #38c172;
}

.hover\:border-green-light:hover {
    border-color: #51d88a;
}

.hover\:border-green-lighter:hover {
    border-color: #a2f5bf;
}

.hover\:border-green-lightest:hover {
    border-color: #e3fcec;
}

.hover\:border-teal-darkest:hover {
    border-color: #0d3331;
}

.hover\:border-teal-darker:hover {
    border-color: #20504f;
}

.hover\:border-teal-dark:hover {
    border-color: #38a89d;
}

.hover\:border-teal:hover {
    border-color: #4dc0b5;
}

.hover\:border-teal-light:hover {
    border-color: #64d5ca;
}

.hover\:border-teal-lighter:hover {
    border-color: #a0f0ed;
}

.hover\:border-teal-lightest:hover {
    border-color: #e8fffe;
}

.hover\:border-blue-darkest:hover {
    border-color: #12283a;
}

.hover\:border-blue-darker:hover {
    border-color: #1c3d5a;
}

.hover\:border-blue-dark:hover {
    border-color: #2779bd;
}

.hover\:border-blue:hover {
    border-color: #3490dc;
}

.hover\:border-blue-light:hover {
    border-color: #6cb2eb;
}

.hover\:border-blue-lighter:hover {
    border-color: #bcdefa;
}

.hover\:border-blue-lightest:hover {
    border-color: #eff8ff;
}

.hover\:border-indigo-darkest:hover {
    border-color: #191e38;
}

.hover\:border-indigo-darker:hover {
    border-color: #2f365f;
}

.hover\:border-indigo-dark:hover {
    border-color: #5661b3;
}

.hover\:border-indigo:hover {
    border-color: #6574cd;
}

.hover\:border-indigo-light:hover {
    border-color: #7886d7;
}

.hover\:border-indigo-lighter:hover {
    border-color: #b2b7ff;
}

.hover\:border-indigo-lightest:hover {
    border-color: #e6e8ff;
}

.hover\:border-purple-darkest:hover {
    border-color: #21183c;
}

.hover\:border-purple-darker:hover {
    border-color: #382b5f;
}

.hover\:border-purple-dark:hover {
    border-color: #794acf;
}

.hover\:border-purple:hover {
    border-color: #9561e2;
}

.hover\:border-purple-light:hover {
    border-color: #a779e9;
}

.hover\:border-purple-lighter:hover {
    border-color: #d6bbfc;
}

.hover\:border-purple-lightest:hover {
    border-color: #f3ebff;
}

.hover\:border-pink-darkest:hover {
    border-color: #451225;
}

.hover\:border-pink-darker:hover {
    border-color: #6f213f;
}

.hover\:border-pink-dark:hover {
    border-color: #eb5286;
}

.hover\:border-pink:hover {
    border-color: #f66d9b;
}

.hover\:border-pink-light:hover {
    border-color: #fa7ea8;
}

.hover\:border-pink-lighter:hover {
    border-color: #ffbbca;
}

.hover\:border-pink-lightest:hover {
    border-color: #ffebef;
}

.rounded-none {
    border-radius: 0;
}

.rounded-sm {
    border-radius: .2rem;
}

.rounded {
    border-radius: .4rem;
}

.rounded-lg {
    border-radius: .8rem;
}

.rounded-1rem {
    border-radius: .4rem !important;
}

.rounded-full {
    border-radius: 9999px;
}

.rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.rounded-t-sm {
    border-top-left-radius: .2rem;
    border-top-right-radius: .2rem;
}

.rounded-r-sm {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem;
}

.rounded-b-sm {
    border-bottom-right-radius: .2rem;
    border-bottom-left-radius: .2rem;
}

.rounded-l-sm {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem;
}

.rounded-t {
    border-top-left-radius: .4rem;
    border-top-right-radius: .4rem;
}

.rounded-r {
    border-top-right-radius: .4rem;
    border-bottom-right-radius: .4rem;
}

.rounded-b {
    border-bottom-right-radius: .4rem;
    border-bottom-left-radius: .4rem;
}

.rounded-l {
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
}

.rounded-t-lg {
    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem;
}

.rounded-r-lg {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem;
}

.rounded-b-lg {
    border-bottom-right-radius: .8rem;
    border-bottom-left-radius: .8rem;
}

.rounded-l-lg {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem;
}

.rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
}

.rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}

.rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
}

.rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
}

.rounded-tl-none {
    border-top-left-radius: 0;
}

.rounded-tr-none {
    border-top-right-radius: 0;
}

.rounded-br-none {
    border-bottom-right-radius: 0;
}

.rounded-bl-none {
    border-bottom-left-radius: 0;
}

.rounded-tl-sm {
    border-top-left-radius: .2rem;
}

.rounded-tr-sm {
    border-top-right-radius: .2rem;
}

.rounded-br-sm {
    border-bottom-right-radius: .2rem;
}

.rounded-bl-sm {
    border-bottom-left-radius: .2rem;
}

.rounded-tl {
    border-top-left-radius: .4rem;
}

.rounded-tr {
    border-top-right-radius: .4rem;
}

.rounded-br {
    border-bottom-right-radius: .4rem;
}

.rounded-bl {
    border-bottom-left-radius: .4rem;
}

.rounded-tl-lg {
    border-top-left-radius: .8rem;
}

.rounded-tr-lg {
    border-top-right-radius: .8rem;
}

.rounded-br-lg {
    border-bottom-right-radius: .8rem;
}

.rounded-bl-lg {
    border-bottom-left-radius: .8rem;
}

.rounded-tl-full {
    border-top-left-radius: 9999px;
}

.rounded-tr-full {
    border-top-right-radius: 9999px;
}

.rounded-br-full {
    border-bottom-right-radius: 9999px;
}

.rounded-bl-full {
    border-bottom-left-radius: 9999px;
}

.border-solid {
    border-style: solid;
}

.border-dashed {
    border-style: dashed;
}

.border-dotted {
    border-style: dotted;
}

.border-none {
    border-style: none;
}

.border-0 {
    border-width: 0;
}

.border-1 {
    border-width: 1px;
}

.border-2 {
    border-width: 2px;
}

.border-3 {
    border-width: 3px;
}

.border-4 {
    border-width: 4px;
}

.border-8 {
    border-width: 8px;
}

.border {
    border-width: 1px;
}

.border-t-0 {
    border-top-width: 0;
}

.border-r-0 {
    border-right-width: 0;
}

.border-b-0 {
    border-bottom-width: 0;
}

.border-l-0 {
    border-left-width: 0;
}

.border-t-1 {
    border-top-width: 1px;
}

.border-r-1 {
    border-right-width: 1px;
}

.border-b-1 {
    border-bottom-width: 1px;
}

.border-l-1 {
    border-left-width: 1px;
}

.border-t-2 {
    border-top-width: 2px;
}

.border-r-2 {
    border-right-width: 2px;
}

.border-b-2 {
    border-bottom-width: 2px;
}

.border-l-2 {
    border-left-width: 2px;
}

.border-t-3 {
    border-top-width: 3px;
}

.border-r-3 {
    border-right-width: 3px;
}

.border-b-3 {
    border-bottom-width: 3px;
}

.border-l-3 {
    border-left-width: 3px;
}

.border-t-4 {
    border-top-width: 4px;
}

.border-r-4 {
    border-right-width: 4px;
}

.border-b-4 {
    border-bottom-width: 4px;
}

.border-l-4 {
    border-left-width: 4px;
}

.border-t-8 {
    border-top-width: 8px;
}

.border-r-8 {
    border-right-width: 8px;
}

.border-b-8 {
    border-bottom-width: 8px;
}

.border-l-8 {
    border-left-width: 8px;
}

.border-t {
    border-top-width: 1px;
}

.border-r {
    border-right-width: 1px;
}

.border-b {
    border-bottom-width: 1px;
}

.border-l {
    border-left-width: 1px;
}

.cursor-auto {
    cursor: auto;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-wait {
    cursor: wait;
}

.cursor-move {
    cursor: move;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

.table {
    display: table;
}

.table-row {
    display: table-row;
}

.table-cell {
    display: table-cell;
}

.hidden {
    display: none;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.flex-row {
    flex-direction: row;
}

.flex-row-reverse {
    flex-direction: row-reverse;
}

.flex-col {
    flex-direction: column;
}

.flex-col-reverse {
    flex-direction: column-reverse;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.flex-no-wrap {
    flex-wrap: nowrap;
}

.items-start {
    align-items: flex-start;
}

.items-end {
    align-items: flex-end;
}

.items-center {
    align-items: center;
}

.items-baseline {
    align-items: baseline;
}

.items-stretch {
    align-items: stretch;
}

.self-auto {
    align-self: auto;
}

.self-start {
    align-self: flex-start;
}

.self-end {
    align-self: flex-end;
}

.self-center {
    align-self: center;
}

.self-stretch {
    align-self: stretch;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.content-center {
    align-content: center;
}

.content-start {
    align-content: flex-start;
}

.content-end {
    align-content: flex-end;
}

.content-between {
    align-content: space-between;
}

.content-around {
    align-content: space-around;
}

.flex-1 {
    flex: 1;
}

.flex-auto {
    flex: auto;
}

.flex-initial {
    flex: initial;
}

.flex-none {
    flex: none;
}

.flex-grow {
    flex-grow: 1;
}

.flex-shrink {
    flex-shrink: 1;
}

.flex-no-grow {
    flex-grow: 0;
}

.flex-no-shrink {
    flex-shrink: 0;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.float-none {
    float: none;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}

.font-sans {
    font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.font-serif {
    font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
}

.font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-100 {
    font-weight: 100;
}

.font-200 {
    font-weight: 200;
}

.font-300 {
    font-weight: 300;
}

.font-400 {
    font-weight: 400;
}

.font-500 {
    font-weight: 500;
}

.font-600 {
    font-weight: 600;
}

.font-700 {
    font-weight: 700;
}

.font-800 {
    font-weight: 800;
}

.font-900 {
    font-weight: 900;
}

.font-hairline {
    font-weight: 100;
}

.font-thin {
    font-weight: 200;
}

.font-light {
    font-weight: 300;
}

.font-normal {
    font-weight: 400;
}

.font-medium {
    font-weight: 500;
}

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

.font-extrabold {
    font-weight: 800;
}

.font-black {
    font-weight: 900;
}

.hover\:font-100:hover {
    font-weight: 100;
}

.hover\:font-200:hover {
    font-weight: 200;
}

.hover\:font-300:hover {
    font-weight: 300;
}

.hover\:font-400:hover {
    font-weight: 400;
}

.hover\:font-500:hover {
    font-weight: 500;
}

.hover\:font-600:hover {
    font-weight: 600;
}

.hover\:font-700:hover {
    font-weight: 700;
}

.hover\:font-800:hover {
    font-weight: 800;
}

.hover\:font-900:hover {
    font-weight: 900;
}

.hover\:font-hairline:hover {
    font-weight: 100;
}

.hover\:font-thin:hover {
    font-weight: 200;
}

.hover\:font-light:hover {
    font-weight: 300;
}

.hover\:font-normal:hover {
    font-weight: 400;
}

.hover\:font-medium:hover {
    font-weight: 500;
}

.hover\:font-semibold:hover {
    font-weight: 600;
}

.hover\:font-bold:hover {
    font-weight: 700;
}

.hover\:font-extrabold:hover {
    font-weight: 800;
}

.hover\:font-black:hover {
    font-weight: 900;
}

.h-4 {
    height: .4rem;
}

.h-8 {
    height: .8rem;
}

.h-12 {
    height: 1.2rem;
}

.h-16 {
    height: 1.6rem;
}

.h-20 {
    height: 2rem;
}

.h-24 {
    height: 2.4rem;
}

.h-28 {
    height: 2.8rem;
}

.h-32 {
    height: 3.2rem;
}

.h-36 {
    height: 3.6rem;
}

.h-40 {
    height: 4rem;
}

.h-44 {
    height: 4.4rem;
}

.h-48 {
    height: 4.8rem;
}

.h-52 {
    height: 5.2rem;
}

.h-56 {
    height: 5.6rem;
}

.h-60 {
    height: 6rem;
}

.h-64 {
    height: 6.4rem;
}

.h-68 {
    height: 6.8rem;
}

.h-72 {
    height: 7.2rem;
}

.h-76 {
    height: 7.6rem;
}

.h-80 {
    height: 8rem !important;
}

.h-84 {
    height: 8.4rem;
}

.h-88 {
    height: 8.8rem;
}

.h-92 {
    height: 9.2rem;
}

.h-96 {
    height: 9.6rem !important;
}

.h-128 {
    height: 12.8rem;
}

.h-160 {
    height: 16rem;
}

.h-192 {
    height: 19.2rem;
}

.h-224 {
    height: 22.4rem;
}

.h-256 {
    height: 25.6rem;
}

.h-288 {
    height: 28.8rem;
}

.h-320 {
    height: 32rem;
}

.h-512 {
    height: 51.2rem;
}

.h-auto {
    height: auto;
}

.h-px {
    height: 1px;
}

.h-full {
    height: 100%;
}

.h-screen {
    height: 100vh;
}

.leading-none {
    line-height: 1;
}

.leading-tight {
    line-height: 1.25;
}

.leading-normal {
    line-height: 1.5;
}

.leading-loose {
    line-height: 2;
}

.m-0 {
    margin: 0;
}

.m-4 {
    margin: .4rem;
}

.m-8 {
    margin: .8rem;
}

.m-12 {
    margin: 1.2rem;
}

.m-16 {
    margin: 1.6rem;
}

.m-20 {
    margin: 2rem;
}

.m-24 {
    margin: 2.4rem;
}

.m-28 {
    margin: 2.8rem;
}

.m-32 {
    margin: 3.2rem;
}

.m-36 {
    margin: 3.6rem;
}

.m-40 {
    margin: 4rem;
}

.m-44 {
    margin: 4.4rem;
}

.m-48 {
    margin: 4.8rem;
}

.m-52 {
    margin: 5.2rem;
}

.m-56 {
    margin: 5.6rem;
}

.m-60 {
    margin: 6rem;
}

.m-64 {
    margin: 6.4rem;
}

.m-68 {
    margin: 6.8rem;
}

.m-72 {
    margin: 7.2rem;
}

.m-76 {
    margin: 7.6rem;
}

.m-80 {
    margin: 8rem;
}

.m-84 {
    margin: 8.4rem;
}

.m-88 {
    margin: 8.8rem;
}

.m-92 {
    margin: 9.2rem;
}

.m-96 {
    margin: 9.6rem;
}

.m-128 {
    margin: 12.8rem;
}

.m-160 {
    margin: 16rem;
}

.m-192 {
    margin: 19.2rem;
}

.m-224 {
    margin: 22.4rem;
}

.m-256 {
    margin: 25.6rem;
}

.m-auto {
    margin: auto;
}

.m-px {
    margin: 1px;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.my-4 {
    margin-top: .4rem;
    margin-bottom: .4rem;
}

.mx-4 {
    margin-left: .4rem;
    margin-right: .4rem;
}

.my-8 {
    margin-top: .8rem;
    margin-bottom: .8rem;
}

.mx-8 {
    margin-left: .8rem;
    margin-right: .8rem;
}

.my-12 {
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;
}

.mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
}

.my-16 {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
}

.mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
}

.my-20 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.mx-20 {
    margin-left: 2rem;
    margin-right: 2rem;
}

.my-24 {
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
}

.mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
}

.my-28 {
    margin-top: 2.8rem;
    margin-bottom: 2.8rem;
}

.mx-28 {
    margin-left: 2.8rem;
    margin-right: 2.8rem;
}

.my-32 {
    margin-top: 3.2rem;
    margin-bottom: 3.2rem;
}

.mx-32 {
    margin-left: 3.2rem;
    margin-right: 3.2rem;
}

.my-36 {
    margin-top: 3.6rem;
    margin-bottom: 3.6rem;
}

.mx-36 {
    margin-left: 3.6rem;
    margin-right: 3.6rem;
}

.my-40 {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.mx-40 {
    margin-left: 4rem;
    margin-right: 4rem;
}

.my-44 {
    margin-top: 4.4rem;
    margin-bottom: 4.4rem;
}

.mx-44 {
    margin-left: 4.4rem;
    margin-right: 4.4rem;
}

.my-48 {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
}

.mx-48 {
    margin-left: 4.8rem;
    margin-right: 4.8rem;
}

.my-52 {
    margin-top: 5.2rem;
    margin-bottom: 5.2rem;
}

.mx-52 {
    margin-left: 5.2rem;
    margin-right: 5.2rem;
}

.my-56 {
    margin-top: 5.6rem;
    margin-bottom: 5.6rem;
}

.mx-56 {
    margin-left: 5.6rem;
    margin-right: 5.6rem;
}

.my-60 {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.mx-60 {
    margin-left: 6rem;
    margin-right: 6rem;
}

.my-64 {
    margin-top: 6.4rem;
    margin-bottom: 6.4rem;
}

.mx-64 {
    margin-left: 6.4rem;
    margin-right: 6.4rem;
}

.my-68 {
    margin-top: 6.8rem;
    margin-bottom: 6.8rem;
}

.mx-68 {
    margin-left: 6.8rem;
    margin-right: 6.8rem;
}

.my-72 {
    margin-top: 7.2rem;
    margin-bottom: 7.2rem;
}

.mx-72 {
    margin-left: 7.2rem;
    margin-right: 7.2rem;
}

.my-76 {
    margin-top: 7.6rem;
    margin-bottom: 7.6rem;
}

.mx-76 {
    margin-left: 7.6rem;
    margin-right: 7.6rem;
}

.my-80 {
    margin-top: 8rem;
    margin-bottom: 8rem;
}

.mx-80 {
    margin-left: 8rem;
    margin-right: 8rem;
}

.my-84 {
    margin-top: 8.4rem;
    margin-bottom: 8.4rem;
}

.mx-84 {
    margin-left: 8.4rem;
    margin-right: 8.4rem;
}

.my-88 {
    margin-top: 8.8rem;
    margin-bottom: 8.8rem;
}

.mx-88 {
    margin-left: 8.8rem;
    margin-right: 8.8rem;
}

.my-92 {
    margin-top: 9.2rem;
    margin-bottom: 9.2rem;
}

.mx-92 {
    margin-left: 9.2rem;
    margin-right: 9.2rem;
}

.my-96 {
    margin-top: 9.6rem;
    margin-bottom: 9.6rem;
}

.mx-96 {
    margin-left: 9.6rem;
    margin-right: 9.6rem;
}

.my-128 {
    margin-top: 12.8rem;
    margin-bottom: 12.8rem;
}

.mx-128 {
    margin-left: 12.8rem;
    margin-right: 12.8rem;
}

.my-160 {
    margin-top: 16rem;
    margin-bottom: 16rem;
}

.mx-160 {
    margin-left: 16rem;
    margin-right: 16rem;
}

.my-192 {
    margin-top: 19.2rem;
    margin-bottom: 19.2rem;
}

.mx-192 {
    margin-left: 19.2rem;
    margin-right: 19.2rem;
}

.my-224 {
    margin-top: 22.4rem;
    margin-bottom: 22.4rem;
}

.mx-224 {
    margin-left: 22.4rem;
    margin-right: 22.4rem;
}

.my-256 {
    margin-top: 25.6rem;
    margin-bottom: 25.6rem;
}

.mx-256 {
    margin-left: 25.6rem;
    margin-right: 25.6rem;
}

.my-auto {
    margin-top: auto;
    margin-bottom: auto;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.my-px {
    margin-top: 1px;
    margin-bottom: 1px;
}

.mx-px {
    margin-left: 1px;
    margin-right: 1px;
}

.mt-0 {
    margin-top: 0;
}

.mr-0 {
    margin-right: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.ml-0 {
    margin-left: 0;
}

.mt-4 {
    margin-top: .4rem;
}

.mr-4 {
    margin-right: .4rem;
}

.mb-4 {
    margin-bottom: .4rem;
}

.ml-4 {
    margin-left: .4rem;
}

.ml-5-px {
    margin-left: 5px;
}

.mt-8 {
    margin-top: .8rem !important;
}

.mr-8 {
    margin-right: .8rem !important;
}

.mb-8 {
    margin-bottom: .8rem;
}

.ml-8 {
    margin-left: .8rem;
}

.mt-12 {
    margin-top: 1.2rem;
}

.mr-12 {
    margin-right: 1.2rem;
}

.mb-12 {
    margin-bottom: 1.2rem;
}

.ml-12 {
    margin-left: 1.2rem;
}

.mt-16 {
    margin-top: 1.6rem;
}

.mr-16 {
    margin-right: 1.6rem;
}

.mb-16 {
    margin-bottom: 1.6rem;
}

.ml-16 {
    margin-left: 1.6rem;
}

.mt-20 {
    margin-top: 2rem !important;
}

.mr-20 {
    margin-right: 2rem;
}

.mb-20 {
    margin-bottom: 2rem;
}

.ml-20 {
    margin-left: 2rem;
}

.mt-24 {
    margin-top: 2.4rem;
}

.mr-24 {
    margin-right: 2.4rem;
}

.mb-24 {
    margin-bottom: 2.4rem;
}

.ml-24 {
    margin-left: 2.4rem;
}

.mt-28 {
    margin-top: 2.8rem;
}

.mr-28 {
    margin-right: 2.8rem;
}

.mb-28 {
    margin-bottom: 2.8rem;
}

.ml-28 {
    margin-left: 2.8rem;
}

.mt-32 {
    margin-top: 3.2rem !important;
}

.mr-32 {
    margin-right: 3.2rem;
}

.mb-32 {
    margin-bottom: 3.2rem;
}

.ml-32 {
    margin-left: 3.2rem;
}

.mt-36 {
    margin-top: 3.6rem;
}

.mr-36 {
    margin-right: 3.6rem;
}

.mb-36 {
    margin-bottom: 3.6rem;
}

.ml-36 {
    margin-left: 3.6rem;
}

.mt-40 {
    margin-top: 4rem;
}

.mr-40 {
    margin-right: 4rem;
}

.mb-40 {
    margin-bottom: 4rem;
}

.ml-40 {
    margin-left: 4rem;
}

.mt-44 {
    margin-top: 4.4rem;
}

.mr-44 {
    margin-right: 4.4rem;
}

.mb-44 {
    margin-bottom: 4.4rem;
}

.ml-44 {
    margin-left: 4.4rem;
}

.mt-48 {
    margin-top: 4.8rem;
}

.mr-48 {
    margin-right: 4.8rem;
}

.mb-48 {
    margin-bottom: 4.8rem;
}

.ml-48 {
    margin-left: 4.8rem;
}

.mt-52 {
    margin-top: 5.2rem;
}

.mr-52 {
    margin-right: 5.2rem;
}

.mb-52 {
    margin-bottom: 5.2rem;
}

.ml-52 {
    margin-left: 5.2rem;
}

.mt-56 {
    margin-top: 5.6rem;
}

.mr-56 {
    margin-right: 5.6rem;
}

.mb-56 {
    margin-bottom: 5.6rem;
}

.ml-56 {
    margin-left: 5.6rem;
}

.mt-60 {
    margin-top: 6rem;
}

.mr-60 {
    margin-right: 6rem;
}

.mb-60 {
    margin-bottom: 6rem;
}

.ml-60 {
    margin-left: 6rem;
}

.mt-64 {
    margin-top: 6.4rem;
}

.mr-64 {
    margin-right: 6.4rem;
}

.mb-64 {
    margin-bottom: 6.4rem;
}

.ml-64 {
    margin-left: 6.4rem;
}

.mt-68 {
    margin-top: 6.8rem;
}

.mr-68 {
    margin-right: 6.8rem;
}

.mb-68 {
    margin-bottom: 6.8rem;
}

.ml-68 {
    margin-left: 6.8rem;
}

.mt-72 {
    margin-top: 7.2rem;
}

.mr-72 {
    margin-right: 7.2rem;
}

.mb-72 {
    margin-bottom: 7.2rem;
}

.ml-72 {
    margin-left: 7.2rem;
}

.mt-76 {
    margin-top: 7.6rem;
}

.mr-76 {
    margin-right: 7.6rem;
}

.mb-76 {
    margin-bottom: 7.6rem;
}

.ml-76 {
    margin-left: 7.6rem;
}

.mt-80 {
    margin-top: 8rem;
}

.mr-80 {
    margin-right: 8rem;
}

.mb-80 {
    margin-bottom: 8rem;
}

.ml-80 {
    margin-left: 8rem;
}

.mt-84 {
    margin-top: 8.4rem;
}

.mr-84 {
    margin-right: 8.4rem;
}

.mb-84 {
    margin-bottom: 8.4rem;
}

.ml-84 {
    margin-left: 8.4rem;
}

.mt-88 {
    margin-top: 8.8rem;
}

.mr-88 {
    margin-right: 8.8rem;
}

.mb-88 {
    margin-bottom: 8.8rem;
}

.ml-88 {
    margin-left: 8.8rem;
}

.mt-92 {
    margin-top: 9.2rem;
}

.mr-92 {
    margin-right: 9.2rem;
}

.mb-92 {
    margin-bottom: 9.2rem;
}

.ml-92 {
    margin-left: 9.2rem;
}

.mt-96 {
    margin-top: 9.6rem;
}

.mr-96 {
    margin-right: 9.6rem;
}

.mb-96 {
    margin-bottom: 9.6rem;
}

.ml-96 {
    margin-left: 9.6rem;
}

.mt-100 {
    margin-top: 10rem;
}

.mt-128 {
    margin-top: 12.8rem;
}

.mr-128 {
    margin-right: 12.8rem;
}

.mb-128 {
    margin-bottom: 12.8rem;
}

.ml-128 {
    margin-left: 12.8rem;
}

.mt-160 {
    margin-top: 16rem;
}

.mr-160 {
    margin-right: 16rem;
}

.mb-160 {
    margin-bottom: 16rem;
}

.ml-160 {
    margin-left: 16rem;
}

.mt-192 {
    margin-top: 19.2rem;
}

.mr-192 {
    margin-right: 19.2rem;
}

.mb-192 {
    margin-bottom: 19.2rem;
}

.ml-192 {
    margin-left: 19.2rem;
}

.mt-224 {
    margin-top: 22.4rem;
}

.mr-224 {
    margin-right: 22.4rem;
}

.mb-224 {
    margin-bottom: 22.4rem;
}

.ml-224 {
    margin-left: 22.4rem;
}

.mt-256 {
    margin-top: 25.6rem;
}

.mr-256 {
    margin-right: 25.6rem;
}

.mb-256 {
    margin-bottom: 25.6rem;
}

.ml-256 {
    margin-left: 25.6rem;
}

.mt-auto {
    margin-top: auto;
}

.mr-auto {
    margin-right: auto;
}

.mb-auto {
    margin-bottom: auto;
}

.ml-auto {
    margin-left: auto;
}

.mt-px {
    margin-top: 1px;
}

.mr-px {
    margin-right: 1px;
}

.mb-px {
    margin-bottom: 1px;
}

.ml-px {
    margin-left: 1px;
}

.max-h-4 {
    max-height: .4rem;
}

.max-h-8 {
    max-height: .8rem;
}

.max-h-12 {
    max-height: 1.2rem;
}

.max-h-16 {
    max-height: 1.6rem;
}

.max-h-20 {
    max-height: 2rem;
}

.max-h-24 {
    max-height: 2.4rem;
}

.max-h-28 {
    max-height: 2.8rem;
}

.max-h-32 {
    max-height: 3.2rem;
}

.max-h-36 {
    max-height: 3.6rem;
}

.max-h-40 {
    max-height: 4rem;
}

.max-h-44 {
    max-height: 4.4rem;
}

.max-h-48 {
    max-height: 4.8rem;
}

.max-h-52 {
    max-height: 5.2rem;
}

.max-h-56 {
    max-height: 5.6rem;
}

.max-h-60 {
    max-height: 6rem;
}

.max-h-64 {
    max-height: 6.4rem;
}

.max-h-68 {
    max-height: 6.8rem;
}

.max-h-72 {
    max-height: 7.2rem;
}

.max-h-76 {
    max-height: 7.6rem;
}

.max-h-80 {
    max-height: 8rem;
}

.max-h-84 {
    max-height: 8.4rem;
}

.max-h-88 {
    max-height: 8.8rem;
}

.max-h-92 {
    max-height: 9.2rem;
}

.max-h-96 {
    max-height: 9.6rem;
}

.max-h-128 {
    max-height: 12.8rem;
}

.max-h-160 {
    max-height: 16rem;
}

.max-h-192 {
    max-height: 19.2rem;
}

.max-h-224 {
    max-height: 22.4rem;
}

.max-h-256 {
    max-height: 25.6rem;
}

.max-h-288 {
    max-height: 28.8rem;
}

.max-h-320 {
    max-height: 32rem;
}

.max-h-512 {
    max-height: 51.2rem;
}

.max-h-full {
    max-height: 100%;
}

.max-h-screen {
    max-height: 100vh;
}

.max-w-4 {
    max-width: .4rem;
}

.max-w-8 {
    max-width: .8rem;
}

.max-w-12 {
    max-width: 1.2rem;
}

.max-w-16 {
    max-width: 1.6rem;
}

.max-w-20 {
    max-width: 2rem;
}

.max-w-24 {
    max-width: 2.4rem;
}

.max-w-28 {
    max-width: 2.8rem;
}

.max-w-32 {
    max-width: 3.2rem;
}

.max-w-36 {
    max-width: 3.6rem;
}

.max-w-40 {
    max-width: 4rem;
}

.max-w-44 {
    max-width: 4.4rem;
}

.max-w-48 {
    max-width: 4.8rem;
}

.max-w-52 {
    max-width: 5.2rem;
}

.max-w-56 {
    max-width: 5.6rem;
}

.max-w-60 {
    max-width: 6rem;
}

.max-w-64 {
    max-width: 6.4rem;
}

.max-w-96 {
    max-width: 9.6rem;
}

.max-w-128 {
    max-width: 12.8rem;
}

.max-w-160 {
    max-width: 16rem;
}

.max-w-192 {
    max-width: 19.2rem;
}

.max-w-224 {
    max-width: 22.4rem;
}

.max-w-256 {
    max-width: 25.6rem;
}

.max-w-288 {
    max-width: 28.8rem;
}

.max-w-320 {
    max-width: 32rem;
}

.max-w-512 {
    max-width: 51.2rem;
}

.max-w-xs {
    max-width: 32rem;
}

.max-w-sm {
    max-width: 48rem;
}

.max-w-md {
    max-width: 64rem;
}

.max-w-lg {
    max-width: 80rem;
}

.max-w-xl {
    max-width: 96rem;
}

.max-w-2xl {
    max-width: 112rem;
}

.max-w-3xl {
    max-width: 128rem;
}

.max-w-4xl {
    max-width: 144rem;
}

.max-w-5xl {
    max-width: 160rem;
}

.max-w-full {
    max-width: 100%;
}

.min-h-4 {
    min-height: .4rem;
}

.min-h-8 {
    min-height: .8rem;
}

.min-h-12 {
    min-height: 1.2rem;
}

.min-h-16 {
    min-height: 1.6rem;
}

.min-h-20 {
    min-height: 2rem;
}

.min-h-24 {
    min-height: 2.4rem;
}

.min-h-28 {
    min-height: 2.8rem;
}

.min-h-32 {
    min-height: 3.2rem;
}

.min-h-36 {
    min-height: 3.6rem;
}

.min-h-40 {
    min-height: 4rem;
}

.min-h-44 {
    min-height: 4.4rem;
}

.min-h-48 {
    min-height: 4.8rem;
}

.min-h-52 {
    min-height: 5.2rem;
}

.min-h-56 {
    min-height: 5.6rem;
}

.min-h-60 {
    min-height: 6rem;
}

.min-h-64 {
    min-height: 6.4rem;
}

.min-h-68 {
    min-height: 6.8rem;
}

.min-h-72 {
    min-height: 7.2rem;
}

.min-h-76 {
    min-height: 7.6rem;
}

.min-h-80 {
    min-height: 8rem;
}

.min-h-84 {
    min-height: 8.4rem;
}

.min-h-88 {
    min-height: 8.8rem;
}

.min-h-92 {
    min-height: 9.2rem;
}

.min-h-96 {
    min-height: 9.6rem;
}

.min-h-128 {
    min-height: 12.8rem;
}

.min-h-160 {
    min-height: 16rem;
}

.min-h-192 {
    min-height: 19.2rem;
}

.min-h-224 {
    min-height: 22.4rem;
}

.min-h-256 {
    min-height: 25.6rem;
}

.min-h-288 {
    min-height: 28.8rem;
}

.min-h-320 {
    min-height: 32rem;
}

.min-h-512 {
    min-height: 51.2rem;
}

.min-h-auto {
    min-height: auto;
}

.min-h-px {
    min-height: 1px;
}

.min-h-full {
    min-height: 100%;
}

.min-h-screen {
    min-height: 100vh;
}

.min-w-0 {
    min-width: 0;
}

.min-w-4 {
    min-width: .4rem;
}

.min-w-8 {
    min-width: .8rem;
}

.min-w-12 {
    min-width: 1.2rem;
}

.min-w-16 {
    min-width: 1.6rem;
}

.min-w-20 {
    min-width: 2rem;
}

.min-w-24 {
    min-width: 2.4rem;
}

.min-w-28 {
    min-width: 2.8rem;
}

.min-w-32 {
    min-width: 3.2rem;
}

.min-w-36 {
    min-width: 3.6rem;
}

.min-w-40 {
    min-width: 4rem;
}

.min-w-44 {
    min-width: 4.4rem;
}

.min-w-48 {
    min-width: 4.8rem;
}

.min-w-52 {
    min-width: 5.2rem;
}

.min-w-56 {
    min-width: 5.6rem;
}

.min-w-60 {
    min-width: 6rem;
}

.min-w-64 {
    min-width: 6.4rem;
}

.min-w-68 {
    min-width: 6.8rem;
}

.min-w-72 {
    min-width: 7.2rem;
}

.min-w-76 {
    min-width: 7.6rem;
}

.min-w-80 {
    min-width: 8rem;
}

.min-w-84 {
    min-width: 8.4rem;
}

.min-w-88 {
    min-width: 8.8rem;
}

.min-w-92 {
    min-width: 9.2rem;
}

.min-w-96 {
    min-width: 9.6rem;
}

.min-w-128 {
    min-width: 12.8rem;
}

.min-w-160 {
    min-width: 16rem;
}

.min-w-192 {
    min-width: 19.2rem;
}

.min-w-224 {
    min-width: 22.4rem;
}

.min-w-256 {
    min-width: 25.6rem;
}

.min-w-288 {
    min-width: 28.8rem;
}

.min-w-320 {
    min-width: 32rem;
}

.min-w-512 {
    min-width: 51.2rem;
}

.min-w-auto {
    min-width: auto;
}

.min-w-px {
    min-width: 1px;
}

.min-w-1\/2 {
    min-width: 50%;
}

.min-w-1\/3 {
    min-width: 33.33333%;
}

.min-w-2\/3 {
    min-width: 66.66667%;
}

.min-w-1\/4 {
    min-width: 25%;
}

.min-w-3\/4 {
    min-width: 75%;
}

.min-w-1\/5 {
    min-width: 20%;
}

.min-w-2\/5 {
    min-width: 40%;
}

.min-w-3\/5 {
    min-width: 60%;
}

.min-w-4\/5 {
    min-width: 80%;
}

.min-w-1\/6 {
    min-width: 16.66667%;
}

.min-w-5\/6 {
    min-width: 83.33333%;
}

.min-w-full {
    min-width: 100%;
}

.min-w-screen {
    min-width: 100vw;
}

.-m-0 {
    margin: 0;
}

.-m-4 {
    margin: -0.4rem;
}

.-m-8 {
    margin: -0.8rem;
}

.-m-12 {
    margin: -1.2rem;
}

.-m-16 {
    margin: -1.6rem;
}

.-m-20 {
    margin: -2rem;
}

.-m-24 {
    margin: -2.4rem;
}

.-m-28 {
    margin: -2.8rem;
}

.-m-32 {
    margin: -3.2rem;
}

.-m-36 {
    margin: -3.6rem;
}

.-m-40 {
    margin: -4rem;
}

.-m-44 {
    margin: -4.4rem;
}

.-m-48 {
    margin: -4.8rem;
}

.-m-52 {
    margin: -5.2rem;
}

.-m-56 {
    margin: -5.6rem;
}

.-m-60 {
    margin: -6rem;
}

.-m-64 {
    margin: -6.4rem;
}

.-m-68 {
    margin: -6.8rem;
}

.-m-72 {
    margin: -7.2rem;
}

.-m-76 {
    margin: -7.6rem;
}

.-m-80 {
    margin: -8rem;
}

.-m-84 {
    margin: -8.4rem;
}

.-m-88 {
    margin: -8.8rem;
}

.-m-92 {
    margin: -9.2rem;
}

.-m-96 {
    margin: -9.6rem;
}

.-m-128 {
    margin: -12.8rem;
}

.-m-160 {
    margin: -16rem;
}

.-m-192 {
    margin: -19.2rem;
}

.-m-224 {
    margin: -22.4rem;
}

.-m-256 {
    margin: -25.6rem;
}

.-m-px {
    margin: -1px;
}

.-my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.-mx-0 {
    margin-left: 0;
    margin-right: 0;
}

.-my-4 {
    margin-top: -0.4rem;
    margin-bottom: -0.4rem;
}

.-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem;
}

.-my-8 {
    margin-top: -0.8rem;
    margin-bottom: -0.8rem;
}

.-mx-8 {
    margin-left: -0.8rem;
    margin-right: -0.8rem;
}

.-my-12 {
    margin-top: -1.2rem;
    margin-bottom: -1.2rem;
}

.-mx-12 {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
}

.-my-16 {
    margin-top: -1.6rem;
    margin-bottom: -1.6rem;
}

.-mx-16 {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
}

.-my-20 {
    margin-top: -2rem;
    margin-bottom: -2rem;
}

.-mx-20 {
    margin-left: -2rem;
    margin-right: -2rem;
}

.-my-24 {
    margin-top: -2.4rem;
    margin-bottom: -2.4rem;
}

.-mx-24 {
    margin-left: -2.4rem;
    margin-right: -2.4rem;
}

.-my-28 {
    margin-top: -2.8rem;
    margin-bottom: -2.8rem;
}

.-mx-28 {
    margin-left: -2.8rem;
    margin-right: -2.8rem;
}

.-my-32 {
    margin-top: -3.2rem;
    margin-bottom: -3.2rem;
}

.-mx-32 {
    margin-left: -3.2rem;
    margin-right: -3.2rem;
}

.-my-36 {
    margin-top: -3.6rem;
    margin-bottom: -3.6rem;
}

.-mx-36 {
    margin-left: -3.6rem;
    margin-right: -3.6rem;
}

.-my-40 {
    margin-top: -4rem;
    margin-bottom: -4rem;
}

.-mx-40 {
    margin-left: -4rem;
    margin-right: -4rem;
}

.-my-44 {
    margin-top: -4.4rem;
    margin-bottom: -4.4rem;
}

.-mx-44 {
    margin-left: -4.4rem;
    margin-right: -4.4rem;
}

.-my-48 {
    margin-top: -4.8rem;
    margin-bottom: -4.8rem;
}

.-mx-48 {
    margin-left: -4.8rem;
    margin-right: -4.8rem;
}

.-my-52 {
    margin-top: -5.2rem;
    margin-bottom: -5.2rem;
}

.-mx-52 {
    margin-left: -5.2rem;
    margin-right: -5.2rem;
}

.-my-56 {
    margin-top: -5.6rem;
    margin-bottom: -5.6rem;
}

.-mx-56 {
    margin-left: -5.6rem;
    margin-right: -5.6rem;
}

.-my-60 {
    margin-top: -6rem;
    margin-bottom: -6rem;
}

.-mx-60 {
    margin-left: -6rem;
    margin-right: -6rem;
}

.-my-64 {
    margin-top: -6.4rem;
    margin-bottom: -6.4rem;
}

.-mx-64 {
    margin-left: -6.4rem;
    margin-right: -6.4rem;
}

.-my-68 {
    margin-top: -6.8rem;
    margin-bottom: -6.8rem;
}

.-mx-68 {
    margin-left: -6.8rem;
    margin-right: -6.8rem;
}

.-my-72 {
    margin-top: -7.2rem;
    margin-bottom: -7.2rem;
}

.-mx-72 {
    margin-left: -7.2rem;
    margin-right: -7.2rem;
}

.-my-76 {
    margin-top: -7.6rem;
    margin-bottom: -7.6rem;
}

.-mx-76 {
    margin-left: -7.6rem;
    margin-right: -7.6rem;
}

.-my-80 {
    margin-top: -8rem;
    margin-bottom: -8rem;
}

.-mx-80 {
    margin-left: -8rem;
    margin-right: -8rem;
}

.-my-84 {
    margin-top: -8.4rem;
    margin-bottom: -8.4rem;
}

.-mx-84 {
    margin-left: -8.4rem;
    margin-right: -8.4rem;
}

.-my-88 {
    margin-top: -8.8rem;
    margin-bottom: -8.8rem;
}

.-mx-88 {
    margin-left: -8.8rem;
    margin-right: -8.8rem;
}

.-my-92 {
    margin-top: -9.2rem;
    margin-bottom: -9.2rem;
}

.-mx-92 {
    margin-left: -9.2rem;
    margin-right: -9.2rem;
}

.-my-96 {
    margin-top: -9.6rem;
    margin-bottom: -9.6rem;
}

.-mx-96 {
    margin-left: -9.6rem;
    margin-right: -9.6rem;
}

.-my-128 {
    margin-top: -12.8rem;
    margin-bottom: -12.8rem;
}

.-mx-128 {
    margin-left: -12.8rem;
    margin-right: -12.8rem;
}

.-my-160 {
    margin-top: -16rem;
    margin-bottom: -16rem;
}

.-mx-160 {
    margin-left: -16rem;
    margin-right: -16rem;
}

.-my-192 {
    margin-top: -19.2rem;
    margin-bottom: -19.2rem;
}

.-mx-192 {
    margin-left: -19.2rem;
    margin-right: -19.2rem;
}

.-my-224 {
    margin-top: -22.4rem;
    margin-bottom: -22.4rem;
}

.-mx-224 {
    margin-left: -22.4rem;
    margin-right: -22.4rem;
}

.-my-256 {
    margin-top: -25.6rem;
    margin-bottom: -25.6rem;
}

.-mx-256 {
    margin-left: -25.6rem;
    margin-right: -25.6rem;
}

.-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
}

.-mx-px {
    margin-left: -1px;
    margin-right: -1px;
}

.-mt-0 {
    margin-top: 0;
}

.-mr-0 {
    margin-right: 0;
}

.-mb-0 {
    margin-bottom: 0;
}

.-ml-0 {
    margin-left: 0;
}

.-mt-4 {
    margin-top: -0.4rem;
}

.-mr-4 {
    margin-right: -0.4rem;
}

.-mb-4 {
    margin-bottom: -0.4rem;
}

.-ml-4 {
    margin-left: -0.4rem;
}

.-mt-8 {
    margin-top: -0.8rem;
}

.-mr-8 {
    margin-right: -0.8rem;
}

.-mb-8 {
    margin-bottom: -0.8rem;
}

.-ml-8 {
    margin-left: -0.8rem;
}

.-mt-12 {
    margin-top: -1.2rem;
}

.-mr-12 {
    margin-right: -1.2rem;
}

.-mb-12 {
    margin-bottom: -1.2rem;
}

.-ml-12 {
    margin-left: -1.2rem;
}

.-mt-16 {
    margin-top: -1.6rem;
}

.-mr-16 {
    margin-right: -1.6rem;
}

.-mb-16 {
    margin-bottom: -1.6rem;
}

.-ml-16 {
    margin-left: -1.6rem;
}

.-mt-20 {
    margin-top: -2rem;
}

.-mr-20 {
    margin-right: -2rem;
}

.-mb-20 {
    margin-bottom: -2rem;
}

.-ml-20 {
    margin-left: -2rem;
}

.-mt-24 {
    margin-top: -2.4rem;
}

.-mr-24 {
    margin-right: -2.4rem;
}

.-mb-24 {
    margin-bottom: -2.4rem;
}

.-ml-24 {
    margin-left: -2.4rem;
}

.-mt-28 {
    margin-top: -2.8rem;
}

.-mr-28 {
    margin-right: -2.8rem;
}

.-mb-28 {
    margin-bottom: -2.8rem;
}

.-ml-28 {
    margin-left: -2.8rem;
}

.-mt-32 {
    margin-top: -3.2rem;
}

.-mr-32 {
    margin-right: -3.2rem;
}

.-mb-32 {
    margin-bottom: -3.2rem;
}

.-ml-32 {
    margin-left: -3.2rem;
}

.-mt-36 {
    margin-top: -3.6rem;
}

.-mr-36 {
    margin-right: -3.6rem;
}

.-mb-36 {
    margin-bottom: -3.6rem;
}

.-ml-36 {
    margin-left: -3.6rem;
}

.-mt-40 {
    margin-top: -4rem;
}

.-mr-40 {
    margin-right: -4rem;
}

.-mb-40 {
    margin-bottom: -4rem;
}

.-ml-40 {
    margin-left: -4rem;
}

.-mt-44 {
    margin-top: -4.4rem;
}

.-mr-44 {
    margin-right: -4.4rem;
}

.-mb-44 {
    margin-bottom: -4.4rem;
}

.-ml-44 {
    margin-left: -4.4rem;
}

.-mt-48 {
    margin-top: -4.8rem;
}

.-mr-48 {
    margin-right: -4.8rem;
}

.-mb-48 {
    margin-bottom: -4.8rem;
}

.-ml-48 {
    margin-left: -4.8rem;
}

.-mt-52 {
    margin-top: -5.2rem;
}

.-mr-52 {
    margin-right: -5.2rem;
}

.-mb-52 {
    margin-bottom: -5.2rem;
}

.-ml-52 {
    margin-left: -5.2rem;
}

.-mt-56 {
    margin-top: -5.6rem;
}

.-mr-56 {
    margin-right: -5.6rem;
}

.-mb-56 {
    margin-bottom: -5.6rem;
}

.-ml-56 {
    margin-left: -5.6rem;
}

.-mt-60 {
    margin-top: -6rem;
}

.-mr-60 {
    margin-right: -6rem;
}

.-mb-60 {
    margin-bottom: -6rem;
}

.-ml-60 {
    margin-left: -6rem;
}

.-mt-64 {
    margin-top: -6.4rem;
}

.-mr-64 {
    margin-right: -6.4rem;
}

.-mb-64 {
    margin-bottom: -6.4rem;
}

.-ml-64 {
    margin-left: -6.4rem;
}

.-mt-68 {
    margin-top: -6.8rem;
}

.-mr-68 {
    margin-right: -6.8rem;
}

.-mb-68 {
    margin-bottom: -6.8rem;
}

.-ml-68 {
    margin-left: -6.8rem;
}

.-mt-72 {
    margin-top: -7.2rem;
}

.-mr-72 {
    margin-right: -7.2rem;
}

.-mb-72 {
    margin-bottom: -7.2rem;
}

.-ml-72 {
    margin-left: -7.2rem;
}

.-mt-76 {
    margin-top: -7.6rem;
}

.-mr-76 {
    margin-right: -7.6rem;
}

.-mb-76 {
    margin-bottom: -7.6rem;
}

.-ml-76 {
    margin-left: -7.6rem;
}

.-mt-80 {
    margin-top: -8rem;
}

.-mr-80 {
    margin-right: -8rem;
}

.-mb-80 {
    margin-bottom: -8rem;
}

.-ml-80 {
    margin-left: -8rem;
}

.-mt-84 {
    margin-top: -8.4rem;
}

.-mr-84 {
    margin-right: -8.4rem;
}

.-mb-84 {
    margin-bottom: -8.4rem;
}

.-ml-84 {
    margin-left: -8.4rem;
}

.-mt-88 {
    margin-top: -8.8rem;
}

.-mr-88 {
    margin-right: -8.8rem;
}

.-mb-88 {
    margin-bottom: -8.8rem;
}

.-ml-88 {
    margin-left: -8.8rem;
}

.-mt-92 {
    margin-top: -9.2rem;
}

.-mr-92 {
    margin-right: -9.2rem;
}

.-mb-92 {
    margin-bottom: -9.2rem;
}

.-ml-92 {
    margin-left: -9.2rem;
}

.-mt-96 {
    margin-top: -9.6rem;
}

.-mr-96 {
    margin-right: -9.6rem;
}

.-mb-96 {
    margin-bottom: -9.6rem;
}

.-ml-96 {
    margin-left: -9.6rem;
}

.-mt-128 {
    margin-top: -12.8rem;
}

.-mr-128 {
    margin-right: -12.8rem;
}

.-mb-128 {
    margin-bottom: -12.8rem;
}

.-ml-128 {
    margin-left: -12.8rem;
}

.-mt-160 {
    margin-top: -16rem;
}

.-mr-160 {
    margin-right: -16rem;
}

.-mb-160 {
    margin-bottom: -16rem;
}

.-ml-160 {
    margin-left: -16rem;
}

.-mt-192 {
    margin-top: -19.2rem;
}

.-mr-192 {
    margin-right: -19.2rem;
}

.-mb-192 {
    margin-bottom: -19.2rem;
}

.-ml-192 {
    margin-left: -19.2rem;
}

.-mt-224 {
    margin-top: -22.4rem;
}

.-mr-224 {
    margin-right: -22.4rem;
}

.-mb-224 {
    margin-bottom: -22.4rem;
}

.-ml-224 {
    margin-left: -22.4rem;
}

.-mt-256 {
    margin-top: -25.6rem;
}

.-mr-256 {
    margin-right: -25.6rem;
}

.-mb-256 {
    margin-bottom: -25.6rem;
}

.-ml-256 {
    margin-left: -25.6rem;
}

.-mt-px {
    margin-top: -1px;
}

.-mr-px {
    margin-right: -1px;
}

.-mb-px {
    margin-bottom: -1px;
}

.-ml-px {
    margin-left: -1px;
}

.opacity-0 {
    opacity: 0;
}

.opacity-25 {
    opacity: .25;
}

.opacity-50 {
    opacity: .5;
}

.opacity-75 {
    opacity: .75;
}

.opacity-100 {
    opacity: 1;
}

.outline-none {
    outline: 0;
}

.focus\:outline-none:focus {
    outline: 0;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-visible {
    overflow: visible;
}

.overflow-scroll {
    overflow: scroll;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-x-visible {
    overflow-x: visible;
}

.overflow-y-visible {
    overflow-y: visible;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.scrolling-touch {
    -webkit-overflow-scrolling: touch;
}

.scrolling-auto {
    -webkit-overflow-scrolling: auto;
}

.p-0 {
    padding: 0;
}

.p-4 {
    padding: .4rem;
}

.p-8 {
    padding: .8rem;
}

.p-12 {
    padding: 1.2rem;
}

.p-16 {
    padding: 1.6rem;
}

.p-20 {
    padding: 2rem;
}

.p-24 {
    padding: 2.4rem;
}

.p-28 {
    padding: 2.8rem;
}

.p-32 {
    padding: 3.2rem;
}

.p-36 {
    padding: 3.6rem;
}

.p-40 {
    padding: 4rem;
}

.p-44 {
    padding: 4.4rem;
}

.p-48 {
    padding: 4.8rem;
}

.p-52 {
    padding: 5.2rem;
}

.p-56 {
    padding: 5.6rem;
}

.p-60 {
    padding: 6rem;
}

.p-64 {
    padding: 6.4rem;
}

.p-68 {
    padding: 6.8rem;
}

.p-72 {
    padding: 7.2rem;
}

.p-76 {
    padding: 7.6rem;
}

.p-80 {
    padding: 8rem;
}

.p-84 {
    padding: 8.4rem;
}

.p-88 {
    padding: 8.8rem;
}

.p-92 {
    padding: 9.2rem;
}

.p-96 {
    padding: 9.6rem;
}

.p-128 {
    padding: 12.8rem;
}

.p-160 {
    padding: 16rem;
}

.p-192 {
    padding: 19.2rem;
}

.p-224 {
    padding: 22.4rem;
}

.p-256 {
    padding: 25.6rem;
}

.p-px {
    padding: 1px;
}

.py-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.px-0 {
    padding-left: 0;
    padding-right: 0;
}

.py-4 {
    padding-top: .4rem;
    padding-bottom: .4rem;
}

.px-4 {
    padding-left: .4rem;
    padding-right: .4rem;
}

.py-8 {
    padding-top: .8rem;
    padding-bottom: .8rem;
}

.px-8 {
    padding-left: .8rem;
    padding-right: .8rem;
}

.py-12 {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
}

.px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
}

.py-16 {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
}

.px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
}

.py-20 {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.px-20 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.py-24 {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
}

.px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}

.py-28 {
    padding-top: 2.8rem;
    padding-bottom: 2.8rem;
}

.px-28 {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
}

.py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
}

.px-32 {
    padding-left: 3.2rem;
    padding-right: 3.2rem;
}

.py-36 {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
}

.px-36 {
    padding-left: 3.6rem;
    padding-right: 3.6rem;
}

.py-40 {
    padding-top: 4rem;
    padding-bottom: 4rem;
}

.px-40 {
    padding-left: 4rem;
    padding-right: 4rem;
}

.py-44 {
    padding-top: 4.4rem;
    padding-bottom: 4.4rem;
}

.px-44 {
    padding-left: 4.4rem;
    padding-right: 4.4rem;
}

.py-48 {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem;
}

.px-48 {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
}

.py-52 {
    padding-top: 5.2rem;
    padding-bottom: 5.2rem;
}

.px-52 {
    padding-left: 5.2rem;
    padding-right: 5.2rem;
}

.py-56 {
    padding-top: 5.6rem;
    padding-bottom: 5.6rem;
}

.px-56 {
    padding-left: 5.6rem;
    padding-right: 5.6rem;
}

.py-60 {
    padding-top: 6rem;
    padding-bottom: 6rem;
}

.px-60 {
    padding-left: 6rem;
    padding-right: 6rem;
}

.py-64 {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
}

.px-64 {
    padding-left: 6.4rem;
    padding-right: 6.4rem;
}

.py-68 {
    padding-top: 6.8rem;
    padding-bottom: 6.8rem;
}

.px-68 {
    padding-left: 6.8rem;
    padding-right: 6.8rem;
}

.py-72 {
    padding-top: 7.2rem;
    padding-bottom: 7.2rem;
}

.px-72 {
    padding-left: 7.2rem;
    padding-right: 7.2rem;
}

.py-76 {
    padding-top: 7.6rem;
    padding-bottom: 7.6rem;
}

.px-76 {
    padding-left: 7.6rem;
    padding-right: 7.6rem;
}

.py-80 {
    padding-top: 8rem;
    padding-bottom: 8rem;
}

.px-80 {
    padding-left: 8rem;
    padding-right: 8rem;
}

.py-84 {
    padding-top: 8.4rem;
    padding-bottom: 8.4rem;
}

.px-84 {
    padding-left: 8.4rem;
    padding-right: 8.4rem;
}

.py-88 {
    padding-top: 8.8rem;
    padding-bottom: 8.8rem;
}

.px-88 {
    padding-left: 8.8rem;
    padding-right: 8.8rem;
}

.py-92 {
    padding-top: 9.2rem;
    padding-bottom: 9.2rem;
}

.px-92 {
    padding-left: 9.2rem;
    padding-right: 9.2rem;
}

.py-96 {
    padding-top: 9.6rem;
    padding-bottom: 9.6rem;
}

.px-96 {
    padding-left: 9.6rem;
    padding-right: 9.6rem;
}

.py-128 {
    padding-top: 12.8rem;
    padding-bottom: 12.8rem;
}

.px-128 {
    padding-left: 12.8rem;
    padding-right: 12.8rem;
}

.py-160 {
    padding-top: 16rem;
    padding-bottom: 16rem;
}

.px-160 {
    padding-left: 16rem;
    padding-right: 16rem;
}

.py-192 {
    padding-top: 19.2rem;
    padding-bottom: 19.2rem;
}

.px-192 {
    padding-left: 19.2rem;
    padding-right: 19.2rem;
}

.py-224 {
    padding-top: 22.4rem;
    padding-bottom: 22.4rem;
}

.px-224 {
    padding-left: 22.4rem;
    padding-right: 22.4rem;
}

.py-256 {
    padding-top: 25.6rem;
    padding-bottom: 25.6rem;
}

.px-256 {
    padding-left: 25.6rem;
    padding-right: 25.6rem;
}

.py-px {
    padding-top: 1px;
    padding-bottom: 1px;
}

.px-px {
    padding-left: 1px;
    padding-right: 1px;
}

.pt-0 {
    padding-top: 0;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pl-0 {
    padding-left: 0;
}

.pt-4 {
    padding-top: .4rem;
}

.pr-4 {
    padding-right: .4rem;
}

.pb-4 {
    padding-bottom: .4rem;
}

.pl-4 {
    padding-left: .4rem;
}

.pt-8 {
    padding-top: .8rem;
}

.pr-8 {
    padding-right: .8rem;
}

.pb-8 {
    padding-bottom: .8rem;
}

.pl-8 {
    padding-left: .8rem;
}

.pt-12 {
    padding-top: 1.2rem;
}

.pr-12 {
    padding-right: 1.2rem;
}

.pb-12 {
    padding-bottom: 1.2rem;
}

.pl-12 {
    padding-left: 1.2rem;
}

.pt-16 {
    padding-top: 1.6rem;
}

.pr-16 {
    padding-right: 1.6rem;
}

.pb-16 {
    padding-bottom: 1.6rem;
}

.pl-16 {
    padding-left: 1.6rem;
}

.pt-20 {
    padding-top: 2rem;
}

.pr-20 {
    padding-right: 2rem;
}

.pb-20 {
    padding-bottom: 2rem;
}

.pl-20 {
    padding-left: 2rem;
}

.pt-24 {
    padding-top: 2.4rem;
}

.pr-24 {
    padding-right: 2.4rem;
}

.pb-24 {
    padding-bottom: 2.4rem;
}

.pl-24 {
    padding-left: 2.4rem;
}

.pt-28 {
    padding-top: 2.8rem;
}

.pr-28 {
    padding-right: 2.8rem;
}

.pb-28 {
    padding-bottom: 2.8rem;
}

.pl-28 {
    padding-left: 2.8rem;
}

.pt-32 {
    padding-top: 3.2rem;
}

.pr-32 {
    padding-right: 3.2rem;
}

.pb-32 {
    padding-bottom: 3.2rem;
}

.pl-32 {
    padding-left: 3.2rem;
}

.pt-36 {
    padding-top: 3.6rem;
}

.pr-36 {
    padding-right: 3.6rem;
}

.pb-36 {
    padding-bottom: 3.6rem;
}

.pl-36 {
    padding-left: 3.6rem;
}

.pt-40 {
    padding-top: 4rem;
}

.pr-40 {
    padding-right: 4rem;
}

.pb-40 {
    padding-bottom: 4rem;
}

.pl-40 {
    padding-left: 4rem;
}

.pt-44 {
    padding-top: 4.4rem;
}

.pr-44 {
    padding-right: 4.4rem;
}

.pb-44 {
    padding-bottom: 4.4rem;
}

.pl-44 {
    padding-left: 4.4rem;
}

.pt-48 {
    padding-top: 4.8rem;
}

.pr-48 {
    padding-right: 4.8rem;
}

.pb-48 {
    padding-bottom: 4.8rem;
}

.pl-48 {
    padding-left: 4.8rem;
}

.pt-52 {
    padding-top: 5.2rem;
}

.pr-52 {
    padding-right: 5.2rem;
}

.pb-52 {
    padding-bottom: 5.2rem;
}

.pl-52 {
    padding-left: 5.2rem;
}

.pt-56 {
    padding-top: 5.6rem;
}

.pr-56 {
    padding-right: 5.6rem;
}

.pb-56 {
    padding-bottom: 5.6rem;
}

.pl-56 {
    padding-left: 5.6rem;
}

.pt-60 {
    padding-top: 6rem;
}

.pr-60 {
    padding-right: 6rem;
}

.pb-60 {
    padding-bottom: 6rem;
}

.pl-60 {
    padding-left: 6rem;
}

.pt-64 {
    padding-top: 6.4rem;
}

.pr-64 {
    padding-right: 6.4rem;
}

.pb-64 {
    padding-bottom: 6.4rem;
}

.pl-64 {
    padding-left: 6.4rem;
}

.pt-68 {
    padding-top: 6.8rem;
}

.pr-68 {
    padding-right: 6.8rem;
}

.pb-68 {
    padding-bottom: 6.8rem;
}

.pl-68 {
    padding-left: 6.8rem;
}

.pt-72 {
    padding-top: 7.2rem;
}

.pr-72 {
    padding-right: 7.2rem;
}

.pb-72 {
    padding-bottom: 7.2rem;
}

.pl-72 {
    padding-left: 7.2rem;
}

.pt-76 {
    padding-top: 7.6rem;
}

.pr-76 {
    padding-right: 7.6rem;
}

.pb-76 {
    padding-bottom: 7.6rem;
}

.pl-76 {
    padding-left: 7.6rem;
}

.pt-80 {
    padding-top: 8rem;
}

.pr-80 {
    padding-right: 8rem;
}

.pb-80 {
    padding-bottom: 8rem;
}

.pl-80 {
    padding-left: 8rem;
}

.pt-84 {
    padding-top: 8.4rem;
}

.pr-84 {
    padding-right: 8.4rem;
}

.pb-84 {
    padding-bottom: 8.4rem;
}

.pl-84 {
    padding-left: 8.4rem;
}

.pt-88 {
    padding-top: 8.8rem;
}

.pr-88 {
    padding-right: 8.8rem;
}

.pb-88 {
    padding-bottom: 8.8rem;
}

.pl-88 {
    padding-left: 8.8rem;
}

.pt-92 {
    padding-top: 9.2rem;
}

.pr-92 {
    padding-right: 9.2rem;
}

.pb-92 {
    padding-bottom: 9.2rem;
}

.pl-92 {
    padding-left: 9.2rem;
}

.pt-96 {
    padding-top: 9.6rem;
}

.pr-96 {
    padding-right: 9.6rem;
}

.pb-96 {
    padding-bottom: 9.6rem;
}

.pl-96 {
    padding-left: 9.6rem;
}

.pt-128 {
    padding-top: 12.8rem;
}

.pr-128 {
    padding-right: 12.8rem;
}

.pb-128 {
    padding-bottom: 12.8rem;
}

.pl-128 {
    padding-left: 12.8rem;
}

.pt-160 {
    padding-top: 16rem;
}

.pr-160 {
    padding-right: 16rem;
}

.pb-160 {
    padding-bottom: 16rem;
}

.pl-160 {
    padding-left: 16rem;
}

.pt-192 {
    padding-top: 19.2rem;
}

.pr-192 {
    padding-right: 19.2rem;
}

.pb-192 {
    padding-bottom: 19.2rem;
}

.pl-192 {
    padding-left: 19.2rem;
}

.pt-224 {
    padding-top: 22.4rem;
}

.pr-224 {
    padding-right: 22.4rem;
}

.pb-224 {
    padding-bottom: 22.4rem;
}

.pl-224 {
    padding-left: 22.4rem;
}

.pt-256 {
    padding-top: 25.6rem;
}

.pr-256 {
    padding-right: 25.6rem;
}

.pb-256 {
    padding-bottom: 25.6rem;
}

.pl-256 {
    padding-left: 25.6rem;
}

.pt-px {
    padding-top: 1px;
}

.pr-px {
    padding-right: 1px;
}

.pb-px {
    padding-bottom: 1px;
}

.pl-px {
    padding-left: 1px;
}

.pointer-events-none {
    pointer-events: none;
}

.pointer-events-auto {
    pointer-events: auto;
}

.static {
    position: static;
}

.fixed {
    position: fixed;
}

.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.pin-none {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
}

.pin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.pin-y {
    top: 0;
    bottom: 0;
}

.pin-x {
    right: 0;
    left: 0;
}

.pin-t {
    top: 0;
}

.pin-r {
    right: 0;
}

.pin-b {
    bottom: 0;
}

.pin-l {
    left: 0;
}

.resize-none {
    resize: none;
}

.resize-y {
    resize: vertical;
}

.resize-x {
    resize: horizontal;
}

.resize {
    resize: both;
}

.shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
}

.shadow-md {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
}

.shadow-lg {
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
}

.shadow-inner {
    box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
}

.shadow-none {
    box-shadow: none;
}

.fill-current {
    fill: currentColor;
}

.stroke-current {
    stroke: currentColor;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-justify {
    text-align: justify;
}

.text-transparent {
    color: transparent;
}

.text-black {
    color: #22292f;
}

.text-grey-darkest {
    color: #3d4852;
}

.text-grey-darker {
    color: #606f7b;
}

.text-grey-dark {
    color: #8795a1;
}

.text-grey {
    color: #b8c2cc;
}

.text-grey-light {
    color: #dae1e7;
}

.text-grey-lighter {
    color: #f1f5f8;
}

.text-grey-lightest {
    color: #f8fafc;
}

.text-white {
    color: #fff;
}

.text-red-darkest {
    color: #3b0d0c;
}

.text-red-darker {
    color: #621b18;
}

.text-red-dark {
    color: #cc1f1a;
}

.text-red {
    color: #e3342f;
}

.text-red-light {
    color: #ef5753;
}

.text-red-lighter {
    color: #f9acaa;
}

.text-red-lightest {
    color: #fcebea;
}

.text-orange-darkest {
    color: #462a16;
}

.text-orange-darker {
    color: #613b1f;
}

.text-orange-dark {
    color: #de751f;
}

.text-orange {
    color: #f6993f;
}

.text-orange-light {
    color: #faad63;
}

.text-orange-lighter {
    color: #fcd9b6;
}

.text-orange-lightest {
    color: #fff5eb;
}

.text-yellow-darkest {
    color: #453411;
}

.text-yellow-darker {
    color: #684f1d;
}

.text-yellow-dark {
    color: #f2d024;
}

.text-yellow {
    color: #ffed4a;
}

.text-yellow-light {
    color: #fff382;
}

.text-yellow-lighter {
    color: #fff9c2;
}

.text-yellow-lightest {
    color: #fcfbeb;
}

.text-green-darkest {
    color: #0f2f21;
}

.text-green-darker {
    color: #1a4731;
}

.text-green-dark {
    color: #1f9d55;
}

.text-green {
    color: #38c172;
}

.text-green-light {
    color: #51d88a;
}

.text-green-lighter {
    color: #a2f5bf;
}

.text-green-lightest {
    color: #e3fcec;
}

.text-teal-darkest {
    color: #0d3331;
}

.text-teal-darker {
    color: #20504f;
}

.text-teal-dark {
    color: #38a89d;
}

.text-teal {
    color: #4dc0b5;
}

.text-teal-light {
    color: #64d5ca;
}

.text-teal-lighter {
    color: #a0f0ed;
}

.text-teal-lightest {
    color: #e8fffe;
}

.text-blue-darkest {
    color: #12283a;
}

.text-blue-darker {
    color: #1c3d5a;
}

.text-blue-dark {
    color: #2779bd;
}

.text-blue {
    color: #3490dc;
}

.text-blue-light {
    color: #6cb2eb;
}

.text-blue-lighter {
    color: #bcdefa;
}

.text-blue-lightest {
    color: #eff8ff;
}

.text-indigo-darkest {
    color: #191e38;
}

.text-indigo-darker {
    color: #2f365f;
}

.text-indigo-dark {
    color: #5661b3;
}

.text-indigo {
    color: #6574cd;
}

.text-indigo-light {
    color: #7886d7;
}

.text-indigo-lighter {
    color: #b2b7ff;
}

.text-indigo-lightest {
    color: #e6e8ff;
}

.text-purple-darkest {
    color: #21183c;
}

.text-purple-darker {
    color: #382b5f;
}

.text-purple-dark {
    color: #794acf;
}

.text-purple {
    color: #9561e2;
}

.text-purple-light {
    color: #a779e9;
}

.text-purple-lighter {
    color: #d6bbfc;
}

.text-purple-lightest {
    color: #f3ebff;
}

.text-pink-darkest {
    color: #451225;
}

.text-pink-darker {
    color: #6f213f;
}

.text-pink-dark {
    color: #eb5286;
}

.text-pink {
    color: #f66d9b;
}

.text-pink-light {
    color: #fa7ea8;
}

.text-pink-lighter {
    color: #ffbbca;
}

.text-pink-lightest {
    color: #ffebef;
}

.hover\:text-transparent:hover {
    color: transparent;
}

.hover\:text-black:hover {
    color: #22292f;
}

.hover\:text-grey-darkest:hover {
    color: #3d4852;
}

.hover\:text-grey-darker:hover {
    color: #606f7b;
}

.hover\:text-grey-dark:hover {
    color: #8795a1;
}

.hover\:text-grey:hover {
    color: #b8c2cc;
}

.hover\:text-grey-light:hover {
    color: #dae1e7;
}

.hover\:text-grey-lighter:hover {
    color: #f1f5f8;
}

.hover\:text-grey-lightest:hover {
    color: #f8fafc;
}

.hover\:text-white:hover {
    color: #fff;
}

.hover\:text-red-darkest:hover {
    color: #3b0d0c;
}

.hover\:text-red-darker:hover {
    color: #621b18;
}

.hover\:text-red-dark:hover {
    color: #cc1f1a;
}

.hover\:text-red:hover {
    color: #e3342f;
}

.hover\:text-red-light:hover {
    color: #ef5753;
}

.hover\:text-red-lighter:hover {
    color: #f9acaa;
}

.hover\:text-red-lightest:hover {
    color: #fcebea;
}

.hover\:text-orange-darkest:hover {
    color: #462a16;
}

.hover\:text-orange-darker:hover {
    color: #613b1f;
}

.hover\:text-orange-dark:hover {
    color: #de751f;
}

.hover\:text-orange:hover {
    color: #f6993f;
}

.hover\:text-orange-light:hover {
    color: #faad63;
}

.hover\:text-orange-lighter:hover {
    color: #fcd9b6;
}

.hover\:text-orange-lightest:hover {
    color: #fff5eb;
}

.hover\:text-yellow-darkest:hover {
    color: #453411;
}

.hover\:text-yellow-darker:hover {
    color: #684f1d;
}

.hover\:text-yellow-dark:hover {
    color: #f2d024;
}

.hover\:text-yellow:hover {
    color: #ffed4a;
}

.hover\:text-yellow-light:hover {
    color: #fff382;
}

.hover\:text-yellow-lighter:hover {
    color: #fff9c2;
}

.hover\:text-yellow-lightest:hover {
    color: #fcfbeb;
}

.hover\:text-green-darkest:hover {
    color: #0f2f21;
}

.hover\:text-green-darker:hover {
    color: #1a4731;
}

.hover\:text-green-dark:hover {
    color: #1f9d55;
}

.hover\:text-green:hover {
    color: #38c172;
}

.hover\:text-green-light:hover {
    color: #51d88a;
}

.hover\:text-green-lighter:hover {
    color: #a2f5bf;
}

.hover\:text-green-lightest:hover {
    color: #e3fcec;
}

.hover\:text-teal-darkest:hover {
    color: #0d3331;
}

.hover\:text-teal-darker:hover {
    color: #20504f;
}

.hover\:text-teal-dark:hover {
    color: #38a89d;
}

.hover\:text-teal:hover {
    color: #4dc0b5;
}

.hover\:text-teal-light:hover {
    color: #64d5ca;
}

.hover\:text-teal-lighter:hover {
    color: #a0f0ed;
}

.hover\:text-teal-lightest:hover {
    color: #e8fffe;
}

.hover\:text-blue-darkest:hover {
    color: #12283a;
}

.hover\:text-blue-darker:hover {
    color: #1c3d5a;
}

.hover\:text-blue-dark:hover {
    color: #2779bd;
}

.hover\:text-blue:hover {
    color: #3490dc;
}

.hover\:text-blue-light:hover {
    color: #6cb2eb;
}

.hover\:text-blue-lighter:hover {
    color: #bcdefa;
}

.hover\:text-blue-lightest:hover {
    color: #eff8ff;
}

.hover\:text-indigo-darkest:hover {
    color: #191e38;
}

.hover\:text-indigo-darker:hover {
    color: #2f365f;
}

.hover\:text-indigo-dark:hover {
    color: #5661b3;
}

.hover\:text-indigo:hover {
    color: #6574cd;
}

.hover\:text-indigo-light:hover {
    color: #7886d7;
}

.hover\:text-indigo-lighter:hover {
    color: #b2b7ff;
}

.hover\:text-indigo-lightest:hover {
    color: #e6e8ff;
}

.hover\:text-purple-darkest:hover {
    color: #21183c;
}

.hover\:text-purple-darker:hover {
    color: #382b5f;
}

.hover\:text-purple-dark:hover {
    color: #794acf;
}

.hover\:text-purple:hover {
    color: #9561e2;
}

.hover\:text-purple-light:hover {
    color: #a779e9;
}

.hover\:text-purple-lighter:hover {
    color: #d6bbfc;
}

.hover\:text-purple-lightest:hover {
    color: #f3ebff;
}

.hover\:text-pink-darkest:hover {
    color: #451225;
}

.hover\:text-pink-darker:hover {
    color: #6f213f;
}

.hover\:text-pink-dark:hover {
    color: #eb5286;
}

.hover\:text-pink:hover {
    color: #f66d9b;
}

.hover\:text-pink-light:hover {
    color: #fa7ea8;
}

.hover\:text-pink-lighter:hover {
    color: #ffbbca;
}

.hover\:text-pink-lightest:hover {
    color: #ffebef;
}

.fs-8 {
    font-size: 8px;
}

.fs-9 {
    font-size: 9px;
}

.fs-10 {
    font-size: 10px;
}

.fs-11 {
    font-size: 11px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.text-10-px {
    font-size: 10px;
}

.text-12px {
    font-size: .8rem;
}

.text-10 {
    font-size: 1rem;
}

.text-11 {
    font-size: 1.1rem;
}

.text-12 {
    font-size: 1.2rem;
}

.text-13 {
    font-size: 1.3rem;
}

.text-14 {
    font-size: 1.4rem;
}

.text-15 {
    font-size: 1.5rem;
}

.text-16 {
    font-size: 1.6rem;
}

.text-17 {
    font-size: 1.7rem;
}

.text-18 {
    font-size: 1.8rem;
}

.text-19 {
    font-size: 1.9rem;
}

.text-20 {
    font-size: 2rem;
}

.text-24 {
    font-size: 2.4rem;
}

.text-28 {
    font-size: 2.8rem;
}

.text-32 {
    font-size: 3.2rem;
}

.text-36 {
    font-size: 3.6rem;
}

.text-40 {
    font-size: 4rem !important;
}

.text-44 {
    font-size: 4.4rem;
}

.text-48 {
    font-size: 4.8rem;
}

.text-52 {
    font-size: 5.2rem;
}

.text-56 {
    font-size: 5.6rem;
}

.text-60 {
    font-size: 6rem ;
}

.text-64 {
    font-size: 6.4rem !important;
}

.text-68 {
    font-size: 6.8rem ;
}

.text-72 {
    font-size: 7.2rem ;
}

.text-96 {
    font-size: 9.6rem ;
}

.text-128 {
    font-size: 12.8rem;
}

.text-xs {
    font-size: 1.2rem;
}

.text-sm {
    font-size: 2.4rem;
}

.text-base {
    font-size: 1.6rem;
}

.text-lg {
    font-size: 1.8rem;
}

.text-xl {
    font-size: 2rem;
}

.text-2xl {
    font-size: 2.4rem;
}

.text-3xl {
    font-size: 3rem;
}

.text-4xl {
    font-size: 3.6rem;
}

.text-5xl {
    font-size: 4.8rem;
}

.italic {
    font-style: italic;
}

.roman {
    font-style: normal;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.capitalize {
    text-transform: capitalize;
}

.normal-case {
    text-transform: none;
}

.underline {
    text-decoration: underline;
}

.line-through {
    text-decoration: line-through;
}

.no-underline {
    text-decoration: none;
}

.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

.hover\:italic:hover {
    font-style: italic;
}

.hover\:roman:hover {
    font-style: normal;
}

.hover\:uppercase:hover {
    text-transform: uppercase;
}

.hover\:lowercase:hover {
    text-transform: lowercase;
}

.hover\:capitalize:hover {
    text-transform: capitalize;
}

.hover\:normal-case:hover {
    text-transform: none;
}

.hover\:underline:hover {
    text-decoration: underline;
}

.hover\:line-through:hover {
    text-decoration: line-through;
}

.hover\:no-underline:hover {
    text-decoration: none;
}

.hover\:antialiased:hover {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hover\:subpixel-antialiased:hover {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

.tracking-tight {
    letter-spacing: -0.05em;
}

.tracking-normal {
    letter-spacing: 0;
}

.tracking-wide {
    letter-spacing: .05em;
}

.select-none {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.select-text {
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

.align-baseline {
    vertical-align: baseline;
}

.align-top {
    vertical-align: top;
}

.align-middle {
    vertical-align: middle;
}

.align-bottom {
    vertical-align: bottom;
}

.align-text-top {
    vertical-align: text-top;
}

.align-text-bottom {
    vertical-align: text-bottom;
}

.visible {
    visibility: visible;
}

.invisible {
    visibility: hidden;
}

.whitespace-normal {
    white-space: normal;
}

.whitespace-no-wrap {
    white-space: nowrap;
}

.whitespace-pre {
    white-space: pre;
}

.whitespace-pre-line {
    white-space: pre-line;
}

.whitespace-pre-wrap {
    white-space: pre-wrap;
}

.break-words {
    word-wrap: break-word;
}

.break-normal {
    word-wrap: normal;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.w-0 {
    width: 0;
}

.w-4 {
    width: .4rem;
}

.w-8 {
    width: .8rem;
}

.w-12 {
    width: 1.2rem;
}

.w-16 {
    width: 1.6rem;
}

.w-20 {
    width: 2rem;
}

.w-24 {
    width: 2.4rem;
}

.w-28 {
    width: 2.8rem;
}

.w-32 {
    width: 3.2rem;
}

.w-36 {
    width: 3.6rem;
}

.w-40 {
    width: 4rem;
}

.w-44 {
    width: 4.4rem;
}

.w-48 {
    width: 4.8rem;
}

.w-52 {
    width: 5.2rem;
}

.w-56 {
    width: 5.6rem;
}

.w-60 {
    width: 6rem;
}

.w-64 {
    width: 6.4rem;
}

.w-68 {
    width: 6.8rem;
}

.w-72 {
    width: 7.2rem;
}

.w-76 {
    width: 7.6rem;
}

.w-80 {
    width: 8rem !important;
}

.w-84 {
    width: 8.4rem ;
}

.w-88 {
    width: 8.8rem;
}

.w-92 {
    width: 9.2rem;
}

.w-96 {
    width: 9.6rem !important;
}

.w-128 {
    width: 12.8rem;
}

.w-160 {
    width: 16rem;
}

.w-192 {
    width: 19.2rem;
}

.w-224 {
    width: 22.4rem;
}

.w-256 {
    width: 25.6rem;
}

.w-288 {
    width: 28.8rem;
}

.w-320 {
    width: 32rem;
}

.w-512 {
    width: 51.2rem;
}

.w-auto {
    width: auto;
}

.w-px {
    width: 1px;
}

.w-1\/2 {
    width: 50%;
}

.w-1\/3 {
    width: 33.33333%;
}

.w-2\/3 {
    width: 66.66667%;
}

.w-1\/4 {
    width: 25%;
}

.w-3\/4 {
    width: 75%;
}

.w-1\/5 {
    width: 20%;
}

.w-2\/5 {
    width: 40%;
}

.w-3\/5 {
    width: 60%;
}

.w-4\/5 {
    width: 80%;
}

.w-1\/6 {
    width: 16.66667%;
}

.w-5\/6 {
    width: 83.33333%;
}

.w-full {
    width: 100%;
}

.w-screen {
    width: 100vw;
}

.z-0 {
    z-index: 0;
}

.z-10 {
    z-index: 10;
}

.z-20 {
    z-index: 20;
}

.z-30 {
    z-index: 30;
}

.z-40 {
    z-index: 40;
}

.z-50 {
    z-index: 50;
}

.z-auto {
    z-index: auto;
}

@media (min-width: 600px) {
    .sm\:list-reset {
        list-style: none;
        padding: 0;
    }
    .sm\:appearance-none {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .sm\:bg-fixed {
        background-attachment: fixed;
    }
    .sm\:bg-local {
        background-attachment: local;
    }
    .sm\:bg-scroll {
        background-attachment: scroll;
    }
    .sm\:bg-transparent {
        background-color: transparent;
    }
    .sm\:bg-black {
        background-color: #22292f;
    }
    .sm\:bg-grey-darkest {
        background-color: #3d4852;
    }
    .sm\:bg-grey-darker {
        background-color: #606f7b;
    }
    .sm\:bg-grey-dark {
        background-color: #8795a1;
    }
    .sm\:bg-grey {
        background-color: #b8c2cc;
    }
    .sm\:bg-grey-light {
        background-color: #dae1e7;
    }
    .sm\:bg-grey-lighter {
        background-color: #f1f5f8;
    }
    .sm\:bg-grey-lightest {
        background-color: #f8fafc;
    }
    .sm\:bg-white {
        background-color: #fff;
    }
    .sm\:bg-red-darkest {
        background-color: #3b0d0c;
    }
    .sm\:bg-red-darker {
        background-color: #621b18;
    }
    .sm\:bg-red-dark {
        background-color: #cc1f1a;
    }
    .sm\:bg-red {
        background-color: #e3342f;
    }
    .sm\:bg-red-light {
        background-color: #ef5753;
    }
    .sm\:bg-red-lighter {
        background-color: #f9acaa;
    }
    .sm\:bg-red-lightest {
        background-color: #fcebea;
    }
    .sm\:bg-orange-darkest {
        background-color: #462a16;
    }
    .sm\:bg-orange-darker {
        background-color: #613b1f;
    }
    .sm\:bg-orange-dark {
        background-color: #de751f;
    }
    .sm\:bg-orange {
        background-color: #f6993f;
    }
    .sm\:bg-orange-light {
        background-color: #faad63;
    }
    .sm\:bg-orange-lighter {
        background-color: #fcd9b6;
    }
    .sm\:bg-orange-lightest {
        background-color: #fff5eb;
    }
    .sm\:bg-yellow-darkest {
        background-color: #453411;
    }
    .sm\:bg-yellow-darker {
        background-color: #684f1d;
    }
    .sm\:bg-yellow-dark {
        background-color: #f2d024;
    }
    .sm\:bg-yellow {
        background-color: #ffed4a;
    }
    .sm\:bg-yellow-light {
        background-color: #fff382;
    }
    .sm\:bg-yellow-lighter {
        background-color: #fff9c2;
    }
    .sm\:bg-yellow-lightest {
        background-color: #fcfbeb;
    }
    .sm\:bg-green-darkest {
        background-color: #0f2f21;
    }
    .sm\:bg-green-darker {
        background-color: #1a4731;
    }
    .sm\:bg-green-dark {
        background-color: #1f9d55;
    }
    .sm\:bg-green {
        background-color: #38c172;
    }
    .sm\:bg-green-light {
        background-color: #51d88a;
    }
    .sm\:bg-green-lighter {
        background-color: #a2f5bf;
    }
    .sm\:bg-green-lightest {
        background-color: #e3fcec;
    }
    .sm\:bg-teal-darkest {
        background-color: #0d3331;
    }
    .sm\:bg-teal-darker {
        background-color: #20504f;
    }
    .sm\:bg-teal-dark {
        background-color: #38a89d;
    }
    .sm\:bg-teal {
        background-color: #4dc0b5;
    }
    .sm\:bg-teal-light {
        background-color: #64d5ca;
    }
    .sm\:bg-teal-lighter {
        background-color: #a0f0ed;
    }
    .sm\:bg-teal-lightest {
        background-color: #e8fffe;
    }
    .sm\:bg-blue-darkest {
        background-color: #12283a;
    }
    .sm\:bg-blue-darker {
        background-color: #1c3d5a;
    }
    .sm\:bg-blue-dark {
        background-color: #2779bd;
    }
    .sm\:bg-blue {
        background-color: #3490dc;
    }
    .sm\:bg-blue-light {
        background-color: #6cb2eb;
    }
    .sm\:bg-blue-lighter {
        background-color: #bcdefa;
    }
    .sm\:bg-blue-lightest {
        background-color: #eff8ff;
    }
    .sm\:bg-indigo-darkest {
        background-color: #191e38;
    }
    .sm\:bg-indigo-darker {
        background-color: #2f365f;
    }
    .sm\:bg-indigo-dark {
        background-color: #5661b3;
    }
    .sm\:bg-indigo {
        background-color: #6574cd;
    }
    .sm\:bg-indigo-light {
        background-color: #7886d7;
    }
    .sm\:bg-indigo-lighter {
        background-color: #b2b7ff;
    }
    .sm\:bg-indigo-lightest {
        background-color: #e6e8ff;
    }
    .sm\:bg-purple-darkest {
        background-color: #21183c;
    }
    .sm\:bg-purple-darker {
        background-color: #382b5f;
    }
    .sm\:bg-purple-dark {
        background-color: #794acf;
    }
    .sm\:bg-purple {
        background-color: #9561e2;
    }
    .sm\:bg-purple-light {
        background-color: #a779e9;
    }
    .sm\:bg-purple-lighter {
        background-color: #d6bbfc;
    }
    .sm\:bg-purple-lightest {
        background-color: #f3ebff;
    }
    .sm\:bg-pink-darkest {
        background-color: #451225;
    }
    .sm\:bg-pink-darker {
        background-color: #6f213f;
    }
    .sm\:bg-pink-dark {
        background-color: #eb5286;
    }
    .sm\:bg-pink {
        background-color: #f66d9b;
    }
    .sm\:bg-pink-light {
        background-color: #fa7ea8;
    }
    .sm\:bg-pink-lighter {
        background-color: #ffbbca;
    }
    .sm\:bg-pink-lightest {
        background-color: #ffebef;
    }
    .sm\:hover\:bg-transparent:hover {
        background-color: transparent;
    }
    .sm\:hover\:bg-black:hover {
        background-color: #22292f;
    }
    .sm\:hover\:bg-grey-darkest:hover {
        background-color: #3d4852;
    }
    .sm\:hover\:bg-grey-darker:hover {
        background-color: #606f7b;
    }
    .sm\:hover\:bg-grey-dark:hover {
        background-color: #8795a1;
    }
    .sm\:hover\:bg-grey:hover {
        background-color: #b8c2cc;
    }
    .sm\:hover\:bg-grey-light:hover {
        background-color: #dae1e7;
    }
    .sm\:hover\:bg-grey-lighter:hover {
        background-color: #f1f5f8;
    }
    .sm\:hover\:bg-grey-lightest:hover {
        background-color: #f8fafc;
    }
    .sm\:hover\:bg-white:hover {
        background-color: #fff;
    }
    .sm\:hover\:bg-red-darkest:hover {
        background-color: #3b0d0c;
    }
    .sm\:hover\:bg-red-darker:hover {
        background-color: #621b18;
    }
    .sm\:hover\:bg-red-dark:hover {
        background-color: #cc1f1a;
    }
    .sm\:hover\:bg-red:hover {
        background-color: #e3342f;
    }
    .sm\:hover\:bg-red-light:hover {
        background-color: #ef5753;
    }
    .sm\:hover\:bg-red-lighter:hover {
        background-color: #f9acaa;
    }
    .sm\:hover\:bg-red-lightest:hover {
        background-color: #fcebea;
    }
    .sm\:hover\:bg-orange-darkest:hover {
        background-color: #462a16;
    }
    .sm\:hover\:bg-orange-darker:hover {
        background-color: #613b1f;
    }
    .sm\:hover\:bg-orange-dark:hover {
        background-color: #de751f;
    }
    .sm\:hover\:bg-orange:hover {
        background-color: #f6993f;
    }
    .sm\:hover\:bg-orange-light:hover {
        background-color: #faad63;
    }
    .sm\:hover\:bg-orange-lighter:hover {
        background-color: #fcd9b6;
    }
    .sm\:hover\:bg-orange-lightest:hover {
        background-color: #fff5eb;
    }
    .sm\:hover\:bg-yellow-darkest:hover {
        background-color: #453411;
    }
    .sm\:hover\:bg-yellow-darker:hover {
        background-color: #684f1d;
    }
    .sm\:hover\:bg-yellow-dark:hover {
        background-color: #f2d024;
    }
    .sm\:hover\:bg-yellow:hover {
        background-color: #ffed4a;
    }
    .sm\:hover\:bg-yellow-light:hover {
        background-color: #fff382;
    }
    .sm\:hover\:bg-yellow-lighter:hover {
        background-color: #fff9c2;
    }
    .sm\:hover\:bg-yellow-lightest:hover {
        background-color: #fcfbeb;
    }
    .sm\:hover\:bg-green-darkest:hover {
        background-color: #0f2f21;
    }
    .sm\:hover\:bg-green-darker:hover {
        background-color: #1a4731;
    }
    .sm\:hover\:bg-green-dark:hover {
        background-color: #1f9d55;
    }
    .sm\:hover\:bg-green:hover {
        background-color: #38c172;
    }
    .sm\:hover\:bg-green-light:hover {
        background-color: #51d88a;
    }
    .sm\:hover\:bg-green-lighter:hover {
        background-color: #a2f5bf;
    }
    .sm\:hover\:bg-green-lightest:hover {
        background-color: #e3fcec;
    }
    .sm\:hover\:bg-teal-darkest:hover {
        background-color: #0d3331;
    }
    .sm\:hover\:bg-teal-darker:hover {
        background-color: #20504f;
    }
    .sm\:hover\:bg-teal-dark:hover {
        background-color: #38a89d;
    }
    .sm\:hover\:bg-teal:hover {
        background-color: #4dc0b5;
    }
    .sm\:hover\:bg-teal-light:hover {
        background-color: #64d5ca;
    }
    .sm\:hover\:bg-teal-lighter:hover {
        background-color: #a0f0ed;
    }
    .sm\:hover\:bg-teal-lightest:hover {
        background-color: #e8fffe;
    }
    .sm\:hover\:bg-blue-darkest:hover {
        background-color: #12283a;
    }
    .sm\:hover\:bg-blue-darker:hover {
        background-color: #1c3d5a;
    }
    .sm\:hover\:bg-blue-dark:hover {
        background-color: #2779bd;
    }
    .sm\:hover\:bg-blue:hover {
        background-color: #3490dc;
    }
    .sm\:hover\:bg-blue-light:hover {
        background-color: #6cb2eb;
    }
    .sm\:hover\:bg-blue-lighter:hover {
        background-color: #bcdefa;
    }
    .sm\:hover\:bg-blue-lightest:hover {
        background-color: #eff8ff;
    }
    .sm\:hover\:bg-indigo-darkest:hover {
        background-color: #191e38;
    }
    .sm\:hover\:bg-indigo-darker:hover {
        background-color: #2f365f;
    }
    .sm\:hover\:bg-indigo-dark:hover {
        background-color: #5661b3;
    }
    .sm\:hover\:bg-indigo:hover {
        background-color: #6574cd;
    }
    .sm\:hover\:bg-indigo-light:hover {
        background-color: #7886d7;
    }
    .sm\:hover\:bg-indigo-lighter:hover {
        background-color: #b2b7ff;
    }
    .sm\:hover\:bg-indigo-lightest:hover {
        background-color: #e6e8ff;
    }
    .sm\:hover\:bg-purple-darkest:hover {
        background-color: #21183c;
    }
    .sm\:hover\:bg-purple-darker:hover {
        background-color: #382b5f;
    }
    .sm\:hover\:bg-purple-dark:hover {
        background-color: #794acf;
    }
    .sm\:hover\:bg-purple:hover {
        background-color: #9561e2;
    }
    .sm\:hover\:bg-purple-light:hover {
        background-color: #a779e9;
    }
    .sm\:hover\:bg-purple-lighter:hover {
        background-color: #d6bbfc;
    }
    .sm\:hover\:bg-purple-lightest:hover {
        background-color: #f3ebff;
    }
    .sm\:hover\:bg-pink-darkest:hover {
        background-color: #451225;
    }
    .sm\:hover\:bg-pink-darker:hover {
        background-color: #6f213f;
    }
    .sm\:hover\:bg-pink-dark:hover {
        background-color: #eb5286;
    }
    .sm\:hover\:bg-pink:hover {
        background-color: #f66d9b;
    }
    .sm\:hover\:bg-pink-light:hover {
        background-color: #fa7ea8;
    }
    .sm\:hover\:bg-pink-lighter:hover {
        background-color: #ffbbca;
    }
    .sm\:hover\:bg-pink-lightest:hover {
        background-color: #ffebef;
    }
    .sm\:bg-bottom {
        background-position: bottom;
    }
    .sm\:bg-center {
        background-position: center;
    }
    .sm\:bg-left {
        background-position: left;
    }
    .sm\:bg-left-bottom {
        background-position: left bottom;
    }
    .sm\:bg-left-top {
        background-position: left top;
    }
    .sm\:bg-right {
        background-position: right;
    }
    .sm\:bg-right-bottom {
        background-position: right bottom;
    }
    .sm\:bg-right-top {
        background-position: right top;
    }
    .sm\:bg-top {
        background-position: top;
    }
    .sm\:bg-repeat {
        background-repeat: repeat;
    }
    .sm\:bg-no-repeat {
        background-repeat: no-repeat;
    }
    .sm\:bg-repeat-x {
        background-repeat: repeat-x;
    }
    .sm\:bg-repeat-y {
        background-repeat: repeat-y;
    }
    .sm\:bg-auto {
        background-size: auto;
    }
    .sm\:bg-cover {
        background-size: cover;
    }
    .sm\:bg-contain {
        background-size: contain;
    }
    .sm\:border-transparent {
        border-color: transparent;
    }
    .sm\:border-black {
        border-color: #22292f;
    }
    .sm\:border-grey-darkest {
        border-color: #3d4852;
    }
    .sm\:border-grey-darker {
        border-color: #606f7b;
    }
    .sm\:border-grey-dark {
        border-color: #8795a1;
    }
    .sm\:border-grey {
        border-color: #b8c2cc;
    }
    .sm\:border-grey-light {
        border-color: #dae1e7;
    }
    .sm\:border-grey-lighter {
        border-color: #f1f5f8;
    }
    .sm\:border-grey-lightest {
        border-color: #f8fafc;
    }
    .sm\:border-white {
        border-color: #fff;
    }
    .sm\:border-red-darkest {
        border-color: #3b0d0c;
    }
    .sm\:border-red-darker {
        border-color: #621b18;
    }
    .sm\:border-red-dark {
        border-color: #cc1f1a;
    }
    .sm\:border-red {
        border-color: #e3342f;
    }
    .sm\:border-red-light {
        border-color: #ef5753;
    }
    .sm\:border-red-lighter {
        border-color: #f9acaa;
    }
    .sm\:border-red-lightest {
        border-color: #fcebea;
    }
    .sm\:border-orange-darkest {
        border-color: #462a16;
    }
    .sm\:border-orange-darker {
        border-color: #613b1f;
    }
    .sm\:border-orange-dark {
        border-color: #de751f;
    }
    .sm\:border-orange {
        border-color: #f6993f;
    }
    .sm\:border-orange-light {
        border-color: #faad63;
    }
    .sm\:border-orange-lighter {
        border-color: #fcd9b6;
    }
    .sm\:border-orange-lightest {
        border-color: #fff5eb;
    }
    .sm\:border-yellow-darkest {
        border-color: #453411;
    }
    .sm\:border-yellow-darker {
        border-color: #684f1d;
    }
    .sm\:border-yellow-dark {
        border-color: #f2d024;
    }
    .sm\:border-yellow {
        border-color: #ffed4a;
    }
    .sm\:border-yellow-light {
        border-color: #fff382;
    }
    .sm\:border-yellow-lighter {
        border-color: #fff9c2;
    }
    .sm\:border-yellow-lightest {
        border-color: #fcfbeb;
    }
    .sm\:border-green-darkest {
        border-color: #0f2f21;
    }
    .sm\:border-green-darker {
        border-color: #1a4731;
    }
    .sm\:border-green-dark {
        border-color: #1f9d55;
    }
    .sm\:border-green {
        border-color: #38c172;
    }
    .sm\:border-green-light {
        border-color: #51d88a;
    }
    .sm\:border-green-lighter {
        border-color: #a2f5bf;
    }
    .sm\:border-green-lightest {
        border-color: #e3fcec;
    }
    .sm\:border-teal-darkest {
        border-color: #0d3331;
    }
    .sm\:border-teal-darker {
        border-color: #20504f;
    }
    .sm\:border-teal-dark {
        border-color: #38a89d;
    }
    .sm\:border-teal {
        border-color: #4dc0b5;
    }
    .sm\:border-teal-light {
        border-color: #64d5ca;
    }
    .sm\:border-teal-lighter {
        border-color: #a0f0ed;
    }
    .sm\:border-teal-lightest {
        border-color: #e8fffe;
    }
    .sm\:border-blue-darkest {
        border-color: #12283a;
    }
    .sm\:border-blue-darker {
        border-color: #1c3d5a;
    }
    .sm\:border-blue-dark {
        border-color: #2779bd;
    }
    .sm\:border-blue {
        border-color: #3490dc;
    }
    .sm\:border-blue-light {
        border-color: #6cb2eb;
    }
    .sm\:border-blue-lighter {
        border-color: #bcdefa;
    }
    .sm\:border-blue-lightest {
        border-color: #eff8ff;
    }
    .sm\:border-indigo-darkest {
        border-color: #191e38;
    }
    .sm\:border-indigo-darker {
        border-color: #2f365f;
    }
    .sm\:border-indigo-dark {
        border-color: #5661b3;
    }
    .sm\:border-indigo {
        border-color: #6574cd;
    }
    .sm\:border-indigo-light {
        border-color: #7886d7;
    }
    .sm\:border-indigo-lighter {
        border-color: #b2b7ff;
    }
    .sm\:border-indigo-lightest {
        border-color: #e6e8ff;
    }
    .sm\:border-purple-darkest {
        border-color: #21183c;
    }
    .sm\:border-purple-darker {
        border-color: #382b5f;
    }
    .sm\:border-purple-dark {
        border-color: #794acf;
    }
    .sm\:border-purple {
        border-color: #9561e2;
    }
    .sm\:border-purple-light {
        border-color: #a779e9;
    }
    .sm\:border-purple-lighter {
        border-color: #d6bbfc;
    }
    .sm\:border-purple-lightest {
        border-color: #f3ebff;
    }
    .sm\:border-pink-darkest {
        border-color: #451225;
    }
    .sm\:border-pink-darker {
        border-color: #6f213f;
    }
    .sm\:border-pink-dark {
        border-color: #eb5286;
    }
    .sm\:border-pink {
        border-color: #f66d9b;
    }
    .sm\:border-pink-light {
        border-color: #fa7ea8;
    }
    .sm\:border-pink-lighter {
        border-color: #ffbbca;
    }
    .sm\:border-pink-lightest {
        border-color: #ffebef;
    }
    .sm\:hover\:border-transparent:hover {
        border-color: transparent;
    }
    .sm\:hover\:border-black:hover {
        border-color: #22292f;
    }
    .sm\:hover\:border-grey-darkest:hover {
        border-color: #3d4852;
    }
    .sm\:hover\:border-grey-darker:hover {
        border-color: #606f7b;
    }
    .sm\:hover\:border-grey-dark:hover {
        border-color: #8795a1;
    }
    .sm\:hover\:border-grey:hover {
        border-color: #b8c2cc;
    }
    .sm\:hover\:border-grey-light:hover {
        border-color: #dae1e7;
    }
    .sm\:hover\:border-grey-lighter:hover {
        border-color: #f1f5f8;
    }
    .sm\:hover\:border-grey-lightest:hover {
        border-color: #f8fafc;
    }
    .sm\:hover\:border-white:hover {
        border-color: #fff;
    }
    .sm\:hover\:border-red-darkest:hover {
        border-color: #3b0d0c;
    }
    .sm\:hover\:border-red-darker:hover {
        border-color: #621b18;
    }
    .sm\:hover\:border-red-dark:hover {
        border-color: #cc1f1a;
    }
    .sm\:hover\:border-red:hover {
        border-color: #e3342f;
    }
    .sm\:hover\:border-red-light:hover {
        border-color: #ef5753;
    }
    .sm\:hover\:border-red-lighter:hover {
        border-color: #f9acaa;
    }
    .sm\:hover\:border-red-lightest:hover {
        border-color: #fcebea;
    }
    .sm\:hover\:border-orange-darkest:hover {
        border-color: #462a16;
    }
    .sm\:hover\:border-orange-darker:hover {
        border-color: #613b1f;
    }
    .sm\:hover\:border-orange-dark:hover {
        border-color: #de751f;
    }
    .sm\:hover\:border-orange:hover {
        border-color: #f6993f;
    }
    .sm\:hover\:border-orange-light:hover {
        border-color: #faad63;
    }
    .sm\:hover\:border-orange-lighter:hover {
        border-color: #fcd9b6;
    }
    .sm\:hover\:border-orange-lightest:hover {
        border-color: #fff5eb;
    }
    .sm\:hover\:border-yellow-darkest:hover {
        border-color: #453411;
    }
    .sm\:hover\:border-yellow-darker:hover {
        border-color: #684f1d;
    }
    .sm\:hover\:border-yellow-dark:hover {
        border-color: #f2d024;
    }
    .sm\:hover\:border-yellow:hover {
        border-color: #ffed4a;
    }
    .sm\:hover\:border-yellow-light:hover {
        border-color: #fff382;
    }
    .sm\:hover\:border-yellow-lighter:hover {
        border-color: #fff9c2;
    }
    .sm\:hover\:border-yellow-lightest:hover {
        border-color: #fcfbeb;
    }
    .sm\:hover\:border-green-darkest:hover {
        border-color: #0f2f21;
    }
    .sm\:hover\:border-green-darker:hover {
        border-color: #1a4731;
    }
    .sm\:hover\:border-green-dark:hover {
        border-color: #1f9d55;
    }
    .sm\:hover\:border-green:hover {
        border-color: #38c172;
    }
    .sm\:hover\:border-green-light:hover {
        border-color: #51d88a;
    }
    .sm\:hover\:border-green-lighter:hover {
        border-color: #a2f5bf;
    }
    .sm\:hover\:border-green-lightest:hover {
        border-color: #e3fcec;
    }
    .sm\:hover\:border-teal-darkest:hover {
        border-color: #0d3331;
    }
    .sm\:hover\:border-teal-darker:hover {
        border-color: #20504f;
    }
    .sm\:hover\:border-teal-dark:hover {
        border-color: #38a89d;
    }
    .sm\:hover\:border-teal:hover {
        border-color: #4dc0b5;
    }
    .sm\:hover\:border-teal-light:hover {
        border-color: #64d5ca;
    }
    .sm\:hover\:border-teal-lighter:hover {
        border-color: #a0f0ed;
    }
    .sm\:hover\:border-teal-lightest:hover {
        border-color: #e8fffe;
    }
    .sm\:hover\:border-blue-darkest:hover {
        border-color: #12283a;
    }
    .sm\:hover\:border-blue-darker:hover {
        border-color: #1c3d5a;
    }
    .sm\:hover\:border-blue-dark:hover {
        border-color: #2779bd;
    }
    .sm\:hover\:border-blue:hover {
        border-color: #3490dc;
    }
    .sm\:hover\:border-blue-light:hover {
        border-color: #6cb2eb;
    }
    .sm\:hover\:border-blue-lighter:hover {
        border-color: #bcdefa;
    }
    .sm\:hover\:border-blue-lightest:hover {
        border-color: #eff8ff;
    }
    .sm\:hover\:border-indigo-darkest:hover {
        border-color: #191e38;
    }
    .sm\:hover\:border-indigo-darker:hover {
        border-color: #2f365f;
    }
    .sm\:hover\:border-indigo-dark:hover {
        border-color: #5661b3;
    }
    .sm\:hover\:border-indigo:hover {
        border-color: #6574cd;
    }
    .sm\:hover\:border-indigo-light:hover {
        border-color: #7886d7;
    }
    .sm\:hover\:border-indigo-lighter:hover {
        border-color: #b2b7ff;
    }
    .sm\:hover\:border-indigo-lightest:hover {
        border-color: #e6e8ff;
    }
    .sm\:hover\:border-purple-darkest:hover {
        border-color: #21183c;
    }
    .sm\:hover\:border-purple-darker:hover {
        border-color: #382b5f;
    }
    .sm\:hover\:border-purple-dark:hover {
        border-color: #794acf;
    }
    .sm\:hover\:border-purple:hover {
        border-color: #9561e2;
    }
    .sm\:hover\:border-purple-light:hover {
        border-color: #a779e9;
    }
    .sm\:hover\:border-purple-lighter:hover {
        border-color: #d6bbfc;
    }
    .sm\:hover\:border-purple-lightest:hover {
        border-color: #f3ebff;
    }
    .sm\:hover\:border-pink-darkest:hover {
        border-color: #451225;
    }
    .sm\:hover\:border-pink-darker:hover {
        border-color: #6f213f;
    }
    .sm\:hover\:border-pink-dark:hover {
        border-color: #eb5286;
    }
    .sm\:hover\:border-pink:hover {
        border-color: #f66d9b;
    }
    .sm\:hover\:border-pink-light:hover {
        border-color: #fa7ea8;
    }
    .sm\:hover\:border-pink-lighter:hover {
        border-color: #ffbbca;
    }
    .sm\:hover\:border-pink-lightest:hover {
        border-color: #ffebef;
    }
    .sm\:rounded-none {
        border-radius: 0;
    }
    .sm\:rounded-sm {
        border-radius: .2rem;
    }
    .sm\:rounded {
        border-radius: .4rem;
    }
    .sm\:rounded-lg {
        border-radius: .8rem;
    }
    .sm\:rounded-full {
        border-radius: 9999px;
    }
    .sm\:rounded-t-none {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .sm\:rounded-r-none {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .sm\:rounded-b-none {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .sm\:rounded-l-none {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .sm\:rounded-t-sm {
        border-top-left-radius: .2rem;
        border-top-right-radius: .2rem;
    }
    .sm\:rounded-r-sm {
        border-top-right-radius: .2rem;
        border-bottom-right-radius: .2rem;
    }
    .sm\:rounded-b-sm {
        border-bottom-right-radius: .2rem;
        border-bottom-left-radius: .2rem;
    }
    .sm\:rounded-l-sm {
        border-top-left-radius: .2rem;
        border-bottom-left-radius: .2rem;
    }
    .sm\:rounded-t {
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
    }
    .sm\:rounded-r {
        border-top-right-radius: .4rem;
        border-bottom-right-radius: .4rem;
    }
    .sm\:rounded-b {
        border-bottom-right-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }
    .sm\:rounded-l {
        border-top-left-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }
    .sm\:rounded-t-lg {
        border-top-left-radius: .8rem;
        border-top-right-radius: .8rem;
    }
    .sm\:rounded-r-lg {
        border-top-right-radius: .8rem;
        border-bottom-right-radius: .8rem;
    }
    .sm\:rounded-b-lg {
        border-bottom-right-radius: .8rem;
        border-bottom-left-radius: .8rem;
    }
    .sm\:rounded-l-lg {
        border-top-left-radius: .8rem;
        border-bottom-left-radius: .8rem;
    }
    .sm\:rounded-t-full {
        border-top-left-radius: 9999px;
        border-top-right-radius: 9999px;
    }
    .sm\:rounded-r-full {
        border-top-right-radius: 9999px;
        border-bottom-right-radius: 9999px;
    }
    .sm\:rounded-b-full {
        border-bottom-right-radius: 9999px;
        border-bottom-left-radius: 9999px;
    }
    .sm\:rounded-l-full {
        border-top-left-radius: 9999px;
        border-bottom-left-radius: 9999px;
    }
    .sm\:rounded-tl-none {
        border-top-left-radius: 0;
    }
    .sm\:rounded-tr-none {
        border-top-right-radius: 0;
    }
    .sm\:rounded-br-none {
        border-bottom-right-radius: 0;
    }
    .sm\:rounded-bl-none {
        border-bottom-left-radius: 0;
    }
    .sm\:rounded-tl-sm {
        border-top-left-radius: .2rem;
    }
    .sm\:rounded-tr-sm {
        border-top-right-radius: .2rem;
    }
    .sm\:rounded-br-sm {
        border-bottom-right-radius: .2rem;
    }
    .sm\:rounded-bl-sm {
        border-bottom-left-radius: .2rem;
    }
    .sm\:rounded-tl {
        border-top-left-radius: .4rem;
    }
    .sm\:rounded-tr {
        border-top-right-radius: .4rem;
    }
    .sm\:rounded-br {
        border-bottom-right-radius: .4rem;
    }
    .sm\:rounded-bl {
        border-bottom-left-radius: .4rem;
    }
    .sm\:rounded-tl-lg {
        border-top-left-radius: .8rem;
    }
    .sm\:rounded-tr-lg {
        border-top-right-radius: .8rem;
    }
    .sm\:rounded-br-lg {
        border-bottom-right-radius: .8rem;
    }
    .sm\:rounded-bl-lg {
        border-bottom-left-radius: .8rem;
    }
    .sm\:rounded-tl-full {
        border-top-left-radius: 9999px;
    }
    .sm\:rounded-tr-full {
        border-top-right-radius: 9999px;
    }
    .sm\:rounded-br-full {
        border-bottom-right-radius: 9999px;
    }
    .sm\:rounded-bl-full {
        border-bottom-left-radius: 9999px;
    }
    .sm\:border-solid {
        border-style: solid;
    }
    .sm\:border-dashed {
        border-style: dashed;
    }
    .sm\:border-dotted {
        border-style: dotted;
    }
    .sm\:border-none {
        border-style: none;
    }
    .sm\:border-0 {
        border-width: 0;
    }
    .sm\:border-1 {
        border-width: 1px;
    }
    .sm\:border-2 {
        border-width: 2px;
    }
    .sm\:border-3 {
        border-width: 3px;
    }
    .sm\:border-4 {
        border-width: 4px;
    }
    .sm\:border-8 {
        border-width: 8px;
    }
    .sm\:border {
        border-width: 1px;
    }
    .sm\:border-t-0 {
        border-top-width: 0;
    }
    .sm\:border-r-0 {
        border-right-width: 0;
    }
    .sm\:border-b-0 {
        border-bottom-width: 0;
    }
    .sm\:border-l-0 {
        border-left-width: 0;
    }
    .sm\:border-t-1 {
        border-top-width: 1px;
    }
    .sm\:border-r-1 {
        border-right-width: 1px;
    }
    .sm\:border-b-1 {
        border-bottom-width: 1px;
    }
    .sm\:border-l-1 {
        border-left-width: 1px;
    }
    .sm\:border-t-2 {
        border-top-width: 2px;
    }
    .sm\:border-r-2 {
        border-right-width: 2px;
    }
    .sm\:border-b-2 {
        border-bottom-width: 2px;
    }
    .sm\:border-l-2 {
        border-left-width: 2px;
    }
    .sm\:border-t-3 {
        border-top-width: 3px;
    }
    .sm\:border-r-3 {
        border-right-width: 3px;
    }
    .sm\:border-b-3 {
        border-bottom-width: 3px;
    }
    .sm\:border-l-3 {
        border-left-width: 3px;
    }
    .sm\:border-t-4 {
        border-top-width: 4px;
    }
    .sm\:border-r-4 {
        border-right-width: 4px;
    }
    .sm\:border-b-4 {
        border-bottom-width: 4px;
    }
    .sm\:border-l-4 {
        border-left-width: 4px;
    }
    .sm\:border-t-8 {
        border-top-width: 8px;
    }
    .sm\:border-r-8 {
        border-right-width: 8px;
    }
    .sm\:border-b-8 {
        border-bottom-width: 8px;
    }
    .sm\:border-l-8 {
        border-left-width: 8px;
    }
    .sm\:border-t {
        border-top-width: 1px;
    }
    .sm\:border-r {
        border-right-width: 1px;
    }
    .sm\:border-b {
        border-bottom-width: 1px;
    }
    .sm\:border-l {
        border-left-width: 1px;
    }
    .sm\:cursor-auto {
        cursor: auto;
    }
    .sm\:cursor-default {
        cursor: default;
    }
    .sm\:cursor-pointer {
        cursor: pointer;
    }
    .sm\:cursor-wait {
        cursor: wait;
    }
    .sm\:cursor-move {
        cursor: move;
    }
    .sm\:cursor-not-allowed {
        cursor: not-allowed;
    }
    .sm\:block {
        display: block;
    }
    .sm\:inline-block {
        display: inline-block;
    }
    .sm\:inline {
        display: inline;
    }
    .sm\:table {
        display: table;
    }
    .sm\:table-row {
        display: table-row;
    }
    .sm\:table-cell {
        display: table-cell;
    }
    .sm\:hidden {
        display: none;
    }
    .sm\:flex {
        display: flex;
    }
    .sm\:inline-flex {
        display: inline-flex;
    }
    .sm\:flex-row {
        flex-direction: row;
    }
    .sm\:flex-row-reverse {
        flex-direction: row-reverse;
    }
    .sm\:flex-col {
        flex-direction: column;
    }
    .sm\:flex-col-reverse {
        flex-direction: column-reverse;
    }
    .sm\:flex-wrap {
        flex-wrap: wrap;
    }
    .sm\:flex-wrap-reverse {
        flex-wrap: wrap-reverse;
    }
    .sm\:flex-no-wrap {
        flex-wrap: nowrap;
    }
    .sm\:items-start {
        align-items: flex-start;
    }
    .sm\:items-end {
        align-items: flex-end;
    }
    .sm\:items-center {
        align-items: center;
    }
    .sm\:items-baseline {
        align-items: baseline;
    }
    .sm\:items-stretch {
        align-items: stretch;
    }
    .sm\:self-auto {
        align-self: auto;
    }
    .sm\:self-start {
        align-self: flex-start;
    }
    .sm\:self-end {
        align-self: flex-end;
    }
    .sm\:self-center {
        align-self: center;
    }
    .sm\:self-stretch {
        align-self: stretch;
    }
    .sm\:justify-start {
        justify-content: flex-start;
    }
    .sm\:justify-end {
        justify-content: flex-end;
    }
    .sm\:justify-center {
        justify-content: center;
    }
    .sm\:justify-between {
        justify-content: space-between;
    }
    .sm\:justify-around {
        justify-content: space-around;
    }
    .sm\:content-center {
        align-content: center;
    }
    .sm\:content-start {
        align-content: flex-start;
    }
    .sm\:content-end {
        align-content: flex-end;
    }
    .sm\:content-between {
        align-content: space-between;
    }
    .sm\:content-around {
        align-content: space-around;
    }
    .sm\:flex-1 {
        flex: 1;
    }
    .sm\:flex-auto {
        flex: auto;
    }
    .sm\:flex-initial {
        flex: initial;
    }
    .sm\:flex-none {
        flex: none;
    }
    .sm\:flex-grow {
        flex-grow: 1;
    }
    .sm\:flex-shrink {
        flex-shrink: 1;
    }
    .sm\:flex-no-grow {
        flex-grow: 0;
    }
    .sm\:flex-no-shrink {
        flex-shrink: 0;
    }
    .sm\:float-right {
        float: right;
    }
    .sm\:float-left {
        float: left;
    }
    .sm\:float-none {
        float: none;
    }
    .sm\:clearfix:after {
        content: "";
        display: table;
        clear: both;
    }
    .sm\:font-sans {
        font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    }
    .sm\:font-serif {
        font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
    }
    .sm\:font-mono {
        font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    }
    .sm\:font-100 {
        font-weight: 100;
    }
    .sm\:font-200 {
        font-weight: 200;
    }
    .sm\:font-300 {
        font-weight: 300;
    }
    .sm\:font-400 {
        font-weight: 400;
    }
    .sm\:font-500 {
        font-weight: 500;
    }
    .sm\:font-600 {
        font-weight: 600;
    }
    .sm\:font-700 {
        font-weight: 700;
    }
    .sm\:font-800 {
        font-weight: 800;
    }
    .sm\:font-900 {
        font-weight: 900;
    }
    .sm\:font-hairline {
        font-weight: 100;
    }
    .sm\:font-thin {
        font-weight: 200;
    }
    .sm\:font-light {
        font-weight: 300;
    }
    .sm\:font-normal {
        font-weight: 400;
    }
    .sm\:font-medium {
        font-weight: 500;
    }
    .sm\:font-semibold {
        font-weight: 600;
    }
    .sm\:font-bold {
        font-weight: 700;
    }
    .sm\:font-extrabold {
        font-weight: 800;
    }
    .sm\:font-black {
        font-weight: 900;
    }
    .sm\:hover\:font-100:hover {
        font-weight: 100;
    }
    .sm\:hover\:font-200:hover {
        font-weight: 200;
    }
    .sm\:hover\:font-300:hover {
        font-weight: 300;
    }
    .sm\:hover\:font-400:hover {
        font-weight: 400;
    }
    .sm\:hover\:font-500:hover {
        font-weight: 500;
    }
    .sm\:hover\:font-600:hover {
        font-weight: 600;
    }
    .sm\:hover\:font-700:hover {
        font-weight: 700;
    }
    .sm\:hover\:font-800:hover {
        font-weight: 800;
    }
    .sm\:hover\:font-900:hover {
        font-weight: 900;
    }
    .sm\:hover\:font-hairline:hover {
        font-weight: 100;
    }
    .sm\:hover\:font-thin:hover {
        font-weight: 200;
    }
    .sm\:hover\:font-light:hover {
        font-weight: 300;
    }
    .sm\:hover\:font-normal:hover {
        font-weight: 400;
    }
    .sm\:hover\:font-medium:hover {
        font-weight: 500;
    }
    .sm\:hover\:font-semibold:hover {
        font-weight: 600;
    }
    .sm\:hover\:font-bold:hover {
        font-weight: 700;
    }
    .sm\:hover\:font-extrabold:hover {
        font-weight: 800;
    }
    .sm\:hover\:font-black:hover {
        font-weight: 900;
    }
    .sm\:h-4 {
        height: .4rem;
    }
    .sm\:h-8 {
        height: .8rem;
    }
    .sm\:h-12 {
        height: 1.2rem;
    }
    .sm\:h-16 {
        height: 1.6rem;
    }
    .sm\:h-20 {
        height: 2rem;
    }
    .sm\:h-24 {
        height: 2.4rem;
    }
    .sm\:h-28 {
        height: 2.8rem;
    }
    .sm\:h-32 {
        height: 3.2rem;
    }
    .sm\:h-36 {
        height: 3.6rem;
    }
    .sm\:h-40 {
        height: 4rem;
    }
    .sm\:h-44 {
        height: 4.4rem;
    }
    .sm\:h-48 {
        height: 4.8rem;
    }
    .sm\:h-52 {
        height: 5.2rem;
    }
    .sm\:h-56 {
        height: 5.6rem;
    }
    .sm\:h-60 {
        height: 6rem;
    }
    .sm\:h-64 {
        height: 6.4rem;
    }
    .sm\:h-68 {
        height: 6.8rem;
    }
    .sm\:h-72 {
        height: 7.2rem;
    }
    .sm\:h-76 {
        height: 7.6rem;
    }
    .sm\:h-80 {
        height: 8rem;
    }
    .sm\:h-84 {
        height: 8.4rem;
    }
    .sm\:h-88 {
        height: 8.8rem;
    }
    .sm\:h-92 {
        height: 9.2rem;
    }
    .sm\:h-96 {
        height: 9.6rem;
    }
    .sm\:h-128 {
        height: 12.8rem;
    }
    .sm\:h-160 {
        height: 16rem;
    }
    .sm\:h-192 {
        height: 19.2rem;
    }
    .sm\:h-224 {
        height: 22.4rem;
    }
    .sm\:h-256 {
        height: 25.6rem;
    }
    .sm\:h-288 {
        height: 28.8rem;
    }
    .sm\:h-320 {
        height: 32rem;
    }
    .sm\:h-512 {
        height: 51.2rem;
    }
    .sm\:h-auto {
        height: auto;
    }
    .sm\:h-px {
        height: 1px;
    }
    .sm\:h-full {
        height: 100%;
    }
    .sm\:h-screen {
        height: 100vh;
    }
    .sm\:leading-none {
        line-height: 1;
    }
    .sm\:leading-tight {
        line-height: 1.25;
    }
    .sm\:leading-normal {
        line-height: 1.5;
    }
    .sm\:leading-loose {
        line-height: 2;
    }
    .sm\:m-0 {
        margin: 0;
    }
    .sm\:m-4 {
        margin: .4rem;
    }
    .sm\:m-8 {
        margin: .8rem;
    }
    .sm\:m-12 {
        margin: 1.2rem;
    }
    .sm\:m-16 {
        margin: 1.6rem;
    }
    .sm\:m-20 {
        margin: 2rem;
    }
    .sm\:m-24 {
        margin: 2.4rem;
    }
    .sm\:m-28 {
        margin: 2.8rem;
    }
    .sm\:m-32 {
        margin: 3.2rem;
    }
    .sm\:m-36 {
        margin: 3.6rem;
    }
    .sm\:m-40 {
        margin: 4rem;
    }
    .sm\:m-44 {
        margin: 4.4rem;
    }
    .sm\:m-48 {
        margin: 4.8rem;
    }
    .sm\:m-52 {
        margin: 5.2rem;
    }
    .sm\:m-56 {
        margin: 5.6rem;
    }
    .sm\:m-60 {
        margin: 6rem;
    }
    .sm\:m-64 {
        margin: 6.4rem;
    }
    .sm\:m-68 {
        margin: 6.8rem;
    }
    .sm\:m-72 {
        margin: 7.2rem;
    }
    .sm\:m-76 {
        margin: 7.6rem;
    }
    .sm\:m-80 {
        margin: 8rem;
    }
    .sm\:m-84 {
        margin: 8.4rem;
    }
    .sm\:m-88 {
        margin: 8.8rem;
    }
    .sm\:m-92 {
        margin: 9.2rem;
    }
    .sm\:m-96 {
        margin: 9.6rem;
    }
    .sm\:m-128 {
        margin: 12.8rem;
    }
    .sm\:m-160 {
        margin: 16rem;
    }
    .sm\:m-192 {
        margin: 19.2rem;
    }
    .sm\:m-224 {
        margin: 22.4rem;
    }
    .sm\:m-256 {
        margin: 25.6rem;
    }
    .sm\:m-auto {
        margin: auto;
    }
    .sm\:m-px {
        margin: 1px;
    }
    .sm\:my-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .sm\:mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .sm\:my-4 {
        margin-top: .4rem;
        margin-bottom: .4rem;
    }
    .sm\:mx-4 {
        margin-left: .4rem;
        margin-right: .4rem;
    }
    .sm\:my-8 {
        margin-top: .8rem;
        margin-bottom: .8rem;
    }
    .sm\:mx-8 {
        margin-left: .8rem;
        margin-right: .8rem;
    }
    .sm\:my-12 {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }
    .sm\:mx-12 {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
    }
    .sm\:my-16 {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }
    .sm\:mx-16 {
        margin-left: 1.6rem;
        margin-right: 1.6rem;
    }
    .sm\:my-20 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .sm\:mx-20 {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .sm\:my-24 {
        margin-top: 2.4rem;
        margin-bottom: 2.4rem;
    }
    .sm\:mx-24 {
        margin-left: 2.4rem;
        margin-right: 2.4rem;
    }
    .sm\:my-28 {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
    }
    .sm\:mx-28 {
        margin-left: 2.8rem;
        margin-right: 2.8rem;
    }
    .sm\:my-32 {
        margin-top: 3.2rem;
        margin-bottom: 3.2rem;
    }
    .sm\:mx-32 {
        margin-left: 3.2rem;
        margin-right: 3.2rem;
    }
    .sm\:my-36 {
        margin-top: 3.6rem;
        margin-bottom: 3.6rem;
    }
    .sm\:mx-36 {
        margin-left: 3.6rem;
        margin-right: 3.6rem;
    }
    .sm\:my-40 {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .sm\:mx-40 {
        margin-left: 4rem;
        margin-right: 4rem;
    }
    .sm\:my-44 {
        margin-top: 4.4rem;
        margin-bottom: 4.4rem;
    }
    .sm\:mx-44 {
        margin-left: 4.4rem;
        margin-right: 4.4rem;
    }
    .sm\:my-48 {
        margin-top: 4.8rem;
        margin-bottom: 4.8rem;
    }
    .sm\:mx-48 {
        margin-left: 4.8rem;
        margin-right: 4.8rem;
    }
    .sm\:my-52 {
        margin-top: 5.2rem;
        margin-bottom: 5.2rem;
    }
    .sm\:mx-52 {
        margin-left: 5.2rem;
        margin-right: 5.2rem;
    }
    .sm\:my-56 {
        margin-top: 5.6rem;
        margin-bottom: 5.6rem;
    }
    .sm\:mx-56 {
        margin-left: 5.6rem;
        margin-right: 5.6rem;
    }
    .sm\:my-60 {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
    .sm\:mx-60 {
        margin-left: 6rem;
        margin-right: 6rem;
    }
    .sm\:my-64 {
        margin-top: 6.4rem;
        margin-bottom: 6.4rem;
    }
    .sm\:mx-64 {
        margin-left: 6.4rem;
        margin-right: 6.4rem;
    }
    .sm\:my-68 {
        margin-top: 6.8rem;
        margin-bottom: 6.8rem;
    }
    .sm\:mx-68 {
        margin-left: 6.8rem;
        margin-right: 6.8rem;
    }
    .sm\:my-72 {
        margin-top: 7.2rem;
        margin-bottom: 7.2rem;
    }
    .sm\:mx-72 {
        margin-left: 7.2rem;
        margin-right: 7.2rem;
    }
    .sm\:my-76 {
        margin-top: 7.6rem;
        margin-bottom: 7.6rem;
    }
    .sm\:mx-76 {
        margin-left: 7.6rem;
        margin-right: 7.6rem;
    }
    .sm\:my-80 {
        margin-top: 8rem;
        margin-bottom: 8rem;
    }
    .sm\:mx-80 {
        margin-left: 8rem;
        margin-right: 8rem;
    }
    .sm\:my-84 {
        margin-top: 8.4rem;
        margin-bottom: 8.4rem;
    }
    .sm\:mx-84 {
        margin-left: 8.4rem;
        margin-right: 8.4rem;
    }
    .sm\:my-88 {
        margin-top: 8.8rem;
        margin-bottom: 8.8rem;
    }
    .sm\:mx-88 {
        margin-left: 8.8rem;
        margin-right: 8.8rem;
    }
    .sm\:my-92 {
        margin-top: 9.2rem;
        margin-bottom: 9.2rem;
    }
    .sm\:mx-92 {
        margin-left: 9.2rem;
        margin-right: 9.2rem;
    }
    .sm\:my-96 {
        margin-top: 9.6rem;
        margin-bottom: 9.6rem;
    }
    .sm\:mx-96 {
        margin-left: 9.6rem;
        margin-right: 9.6rem;
    }
    .sm\:my-128 {
        margin-top: 12.8rem;
        margin-bottom: 12.8rem;
    }
    .sm\:mx-128 {
        margin-left: 12.8rem;
        margin-right: 12.8rem;
    }
    .sm\:my-160 {
        margin-top: 16rem;
        margin-bottom: 16rem;
    }
    .sm\:mx-160 {
        margin-left: 16rem;
        margin-right: 16rem;
    }
    .sm\:my-192 {
        margin-top: 19.2rem;
        margin-bottom: 19.2rem;
    }
    .sm\:mx-192 {
        margin-left: 19.2rem;
        margin-right: 19.2rem;
    }
    .sm\:my-224 {
        margin-top: 22.4rem;
        margin-bottom: 22.4rem;
    }
    .sm\:mx-224 {
        margin-left: 22.4rem;
        margin-right: 22.4rem;
    }
    .sm\:my-256 {
        margin-top: 25.6rem;
        margin-bottom: 25.6rem;
    }
    .sm\:mx-256 {
        margin-left: 25.6rem;
        margin-right: 25.6rem;
    }
    .sm\:my-auto {
        margin-top: auto;
        margin-bottom: auto;
    }
    .sm\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .sm\:my-px {
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .sm\:mx-px {
        margin-left: 1px;
        margin-right: 1px;
    }
    .sm\:mt-0 {
        margin-top: 0;
    }
    .sm\:mr-0 {
        margin-right: 0;
    }
    .sm\:mb-0 {
        margin-bottom: 0;
    }
    .sm\:ml-0 {
        margin-left: 0;
    }
    .sm\:mt-4 {
        margin-top: .4rem;
    }
    .sm\:mr-4 {
        margin-right: .4rem;
    }
    .sm\:mb-4 {
        margin-bottom: .4rem;
    }
    .sm\:ml-4 {
        margin-left: .4rem;
    }
    .sm\:mt-8 {
        margin-top: .8rem;
    }
    .sm\:mr-8 {
        margin-right: .8rem;
    }
    .sm\:mb-8 {
        margin-bottom: .8rem;
    }
    .sm\:ml-8 {
        margin-left: .8rem;
    }
    .sm\:mt-12 {
        margin-top: 1.2rem;
    }
    .sm\:mr-12 {
        margin-right: 1.2rem;
    }
    .sm\:mb-12 {
        margin-bottom: 1.2rem;
    }
    .sm\:ml-12 {
        margin-left: 1.2rem;
    }
    .sm\:mt-16 {
        margin-top: 1.6rem;
    }
    .sm\:mr-16 {
        margin-right: 1.6rem;
    }
    .sm\:mb-16 {
        margin-bottom: 1.6rem;
    }
    .sm\:ml-16 {
        margin-left: 1.6rem;
    }
    .sm\:mt-20 {
        margin-top: 2rem;
    }
    .sm\:mr-20 {
        margin-right: 2rem;
    }
    .sm\:mb-20 {
        margin-bottom: 2rem;
    }
    .sm\:ml-20 {
        margin-left: 2rem;
    }
    .sm\:mt-24 {
        margin-top: 2.4rem;
    }
    .sm\:mr-24 {
        margin-right: 2.4rem;
    }
    .sm\:mb-24 {
        margin-bottom: 2.4rem;
    }
    .sm\:ml-24 {
        margin-left: 2.4rem;
    }
    .sm\:mt-28 {
        margin-top: 2.8rem;
    }
    .sm\:mr-28 {
        margin-right: 2.8rem;
    }
    .sm\:mb-28 {
        margin-bottom: 2.8rem;
    }
    .sm\:ml-28 {
        margin-left: 2.8rem;
    }
    .sm\:mt-32 {
        margin-top: 3.2rem;
    }
    .sm\:mr-32 {
        margin-right: 3.2rem;
    }
    .sm\:mb-32 {
        margin-bottom: 3.2rem;
    }
    .sm\:ml-32 {
        margin-left: 3.2rem;
    }
    .sm\:mt-36 {
        margin-top: 3.6rem;
    }
    .sm\:mr-36 {
        margin-right: 3.6rem;
    }
    .sm\:mb-36 {
        margin-bottom: 3.6rem;
    }
    .sm\:ml-36 {
        margin-left: 3.6rem;
    }
    .sm\:mt-40 {
        margin-top: 4rem;
    }
    .sm\:mr-40 {
        margin-right: 4rem;
    }
    .sm\:mb-40 {
        margin-bottom: 4rem;
    }
    .sm\:ml-40 {
        margin-left: 4rem;
    }
    .sm\:mt-44 {
        margin-top: 4.4rem;
    }
    .sm\:mr-44 {
        margin-right: 4.4rem;
    }
    .sm\:mb-44 {
        margin-bottom: 4.4rem;
    }
    .sm\:ml-44 {
        margin-left: 4.4rem;
    }
    .sm\:mt-48 {
        margin-top: 4.8rem;
    }
    .sm\:mr-48 {
        margin-right: 4.8rem;
    }
    .sm\:mb-48 {
        margin-bottom: 4.8rem;
    }
    .sm\:ml-48 {
        margin-left: 4.8rem;
    }
    .sm\:mt-52 {
        margin-top: 5.2rem;
    }
    .sm\:mr-52 {
        margin-right: 5.2rem;
    }
    .sm\:mb-52 {
        margin-bottom: 5.2rem;
    }
    .sm\:ml-52 {
        margin-left: 5.2rem;
    }
    .sm\:mt-56 {
        margin-top: 5.6rem;
    }
    .sm\:mr-56 {
        margin-right: 5.6rem;
    }
    .sm\:mb-56 {
        margin-bottom: 5.6rem;
    }
    .sm\:ml-56 {
        margin-left: 5.6rem;
    }
    .sm\:mt-60 {
        margin-top: 6rem;
    }
    .sm\:mr-60 {
        margin-right: 6rem;
    }
    .sm\:mb-60 {
        margin-bottom: 6rem;
    }
    .sm\:ml-60 {
        margin-left: 6rem;
    }
    .sm\:mt-64 {
        margin-top: 6.4rem;
    }
    .sm\:mr-64 {
        margin-right: 6.4rem;
    }
    .sm\:mb-64 {
        margin-bottom: 6.4rem;
    }
    .sm\:ml-64 {
        margin-left: 6.4rem;
    }
    .sm\:mt-68 {
        margin-top: 6.8rem;
    }
    .sm\:mr-68 {
        margin-right: 6.8rem;
    }
    .sm\:mb-68 {
        margin-bottom: 6.8rem;
    }
    .sm\:ml-68 {
        margin-left: 6.8rem;
    }
    .sm\:mt-72 {
        margin-top: 7.2rem;
    }
    .sm\:mr-72 {
        margin-right: 7.2rem;
    }
    .sm\:mb-72 {
        margin-bottom: 7.2rem;
    }
    .sm\:ml-72 {
        margin-left: 7.2rem;
    }
    .sm\:mt-76 {
        margin-top: 7.6rem;
    }
    .sm\:mr-76 {
        margin-right: 7.6rem;
    }
    .sm\:mb-76 {
        margin-bottom: 7.6rem;
    }
    .sm\:ml-76 {
        margin-left: 7.6rem;
    }
    .sm\:mt-80 {
        margin-top: 8rem;
    }
    .sm\:mr-80 {
        margin-right: 8rem;
    }
    .sm\:mb-80 {
        margin-bottom: 8rem;
    }
    .sm\:ml-80 {
        margin-left: 8rem;
    }
    .sm\:mt-84 {
        margin-top: 8.4rem;
    }
    .sm\:mr-84 {
        margin-right: 8.4rem;
    }
    .sm\:mb-84 {
        margin-bottom: 8.4rem;
    }
    .sm\:ml-84 {
        margin-left: 8.4rem;
    }
    .sm\:mt-88 {
        margin-top: 8.8rem;
    }
    .sm\:mr-88 {
        margin-right: 8.8rem;
    }
    .sm\:mb-88 {
        margin-bottom: 8.8rem;
    }
    .sm\:ml-88 {
        margin-left: 8.8rem;
    }
    .sm\:mt-92 {
        margin-top: 9.2rem;
    }
    .sm\:mr-92 {
        margin-right: 9.2rem;
    }
    .sm\:mb-92 {
        margin-bottom: 9.2rem;
    }
    .sm\:ml-92 {
        margin-left: 9.2rem;
    }
    .sm\:mt-96 {
        margin-top: 9.6rem;
    }
    .sm\:mr-96 {
        margin-right: 9.6rem;
    }
    .sm\:mb-96 {
        margin-bottom: 9.6rem;
    }
    .sm\:ml-96 {
        margin-left: 9.6rem;
    }
    .sm\:mt-128 {
        margin-top: 12.8rem;
    }
    .sm\:mr-128 {
        margin-right: 12.8rem;
    }
    .sm\:mb-128 {
        margin-bottom: 12.8rem;
    }
    .sm\:ml-128 {
        margin-left: 12.8rem;
    }
    .sm\:mt-160 {
        margin-top: 16rem;
    }
    .sm\:mr-160 {
        margin-right: 16rem;
    }
    .sm\:mb-160 {
        margin-bottom: 16rem;
    }
    .sm\:ml-160 {
        margin-left: 16rem;
    }
    .sm\:mt-192 {
        margin-top: 19.2rem;
    }
    .sm\:mr-192 {
        margin-right: 19.2rem;
    }
    .sm\:mb-192 {
        margin-bottom: 19.2rem;
    }
    .sm\:ml-192 {
        margin-left: 19.2rem;
    }
    .sm\:mt-224 {
        margin-top: 22.4rem;
    }
    .sm\:mr-224 {
        margin-right: 22.4rem;
    }
    .sm\:mb-224 {
        margin-bottom: 22.4rem;
    }
    .sm\:ml-224 {
        margin-left: 22.4rem;
    }
    .sm\:mt-256 {
        margin-top: 25.6rem;
    }
    .sm\:mr-256 {
        margin-right: 25.6rem;
    }
    .sm\:mb-256 {
        margin-bottom: 25.6rem;
    }
    .sm\:ml-256 {
        margin-left: 25.6rem;
    }
    .sm\:mt-auto {
        margin-top: auto;
    }
    .sm\:mr-auto {
        margin-right: auto;
    }
    .sm\:mb-auto {
        margin-bottom: auto;
    }
    .sm\:ml-auto {
        margin-left: auto;
    }
    .sm\:mt-px {
        margin-top: 1px;
    }
    .sm\:mr-px {
        margin-right: 1px;
    }
    .sm\:mb-px {
        margin-bottom: 1px;
    }
    .sm\:ml-px {
        margin-left: 1px;
    }
    .sm\:max-h-4 {
        max-height: .4rem;
    }
    .sm\:max-h-8 {
        max-height: .8rem;
    }
    .sm\:max-h-12 {
        max-height: 1.2rem;
    }
    .sm\:max-h-16 {
        max-height: 1.6rem;
    }
    .sm\:max-h-20 {
        max-height: 2rem;
    }
    .sm\:max-h-24 {
        max-height: 2.4rem;
    }
    .sm\:max-h-28 {
        max-height: 2.8rem;
    }
    .sm\:max-h-32 {
        max-height: 3.2rem;
    }
    .sm\:max-h-36 {
        max-height: 3.6rem;
    }
    .sm\:max-h-40 {
        max-height: 4rem;
    }
    .sm\:max-h-44 {
        max-height: 4.4rem;
    }
    .sm\:max-h-48 {
        max-height: 4.8rem;
    }
    .sm\:max-h-52 {
        max-height: 5.2rem;
    }
    .sm\:max-h-56 {
        max-height: 5.6rem;
    }
    .sm\:max-h-60 {
        max-height: 6rem;
    }
    .sm\:max-h-64 {
        max-height: 6.4rem;
    }
    .sm\:max-h-68 {
        max-height: 6.8rem;
    }
    .sm\:max-h-72 {
        max-height: 7.2rem;
    }
    .sm\:max-h-76 {
        max-height: 7.6rem;
    }
    .sm\:max-h-80 {
        max-height: 8rem;
    }
    .sm\:max-h-84 {
        max-height: 8.4rem;
    }
    .sm\:max-h-88 {
        max-height: 8.8rem;
    }
    .sm\:max-h-92 {
        max-height: 9.2rem;
    }
    .sm\:max-h-96 {
        max-height: 9.6rem;
    }
    .sm\:max-h-128 {
        max-height: 12.8rem;
    }
    .sm\:max-h-160 {
        max-height: 16rem;
    }
    .sm\:max-h-192 {
        max-height: 19.2rem;
    }
    .sm\:max-h-224 {
        max-height: 22.4rem;
    }
    .sm\:max-h-256 {
        max-height: 25.6rem;
    }
    .sm\:max-h-288 {
        max-height: 28.8rem;
    }
    .sm\:max-h-320 {
        max-height: 32rem;
    }
    .sm\:max-h-512 {
        max-height: 51.2rem;
    }
    .sm\:max-h-full {
        max-height: 100%;
    }
    .sm\:max-h-screen {
        max-height: 100vh;
    }
    .sm\:max-w-4 {
        max-width: .4rem;
    }
    .sm\:max-w-8 {
        max-width: .8rem;
    }
    .sm\:max-w-12 {
        max-width: 1.2rem;
    }
    .sm\:max-w-16 {
        max-width: 1.6rem;
    }
    .sm\:max-w-20 {
        max-width: 2rem;
    }
    .sm\:max-w-24 {
        max-width: 2.4rem;
    }
    .sm\:max-w-28 {
        max-width: 2.8rem;
    }
    .sm\:max-w-32 {
        max-width: 3.2rem;
    }
    .sm\:max-w-36 {
        max-width: 3.6rem;
    }
    .sm\:max-w-40 {
        max-width: 4rem;
    }
    .sm\:max-w-44 {
        max-width: 4.4rem;
    }
    .sm\:max-w-48 {
        max-width: 4.8rem;
    }
    .sm\:max-w-52 {
        max-width: 5.2rem;
    }
    .sm\:max-w-56 {
        max-width: 5.6rem;
    }
    .sm\:max-w-60 {
        max-width: 6rem;
    }
    .sm\:max-w-64 {
        max-width: 6.4rem;
    }
    .sm\:max-w-96 {
        max-width: 9.6rem;
    }
    .sm\:max-w-128 {
        max-width: 12.8rem;
    }
    .sm\:max-w-160 {
        max-width: 16rem;
    }
    .sm\:max-w-192 {
        max-width: 19.2rem;
    }
    .sm\:max-w-224 {
        max-width: 22.4rem;
    }
    .sm\:max-w-256 {
        max-width: 25.6rem;
    }
    .sm\:max-w-288 {
        max-width: 28.8rem;
    }
    .sm\:max-w-320 {
        max-width: 32rem;
    }
    .sm\:max-w-512 {
        max-width: 51.2rem;
    }
    .sm\:max-w-xs {
        max-width: 32rem;
    }
    .sm\:max-w-sm {
        max-width: 48rem;
    }
    .sm\:max-w-md {
        max-width: 64rem;
    }
    .sm\:max-w-lg {
        max-width: 80rem;
    }
    .sm\:max-w-xl {
        max-width: 96rem;
    }
    .sm\:max-w-2xl {
        max-width: 112rem;
    }
    .sm\:max-w-3xl {
        max-width: 128rem;
    }
    .sm\:max-w-4xl {
        max-width: 144rem;
    }
    .sm\:max-w-5xl {
        max-width: 160rem;
    }
    .sm\:max-w-full {
        max-width: 100%;
    }
    .sm\:min-h-4 {
        min-height: .4rem;
    }
    .sm\:min-h-8 {
        min-height: .8rem;
    }
    .sm\:min-h-12 {
        min-height: 1.2rem;
    }
    .sm\:min-h-16 {
        min-height: 1.6rem;
    }
    .sm\:min-h-20 {
        min-height: 2rem;
    }
    .sm\:min-h-24 {
        min-height: 2.4rem;
    }
    .sm\:min-h-28 {
        min-height: 2.8rem;
    }
    .sm\:min-h-32 {
        min-height: 3.2rem;
    }
    .sm\:min-h-36 {
        min-height: 3.6rem;
    }
    .sm\:min-h-40 {
        min-height: 4rem;
    }
    .sm\:min-h-44 {
        min-height: 4.4rem;
    }
    .sm\:min-h-48 {
        min-height: 4.8rem;
    }
    .sm\:min-h-52 {
        min-height: 5.2rem;
    }
    .sm\:min-h-56 {
        min-height: 5.6rem;
    }
    .sm\:min-h-60 {
        min-height: 6rem;
    }
    .sm\:min-h-64 {
        min-height: 6.4rem;
    }
    .sm\:min-h-68 {
        min-height: 6.8rem;
    }
    .sm\:min-h-72 {
        min-height: 7.2rem;
    }
    .sm\:min-h-76 {
        min-height: 7.6rem;
    }
    .sm\:min-h-80 {
        min-height: 8rem;
    }
    .sm\:min-h-84 {
        min-height: 8.4rem;
    }
    .sm\:min-h-88 {
        min-height: 8.8rem;
    }
    .sm\:min-h-92 {
        min-height: 9.2rem;
    }
    .sm\:min-h-96 {
        min-height: 9.6rem;
    }
    .sm\:min-h-128 {
        min-height: 12.8rem;
    }
    .sm\:min-h-160 {
        min-height: 16rem;
    }
    .sm\:min-h-192 {
        min-height: 19.2rem;
    }
    .sm\:min-h-224 {
        min-height: 22.4rem;
    }
    .sm\:min-h-256 {
        min-height: 25.6rem;
    }
    .sm\:min-h-288 {
        min-height: 28.8rem;
    }
    .sm\:min-h-320 {
        min-height: 32rem;
    }
    .sm\:min-h-512 {
        min-height: 51.2rem;
    }
    .sm\:min-h-auto {
        min-height: auto;
    }
    .sm\:min-h-px {
        min-height: 1px;
    }
    .sm\:min-h-full {
        min-height: 100%;
    }
    .sm\:min-h-screen {
        min-height: 100vh;
    }
    .sm\:min-w-0 {
        min-width: 0;
    }
    .sm\:min-w-4 {
        min-width: .4rem;
    }
    .sm\:min-w-8 {
        min-width: .8rem;
    }
    .sm\:min-w-12 {
        min-width: 1.2rem;
    }
    .sm\:min-w-16 {
        min-width: 1.6rem;
    }
    .sm\:min-w-20 {
        min-width: 2rem;
    }
    .sm\:min-w-24 {
        min-width: 2.4rem;
    }
    .sm\:min-w-28 {
        min-width: 2.8rem;
    }
    .sm\:min-w-32 {
        min-width: 3.2rem;
    }
    .sm\:min-w-36 {
        min-width: 3.6rem;
    }
    .sm\:min-w-40 {
        min-width: 4rem;
    }
    .sm\:min-w-44 {
        min-width: 4.4rem;
    }
    .sm\:min-w-48 {
        min-width: 4.8rem;
    }
    .sm\:min-w-52 {
        min-width: 5.2rem;
    }
    .sm\:min-w-56 {
        min-width: 5.6rem;
    }
    .sm\:min-w-60 {
        min-width: 6rem;
    }
    .sm\:min-w-64 {
        min-width: 6.4rem;
    }
    .sm\:min-w-68 {
        min-width: 6.8rem;
    }
    .sm\:min-w-72 {
        min-width: 7.2rem;
    }
    .sm\:min-w-76 {
        min-width: 7.6rem;
    }
    .sm\:min-w-80 {
        min-width: 8rem;
    }
    .sm\:min-w-84 {
        min-width: 8.4rem;
    }
    .sm\:min-w-88 {
        min-width: 8.8rem;
    }
    .sm\:min-w-92 {
        min-width: 9.2rem;
    }
    .sm\:min-w-96 {
        min-width: 9.6rem;
    }
    .sm\:min-w-128 {
        min-width: 12.8rem;
    }
    .sm\:min-w-160 {
        min-width: 16rem;
    }
    .sm\:min-w-192 {
        min-width: 19.2rem;
    }
    .sm\:min-w-224 {
        min-width: 22.4rem;
    }
    .sm\:min-w-256 {
        min-width: 25.6rem;
    }
    .sm\:min-w-288 {
        min-width: 28.8rem;
    }
    .sm\:min-w-320 {
        min-width: 32rem;
    }
    .sm\:min-w-512 {
        min-width: 51.2rem;
    }
    .sm\:min-w-auto {
        min-width: auto;
    }
    .sm\:min-w-px {
        min-width: 1px;
    }
    .sm\:min-w-1\/2 {
        min-width: 50%;
    }
    .sm\:min-w-1\/3 {
        min-width: 33.33333%;
    }
    .sm\:min-w-2\/3 {
        min-width: 66.66667%;
    }
    .sm\:min-w-1\/4 {
        min-width: 25%;
    }
    .sm\:min-w-3\/4 {
        min-width: 75%;
    }
    .sm\:min-w-1\/5 {
        min-width: 20%;
    }
    .sm\:min-w-2\/5 {
        min-width: 40%;
    }
    .sm\:min-w-3\/5 {
        min-width: 60%;
    }
    .sm\:min-w-4\/5 {
        min-width: 80%;
    }
    .sm\:min-w-1\/6 {
        min-width: 16.66667%;
    }
    .sm\:min-w-5\/6 {
        min-width: 83.33333%;
    }
    .sm\:min-w-full {
        min-width: 100%;
    }
    .sm\:min-w-screen {
        min-width: 100vw;
    }
    .sm\:-m-0 {
        margin: 0;
    }
    .sm\:-m-4 {
        margin: -0.4rem;
    }
    .sm\:-m-8 {
        margin: -0.8rem;
    }
    .sm\:-m-12 {
        margin: -1.2rem;
    }
    .sm\:-m-16 {
        margin: -1.6rem;
    }
    .sm\:-m-20 {
        margin: -2rem;
    }
    .sm\:-m-24 {
        margin: -2.4rem;
    }
    .sm\:-m-28 {
        margin: -2.8rem;
    }
    .sm\:-m-32 {
        margin: -3.2rem;
    }
    .sm\:-m-36 {
        margin: -3.6rem;
    }
    .sm\:-m-40 {
        margin: -4rem;
    }
    .sm\:-m-44 {
        margin: -4.4rem;
    }
    .sm\:-m-48 {
        margin: -4.8rem;
    }
    .sm\:-m-52 {
        margin: -5.2rem;
    }
    .sm\:-m-56 {
        margin: -5.6rem;
    }
    .sm\:-m-60 {
        margin: -6rem;
    }
    .sm\:-m-64 {
        margin: -6.4rem;
    }
    .sm\:-m-68 {
        margin: -6.8rem;
    }
    .sm\:-m-72 {
        margin: -7.2rem;
    }
    .sm\:-m-76 {
        margin: -7.6rem;
    }
    .sm\:-m-80 {
        margin: -8rem;
    }
    .sm\:-m-84 {
        margin: -8.4rem;
    }
    .sm\:-m-88 {
        margin: -8.8rem;
    }
    .sm\:-m-92 {
        margin: -9.2rem;
    }
    .sm\:-m-96 {
        margin: -9.6rem;
    }
    .sm\:-m-128 {
        margin: -12.8rem;
    }
    .sm\:-m-160 {
        margin: -16rem;
    }
    .sm\:-m-192 {
        margin: -19.2rem;
    }
    .sm\:-m-224 {
        margin: -22.4rem;
    }
    .sm\:-m-256 {
        margin: -25.6rem;
    }
    .sm\:-m-px {
        margin: -1px;
    }
    .sm\:-my-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .sm\:-mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .sm\:-my-4 {
        margin-top: -0.4rem;
        margin-bottom: -0.4rem;
    }
    .sm\:-mx-4 {
        margin-left: -0.4rem;
        margin-right: -0.4rem;
    }
    .sm\:-my-8 {
        margin-top: -0.8rem;
        margin-bottom: -0.8rem;
    }
    .sm\:-mx-8 {
        margin-left: -0.8rem;
        margin-right: -0.8rem;
    }
    .sm\:-my-12 {
        margin-top: -1.2rem;
        margin-bottom: -1.2rem;
    }
    .sm\:-mx-12 {
        margin-left: -1.2rem;
        margin-right: -1.2rem;
    }
    .sm\:-my-16 {
        margin-top: -1.6rem;
        margin-bottom: -1.6rem;
    }
    .sm\:-mx-16 {
        margin-left: -1.6rem;
        margin-right: -1.6rem;
    }
    .sm\:-my-20 {
        margin-top: -2rem;
        margin-bottom: -2rem;
    }
    .sm\:-mx-20 {
        margin-left: -2rem;
        margin-right: -2rem;
    }
    .sm\:-my-24 {
        margin-top: -2.4rem;
        margin-bottom: -2.4rem;
    }
    .sm\:-mx-24 {
        margin-left: -2.4rem;
        margin-right: -2.4rem;
    }
    .sm\:-my-28 {
        margin-top: -2.8rem;
        margin-bottom: -2.8rem;
    }
    .sm\:-mx-28 {
        margin-left: -2.8rem;
        margin-right: -2.8rem;
    }
    .sm\:-my-32 {
        margin-top: -3.2rem;
        margin-bottom: -3.2rem;
    }
    .sm\:-mx-32 {
        margin-left: -3.2rem;
        margin-right: -3.2rem;
    }
    .sm\:-my-36 {
        margin-top: -3.6rem;
        margin-bottom: -3.6rem;
    }
    .sm\:-mx-36 {
        margin-left: -3.6rem;
        margin-right: -3.6rem;
    }
    .sm\:-my-40 {
        margin-top: -4rem;
        margin-bottom: -4rem;
    }
    .sm\:-mx-40 {
        margin-left: -4rem;
        margin-right: -4rem;
    }
    .sm\:-my-44 {
        margin-top: -4.4rem;
        margin-bottom: -4.4rem;
    }
    .sm\:-mx-44 {
        margin-left: -4.4rem;
        margin-right: -4.4rem;
    }
    .sm\:-my-48 {
        margin-top: -4.8rem;
        margin-bottom: -4.8rem;
    }
    .sm\:-mx-48 {
        margin-left: -4.8rem;
        margin-right: -4.8rem;
    }
    .sm\:-my-52 {
        margin-top: -5.2rem;
        margin-bottom: -5.2rem;
    }
    .sm\:-mx-52 {
        margin-left: -5.2rem;
        margin-right: -5.2rem;
    }
    .sm\:-my-56 {
        margin-top: -5.6rem;
        margin-bottom: -5.6rem;
    }
    .sm\:-mx-56 {
        margin-left: -5.6rem;
        margin-right: -5.6rem;
    }
    .sm\:-my-60 {
        margin-top: -6rem;
        margin-bottom: -6rem;
    }
    .sm\:-mx-60 {
        margin-left: -6rem;
        margin-right: -6rem;
    }
    .sm\:-my-64 {
        margin-top: -6.4rem;
        margin-bottom: -6.4rem;
    }
    .sm\:-mx-64 {
        margin-left: -6.4rem;
        margin-right: -6.4rem;
    }
    .sm\:-my-68 {
        margin-top: -6.8rem;
        margin-bottom: -6.8rem;
    }
    .sm\:-mx-68 {
        margin-left: -6.8rem;
        margin-right: -6.8rem;
    }
    .sm\:-my-72 {
        margin-top: -7.2rem;
        margin-bottom: -7.2rem;
    }
    .sm\:-mx-72 {
        margin-left: -7.2rem;
        margin-right: -7.2rem;
    }
    .sm\:-my-76 {
        margin-top: -7.6rem;
        margin-bottom: -7.6rem;
    }
    .sm\:-mx-76 {
        margin-left: -7.6rem;
        margin-right: -7.6rem;
    }
    .sm\:-my-80 {
        margin-top: -8rem;
        margin-bottom: -8rem;
    }
    .sm\:-mx-80 {
        margin-left: -8rem;
        margin-right: -8rem;
    }
    .sm\:-my-84 {
        margin-top: -8.4rem;
        margin-bottom: -8.4rem;
    }
    .sm\:-mx-84 {
        margin-left: -8.4rem;
        margin-right: -8.4rem;
    }
    .sm\:-my-88 {
        margin-top: -8.8rem;
        margin-bottom: -8.8rem;
    }
    .sm\:-mx-88 {
        margin-left: -8.8rem;
        margin-right: -8.8rem;
    }
    .sm\:-my-92 {
        margin-top: -9.2rem;
        margin-bottom: -9.2rem;
    }
    .sm\:-mx-92 {
        margin-left: -9.2rem;
        margin-right: -9.2rem;
    }
    .sm\:-my-96 {
        margin-top: -9.6rem;
        margin-bottom: -9.6rem;
    }
    .sm\:-mx-96 {
        margin-left: -9.6rem;
        margin-right: -9.6rem;
    }
    .sm\:-my-128 {
        margin-top: -12.8rem;
        margin-bottom: -12.8rem;
    }
    .sm\:-mx-128 {
        margin-left: -12.8rem;
        margin-right: -12.8rem;
    }
    .sm\:-my-160 {
        margin-top: -16rem;
        margin-bottom: -16rem;
    }
    .sm\:-mx-160 {
        margin-left: -16rem;
        margin-right: -16rem;
    }
    .sm\:-my-192 {
        margin-top: -19.2rem;
        margin-bottom: -19.2rem;
    }
    .sm\:-mx-192 {
        margin-left: -19.2rem;
        margin-right: -19.2rem;
    }
    .sm\:-my-224 {
        margin-top: -22.4rem;
        margin-bottom: -22.4rem;
    }
    .sm\:-mx-224 {
        margin-left: -22.4rem;
        margin-right: -22.4rem;
    }
    .sm\:-my-256 {
        margin-top: -25.6rem;
        margin-bottom: -25.6rem;
    }
    .sm\:-mx-256 {
        margin-left: -25.6rem;
        margin-right: -25.6rem;
    }
    .sm\:-my-px {
        margin-top: -1px;
        margin-bottom: -1px;
    }
    .sm\:-mx-px {
        margin-left: -1px;
        margin-right: -1px;
    }
    .sm\:-mt-0 {
        margin-top: 0;
    }
    .sm\:-mr-0 {
        margin-right: 0;
    }
    .sm\:-mb-0 {
        margin-bottom: 0;
    }
    .sm\:-ml-0 {
        margin-left: 0;
    }
    .sm\:-mt-4 {
        margin-top: -0.4rem;
    }
    .sm\:-mr-4 {
        margin-right: -0.4rem;
    }
    .sm\:-mb-4 {
        margin-bottom: -0.4rem;
    }
    .sm\:-ml-4 {
        margin-left: -0.4rem;
    }
    .sm\:-mt-8 {
        margin-top: -0.8rem;
    }
    .sm\:-mr-8 {
        margin-right: -0.8rem;
    }
    .sm\:-mb-8 {
        margin-bottom: -0.8rem;
    }
    .sm\:-ml-8 {
        margin-left: -0.8rem;
    }
    .sm\:-mt-12 {
        margin-top: -1.2rem;
    }
    .sm\:-mr-12 {
        margin-right: -1.2rem;
    }
    .sm\:-mb-12 {
        margin-bottom: -1.2rem;
    }
    .sm\:-ml-12 {
        margin-left: -1.2rem;
    }
    .sm\:-mt-16 {
        margin-top: -1.6rem;
    }
    .sm\:-mr-16 {
        margin-right: -1.6rem;
    }
    .sm\:-mb-16 {
        margin-bottom: -1.6rem;
    }
    .sm\:-ml-16 {
        margin-left: -1.6rem;
    }
    .sm\:-mt-20 {
        margin-top: -2rem;
    }
    .sm\:-mr-20 {
        margin-right: -2rem;
    }
    .sm\:-mb-20 {
        margin-bottom: -2rem;
    }
    .sm\:-ml-20 {
        margin-left: -2rem;
    }
    .sm\:-mt-24 {
        margin-top: -2.4rem;
    }
    .sm\:-mr-24 {
        margin-right: -2.4rem;
    }
    .sm\:-mb-24 {
        margin-bottom: -2.4rem;
    }
    .sm\:-ml-24 {
        margin-left: -2.4rem;
    }
    .sm\:-mt-28 {
        margin-top: -2.8rem;
    }
    .sm\:-mr-28 {
        margin-right: -2.8rem;
    }
    .sm\:-mb-28 {
        margin-bottom: -2.8rem;
    }
    .sm\:-ml-28 {
        margin-left: -2.8rem;
    }
    .sm\:-mt-32 {
        margin-top: -3.2rem;
    }
    .sm\:-mr-32 {
        margin-right: -3.2rem;
    }
    .sm\:-mb-32 {
        margin-bottom: -3.2rem;
    }
    .sm\:-ml-32 {
        margin-left: -3.2rem;
    }
    .sm\:-mt-36 {
        margin-top: -3.6rem;
    }
    .sm\:-mr-36 {
        margin-right: -3.6rem;
    }
    .sm\:-mb-36 {
        margin-bottom: -3.6rem;
    }
    .sm\:-ml-36 {
        margin-left: -3.6rem;
    }
    .sm\:-mt-40 {
        margin-top: -4rem;
    }
    .sm\:-mr-40 {
        margin-right: -4rem;
    }
    .sm\:-mb-40 {
        margin-bottom: -4rem;
    }
    .sm\:-ml-40 {
        margin-left: -4rem;
    }
    .sm\:-mt-44 {
        margin-top: -4.4rem;
    }
    .sm\:-mr-44 {
        margin-right: -4.4rem;
    }
    .sm\:-mb-44 {
        margin-bottom: -4.4rem;
    }
    .sm\:-ml-44 {
        margin-left: -4.4rem;
    }
    .sm\:-mt-48 {
        margin-top: -4.8rem;
    }
    .sm\:-mr-48 {
        margin-right: -4.8rem;
    }
    .sm\:-mb-48 {
        margin-bottom: -4.8rem;
    }
    .sm\:-ml-48 {
        margin-left: -4.8rem;
    }
    .sm\:-mt-52 {
        margin-top: -5.2rem;
    }
    .sm\:-mr-52 {
        margin-right: -5.2rem;
    }
    .sm\:-mb-52 {
        margin-bottom: -5.2rem;
    }
    .sm\:-ml-52 {
        margin-left: -5.2rem;
    }
    .sm\:-mt-56 {
        margin-top: -5.6rem;
    }
    .sm\:-mr-56 {
        margin-right: -5.6rem;
    }
    .sm\:-mb-56 {
        margin-bottom: -5.6rem;
    }
    .sm\:-ml-56 {
        margin-left: -5.6rem;
    }
    .sm\:-mt-60 {
        margin-top: -6rem;
    }
    .sm\:-mr-60 {
        margin-right: -6rem;
    }
    .sm\:-mb-60 {
        margin-bottom: -6rem;
    }
    .sm\:-ml-60 {
        margin-left: -6rem;
    }
    .sm\:-mt-64 {
        margin-top: -6.4rem;
    }
    .sm\:-mr-64 {
        margin-right: -6.4rem;
    }
    .sm\:-mb-64 {
        margin-bottom: -6.4rem;
    }
    .sm\:-ml-64 {
        margin-left: -6.4rem;
    }
    .sm\:-mt-68 {
        margin-top: -6.8rem;
    }
    .sm\:-mr-68 {
        margin-right: -6.8rem;
    }
    .sm\:-mb-68 {
        margin-bottom: -6.8rem;
    }
    .sm\:-ml-68 {
        margin-left: -6.8rem;
    }
    .sm\:-mt-72 {
        margin-top: -7.2rem;
    }
    .sm\:-mr-72 {
        margin-right: -7.2rem;
    }
    .sm\:-mb-72 {
        margin-bottom: -7.2rem;
    }
    .sm\:-ml-72 {
        margin-left: -7.2rem;
    }
    .sm\:-mt-76 {
        margin-top: -7.6rem;
    }
    .sm\:-mr-76 {
        margin-right: -7.6rem;
    }
    .sm\:-mb-76 {
        margin-bottom: -7.6rem;
    }
    .sm\:-ml-76 {
        margin-left: -7.6rem;
    }
    .sm\:-mt-80 {
        margin-top: -8rem;
    }
    .sm\:-mr-80 {
        margin-right: -8rem;
    }
    .sm\:-mb-80 {
        margin-bottom: -8rem;
    }
    .sm\:-ml-80 {
        margin-left: -8rem;
    }
    .sm\:-mt-84 {
        margin-top: -8.4rem;
    }
    .sm\:-mr-84 {
        margin-right: -8.4rem;
    }
    .sm\:-mb-84 {
        margin-bottom: -8.4rem;
    }
    .sm\:-ml-84 {
        margin-left: -8.4rem;
    }
    .sm\:-mt-88 {
        margin-top: -8.8rem;
    }
    .sm\:-mr-88 {
        margin-right: -8.8rem;
    }
    .sm\:-mb-88 {
        margin-bottom: -8.8rem;
    }
    .sm\:-ml-88 {
        margin-left: -8.8rem;
    }
    .sm\:-mt-92 {
        margin-top: -9.2rem;
    }
    .sm\:-mr-92 {
        margin-right: -9.2rem;
    }
    .sm\:-mb-92 {
        margin-bottom: -9.2rem;
    }
    .sm\:-ml-92 {
        margin-left: -9.2rem;
    }
    .sm\:-mt-96 {
        margin-top: -9.6rem;
    }
    .sm\:-mr-96 {
        margin-right: -9.6rem;
    }
    .sm\:-mb-96 {
        margin-bottom: -9.6rem;
    }
    .sm\:-ml-96 {
        margin-left: -9.6rem;
    }
    .sm\:-mt-128 {
        margin-top: -12.8rem;
    }
    .sm\:-mr-128 {
        margin-right: -12.8rem;
    }
    .sm\:-mb-128 {
        margin-bottom: -12.8rem;
    }
    .sm\:-ml-128 {
        margin-left: -12.8rem;
    }
    .sm\:-mt-160 {
        margin-top: -16rem;
    }
    .sm\:-mr-160 {
        margin-right: -16rem;
    }
    .sm\:-mb-160 {
        margin-bottom: -16rem;
    }
    .sm\:-ml-160 {
        margin-left: -16rem;
    }
    .sm\:-mt-192 {
        margin-top: -19.2rem;
    }
    .sm\:-mr-192 {
        margin-right: -19.2rem;
    }
    .sm\:-mb-192 {
        margin-bottom: -19.2rem;
    }
    .sm\:-ml-192 {
        margin-left: -19.2rem;
    }
    .sm\:-mt-224 {
        margin-top: -22.4rem;
    }
    .sm\:-mr-224 {
        margin-right: -22.4rem;
    }
    .sm\:-mb-224 {
        margin-bottom: -22.4rem;
    }
    .sm\:-ml-224 {
        margin-left: -22.4rem;
    }
    .sm\:-mt-256 {
        margin-top: -25.6rem;
    }
    .sm\:-mr-256 {
        margin-right: -25.6rem;
    }
    .sm\:-mb-256 {
        margin-bottom: -25.6rem;
    }
    .sm\:-ml-256 {
        margin-left: -25.6rem;
    }
    .sm\:-mt-px {
        margin-top: -1px;
    }
    .sm\:-mr-px {
        margin-right: -1px;
    }
    .sm\:-mb-px {
        margin-bottom: -1px;
    }
    .sm\:-ml-px {
        margin-left: -1px;
    }
    .sm\:opacity-0 {
        opacity: 0;
    }
    .sm\:opacity-25 {
        opacity: .25;
    }
    .sm\:opacity-50 {
        opacity: .5;
    }
    .sm\:opacity-75 {
        opacity: .75;
    }
    .sm\:opacity-100 {
        opacity: 1;
    }
    .sm\:overflow-auto {
        overflow: auto;
    }
    .sm\:overflow-hidden {
        overflow: hidden;
    }
    .sm\:overflow-visible {
        overflow: visible;
    }
    .sm\:overflow-scroll {
        overflow: scroll;
    }
    .sm\:overflow-x-auto {
        overflow-x: auto;
    }
    .sm\:overflow-y-auto {
        overflow-y: auto;
    }
    .sm\:overflow-x-hidden {
        overflow-x: hidden;
    }
    .sm\:overflow-y-hidden {
        overflow-y: hidden;
    }
    .sm\:overflow-x-visible {
        overflow-x: visible;
    }
    .sm\:overflow-y-visible {
        overflow-y: visible;
    }
    .sm\:overflow-x-scroll {
        overflow-x: scroll;
    }
    .sm\:overflow-y-scroll {
        overflow-y: scroll;
    }
    .sm\:scrolling-touch {
        -webkit-overflow-scrolling: touch;
    }
    .sm\:scrolling-auto {
        -webkit-overflow-scrolling: auto;
    }
    .sm\:p-0 {
        padding: 0;
    }
    .sm\:p-4 {
        padding: .4rem;
    }
    .sm\:p-8 {
        padding: .8rem;
    }
    .sm\:p-12 {
        padding: 1.2rem;
    }
    .sm\:p-16 {
        padding: 1.6rem;
    }
    .sm\:p-20 {
        padding: 2rem;
    }
    .sm\:p-24 {
        padding: 2.4rem;
    }
    .sm\:p-28 {
        padding: 2.8rem;
    }
    .sm\:p-32 {
        padding: 3.2rem;
    }
    .sm\:p-36 {
        padding: 3.6rem;
    }
    .sm\:p-40 {
        padding: 4rem;
    }
    .sm\:p-44 {
        padding: 4.4rem;
    }
    .sm\:p-48 {
        padding: 4.8rem;
    }
    .sm\:p-52 {
        padding: 5.2rem;
    }
    .sm\:p-56 {
        padding: 5.6rem;
    }
    .sm\:p-60 {
        padding: 6rem;
    }
    .sm\:p-64 {
        padding: 6.4rem;
    }
    .sm\:p-68 {
        padding: 6.8rem;
    }
    .sm\:p-72 {
        padding: 7.2rem;
    }
    .sm\:p-76 {
        padding: 7.6rem;
    }
    .sm\:p-80 {
        padding: 8rem;
    }
    .sm\:p-84 {
        padding: 8.4rem;
    }
    .sm\:p-88 {
        padding: 8.8rem;
    }
    .sm\:p-92 {
        padding: 9.2rem;
    }
    .sm\:p-96 {
        padding: 9.6rem;
    }
    .sm\:p-128 {
        padding: 12.8rem;
    }
    .sm\:p-160 {
        padding: 16rem;
    }
    .sm\:p-192 {
        padding: 19.2rem;
    }
    .sm\:p-224 {
        padding: 22.4rem;
    }
    .sm\:p-256 {
        padding: 25.6rem;
    }
    .sm\:p-px {
        padding: 1px;
    }
    .sm\:py-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .sm\:px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .sm\:py-4 {
        padding-top: .4rem;
        padding-bottom: .4rem;
    }
    .sm\:px-4 {
        padding-left: .4rem;
        padding-right: .4rem;
    }
    .sm\:py-8 {
        padding-top: .8rem;
        padding-bottom: .8rem;
    }
    .sm\:px-8 {
        padding-left: .8rem;
        padding-right: .8rem;
    }
    .sm\:py-12 {
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }
    .sm\:px-12 {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
    .sm\:py-16 {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
    .sm\:px-16 {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
    .sm\:py-20 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .sm\:px-20 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .sm\:py-24 {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }
    .sm\:px-24 {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }
    .sm\:py-28 {
        padding-top: 2.8rem;
        padding-bottom: 2.8rem;
    }
    .sm\:px-28 {
        padding-left: 2.8rem;
        padding-right: 2.8rem;
    }
    .sm\:py-32 {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
    }
    .sm\:px-32 {
        padding-left: 3.2rem;
        padding-right: 3.2rem;
    }
    .sm\:py-36 {
        padding-top: 3.6rem;
        padding-bottom: 3.6rem;
    }
    .sm\:px-36 {
        padding-left: 3.6rem;
        padding-right: 3.6rem;
    }
    .sm\:py-40 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .sm\:px-40 {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .sm\:py-44 {
        padding-top: 4.4rem;
        padding-bottom: 4.4rem;
    }
    .sm\:px-44 {
        padding-left: 4.4rem;
        padding-right: 4.4rem;
    }
    .sm\:py-48 {
        padding-top: 4.8rem;
        padding-bottom: 4.8rem;
    }
    .sm\:px-48 {
        padding-left: 4.8rem;
        padding-right: 4.8rem;
    }
    .sm\:py-52 {
        padding-top: 5.2rem;
        padding-bottom: 5.2rem;
    }
    .sm\:px-52 {
        padding-left: 5.2rem;
        padding-right: 5.2rem;
    }
    .sm\:py-56 {
        padding-top: 5.6rem;
        padding-bottom: 5.6rem;
    }
    .sm\:px-56 {
        padding-left: 5.6rem;
        padding-right: 5.6rem;
    }
    .sm\:py-60 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .sm\:px-60 {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .sm\:py-64 {
        padding-top: 6.4rem;
        padding-bottom: 6.4rem;
    }
    .sm\:px-64 {
        padding-left: 6.4rem;
        padding-right: 6.4rem;
    }
    .sm\:py-68 {
        padding-top: 6.8rem;
        padding-bottom: 6.8rem;
    }
    .sm\:px-68 {
        padding-left: 6.8rem;
        padding-right: 6.8rem;
    }
    .sm\:py-72 {
        padding-top: 7.2rem;
        padding-bottom: 7.2rem;
    }
    .sm\:px-72 {
        padding-left: 7.2rem;
        padding-right: 7.2rem;
    }
    .sm\:py-76 {
        padding-top: 7.6rem;
        padding-bottom: 7.6rem;
    }
    .sm\:px-76 {
        padding-left: 7.6rem;
        padding-right: 7.6rem;
    }
    .sm\:py-80 {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    .sm\:px-80 {
        padding-left: 8rem;
        padding-right: 8rem;
    }
    .sm\:py-84 {
        padding-top: 8.4rem;
        padding-bottom: 8.4rem;
    }
    .sm\:px-84 {
        padding-left: 8.4rem;
        padding-right: 8.4rem;
    }
    .sm\:py-88 {
        padding-top: 8.8rem;
        padding-bottom: 8.8rem;
    }
    .sm\:px-88 {
        padding-left: 8.8rem;
        padding-right: 8.8rem;
    }
    .sm\:py-92 {
        padding-top: 9.2rem;
        padding-bottom: 9.2rem;
    }
    .sm\:px-92 {
        padding-left: 9.2rem;
        padding-right: 9.2rem;
    }
    .sm\:py-96 {
        padding-top: 9.6rem;
        padding-bottom: 9.6rem;
    }
    .sm\:px-96 {
        padding-left: 9.6rem;
        padding-right: 9.6rem;
    }
    .sm\:py-128 {
        padding-top: 12.8rem;
        padding-bottom: 12.8rem;
    }
    .sm\:px-128 {
        padding-left: 12.8rem;
        padding-right: 12.8rem;
    }
    .sm\:py-160 {
        padding-top: 16rem;
        padding-bottom: 16rem;
    }
    .sm\:px-160 {
        padding-left: 16rem;
        padding-right: 16rem;
    }
    .sm\:py-192 {
        padding-top: 19.2rem;
        padding-bottom: 19.2rem;
    }
    .sm\:px-192 {
        padding-left: 19.2rem;
        padding-right: 19.2rem;
    }
    .sm\:py-224 {
        padding-top: 22.4rem;
        padding-bottom: 22.4rem;
    }
    .sm\:px-224 {
        padding-left: 22.4rem;
        padding-right: 22.4rem;
    }
    .sm\:py-256 {
        padding-top: 25.6rem;
        padding-bottom: 25.6rem;
    }
    .sm\:px-256 {
        padding-left: 25.6rem;
        padding-right: 25.6rem;
    }
    .sm\:py-px {
        padding-top: 1px;
        padding-bottom: 1px;
    }
    .sm\:px-px {
        padding-left: 1px;
        padding-right: 1px;
    }
    .sm\:pt-0 {
        padding-top: 0;
    }
    .sm\:pr-0 {
        padding-right: 0;
    }
    .sm\:pb-0 {
        padding-bottom: 0;
    }
    .sm\:pl-0 {
        padding-left: 0;
    }
    .sm\:pt-4 {
        padding-top: .4rem;
    }
    .sm\:pr-4 {
        padding-right: .4rem;
    }
    .sm\:pb-4 {
        padding-bottom: .4rem;
    }
    .sm\:pl-4 {
        padding-left: .4rem;
    }
    .sm\:pt-8 {
        padding-top: .8rem;
    }
    .sm\:pr-8 {
        padding-right: .8rem;
    }
    .sm\:pb-8 {
        padding-bottom: .8rem;
    }
    .sm\:pl-8 {
        padding-left: .8rem;
    }
    .sm\:pt-12 {
        padding-top: 1.2rem;
    }
    .sm\:pr-12 {
        padding-right: 1.2rem;
    }
    .sm\:pb-12 {
        padding-bottom: 1.2rem;
    }
    .sm\:pl-12 {
        padding-left: 1.2rem;
    }
    .sm\:pt-16 {
        padding-top: 1.6rem;
    }
    .sm\:pr-16 {
        padding-right: 1.6rem;
    }
    .sm\:pb-16 {
        padding-bottom: 1.6rem;
    }
    .sm\:pl-16 {
        padding-left: 1.6rem;
    }
    .sm\:pt-20 {
        padding-top: 2rem;
    }
    .sm\:pr-20 {
        padding-right: 2rem;
    }
    .sm\:pb-20 {
        padding-bottom: 2rem;
    }
    .sm\:pl-20 {
        padding-left: 2rem;
    }
    .sm\:pt-24 {
        padding-top: 2.4rem;
    }
    .sm\:pr-24 {
        padding-right: 2.4rem;
    }
    .sm\:pb-24 {
        padding-bottom: 2.4rem;
    }
    .sm\:pl-24 {
        padding-left: 2.4rem;
    }
    .sm\:pt-28 {
        padding-top: 2.8rem;
    }
    .sm\:pr-28 {
        padding-right: 2.8rem;
    }
    .sm\:pb-28 {
        padding-bottom: 2.8rem;
    }
    .sm\:pl-28 {
        padding-left: 2.8rem;
    }
    .sm\:pt-32 {
        padding-top: 3.2rem;
    }
    .sm\:pr-32 {
        padding-right: 3.2rem;
    }
    .sm\:pb-32 {
        padding-bottom: 3.2rem;
    }
    .sm\:pl-32 {
        padding-left: 3.2rem;
    }
    .sm\:pt-36 {
        padding-top: 3.6rem;
    }
    .sm\:pr-36 {
        padding-right: 3.6rem;
    }
    .sm\:pb-36 {
        padding-bottom: 3.6rem;
    }
    .sm\:pl-36 {
        padding-left: 3.6rem;
    }
    .sm\:pt-40 {
        padding-top: 4rem;
    }
    .sm\:pr-40 {
        padding-right: 4rem;
    }
    .sm\:pb-40 {
        padding-bottom: 4rem;
    }
    .sm\:pl-40 {
        padding-left: 4rem;
    }
    .sm\:pt-44 {
        padding-top: 4.4rem;
    }
    .sm\:pr-44 {
        padding-right: 4.4rem;
    }
    .sm\:pb-44 {
        padding-bottom: 4.4rem;
    }
    .sm\:pl-44 {
        padding-left: 4.4rem;
    }
    .sm\:pt-48 {
        padding-top: 4.8rem;
    }
    .sm\:pr-48 {
        padding-right: 4.8rem;
    }
    .sm\:pb-48 {
        padding-bottom: 4.8rem;
    }
    .sm\:pl-48 {
        padding-left: 4.8rem;
    }
    .sm\:pt-52 {
        padding-top: 5.2rem;
    }
    .sm\:pr-52 {
        padding-right: 5.2rem;
    }
    .sm\:pb-52 {
        padding-bottom: 5.2rem;
    }
    .sm\:pl-52 {
        padding-left: 5.2rem;
    }
    .sm\:pt-56 {
        padding-top: 5.6rem;
    }
    .sm\:pr-56 {
        padding-right: 5.6rem;
    }
    .sm\:pb-56 {
        padding-bottom: 5.6rem;
    }
    .sm\:pl-56 {
        padding-left: 5.6rem;
    }
    .sm\:pt-60 {
        padding-top: 6rem;
    }
    .sm\:pr-60 {
        padding-right: 6rem;
    }
    .sm\:pb-60 {
        padding-bottom: 6rem;
    }
    .sm\:pl-60 {
        padding-left: 6rem;
    }
    .sm\:pt-64 {
        padding-top: 6.4rem;
    }
    .sm\:pr-64 {
        padding-right: 6.4rem;
    }
    .sm\:pb-64 {
        padding-bottom: 6.4rem;
    }
    .sm\:pl-64 {
        padding-left: 6.4rem;
    }
    .sm\:pt-68 {
        padding-top: 6.8rem;
    }
    .sm\:pr-68 {
        padding-right: 6.8rem;
    }
    .sm\:pb-68 {
        padding-bottom: 6.8rem;
    }
    .sm\:pl-68 {
        padding-left: 6.8rem;
    }
    .sm\:pt-72 {
        padding-top: 7.2rem;
    }
    .sm\:pr-72 {
        padding-right: 7.2rem;
    }
    .sm\:pb-72 {
        padding-bottom: 7.2rem;
    }
    .sm\:pl-72 {
        padding-left: 7.2rem;
    }
    .sm\:pt-76 {
        padding-top: 7.6rem;
    }
    .sm\:pr-76 {
        padding-right: 7.6rem;
    }
    .sm\:pb-76 {
        padding-bottom: 7.6rem;
    }
    .sm\:pl-76 {
        padding-left: 7.6rem;
    }
    .sm\:pt-80 {
        padding-top: 8rem;
    }
    .sm\:pr-80 {
        padding-right: 8rem;
    }
    .sm\:pb-80 {
        padding-bottom: 8rem;
    }
    .sm\:pl-80 {
        padding-left: 8rem;
    }
    .sm\:pt-84 {
        padding-top: 8.4rem;
    }
    .sm\:pr-84 {
        padding-right: 8.4rem;
    }
    .sm\:pb-84 {
        padding-bottom: 8.4rem;
    }
    .sm\:pl-84 {
        padding-left: 8.4rem;
    }
    .sm\:pt-88 {
        padding-top: 8.8rem;
    }
    .sm\:pr-88 {
        padding-right: 8.8rem;
    }
    .sm\:pb-88 {
        padding-bottom: 8.8rem;
    }
    .sm\:pl-88 {
        padding-left: 8.8rem;
    }
    .sm\:pt-92 {
        padding-top: 9.2rem;
    }
    .sm\:pr-92 {
        padding-right: 9.2rem;
    }
    .sm\:pb-92 {
        padding-bottom: 9.2rem;
    }
    .sm\:pl-92 {
        padding-left: 9.2rem;
    }
    .sm\:pt-96 {
        padding-top: 9.6rem;
    }
    .sm\:pr-96 {
        padding-right: 9.6rem;
    }
    .sm\:pb-96 {
        padding-bottom: 9.6rem;
    }
    .sm\:pl-96 {
        padding-left: 9.6rem;
    }
    .sm\:pt-128 {
        padding-top: 12.8rem;
    }
    .sm\:pr-128 {
        padding-right: 12.8rem;
    }
    .sm\:pb-128 {
        padding-bottom: 12.8rem;
    }
    .sm\:pl-128 {
        padding-left: 12.8rem;
    }
    .sm\:pt-160 {
        padding-top: 16rem;
    }
    .sm\:pr-160 {
        padding-right: 16rem;
    }
    .sm\:pb-160 {
        padding-bottom: 16rem;
    }
    .sm\:pl-160 {
        padding-left: 16rem;
    }
    .sm\:pt-192 {
        padding-top: 19.2rem;
    }
    .sm\:pr-192 {
        padding-right: 19.2rem;
    }
    .sm\:pb-192 {
        padding-bottom: 19.2rem;
    }
    .sm\:pl-192 {
        padding-left: 19.2rem;
    }
    .sm\:pt-224 {
        padding-top: 22.4rem;
    }
    .sm\:pr-224 {
        padding-right: 22.4rem;
    }
    .sm\:pb-224 {
        padding-bottom: 22.4rem;
    }
    .sm\:pl-224 {
        padding-left: 22.4rem;
    }
    .sm\:pt-256 {
        padding-top: 25.6rem;
    }
    .sm\:pr-256 {
        padding-right: 25.6rem;
    }
    .sm\:pb-256 {
        padding-bottom: 25.6rem;
    }
    .sm\:pl-256 {
        padding-left: 25.6rem;
    }
    .sm\:pt-px {
        padding-top: 1px;
    }
    .sm\:pr-px {
        padding-right: 1px;
    }
    .sm\:pb-px {
        padding-bottom: 1px;
    }
    .sm\:pl-px {
        padding-left: 1px;
    }
    .sm\:pointer-events-none {
        pointer-events: none;
    }
    .sm\:pointer-events-auto {
        pointer-events: auto;
    }
    .sm\:static {
        position: static;
    }
    .sm\:fixed {
        position: fixed;
    }
    .sm\:absolute {
        position: absolute;
    }
    .sm\:relative {
        position: relative;
    }
    .sm\:sticky {
        position: -webkit-sticky;
        position: sticky;
    }
    .sm\:pin-none {
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }
    .sm\:pin {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .sm\:pin-y {
        top: 0;
        bottom: 0;
    }
    .sm\:pin-x {
        right: 0;
        left: 0;
    }
    .sm\:pin-t {
        top: 0;
    }
    .sm\:pin-r {
        right: 0;
    }
    .sm\:pin-b {
        bottom: 0;
    }
    .sm\:pin-l {
        left: 0;
    }
    .sm\:resize-none {
        resize: none;
    }
    .sm\:resize-y {
        resize: vertical;
    }
    .sm\:resize-x {
        resize: horizontal;
    }
    .sm\:resize {
        resize: both;
    }
    .sm\:shadow {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    }
    .sm\:shadow-md {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
    }
    .sm\:shadow-lg {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    }
    .sm\:shadow-inner {
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
    }
    .sm\:shadow-none {
        box-shadow: none;
    }
    .sm\:text-left {
        text-align: left;
    }
    .sm\:text-center {
        text-align: center;
    }
    .sm\:text-right {
        text-align: right;
    }
    .sm\:text-justify {
        text-align: justify;
    }
    .sm\:text-transparent {
        color: transparent;
    }
    .sm\:text-black {
        color: #22292f;
    }
    .sm\:text-grey-darkest {
        color: #3d4852;
    }
    .sm\:text-grey-darker {
        color: #606f7b;
    }
    .sm\:text-grey-dark {
        color: #8795a1;
    }
    .sm\:text-grey {
        color: #b8c2cc;
    }
    .sm\:text-grey-light {
        color: #dae1e7;
    }
    .sm\:text-grey-lighter {
        color: #f1f5f8;
    }
    .sm\:text-grey-lightest {
        color: #f8fafc;
    }
    .sm\:text-white {
        color: #fff;
    }
    .sm\:text-red-darkest {
        color: #3b0d0c;
    }
    .sm\:text-red-darker {
        color: #621b18;
    }
    .sm\:text-red-dark {
        color: #cc1f1a;
    }
    .sm\:text-red {
        color: #e3342f;
    }
    .sm\:text-red-light {
        color: #ef5753;
    }
    .sm\:text-red-lighter {
        color: #f9acaa;
    }
    .sm\:text-red-lightest {
        color: #fcebea;
    }
    .sm\:text-orange-darkest {
        color: #462a16;
    }
    .sm\:text-orange-darker {
        color: #613b1f;
    }
    .sm\:text-orange-dark {
        color: #de751f;
    }
    .sm\:text-orange {
        color: #f6993f;
    }
    .sm\:text-orange-light {
        color: #faad63;
    }
    .sm\:text-orange-lighter {
        color: #fcd9b6;
    }
    .sm\:text-orange-lightest {
        color: #fff5eb;
    }
    .sm\:text-yellow-darkest {
        color: #453411;
    }
    .sm\:text-yellow-darker {
        color: #684f1d;
    }
    .sm\:text-yellow-dark {
        color: #f2d024;
    }
    .sm\:text-yellow {
        color: #ffed4a;
    }
    .sm\:text-yellow-light {
        color: #fff382;
    }
    .sm\:text-yellow-lighter {
        color: #fff9c2;
    }
    .sm\:text-yellow-lightest {
        color: #fcfbeb;
    }
    .sm\:text-green-darkest {
        color: #0f2f21;
    }
    .sm\:text-green-darker {
        color: #1a4731;
    }
    .sm\:text-green-dark {
        color: #1f9d55;
    }
    .sm\:text-green {
        color: #38c172;
    }
    .sm\:text-green-light {
        color: #51d88a;
    }
    .sm\:text-green-lighter {
        color: #a2f5bf;
    }
    .sm\:text-green-lightest {
        color: #e3fcec;
    }
    .sm\:text-teal-darkest {
        color: #0d3331;
    }
    .sm\:text-teal-darker {
        color: #20504f;
    }
    .sm\:text-teal-dark {
        color: #38a89d;
    }
    .sm\:text-teal {
        color: #4dc0b5;
    }
    .sm\:text-teal-light {
        color: #64d5ca;
    }
    .sm\:text-teal-lighter {
        color: #a0f0ed;
    }
    .sm\:text-teal-lightest {
        color: #e8fffe;
    }
    .sm\:text-blue-darkest {
        color: #12283a;
    }
    .sm\:text-blue-darker {
        color: #1c3d5a;
    }
    .sm\:text-blue-dark {
        color: #2779bd;
    }
    .sm\:text-blue {
        color: #3490dc;
    }
    .sm\:text-blue-light {
        color: #6cb2eb;
    }
    .sm\:text-blue-lighter {
        color: #bcdefa;
    }
    .sm\:text-blue-lightest {
        color: #eff8ff;
    }
    .sm\:text-indigo-darkest {
        color: #191e38;
    }
    .sm\:text-indigo-darker {
        color: #2f365f;
    }
    .sm\:text-indigo-dark {
        color: #5661b3;
    }
    .sm\:text-indigo {
        color: #6574cd;
    }
    .sm\:text-indigo-light {
        color: #7886d7;
    }
    .sm\:text-indigo-lighter {
        color: #b2b7ff;
    }
    .sm\:text-indigo-lightest {
        color: #e6e8ff;
    }
    .sm\:text-purple-darkest {
        color: #21183c;
    }
    .sm\:text-purple-darker {
        color: #382b5f;
    }
    .sm\:text-purple-dark {
        color: #794acf;
    }
    .sm\:text-purple {
        color: #9561e2;
    }
    .sm\:text-purple-light {
        color: #a779e9;
    }
    .sm\:text-purple-lighter {
        color: #d6bbfc;
    }
    .sm\:text-purple-lightest {
        color: #f3ebff;
    }
    .sm\:text-pink-darkest {
        color: #451225;
    }
    .sm\:text-pink-darker {
        color: #6f213f;
    }
    .sm\:text-pink-dark {
        color: #eb5286;
    }
    .sm\:text-pink {
        color: #f66d9b;
    }
    .sm\:text-pink-light {
        color: #fa7ea8;
    }
    .sm\:text-pink-lighter {
        color: #ffbbca;
    }
    .sm\:text-pink-lightest {
        color: #ffebef;
    }
    .sm\:hover\:text-transparent:hover {
        color: transparent;
    }
    .sm\:hover\:text-black:hover {
        color: #22292f;
    }
    .sm\:hover\:text-grey-darkest:hover {
        color: #3d4852;
    }
    .sm\:hover\:text-grey-darker:hover {
        color: #606f7b;
    }
    .sm\:hover\:text-grey-dark:hover {
        color: #8795a1;
    }
    .sm\:hover\:text-grey:hover {
        color: #b8c2cc;
    }
    .sm\:hover\:text-grey-light:hover {
        color: #dae1e7;
    }
    .sm\:hover\:text-grey-lighter:hover {
        color: #f1f5f8;
    }
    .sm\:hover\:text-grey-lightest:hover {
        color: #f8fafc;
    }
    .sm\:hover\:text-white:hover {
        color: #fff;
    }
    .sm\:hover\:text-red-darkest:hover {
        color: #3b0d0c;
    }
    .sm\:hover\:text-red-darker:hover {
        color: #621b18;
    }
    .sm\:hover\:text-red-dark:hover {
        color: #cc1f1a;
    }
    .sm\:hover\:text-red:hover {
        color: #e3342f;
    }
    .sm\:hover\:text-red-light:hover {
        color: #ef5753;
    }
    .sm\:hover\:text-red-lighter:hover {
        color: #f9acaa;
    }
    .sm\:hover\:text-red-lightest:hover {
        color: #fcebea;
    }
    .sm\:hover\:text-orange-darkest:hover {
        color: #462a16;
    }
    .sm\:hover\:text-orange-darker:hover {
        color: #613b1f;
    }
    .sm\:hover\:text-orange-dark:hover {
        color: #de751f;
    }
    .sm\:hover\:text-orange:hover {
        color: #f6993f;
    }
    .sm\:hover\:text-orange-light:hover {
        color: #faad63;
    }
    .sm\:hover\:text-orange-lighter:hover {
        color: #fcd9b6;
    }
    .sm\:hover\:text-orange-lightest:hover {
        color: #fff5eb;
    }
    .sm\:hover\:text-yellow-darkest:hover {
        color: #453411;
    }
    .sm\:hover\:text-yellow-darker:hover {
        color: #684f1d;
    }
    .sm\:hover\:text-yellow-dark:hover {
        color: #f2d024;
    }
    .sm\:hover\:text-yellow:hover {
        color: #ffed4a;
    }
    .sm\:hover\:text-yellow-light:hover {
        color: #fff382;
    }
    .sm\:hover\:text-yellow-lighter:hover {
        color: #fff9c2;
    }
    .sm\:hover\:text-yellow-lightest:hover {
        color: #fcfbeb;
    }
    .sm\:hover\:text-green-darkest:hover {
        color: #0f2f21;
    }
    .sm\:hover\:text-green-darker:hover {
        color: #1a4731;
    }
    .sm\:hover\:text-green-dark:hover {
        color: #1f9d55;
    }
    .sm\:hover\:text-green:hover {
        color: #38c172;
    }
    .sm\:hover\:text-green-light:hover {
        color: #51d88a;
    }
    .sm\:hover\:text-green-lighter:hover {
        color: #a2f5bf;
    }
    .sm\:hover\:text-green-lightest:hover {
        color: #e3fcec;
    }
    .sm\:hover\:text-teal-darkest:hover {
        color: #0d3331;
    }
    .sm\:hover\:text-teal-darker:hover {
        color: #20504f;
    }
    .sm\:hover\:text-teal-dark:hover {
        color: #38a89d;
    }
    .sm\:hover\:text-teal:hover {
        color: #4dc0b5;
    }
    .sm\:hover\:text-teal-light:hover {
        color: #64d5ca;
    }
    .sm\:hover\:text-teal-lighter:hover {
        color: #a0f0ed;
    }
    .sm\:hover\:text-teal-lightest:hover {
        color: #e8fffe;
    }
    .sm\:hover\:text-blue-darkest:hover {
        color: #12283a;
    }
    .sm\:hover\:text-blue-darker:hover {
        color: #1c3d5a;
    }
    .sm\:hover\:text-blue-dark:hover {
        color: #2779bd;
    }
    .sm\:hover\:text-blue:hover {
        color: #3490dc;
    }
    .sm\:hover\:text-blue-light:hover {
        color: #6cb2eb;
    }
    .sm\:hover\:text-blue-lighter:hover {
        color: #bcdefa;
    }
    .sm\:hover\:text-blue-lightest:hover {
        color: #eff8ff;
    }
    .sm\:hover\:text-indigo-darkest:hover {
        color: #191e38;
    }
    .sm\:hover\:text-indigo-darker:hover {
        color: #2f365f;
    }
    .sm\:hover\:text-indigo-dark:hover {
        color: #5661b3;
    }
    .sm\:hover\:text-indigo:hover {
        color: #6574cd;
    }
    .sm\:hover\:text-indigo-light:hover {
        color: #7886d7;
    }
    .sm\:hover\:text-indigo-lighter:hover {
        color: #b2b7ff;
    }
    .sm\:hover\:text-indigo-lightest:hover {
        color: #e6e8ff;
    }
    .sm\:hover\:text-purple-darkest:hover {
        color: #21183c;
    }
    .sm\:hover\:text-purple-darker:hover {
        color: #382b5f;
    }
    .sm\:hover\:text-purple-dark:hover {
        color: #794acf;
    }
    .sm\:hover\:text-purple:hover {
        color: #9561e2;
    }
    .sm\:hover\:text-purple-light:hover {
        color: #a779e9;
    }
    .sm\:hover\:text-purple-lighter:hover {
        color: #d6bbfc;
    }
    .sm\:hover\:text-purple-lightest:hover {
        color: #f3ebff;
    }
    .sm\:hover\:text-pink-darkest:hover {
        color: #451225;
    }
    .sm\:hover\:text-pink-darker:hover {
        color: #6f213f;
    }
    .sm\:hover\:text-pink-dark:hover {
        color: #eb5286;
    }
    .sm\:hover\:text-pink:hover {
        color: #f66d9b;
    }
    .sm\:hover\:text-pink-light:hover {
        color: #fa7ea8;
    }
    .sm\:hover\:text-pink-lighter:hover {
        color: #ffbbca;
    }
    .sm\:hover\:text-pink-lightest:hover {
        color: #ffebef;
    }
    .sm\:text-10 {
        font-size: 1rem;
    }
    .sm\:text-11 {
        font-size: 1.1rem;
    }
    .sm\:text-12 {
        font-size: 1.2rem;
    }
    .sm\:text-13 {
        font-size: 1.3rem;
    }
    .sm\:text-14 {
        font-size: 1.4rem;
    }
    .sm\:text-15 {
        font-size: 1.5rem;
    }
    .sm\:text-16 {
        font-size: 1.6rem;
    }
    .sm\:text-17 {
        font-size: 1.7rem;
    }
    .sm\:text-18 {
        font-size: 1.8rem;
    }
    .sm\:text-19 {
        font-size: 1.9rem;
    }
    .sm\:text-20 {
        font-size: 2rem;
    }
    .sm\:text-24 {
        font-size: 2.4rem;
    }
    .sm\:text-28 {
        font-size: 2.8rem;
    }
    .sm\:text-32 {
        font-size: 3.2rem;
    }
    .sm\:text-36 {
        font-size: 3.6rem;
    }
    .sm\:text-40 {
        font-size: 4rem;
    }
    .sm\:text-44 {
        font-size: 4.4rem;
    }
    .sm\:text-48 {
        font-size: 4.8rem;
    }
    .sm\:text-52 {
        font-size: 5.2rem;
    }
    .sm\:text-56 {
        font-size: 5.6rem;
    }
    .sm\:text-60 {
        font-size: 6rem;
    }
    .sm\:text-64 {
        font-size: 6.4rem;
    }
    .sm\:text-68 {
        font-size: 6.8rem;
    }
    .sm\:text-72 {
        font-size: 7.2rem;
    }
    .sm\:text-96 {
        font-size: 9.6rem;
    }
    .sm\:text-128 {
        font-size: 12.8rem;
    }
    .sm\:text-xs {
        font-size: 1.2rem;
    }
    .sm\:text-sm {
        font-size: 2.4rem;
    }
    .sm\:text-base {
        font-size: 1.6rem;
    }
    .sm\:text-lg {
        font-size: 1.8rem;
    }
    .sm\:text-xl {
        font-size: 2rem;
    }
    .sm\:text-2xl {
        font-size: 2.4rem;
    }
    .sm\:text-3xl {
        font-size: 3rem;
    }
    .sm\:text-4xl {
        font-size: 3.6rem;
    }
    .sm\:text-5xl {
        font-size: 4.8rem;
    }
    .sm\:italic {
        font-style: italic;
    }
    .sm\:roman {
        font-style: normal;
    }
    .sm\:uppercase {
        text-transform: uppercase;
    }
    .sm\:lowercase {
        text-transform: lowercase;
    }
    .sm\:capitalize {
        text-transform: capitalize;
    }
    .sm\:normal-case {
        text-transform: none;
    }
    .sm\:underline {
        text-decoration: underline;
    }
    .sm\:line-through {
        text-decoration: line-through;
    }
    .sm\:no-underline {
        text-decoration: none;
    }
    .sm\:antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .sm\:subpixel-antialiased {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
    .sm\:hover\:italic:hover {
        font-style: italic;
    }
    .sm\:hover\:roman:hover {
        font-style: normal;
    }
    .sm\:hover\:uppercase:hover {
        text-transform: uppercase;
    }
    .sm\:hover\:lowercase:hover {
        text-transform: lowercase;
    }
    .sm\:hover\:capitalize:hover {
        text-transform: capitalize;
    }
    .sm\:hover\:normal-case:hover {
        text-transform: none;
    }
    .sm\:hover\:underline:hover {
        text-decoration: underline;
    }
    .sm\:hover\:line-through:hover {
        text-decoration: line-through;
    }
    .sm\:hover\:no-underline:hover {
        text-decoration: none;
    }
    .sm\:hover\:antialiased:hover {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .sm\:hover\:subpixel-antialiased:hover {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
    .sm\:tracking-tight {
        letter-spacing: -0.05em;
    }
    .sm\:tracking-normal {
        letter-spacing: 0;
    }
    .sm\:tracking-wide {
        letter-spacing: .05em;
    }
    .sm\:select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .sm\:select-text {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
    .sm\:align-baseline {
        vertical-align: baseline;
    }
    .sm\:align-top {
        vertical-align: top;
    }
    .sm\:align-middle {
        vertical-align: middle;
    }
    .sm\:align-bottom {
        vertical-align: bottom;
    }
    .sm\:align-text-top {
        vertical-align: text-top;
    }
    .sm\:align-text-bottom {
        vertical-align: text-bottom;
    }
    .sm\:visible {
        visibility: visible;
    }
    .sm\:invisible {
        visibility: hidden;
    }
    .sm\:whitespace-normal {
        white-space: normal;
    }
    .sm\:whitespace-no-wrap {
        white-space: nowrap;
    }
    .sm\:whitespace-pre {
        white-space: pre;
    }
    .sm\:whitespace-pre-line {
        white-space: pre-line;
    }
    .sm\:whitespace-pre-wrap {
        white-space: pre-wrap;
    }
    .sm\:break-words {
        word-wrap: break-word;
    }
    .sm\:break-normal {
        word-wrap: normal;
    }
    .sm\:truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .sm\:w-0 {
        width: 0;
    }
    .sm\:w-4 {
        width: .4rem;
    }
    .sm\:w-8 {
        width: .8rem;
    }
    .sm\:w-12 {
        width: 1.2rem;
    }
    .sm\:w-16 {
        width: 1.6rem;
    }
    .sm\:w-20 {
        width: 2rem;
    }
    .sm\:w-24 {
        width: 2.4rem;
    }
    .sm\:w-28 {
        width: 2.8rem;
    }
    .sm\:w-32 {
        width: 3.2rem;
    }
    .sm\:w-36 {
        width: 3.6rem;
    }
    .sm\:w-40 {
        width: 4rem;
    }
    .sm\:w-44 {
        width: 4.4rem;
    }
    .sm\:w-48 {
        width: 4.8rem;
    }
    .sm\:w-52 {
        width: 5.2rem;
    }
    .sm\:w-56 {
        width: 5.6rem;
    }
    .sm\:w-60 {
        width: 6rem;
    }
    .sm\:w-64 {
        width: 6.4rem;
    }
    .sm\:w-68 {
        width: 6.8rem;
    }
    .sm\:w-72 {
        width: 7.2rem;
    }
    .sm\:w-76 {
        width: 7.6rem;
    }
    .sm\:w-80 {
        width: 8rem;
    }
    .sm\:w-84 {
        width: 8.4rem;
    }
    .sm\:w-88 {
        width: 8.8rem;
    }
    .sm\:w-92 {
        width: 9.2rem;
    }
    .sm\:w-96 {
        width: 9.6rem;
    }
    .sm\:w-128 {
        width: 12.8rem;
    }
    .sm\:w-160 {
        width: 16rem;
    }
    .sm\:w-192 {
        width: 19.2rem;
    }
    .sm\:w-224 {
        width: 22.4rem;
    }
    .sm\:w-256 {
        width: 25.6rem;
    }
    .sm\:w-288 {
        width: 28.8rem;
    }
    .sm\:w-320 {
        width: 32rem;
    }
    .sm\:w-512 {
        width: 51.2rem;
    }
    .sm\:w-auto {
        width: auto;
    }
    .sm\:w-px {
        width: 1px;
    }
    .sm\:w-1\/2 {
        width: 50%;
    }
    .sm\:w-1\/3 {
        width: 33.33333%;
    }
    .sm\:w-2\/3 {
        width: 66.66667%;
    }
    .sm\:w-1\/4 {
        width: 25%;
    }
    .sm\:w-3\/4 {
        width: 75%;
    }
    .sm\:w-1\/5 {
        width: 20%;
    }
    .sm\:w-2\/5 {
        width: 40%;
    }
    .sm\:w-3\/5 {
        width: 60%;
    }
    .sm\:w-4\/5 {
        width: 80%;
    }
    .sm\:w-1\/6 {
        width: 16.66667%;
    }
    .sm\:w-5\/6 {
        width: 83.33333%;
    }
    .sm\:w-full {
        width: 100%;
    }
    .sm\:w-screen {
        width: 100vw;
    }
    .sm\:z-0 {
        z-index: 0;
    }
    .sm\:z-10 {
        z-index: 10;
    }
    .sm\:z-20 {
        z-index: 20;
    }
    .sm\:z-30 {
        z-index: 30;
    }
    .sm\:z-40 {
        z-index: 40;
    }
    .sm\:z-50 {
        z-index: 50;
    }
    .sm\:z-auto {
        z-index: auto;
    }
}

@media (min-width: 960px) {
    .md\:list-reset {
        list-style: none;
        padding: 0;
    }
    .md\:appearance-none {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .md\:bg-fixed {
        background-attachment: fixed;
    }
    .md\:bg-local {
        background-attachment: local;
    }
    .md\:bg-scroll {
        background-attachment: scroll;
    }
    .md\:bg-transparent {
        background-color: transparent;
    }
    .md\:bg-black {
        background-color: #22292f;
    }
    .md\:bg-grey-darkest {
        background-color: #3d4852;
    }
    .md\:bg-grey-darker {
        background-color: #606f7b;
    }
    .md\:bg-grey-dark {
        background-color: #8795a1;
    }
    .md\:bg-grey {
        background-color: #b8c2cc;
    }
    .md\:bg-grey-light {
        background-color: #dae1e7;
    }
    .md\:bg-grey-lighter {
        background-color: #f1f5f8;
    }
    .md\:bg-grey-lightest {
        background-color: #f8fafc;
    }
    .md\:bg-white {
        background-color: #fff;
    }
    .md\:bg-red-darkest {
        background-color: #3b0d0c;
    }
    .md\:bg-red-darker {
        background-color: #621b18;
    }
    .md\:bg-red-dark {
        background-color: #cc1f1a;
    }
    .md\:bg-red {
        background-color: #e3342f;
    }
    .md\:bg-red-light {
        background-color: #ef5753;
    }
    .md\:bg-red-lighter {
        background-color: #f9acaa;
    }
    .md\:bg-red-lightest {
        background-color: #fcebea;
    }
    .md\:bg-orange-darkest {
        background-color: #462a16;
    }
    .md\:bg-orange-darker {
        background-color: #613b1f;
    }
    .md\:bg-orange-dark {
        background-color: #de751f;
    }
    .md\:bg-orange {
        background-color: #f6993f;
    }
    .md\:bg-orange-light {
        background-color: #faad63;
    }
    .md\:bg-orange-lighter {
        background-color: #fcd9b6;
    }
    .md\:bg-orange-lightest {
        background-color: #fff5eb;
    }
    .md\:bg-yellow-darkest {
        background-color: #453411;
    }
    .md\:bg-yellow-darker {
        background-color: #684f1d;
    }
    .md\:bg-yellow-dark {
        background-color: #f2d024;
    }
    .md\:bg-yellow {
        background-color: #ffed4a;
    }
    .md\:bg-yellow-light {
        background-color: #fff382;
    }
    .md\:bg-yellow-lighter {
        background-color: #fff9c2;
    }
    .md\:bg-yellow-lightest {
        background-color: #fcfbeb;
    }
    .md\:bg-green-darkest {
        background-color: #0f2f21;
    }
    .md\:bg-green-darker {
        background-color: #1a4731;
    }
    .md\:bg-green-dark {
        background-color: #1f9d55;
    }
    .md\:bg-green {
        background-color: #38c172;
    }
    .md\:bg-green-light {
        background-color: #51d88a;
    }
    .md\:bg-green-lighter {
        background-color: #a2f5bf;
    }
    .md\:bg-green-lightest {
        background-color: #e3fcec;
    }
    .md\:bg-teal-darkest {
        background-color: #0d3331;
    }
    .md\:bg-teal-darker {
        background-color: #20504f;
    }
    .md\:bg-teal-dark {
        background-color: #38a89d;
    }
    .md\:bg-teal {
        background-color: #4dc0b5;
    }
    .md\:bg-teal-light {
        background-color: #64d5ca;
    }
    .md\:bg-teal-lighter {
        background-color: #a0f0ed;
    }
    .md\:bg-teal-lightest {
        background-color: #e8fffe;
    }
    .md\:bg-blue-darkest {
        background-color: #12283a;
    }
    .md\:bg-blue-darker {
        background-color: #1c3d5a;
    }
    .md\:bg-blue-dark {
        background-color: #2779bd;
    }
    .md\:bg-blue {
        background-color: #3490dc;
    }
    .md\:bg-blue-light {
        background-color: #6cb2eb;
    }
    .md\:bg-blue-lighter {
        background-color: #bcdefa;
    }
    .md\:bg-blue-lightest {
        background-color: #eff8ff;
    }
    .md\:bg-indigo-darkest {
        background-color: #191e38;
    }
    .md\:bg-indigo-darker {
        background-color: #2f365f;
    }
    .md\:bg-indigo-dark {
        background-color: #5661b3;
    }
    .md\:bg-indigo {
        background-color: #6574cd;
    }
    .md\:bg-indigo-light {
        background-color: #7886d7;
    }
    .md\:bg-indigo-lighter {
        background-color: #b2b7ff;
    }
    .md\:bg-indigo-lightest {
        background-color: #e6e8ff;
    }
    .md\:bg-purple-darkest {
        background-color: #21183c;
    }
    .md\:bg-purple-darker {
        background-color: #382b5f;
    }
    .md\:bg-purple-dark {
        background-color: #794acf;
    }
    .md\:bg-purple {
        background-color: #9561e2;
    }
    .md\:bg-purple-light {
        background-color: #a779e9;
    }
    .md\:bg-purple-lighter {
        background-color: #d6bbfc;
    }
    .md\:bg-purple-lightest {
        background-color: #f3ebff;
    }
    .md\:bg-pink-darkest {
        background-color: #451225;
    }
    .md\:bg-pink-darker {
        background-color: #6f213f;
    }
    .md\:bg-pink-dark {
        background-color: #eb5286;
    }
    .md\:bg-pink {
        background-color: #f66d9b;
    }
    .md\:bg-pink-light {
        background-color: #fa7ea8;
    }
    .md\:bg-pink-lighter {
        background-color: #ffbbca;
    }
    .md\:bg-pink-lightest {
        background-color: #ffebef;
    }
    .md\:hover\:bg-transparent:hover {
        background-color: transparent;
    }
    .md\:hover\:bg-black:hover {
        background-color: #22292f;
    }
    .md\:hover\:bg-grey-darkest:hover {
        background-color: #3d4852;
    }
    .md\:hover\:bg-grey-darker:hover {
        background-color: #606f7b;
    }
    .md\:hover\:bg-grey-dark:hover {
        background-color: #8795a1;
    }
    .md\:hover\:bg-grey:hover {
        background-color: #b8c2cc;
    }
    .md\:hover\:bg-grey-light:hover {
        background-color: #dae1e7;
    }
    .md\:hover\:bg-grey-lighter:hover {
        background-color: #f1f5f8;
    }
    .md\:hover\:bg-grey-lightest:hover {
        background-color: #f8fafc;
    }
    .md\:hover\:bg-white:hover {
        background-color: #fff;
    }
    .md\:hover\:bg-red-darkest:hover {
        background-color: #3b0d0c;
    }
    .md\:hover\:bg-red-darker:hover {
        background-color: #621b18;
    }
    .md\:hover\:bg-red-dark:hover {
        background-color: #cc1f1a;
    }
    .md\:hover\:bg-red:hover {
        background-color: #e3342f;
    }
    .md\:hover\:bg-red-light:hover {
        background-color: #ef5753;
    }
    .md\:hover\:bg-red-lighter:hover {
        background-color: #f9acaa;
    }
    .md\:hover\:bg-red-lightest:hover {
        background-color: #fcebea;
    }
    .md\:hover\:bg-orange-darkest:hover {
        background-color: #462a16;
    }
    .md\:hover\:bg-orange-darker:hover {
        background-color: #613b1f;
    }
    .md\:hover\:bg-orange-dark:hover {
        background-color: #de751f;
    }
    .md\:hover\:bg-orange:hover {
        background-color: #f6993f;
    }
    .md\:hover\:bg-orange-light:hover {
        background-color: #faad63;
    }
    .md\:hover\:bg-orange-lighter:hover {
        background-color: #fcd9b6;
    }
    .md\:hover\:bg-orange-lightest:hover {
        background-color: #fff5eb;
    }
    .md\:hover\:bg-yellow-darkest:hover {
        background-color: #453411;
    }
    .md\:hover\:bg-yellow-darker:hover {
        background-color: #684f1d;
    }
    .md\:hover\:bg-yellow-dark:hover {
        background-color: #f2d024;
    }
    .md\:hover\:bg-yellow:hover {
        background-color: #ffed4a;
    }
    .md\:hover\:bg-yellow-light:hover {
        background-color: #fff382;
    }
    .md\:hover\:bg-yellow-lighter:hover {
        background-color: #fff9c2;
    }
    .md\:hover\:bg-yellow-lightest:hover {
        background-color: #fcfbeb;
    }
    .md\:hover\:bg-green-darkest:hover {
        background-color: #0f2f21;
    }
    .md\:hover\:bg-green-darker:hover {
        background-color: #1a4731;
    }
    .md\:hover\:bg-green-dark:hover {
        background-color: #1f9d55;
    }
    .md\:hover\:bg-green:hover {
        background-color: #38c172;
    }
    .md\:hover\:bg-green-light:hover {
        background-color: #51d88a;
    }
    .md\:hover\:bg-green-lighter:hover {
        background-color: #a2f5bf;
    }
    .md\:hover\:bg-green-lightest:hover {
        background-color: #e3fcec;
    }
    .md\:hover\:bg-teal-darkest:hover {
        background-color: #0d3331;
    }
    .md\:hover\:bg-teal-darker:hover {
        background-color: #20504f;
    }
    .md\:hover\:bg-teal-dark:hover {
        background-color: #38a89d;
    }
    .md\:hover\:bg-teal:hover {
        background-color: #4dc0b5;
    }
    .md\:hover\:bg-teal-light:hover {
        background-color: #64d5ca;
    }
    .md\:hover\:bg-teal-lighter:hover {
        background-color: #a0f0ed;
    }
    .md\:hover\:bg-teal-lightest:hover {
        background-color: #e8fffe;
    }
    .md\:hover\:bg-blue-darkest:hover {
        background-color: #12283a;
    }
    .md\:hover\:bg-blue-darker:hover {
        background-color: #1c3d5a;
    }
    .md\:hover\:bg-blue-dark:hover {
        background-color: #2779bd;
    }
    .md\:hover\:bg-blue:hover {
        background-color: #3490dc;
    }
    .md\:hover\:bg-blue-light:hover {
        background-color: #6cb2eb;
    }
    .md\:hover\:bg-blue-lighter:hover {
        background-color: #bcdefa;
    }
    .md\:hover\:bg-blue-lightest:hover {
        background-color: #eff8ff;
    }
    .md\:hover\:bg-indigo-darkest:hover {
        background-color: #191e38;
    }
    .md\:hover\:bg-indigo-darker:hover {
        background-color: #2f365f;
    }
    .md\:hover\:bg-indigo-dark:hover {
        background-color: #5661b3;
    }
    .md\:hover\:bg-indigo:hover {
        background-color: #6574cd;
    }
    .md\:hover\:bg-indigo-light:hover {
        background-color: #7886d7;
    }
    .md\:hover\:bg-indigo-lighter:hover {
        background-color: #b2b7ff;
    }
    .md\:hover\:bg-indigo-lightest:hover {
        background-color: #e6e8ff;
    }
    .md\:hover\:bg-purple-darkest:hover {
        background-color: #21183c;
    }
    .md\:hover\:bg-purple-darker:hover {
        background-color: #382b5f;
    }
    .md\:hover\:bg-purple-dark:hover {
        background-color: #794acf;
    }
    .md\:hover\:bg-purple:hover {
        background-color: #9561e2;
    }
    .md\:hover\:bg-purple-light:hover {
        background-color: #a779e9;
    }
    .md\:hover\:bg-purple-lighter:hover {
        background-color: #d6bbfc;
    }
    .md\:hover\:bg-purple-lightest:hover {
        background-color: #f3ebff;
    }
    .md\:hover\:bg-pink-darkest:hover {
        background-color: #451225;
    }
    .md\:hover\:bg-pink-darker:hover {
        background-color: #6f213f;
    }
    .md\:hover\:bg-pink-dark:hover {
        background-color: #eb5286;
    }
    .md\:hover\:bg-pink:hover {
        background-color: #f66d9b;
    }
    .md\:hover\:bg-pink-light:hover {
        background-color: #fa7ea8;
    }
    .md\:hover\:bg-pink-lighter:hover {
        background-color: #ffbbca;
    }
    .md\:hover\:bg-pink-lightest:hover {
        background-color: #ffebef;
    }
    .md\:bg-bottom {
        background-position: bottom;
    }
    .md\:bg-center {
        background-position: center;
    }
    .md\:bg-left {
        background-position: left;
    }
    .md\:bg-left-bottom {
        background-position: left bottom;
    }
    .md\:bg-left-top {
        background-position: left top;
    }
    .md\:bg-right {
        background-position: right;
    }
    .md\:bg-right-bottom {
        background-position: right bottom;
    }
    .md\:bg-right-top {
        background-position: right top;
    }
    .md\:bg-top {
        background-position: top;
    }
    .md\:bg-repeat {
        background-repeat: repeat;
    }
    .md\:bg-no-repeat {
        background-repeat: no-repeat;
    }
    .md\:bg-repeat-x {
        background-repeat: repeat-x;
    }
    .md\:bg-repeat-y {
        background-repeat: repeat-y;
    }
    .md\:bg-auto {
        background-size: auto;
    }
    .md\:bg-cover {
        background-size: cover;
    }
    .md\:bg-contain {
        background-size: contain;
    }
    .md\:border-transparent {
        border-color: transparent;
    }
    .md\:border-black {
        border-color: #22292f;
    }
    .md\:border-grey-darkest {
        border-color: #3d4852;
    }
    .md\:border-grey-darker {
        border-color: #606f7b;
    }
    .md\:border-grey-dark {
        border-color: #8795a1;
    }
    .md\:border-grey {
        border-color: #b8c2cc;
    }
    .md\:border-grey-light {
        border-color: #dae1e7;
    }
    .md\:border-grey-lighter {
        border-color: #f1f5f8;
    }
    .md\:border-grey-lightest {
        border-color: #f8fafc;
    }
    .md\:border-white {
        border-color: #fff;
    }
    .md\:border-red-darkest {
        border-color: #3b0d0c;
    }
    .md\:border-red-darker {
        border-color: #621b18;
    }
    .md\:border-red-dark {
        border-color: #cc1f1a;
    }
    .md\:border-red {
        border-color: #e3342f;
    }
    .md\:border-red-light {
        border-color: #ef5753;
    }
    .md\:border-red-lighter {
        border-color: #f9acaa;
    }
    .md\:border-red-lightest {
        border-color: #fcebea;
    }
    .md\:border-orange-darkest {
        border-color: #462a16;
    }
    .md\:border-orange-darker {
        border-color: #613b1f;
    }
    .md\:border-orange-dark {
        border-color: #de751f;
    }
    .md\:border-orange {
        border-color: #f6993f;
    }
    .md\:border-orange-light {
        border-color: #faad63;
    }
    .md\:border-orange-lighter {
        border-color: #fcd9b6;
    }
    .md\:border-orange-lightest {
        border-color: #fff5eb;
    }
    .md\:border-yellow-darkest {
        border-color: #453411;
    }
    .md\:border-yellow-darker {
        border-color: #684f1d;
    }
    .md\:border-yellow-dark {
        border-color: #f2d024;
    }
    .md\:border-yellow {
        border-color: #ffed4a;
    }
    .md\:border-yellow-light {
        border-color: #fff382;
    }
    .md\:border-yellow-lighter {
        border-color: #fff9c2;
    }
    .md\:border-yellow-lightest {
        border-color: #fcfbeb;
    }
    .md\:border-green-darkest {
        border-color: #0f2f21;
    }
    .md\:border-green-darker {
        border-color: #1a4731;
    }
    .md\:border-green-dark {
        border-color: #1f9d55;
    }
    .md\:border-green {
        border-color: #38c172;
    }
    .md\:border-green-light {
        border-color: #51d88a;
    }
    .md\:border-green-lighter {
        border-color: #a2f5bf;
    }
    .md\:border-green-lightest {
        border-color: #e3fcec;
    }
    .md\:border-teal-darkest {
        border-color: #0d3331;
    }
    .md\:border-teal-darker {
        border-color: #20504f;
    }
    .md\:border-teal-dark {
        border-color: #38a89d;
    }
    .md\:border-teal {
        border-color: #4dc0b5;
    }
    .md\:border-teal-light {
        border-color: #64d5ca;
    }
    .md\:border-teal-lighter {
        border-color: #a0f0ed;
    }
    .md\:border-teal-lightest {
        border-color: #e8fffe;
    }
    .md\:border-blue-darkest {
        border-color: #12283a;
    }
    .md\:border-blue-darker {
        border-color: #1c3d5a;
    }
    .md\:border-blue-dark {
        border-color: #2779bd;
    }
    .md\:border-blue {
        border-color: #3490dc;
    }
    .md\:border-blue-light {
        border-color: #6cb2eb;
    }
    .md\:border-blue-lighter {
        border-color: #bcdefa;
    }
    .md\:border-blue-lightest {
        border-color: #eff8ff;
    }
    .md\:border-indigo-darkest {
        border-color: #191e38;
    }
    .md\:border-indigo-darker {
        border-color: #2f365f;
    }
    .md\:border-indigo-dark {
        border-color: #5661b3;
    }
    .md\:border-indigo {
        border-color: #6574cd;
    }
    .md\:border-indigo-light {
        border-color: #7886d7;
    }
    .md\:border-indigo-lighter {
        border-color: #b2b7ff;
    }
    .md\:border-indigo-lightest {
        border-color: #e6e8ff;
    }
    .md\:border-purple-darkest {
        border-color: #21183c;
    }
    .md\:border-purple-darker {
        border-color: #382b5f;
    }
    .md\:border-purple-dark {
        border-color: #794acf;
    }
    .md\:border-purple {
        border-color: #9561e2;
    }
    .md\:border-purple-light {
        border-color: #a779e9;
    }
    .md\:border-purple-lighter {
        border-color: #d6bbfc;
    }
    .md\:border-purple-lightest {
        border-color: #f3ebff;
    }
    .md\:border-pink-darkest {
        border-color: #451225;
    }
    .md\:border-pink-darker {
        border-color: #6f213f;
    }
    .md\:border-pink-dark {
        border-color: #eb5286;
    }
    .md\:border-pink {
        border-color: #f66d9b;
    }
    .md\:border-pink-light {
        border-color: #fa7ea8;
    }
    .md\:border-pink-lighter {
        border-color: #ffbbca;
    }
    .md\:border-pink-lightest {
        border-color: #ffebef;
    }
    .md\:hover\:border-transparent:hover {
        border-color: transparent;
    }
    .md\:hover\:border-black:hover {
        border-color: #22292f;
    }
    .md\:hover\:border-grey-darkest:hover {
        border-color: #3d4852;
    }
    .md\:hover\:border-grey-darker:hover {
        border-color: #606f7b;
    }
    .md\:hover\:border-grey-dark:hover {
        border-color: #8795a1;
    }
    .md\:hover\:border-grey:hover {
        border-color: #b8c2cc;
    }
    .md\:hover\:border-grey-light:hover {
        border-color: #dae1e7;
    }
    .md\:hover\:border-grey-lighter:hover {
        border-color: #f1f5f8;
    }
    .md\:hover\:border-grey-lightest:hover {
        border-color: #f8fafc;
    }
    .md\:hover\:border-white:hover {
        border-color: #fff;
    }
    .md\:hover\:border-red-darkest:hover {
        border-color: #3b0d0c;
    }
    .md\:hover\:border-red-darker:hover {
        border-color: #621b18;
    }
    .md\:hover\:border-red-dark:hover {
        border-color: #cc1f1a;
    }
    .md\:hover\:border-red:hover {
        border-color: #e3342f;
    }
    .md\:hover\:border-red-light:hover {
        border-color: #ef5753;
    }
    .md\:hover\:border-red-lighter:hover {
        border-color: #f9acaa;
    }
    .md\:hover\:border-red-lightest:hover {
        border-color: #fcebea;
    }
    .md\:hover\:border-orange-darkest:hover {
        border-color: #462a16;
    }
    .md\:hover\:border-orange-darker:hover {
        border-color: #613b1f;
    }
    .md\:hover\:border-orange-dark:hover {
        border-color: #de751f;
    }
    .md\:hover\:border-orange:hover {
        border-color: #f6993f;
    }
    .md\:hover\:border-orange-light:hover {
        border-color: #faad63;
    }
    .md\:hover\:border-orange-lighter:hover {
        border-color: #fcd9b6;
    }
    .md\:hover\:border-orange-lightest:hover {
        border-color: #fff5eb;
    }
    .md\:hover\:border-yellow-darkest:hover {
        border-color: #453411;
    }
    .md\:hover\:border-yellow-darker:hover {
        border-color: #684f1d;
    }
    .md\:hover\:border-yellow-dark:hover {
        border-color: #f2d024;
    }
    .md\:hover\:border-yellow:hover {
        border-color: #ffed4a;
    }
    .md\:hover\:border-yellow-light:hover {
        border-color: #fff382;
    }
    .md\:hover\:border-yellow-lighter:hover {
        border-color: #fff9c2;
    }
    .md\:hover\:border-yellow-lightest:hover {
        border-color: #fcfbeb;
    }
    .md\:hover\:border-green-darkest:hover {
        border-color: #0f2f21;
    }
    .md\:hover\:border-green-darker:hover {
        border-color: #1a4731;
    }
    .md\:hover\:border-green-dark:hover {
        border-color: #1f9d55;
    }
    .md\:hover\:border-green:hover {
        border-color: #38c172;
    }
    .md\:hover\:border-green-light:hover {
        border-color: #51d88a;
    }
    .md\:hover\:border-green-lighter:hover {
        border-color: #a2f5bf;
    }
    .md\:hover\:border-green-lightest:hover {
        border-color: #e3fcec;
    }
    .md\:hover\:border-teal-darkest:hover {
        border-color: #0d3331;
    }
    .md\:hover\:border-teal-darker:hover {
        border-color: #20504f;
    }
    .md\:hover\:border-teal-dark:hover {
        border-color: #38a89d;
    }
    .md\:hover\:border-teal:hover {
        border-color: #4dc0b5;
    }
    .md\:hover\:border-teal-light:hover {
        border-color: #64d5ca;
    }
    .md\:hover\:border-teal-lighter:hover {
        border-color: #a0f0ed;
    }
    .md\:hover\:border-teal-lightest:hover {
        border-color: #e8fffe;
    }
    .md\:hover\:border-blue-darkest:hover {
        border-color: #12283a;
    }
    .md\:hover\:border-blue-darker:hover {
        border-color: #1c3d5a;
    }
    .md\:hover\:border-blue-dark:hover {
        border-color: #2779bd;
    }
    .md\:hover\:border-blue:hover {
        border-color: #3490dc;
    }
    .md\:hover\:border-blue-light:hover {
        border-color: #6cb2eb;
    }
    .md\:hover\:border-blue-lighter:hover {
        border-color: #bcdefa;
    }
    .md\:hover\:border-blue-lightest:hover {
        border-color: #eff8ff;
    }
    .md\:hover\:border-indigo-darkest:hover {
        border-color: #191e38;
    }
    .md\:hover\:border-indigo-darker:hover {
        border-color: #2f365f;
    }
    .md\:hover\:border-indigo-dark:hover {
        border-color: #5661b3;
    }
    .md\:hover\:border-indigo:hover {
        border-color: #6574cd;
    }
    .md\:hover\:border-indigo-light:hover {
        border-color: #7886d7;
    }
    .md\:hover\:border-indigo-lighter:hover {
        border-color: #b2b7ff;
    }
    .md\:hover\:border-indigo-lightest:hover {
        border-color: #e6e8ff;
    }
    .md\:hover\:border-purple-darkest:hover {
        border-color: #21183c;
    }
    .md\:hover\:border-purple-darker:hover {
        border-color: #382b5f;
    }
    .md\:hover\:border-purple-dark:hover {
        border-color: #794acf;
    }
    .md\:hover\:border-purple:hover {
        border-color: #9561e2;
    }
    .md\:hover\:border-purple-light:hover {
        border-color: #a779e9;
    }
    .md\:hover\:border-purple-lighter:hover {
        border-color: #d6bbfc;
    }
    .md\:hover\:border-purple-lightest:hover {
        border-color: #f3ebff;
    }
    .md\:hover\:border-pink-darkest:hover {
        border-color: #451225;
    }
    .md\:hover\:border-pink-darker:hover {
        border-color: #6f213f;
    }
    .md\:hover\:border-pink-dark:hover {
        border-color: #eb5286;
    }
    .md\:hover\:border-pink:hover {
        border-color: #f66d9b;
    }
    .md\:hover\:border-pink-light:hover {
        border-color: #fa7ea8;
    }
    .md\:hover\:border-pink-lighter:hover {
        border-color: #ffbbca;
    }
    .md\:hover\:border-pink-lightest:hover {
        border-color: #ffebef;
    }
    .md\:rounded-none {
        border-radius: 0;
    }
    .md\:rounded-sm {
        border-radius: .2rem;
    }
    .md\:rounded {
        border-radius: .4rem;
    }
    .md\:rounded-lg {
        border-radius: .8rem;
    }
    .md\:rounded-full {
        border-radius: 9999px;
    }
    .md\:rounded-t-none {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .md\:rounded-r-none {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .md\:rounded-b-none {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .md\:rounded-l-none {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .md\:rounded-t-sm {
        border-top-left-radius: .2rem;
        border-top-right-radius: .2rem;
    }
    .md\:rounded-r-sm {
        border-top-right-radius: .2rem;
        border-bottom-right-radius: .2rem;
    }
    .md\:rounded-b-sm {
        border-bottom-right-radius: .2rem;
        border-bottom-left-radius: .2rem;
    }
    .md\:rounded-l-sm {
        border-top-left-radius: .2rem;
        border-bottom-left-radius: .2rem;
    }
    .md\:rounded-t {
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
    }
    .md\:rounded-r {
        border-top-right-radius: .4rem;
        border-bottom-right-radius: .4rem;
    }
    .md\:rounded-b {
        border-bottom-right-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }
    .md\:rounded-l {
        border-top-left-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }
    .md\:rounded-t-lg {
        border-top-left-radius: .8rem;
        border-top-right-radius: .8rem;
    }
    .md\:rounded-r-lg {
        border-top-right-radius: .8rem;
        border-bottom-right-radius: .8rem;
    }
    .md\:rounded-b-lg {
        border-bottom-right-radius: .8rem;
        border-bottom-left-radius: .8rem;
    }
    .md\:rounded-l-lg {
        border-top-left-radius: .8rem;
        border-bottom-left-radius: .8rem;
    }
    .md\:rounded-t-full {
        border-top-left-radius: 9999px;
        border-top-right-radius: 9999px;
    }
    .md\:rounded-r-full {
        border-top-right-radius: 9999px;
        border-bottom-right-radius: 9999px;
    }
    .md\:rounded-b-full {
        border-bottom-right-radius: 9999px;
        border-bottom-left-radius: 9999px;
    }
    .md\:rounded-l-full {
        border-top-left-radius: 9999px;
        border-bottom-left-radius: 9999px;
    }
    .md\:rounded-tl-none {
        border-top-left-radius: 0;
    }
    .md\:rounded-tr-none {
        border-top-right-radius: 0;
    }
    .md\:rounded-br-none {
        border-bottom-right-radius: 0;
    }
    .md\:rounded-bl-none {
        border-bottom-left-radius: 0;
    }
    .md\:rounded-tl-sm {
        border-top-left-radius: .2rem;
    }
    .md\:rounded-tr-sm {
        border-top-right-radius: .2rem;
    }
    .md\:rounded-br-sm {
        border-bottom-right-radius: .2rem;
    }
    .md\:rounded-bl-sm {
        border-bottom-left-radius: .2rem;
    }
    .md\:rounded-tl {
        border-top-left-radius: .4rem;
    }
    .md\:rounded-tr {
        border-top-right-radius: .4rem;
    }
    .md\:rounded-br {
        border-bottom-right-radius: .4rem;
    }
    .md\:rounded-bl {
        border-bottom-left-radius: .4rem;
    }
    .md\:rounded-tl-lg {
        border-top-left-radius: .8rem;
    }
    .md\:rounded-tr-lg {
        border-top-right-radius: .8rem;
    }
    .md\:rounded-br-lg {
        border-bottom-right-radius: .8rem;
    }
    .md\:rounded-bl-lg {
        border-bottom-left-radius: .8rem;
    }
    .md\:rounded-tl-full {
        border-top-left-radius: 9999px;
    }
    .md\:rounded-tr-full {
        border-top-right-radius: 9999px;
    }
    .md\:rounded-br-full {
        border-bottom-right-radius: 9999px;
    }
    .md\:rounded-bl-full {
        border-bottom-left-radius: 9999px;
    }
    .md\:border-solid {
        border-style: solid;
    }
    .md\:border-dashed {
        border-style: dashed;
    }
    .md\:border-dotted {
        border-style: dotted;
    }
    .md\:border-none {
        border-style: none;
    }
    .md\:border-0 {
        border-width: 0;
    }
    .md\:border-1 {
        border-width: 1px;
    }
    .md\:border-2 {
        border-width: 2px;
    }
    .md\:border-3 {
        border-width: 3px;
    }
    .md\:border-4 {
        border-width: 4px;
    }
    .md\:border-8 {
        border-width: 8px;
    }
    .md\:border {
        border-width: 1px;
    }
    .md\:border-t-0 {
        border-top-width: 0;
    }
    .md\:border-r-0 {
        border-right-width: 0;
    }
    .md\:border-b-0 {
        border-bottom-width: 0;
    }
    .md\:border-l-0 {
        border-left-width: 0;
    }
    .md\:border-t-1 {
        border-top-width: 1px;
    }
    .md\:border-r-1 {
        border-right-width: 1px;
    }
    .md\:border-b-1 {
        border-bottom-width: 1px;
    }
    .md\:border-l-1 {
        border-left-width: 1px;
    }
    .md\:border-t-2 {
        border-top-width: 2px;
    }
    .md\:border-r-2 {
        border-right-width: 2px;
    }
    .md\:border-b-2 {
        border-bottom-width: 2px;
    }
    .md\:border-l-2 {
        border-left-width: 2px;
    }
    .md\:border-t-3 {
        border-top-width: 3px;
    }
    .md\:border-r-3 {
        border-right-width: 3px;
    }
    .md\:border-b-3 {
        border-bottom-width: 3px;
    }
    .md\:border-l-3 {
        border-left-width: 3px;
    }
    .md\:border-t-4 {
        border-top-width: 4px;
    }
    .md\:border-r-4 {
        border-right-width: 4px;
    }
    .md\:border-b-4 {
        border-bottom-width: 4px;
    }
    .md\:border-l-4 {
        border-left-width: 4px;
    }
    .md\:border-t-8 {
        border-top-width: 8px;
    }
    .md\:border-r-8 {
        border-right-width: 8px;
    }
    .md\:border-b-8 {
        border-bottom-width: 8px;
    }
    .md\:border-l-8 {
        border-left-width: 8px;
    }
    .md\:border-t {
        border-top-width: 1px;
    }
    .md\:border-r {
        border-right-width: 1px;
    }
    .md\:border-b {
        border-bottom-width: 1px;
    }
    .md\:border-l {
        border-left-width: 1px;
    }
    .md\:cursor-auto {
        cursor: auto;
    }
    .md\:cursor-default {
        cursor: default;
    }
    .md\:cursor-pointer {
        cursor: pointer;
    }
    .md\:cursor-wait {
        cursor: wait;
    }
    .md\:cursor-move {
        cursor: move;
    }
    .md\:cursor-not-allowed {
        cursor: not-allowed;
    }
    .md\:block {
        display: block;
    }
    .md\:inline-block {
        display: inline-block;
    }
    .md\:inline {
        display: inline;
    }
    .md\:table {
        display: table;
    }
    .md\:table-row {
        display: table-row;
    }
    .md\:table-cell {
        display: table-cell;
    }
    .md\:hidden {
        display: none;
    }
    .md\:flex {
        display: flex;
    }
    .md\:inline-flex {
        display: inline-flex;
    }
    .md\:flex-row {
        flex-direction: row;
    }
    .md\:flex-row-reverse {
        flex-direction: row-reverse;
    }
    .md\:flex-col {
        flex-direction: column;
    }
    .md\:flex-col-reverse {
        flex-direction: column-reverse;
    }
    .md\:flex-wrap {
        flex-wrap: wrap;
    }
    .md\:flex-wrap-reverse {
        flex-wrap: wrap-reverse;
    }
    .md\:flex-no-wrap {
        flex-wrap: nowrap;
    }
    .md\:items-start {
        align-items: flex-start;
    }
    .md\:items-end {
        align-items: flex-end;
    }
    .md\:items-center {
        align-items: center;
    }
    .md\:items-baseline {
        align-items: baseline;
    }
    .md\:items-stretch {
        align-items: stretch;
    }
    .md\:self-auto {
        align-self: auto;
    }
    .md\:self-start {
        align-self: flex-start;
    }
    .md\:self-end {
        align-self: flex-end;
    }
    .md\:self-center {
        align-self: center;
    }
    .md\:self-stretch {
        align-self: stretch;
    }
    .md\:justify-start {
        justify-content: flex-start;
    }
    .md\:justify-end {
        justify-content: flex-end;
    }
    .md\:justify-center {
        justify-content: center;
    }
    .md\:justify-between {
        justify-content: space-between;
    }
    .md\:justify-around {
        justify-content: space-around;
    }
    .md\:content-center {
        align-content: center;
    }
    .md\:content-start {
        align-content: flex-start;
    }
    .md\:content-end {
        align-content: flex-end;
    }
    .md\:content-between {
        align-content: space-between;
    }
    .md\:content-around {
        align-content: space-around;
    }
    .md\:flex-1 {
        flex: 1;
    }
    .md\:flex-auto {
        flex: auto;
    }
    .md\:flex-initial {
        flex: initial;
    }
    .md\:flex-none {
        flex: none;
    }
    .md\:flex-grow {
        flex-grow: 1;
    }
    .md\:flex-shrink {
        flex-shrink: 1;
    }
    .md\:flex-no-grow {
        flex-grow: 0;
    }
    .md\:flex-no-shrink {
        flex-shrink: 0;
    }
    .md\:float-right {
        float: right;
    }
    .md\:float-left {
        float: left;
    }
    .md\:float-none {
        float: none;
    }
    .md\:clearfix:after {
        content: "";
        display: table;
        clear: both;
    }
    .md\:font-sans {
        font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    }
    .md\:font-serif {
        font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
    }
    .md\:font-mono {
        font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    }
    .md\:font-100 {
        font-weight: 100;
    }
    .md\:font-200 {
        font-weight: 200;
    }
    .md\:font-300 {
        font-weight: 300;
    }
    .md\:font-400 {
        font-weight: 400;
    }
    .md\:font-500 {
        font-weight: 500;
    }
    .md\:font-600 {
        font-weight: 600;
    }
    .md\:font-700 {
        font-weight: 700;
    }
    .md\:font-800 {
        font-weight: 800;
    }
    .md\:font-900 {
        font-weight: 900;
    }
    .md\:font-hairline {
        font-weight: 100;
    }
    .md\:font-thin {
        font-weight: 200;
    }
    .md\:font-light {
        font-weight: 300;
    }
    .md\:font-normal {
        font-weight: 400;
    }
    .md\:font-medium {
        font-weight: 500;
    }
    .md\:font-semibold {
        font-weight: 600;
    }
    .md\:font-bold {
        font-weight: 700;
    }
    .md\:font-extrabold {
        font-weight: 800;
    }
    .md\:font-black {
        font-weight: 900;
    }
    .md\:hover\:font-100:hover {
        font-weight: 100;
    }
    .md\:hover\:font-200:hover {
        font-weight: 200;
    }
    .md\:hover\:font-300:hover {
        font-weight: 300;
    }
    .md\:hover\:font-400:hover {
        font-weight: 400;
    }
    .md\:hover\:font-500:hover {
        font-weight: 500;
    }
    .md\:hover\:font-600:hover {
        font-weight: 600;
    }
    .md\:hover\:font-700:hover {
        font-weight: 700;
    }
    .md\:hover\:font-800:hover {
        font-weight: 800;
    }
    .md\:hover\:font-900:hover {
        font-weight: 900;
    }
    .md\:hover\:font-hairline:hover {
        font-weight: 100;
    }
    .md\:hover\:font-thin:hover {
        font-weight: 200;
    }
    .md\:hover\:font-light:hover {
        font-weight: 300;
    }
    .md\:hover\:font-normal:hover {
        font-weight: 400;
    }
    .md\:hover\:font-medium:hover {
        font-weight: 500;
    }
    .md\:hover\:font-semibold:hover {
        font-weight: 600;
    }
    .md\:hover\:font-bold:hover {
        font-weight: 700;
    }
    .md\:hover\:font-extrabold:hover {
        font-weight: 800;
    }
    .md\:hover\:font-black:hover {
        font-weight: 900;
    }
    .md\:h-4 {
        height: .4rem;
    }
    .md\:h-8 {
        height: .8rem;
    }
    .md\:h-12 {
        height: 1.2rem;
    }
    .md\:h-16 {
        height: 1.6rem;
    }
    .md\:h-20 {
        height: 2rem;
    }
    .md\:h-24 {
        height: 2.4rem;
    }
    .md\:h-28 {
        height: 2.8rem;
    }
    .md\:h-32 {
        height: 3.2rem;
    }
    .md\:h-36 {
        height: 3.6rem;
    }
    .md\:h-40 {
        height: 4rem;
    }
    .md\:h-44 {
        height: 4.4rem;
    }
    .md\:h-48 {
        height: 4.8rem;
    }
    .md\:h-52 {
        height: 5.2rem;
    }
    .md\:h-56 {
        height: 5.6rem;
    }
    .md\:h-60 {
        height: 6rem;
    }
    .md\:h-64 {
        height: 6.4rem;
    }
    .md\:h-68 {
        height: 6.8rem;
    }
    .md\:h-72 {
        height: 7.2rem;
    }
    .md\:h-76 {
        height: 7.6rem;
    }
    .md\:h-80 {
        height: 8rem;
    }
    .md\:h-84 {
        height: 8.4rem;
    }
    .md\:h-88 {
        height: 8.8rem;
    }
    .md\:h-92 {
        height: 9.2rem;
    }
    .md\:h-96 {
        height: 9.6rem;
    }
    .md\:h-128 {
        height: 12.8rem;
    }
    .md\:h-160 {
        height: 16rem;
    }
    .md\:h-192 {
        height: 19.2rem;
    }
    .md\:h-224 {
        height: 22.4rem;
    }
    .md\:h-256 {
        height: 25.6rem;
    }
    .md\:h-288 {
        height: 28.8rem;
    }
    .md\:h-320 {
        height: 32rem;
    }
    .md\:h-512 {
        height: 51.2rem;
    }
    .md\:h-auto {
        height: auto;
    }
    .md\:h-px {
        height: 1px;
    }
    .md\:h-full {
        height: 100%;
    }
    .md\:h-screen {
        height: 100vh;
    }
    .md\:leading-none {
        line-height: 1;
    }
    .md\:leading-tight {
        line-height: 1.25;
    }
    .md\:leading-normal {
        line-height: 1.5;
    }
    .md\:leading-loose {
        line-height: 2;
    }
    .md\:m-0 {
        margin: 0;
    }
    .md\:m-4 {
        margin: .4rem;
    }
    .md\:m-8 {
        margin: .8rem;
    }
    .md\:m-12 {
        margin: 1.2rem;
    }
    .md\:m-16 {
        margin: 1.6rem;
    }
    .md\:m-20 {
        margin: 2rem;
    }
    .md\:m-24 {
        margin: 2.4rem;
    }
    .md\:m-28 {
        margin: 2.8rem;
    }
    .md\:m-32 {
        margin: 3.2rem;
    }
    .md\:m-36 {
        margin: 3.6rem;
    }
    .md\:m-40 {
        margin: 4rem;
    }
    .md\:m-44 {
        margin: 4.4rem;
    }
    .md\:m-48 {
        margin: 4.8rem;
    }
    .md\:m-52 {
        margin: 5.2rem;
    }
    .md\:m-56 {
        margin: 5.6rem;
    }
    .md\:m-60 {
        margin: 6rem;
    }
    .md\:m-64 {
        margin: 6.4rem;
    }
    .md\:m-68 {
        margin: 6.8rem;
    }
    .md\:m-72 {
        margin: 7.2rem;
    }
    .md\:m-76 {
        margin: 7.6rem;
    }
    .md\:m-80 {
        margin: 8rem;
    }
    .md\:m-84 {
        margin: 8.4rem;
    }
    .md\:m-88 {
        margin: 8.8rem;
    }
    .md\:m-92 {
        margin: 9.2rem;
    }
    .md\:m-96 {
        margin: 9.6rem;
    }
    .md\:m-128 {
        margin: 12.8rem;
    }
    .md\:m-160 {
        margin: 16rem;
    }
    .md\:m-192 {
        margin: 19.2rem;
    }
    .md\:m-224 {
        margin: 22.4rem;
    }
    .md\:m-256 {
        margin: 25.6rem;
    }
    .md\:m-auto {
        margin: auto;
    }
    .md\:m-px {
        margin: 1px;
    }
    .md\:my-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .md\:mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .md\:my-4 {
        margin-top: .4rem;
        margin-bottom: .4rem;
    }
    .md\:mx-4 {
        margin-left: .4rem;
        margin-right: .4rem;
    }
    .md\:my-8 {
        margin-top: .8rem;
        margin-bottom: .8rem;
    }
    .md\:mx-8 {
        margin-left: .8rem;
        margin-right: .8rem;
    }
    .md\:my-12 {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }
    .md\:mx-12 {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
    }
    .md\:my-16 {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }
    .md\:mx-16 {
        margin-left: 1.6rem;
        margin-right: 1.6rem;
    }
    .md\:my-20 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .md\:mx-20 {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .md\:my-24 {
        margin-top: 2.4rem;
        margin-bottom: 2.4rem;
    }
    .md\:mx-24 {
        margin-left: 2.4rem;
        margin-right: 2.4rem;
    }
    .md\:my-28 {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
    }
    .md\:mx-28 {
        margin-left: 2.8rem;
        margin-right: 2.8rem;
    }
    .md\:my-32 {
        margin-top: 3.2rem;
        margin-bottom: 3.2rem;
    }
    .md\:mx-32 {
        margin-left: 3.2rem;
        margin-right: 3.2rem;
    }
    .md\:my-36 {
        margin-top: 3.6rem;
        margin-bottom: 3.6rem;
    }
    .md\:mx-36 {
        margin-left: 3.6rem;
        margin-right: 3.6rem;
    }
    .md\:my-40 {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .md\:mx-40 {
        margin-left: 4rem;
        margin-right: 4rem;
    }
    .md\:my-44 {
        margin-top: 4.4rem;
        margin-bottom: 4.4rem;
    }
    .md\:mx-44 {
        margin-left: 4.4rem;
        margin-right: 4.4rem;
    }
    .md\:my-48 {
        margin-top: 4.8rem;
        margin-bottom: 4.8rem;
    }
    .md\:mx-48 {
        margin-left: 4.8rem;
        margin-right: 4.8rem;
    }
    .md\:my-52 {
        margin-top: 5.2rem;
        margin-bottom: 5.2rem;
    }
    .md\:mx-52 {
        margin-left: 5.2rem;
        margin-right: 5.2rem;
    }
    .md\:my-56 {
        margin-top: 5.6rem;
        margin-bottom: 5.6rem;
    }
    .md\:mx-56 {
        margin-left: 5.6rem;
        margin-right: 5.6rem;
    }
    .md\:my-60 {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
    .md\:mx-60 {
        margin-left: 6rem;
        margin-right: 6rem;
    }
    .md\:my-64 {
        margin-top: 6.4rem;
        margin-bottom: 6.4rem;
    }
    .md\:mx-64 {
        margin-left: 6.4rem;
        margin-right: 6.4rem;
    }
    .md\:my-68 {
        margin-top: 6.8rem;
        margin-bottom: 6.8rem;
    }
    .md\:mx-68 {
        margin-left: 6.8rem;
        margin-right: 6.8rem;
    }
    .md\:my-72 {
        margin-top: 7.2rem;
        margin-bottom: 7.2rem;
    }
    .md\:mx-72 {
        margin-left: 7.2rem;
        margin-right: 7.2rem;
    }
    .md\:my-76 {
        margin-top: 7.6rem;
        margin-bottom: 7.6rem;
    }
    .md\:mx-76 {
        margin-left: 7.6rem;
        margin-right: 7.6rem;
    }
    .md\:my-80 {
        margin-top: 8rem;
        margin-bottom: 8rem;
    }
    .md\:mx-80 {
        margin-left: 8rem;
        margin-right: 8rem;
    }
    .md\:my-84 {
        margin-top: 8.4rem;
        margin-bottom: 8.4rem;
    }
    .md\:mx-84 {
        margin-left: 8.4rem;
        margin-right: 8.4rem;
    }
    .md\:my-88 {
        margin-top: 8.8rem;
        margin-bottom: 8.8rem;
    }
    .md\:mx-88 {
        margin-left: 8.8rem;
        margin-right: 8.8rem;
    }
    .md\:my-92 {
        margin-top: 9.2rem;
        margin-bottom: 9.2rem;
    }
    .md\:mx-92 {
        margin-left: 9.2rem;
        margin-right: 9.2rem;
    }
    .md\:my-96 {
        margin-top: 9.6rem;
        margin-bottom: 9.6rem;
    }
    .md\:mx-96 {
        margin-left: 9.6rem;
        margin-right: 9.6rem;
    }
    .md\:my-128 {
        margin-top: 12.8rem;
        margin-bottom: 12.8rem;
    }
    .md\:mx-128 {
        margin-left: 12.8rem;
        margin-right: 12.8rem;
    }
    .md\:my-160 {
        margin-top: 16rem;
        margin-bottom: 16rem;
    }
    .md\:mx-160 {
        margin-left: 16rem;
        margin-right: 16rem;
    }
    .md\:my-192 {
        margin-top: 19.2rem;
        margin-bottom: 19.2rem;
    }
    .md\:mx-192 {
        margin-left: 19.2rem;
        margin-right: 19.2rem;
    }
    .md\:my-224 {
        margin-top: 22.4rem;
        margin-bottom: 22.4rem;
    }
    .md\:mx-224 {
        margin-left: 22.4rem;
        margin-right: 22.4rem;
    }
    .md\:my-256 {
        margin-top: 25.6rem;
        margin-bottom: 25.6rem;
    }
    .md\:mx-256 {
        margin-left: 25.6rem;
        margin-right: 25.6rem;
    }
    .md\:my-auto {
        margin-top: auto;
        margin-bottom: auto;
    }
    .md\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .md\:my-px {
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .md\:mx-px {
        margin-left: 1px;
        margin-right: 1px;
    }
    .md\:mt-0 {
        margin-top: 0;
    }
    .md\:mr-0 {
        margin-right: 0;
    }
    .md\:mb-0 {
        margin-bottom: 0;
    }
    .md\:ml-0 {
        margin-left: 0;
    }
    .md\:mt-4 {
        margin-top: .4rem;
    }
    .md\:mr-4 {
        margin-right: .4rem;
    }
    .md\:mb-4 {
        margin-bottom: .4rem;
    }
    .md\:ml-4 {
        margin-left: .4rem;
    }
    .md\:mt-8 {
        margin-top: .8rem;
    }
    .md\:mr-8 {
        margin-right: .8rem;
    }
    .md\:mb-8 {
        margin-bottom: .8rem;
    }
    .md\:ml-8 {
        margin-left: .8rem;
    }
    .md\:mt-12 {
        margin-top: 1.2rem;
    }
    .md\:mr-12 {
        margin-right: 1.2rem;
    }
    .md\:mb-12 {
        margin-bottom: 1.2rem;
    }
    .md\:ml-12 {
        margin-left: 1.2rem;
    }
    .md\:mt-16 {
        margin-top: 1.6rem;
    }
    .md\:mr-16 {
        margin-right: 1.6rem;
    }
    .md\:mb-16 {
        margin-bottom: 1.6rem;
    }
    .md\:ml-16 {
        margin-left: 1.6rem;
    }
    .md\:mt-20 {
        margin-top: 2rem;
    }
    .md\:mr-20 {
        margin-right: 2rem;
    }
    .md\:mb-20 {
        margin-bottom: 2rem;
    }
    .md\:ml-20 {
        margin-left: 2rem;
    }
    .md\:mt-24 {
        margin-top: 2.4rem;
    }
    .md\:mr-24 {
        margin-right: 2.4rem;
    }
    .md\:mb-24 {
        margin-bottom: 2.4rem;
    }
    .md\:ml-24 {
        margin-left: 2.4rem;
    }
    .md\:mt-28 {
        margin-top: 2.8rem;
    }
    .md\:mr-28 {
        margin-right: 2.8rem;
    }
    .md\:mb-28 {
        margin-bottom: 2.8rem;
    }
    .md\:ml-28 {
        margin-left: 2.8rem;
    }
    .md\:mt-32 {
        margin-top: 3.2rem;
    }
    .md\:mr-32 {
        margin-right: 3.2rem;
    }
    .md\:mb-32 {
        margin-bottom: 3.2rem;
    }
    .md\:ml-32 {
        margin-left: 3.2rem;
    }
    .md\:mt-36 {
        margin-top: 3.6rem;
    }
    .md\:mr-36 {
        margin-right: 3.6rem;
    }
    .md\:mb-36 {
        margin-bottom: 3.6rem;
    }
    .md\:ml-36 {
        margin-left: 3.6rem;
    }
    .md\:mt-40 {
        margin-top: 4rem;
    }
    .md\:mr-40 {
        margin-right: 4rem;
    }
    .md\:mb-40 {
        margin-bottom: 4rem;
    }
    .md\:ml-40 {
        margin-left: 4rem;
    }
    .md\:mt-44 {
        margin-top: 4.4rem;
    }
    .md\:mr-44 {
        margin-right: 4.4rem;
    }
    .md\:mb-44 {
        margin-bottom: 4.4rem;
    }
    .md\:ml-44 {
        margin-left: 4.4rem;
    }
    .md\:mt-48 {
        margin-top: 4.8rem;
    }
    .md\:mr-48 {
        margin-right: 4.8rem;
    }
    .md\:mb-48 {
        margin-bottom: 4.8rem;
    }
    .md\:ml-48 {
        margin-left: 4.8rem;
    }
    .md\:mt-52 {
        margin-top: 5.2rem;
    }
    .md\:mr-52 {
        margin-right: 5.2rem;
    }
    .md\:mb-52 {
        margin-bottom: 5.2rem;
    }
    .md\:ml-52 {
        margin-left: 5.2rem;
    }
    .md\:mt-56 {
        margin-top: 5.6rem;
    }
    .md\:mr-56 {
        margin-right: 5.6rem;
    }
    .md\:mb-56 {
        margin-bottom: 5.6rem;
    }
    .md\:ml-56 {
        margin-left: 5.6rem;
    }
    .md\:mt-60 {
        margin-top: 6rem;
    }
    .md\:mr-60 {
        margin-right: 6rem;
    }
    .md\:mb-60 {
        margin-bottom: 6rem;
    }
    .md\:ml-60 {
        margin-left: 6rem;
    }
    .md\:mt-64 {
        margin-top: 6.4rem;
    }
    .md\:mr-64 {
        margin-right: 6.4rem;
    }
    .md\:mb-64 {
        margin-bottom: 6.4rem;
    }
    .md\:ml-64 {
        margin-left: 6.4rem;
    }
    .md\:mt-68 {
        margin-top: 6.8rem;
    }
    .md\:mr-68 {
        margin-right: 6.8rem;
    }
    .md\:mb-68 {
        margin-bottom: 6.8rem;
    }
    .md\:ml-68 {
        margin-left: 6.8rem;
    }
    .md\:mt-72 {
        margin-top: 7.2rem;
    }
    .md\:mr-72 {
        margin-right: 7.2rem;
    }
    .md\:mb-72 {
        margin-bottom: 7.2rem;
    }
    .md\:ml-72 {
        margin-left: 7.2rem;
    }
    .md\:mt-76 {
        margin-top: 7.6rem;
    }
    .md\:mr-76 {
        margin-right: 7.6rem;
    }
    .md\:mb-76 {
        margin-bottom: 7.6rem;
    }
    .md\:ml-76 {
        margin-left: 7.6rem;
    }
    .md\:mt-80 {
        margin-top: 8rem;
    }
    .md\:mr-80 {
        margin-right: 8rem;
    }
    .md\:mb-80 {
        margin-bottom: 8rem;
    }
    .md\:ml-80 {
        margin-left: 8rem;
    }
    .md\:mt-84 {
        margin-top: 8.4rem;
    }
    .md\:mr-84 {
        margin-right: 8.4rem;
    }
    .md\:mb-84 {
        margin-bottom: 8.4rem;
    }
    .md\:ml-84 {
        margin-left: 8.4rem;
    }
    .md\:mt-88 {
        margin-top: 8.8rem;
    }
    .md\:mr-88 {
        margin-right: 8.8rem;
    }
    .md\:mb-88 {
        margin-bottom: 8.8rem;
    }
    .md\:ml-88 {
        margin-left: 8.8rem;
    }
    .md\:mt-92 {
        margin-top: 9.2rem;
    }
    .md\:mr-92 {
        margin-right: 9.2rem;
    }
    .md\:mb-92 {
        margin-bottom: 9.2rem;
    }
    .md\:ml-92 {
        margin-left: 9.2rem;
    }
    .md\:mt-96 {
        margin-top: 9.6rem;
    }
    .md\:mr-96 {
        margin-right: 9.6rem;
    }
    .md\:mb-96 {
        margin-bottom: 9.6rem;
    }
    .md\:ml-96 {
        margin-left: 9.6rem;
    }
    .md\:mt-128 {
        margin-top: 12.8rem;
    }
    .md\:mr-128 {
        margin-right: 12.8rem;
    }
    .md\:mb-128 {
        margin-bottom: 12.8rem;
    }
    .md\:ml-128 {
        margin-left: 12.8rem;
    }
    .md\:mt-160 {
        margin-top: 16rem;
    }
    .md\:mr-160 {
        margin-right: 16rem;
    }
    .md\:mb-160 {
        margin-bottom: 16rem;
    }
    .md\:ml-160 {
        margin-left: 16rem;
    }
    .md\:mt-192 {
        margin-top: 19.2rem;
    }
    .md\:mr-192 {
        margin-right: 19.2rem;
    }
    .md\:mb-192 {
        margin-bottom: 19.2rem;
    }
    .md\:ml-192 {
        margin-left: 19.2rem;
    }
    .md\:mt-224 {
        margin-top: 22.4rem;
    }
    .md\:mr-224 {
        margin-right: 22.4rem;
    }
    .md\:mb-224 {
        margin-bottom: 22.4rem;
    }
    .md\:ml-224 {
        margin-left: 22.4rem;
    }
    .md\:mt-256 {
        margin-top: 25.6rem;
    }
    .md\:mr-256 {
        margin-right: 25.6rem;
    }
    .md\:mb-256 {
        margin-bottom: 25.6rem;
    }
    .md\:ml-256 {
        margin-left: 25.6rem;
    }
    .md\:mt-auto {
        margin-top: auto;
    }
    .md\:mr-auto {
        margin-right: auto;
    }
    .md\:mb-auto {
        margin-bottom: auto;
    }
    .md\:ml-auto {
        margin-left: auto;
    }
    .md\:mt-px {
        margin-top: 1px;
    }
    .md\:mr-px {
        margin-right: 1px;
    }
    .md\:mb-px {
        margin-bottom: 1px;
    }
    .md\:ml-px {
        margin-left: 1px;
    }
    .md\:max-h-4 {
        max-height: .4rem;
    }
    .md\:max-h-8 {
        max-height: .8rem;
    }
    .md\:max-h-12 {
        max-height: 1.2rem;
    }
    .md\:max-h-16 {
        max-height: 1.6rem;
    }
    .md\:max-h-20 {
        max-height: 2rem;
    }
    .md\:max-h-24 {
        max-height: 2.4rem;
    }
    .md\:max-h-28 {
        max-height: 2.8rem;
    }
    .md\:max-h-32 {
        max-height: 3.2rem;
    }
    .md\:max-h-36 {
        max-height: 3.6rem;
    }
    .md\:max-h-40 {
        max-height: 4rem;
    }
    .md\:max-h-44 {
        max-height: 4.4rem;
    }
    .md\:max-h-48 {
        max-height: 4.8rem;
    }
    .md\:max-h-52 {
        max-height: 5.2rem;
    }
    .md\:max-h-56 {
        max-height: 5.6rem;
    }
    .md\:max-h-60 {
        max-height: 6rem;
    }
    .md\:max-h-64 {
        max-height: 6.4rem;
    }
    .md\:max-h-68 {
        max-height: 6.8rem;
    }
    .md\:max-h-72 {
        max-height: 7.2rem;
    }
    .md\:max-h-76 {
        max-height: 7.6rem;
    }
    .md\:max-h-80 {
        max-height: 8rem;
    }
    .md\:max-h-84 {
        max-height: 8.4rem;
    }
    .md\:max-h-88 {
        max-height: 8.8rem;
    }
    .md\:max-h-92 {
        max-height: 9.2rem;
    }
    .md\:max-h-96 {
        max-height: 9.6rem;
    }
    .md\:max-h-128 {
        max-height: 12.8rem;
    }
    .md\:max-h-160 {
        max-height: 16rem;
    }
    .md\:max-h-192 {
        max-height: 19.2rem;
    }
    .md\:max-h-224 {
        max-height: 22.4rem;
    }
    .md\:max-h-256 {
        max-height: 25.6rem;
    }
    .md\:max-h-288 {
        max-height: 28.8rem;
    }
    .md\:max-h-320 {
        max-height: 32rem;
    }
    .md\:max-h-512 {
        max-height: 51.2rem;
    }
    .md\:max-h-full {
        max-height: 100%;
    }
    .md\:max-h-screen {
        max-height: 100vh;
    }
    .md\:max-w-4 {
        max-width: .4rem;
    }
    .md\:max-w-8 {
        max-width: .8rem;
    }
    .md\:max-w-12 {
        max-width: 1.2rem;
    }
    .md\:max-w-16 {
        max-width: 1.6rem;
    }
    .md\:max-w-20 {
        max-width: 2rem;
    }
    .md\:max-w-24 {
        max-width: 2.4rem;
    }
    .md\:max-w-28 {
        max-width: 2.8rem;
    }
    .md\:max-w-32 {
        max-width: 3.2rem;
    }
    .md\:max-w-36 {
        max-width: 3.6rem;
    }
    .md\:max-w-40 {
        max-width: 4rem;
    }
    .md\:max-w-44 {
        max-width: 4.4rem;
    }
    .md\:max-w-48 {
        max-width: 4.8rem;
    }
    .md\:max-w-52 {
        max-width: 5.2rem;
    }
    .md\:max-w-56 {
        max-width: 5.6rem;
    }
    .md\:max-w-60 {
        max-width: 6rem;
    }
    .md\:max-w-64 {
        max-width: 6.4rem;
    }
    .md\:max-w-96 {
        max-width: 9.6rem;
    }
    .md\:max-w-128 {
        max-width: 12.8rem;
    }
    .md\:max-w-160 {
        max-width: 16rem;
    }
    .md\:max-w-192 {
        max-width: 19.2rem;
    }
    .md\:max-w-224 {
        max-width: 22.4rem;
    }
    .md\:max-w-256 {
        max-width: 25.6rem;
    }
    .md\:max-w-288 {
        max-width: 28.8rem;
    }
    .md\:max-w-320 {
        max-width: 32rem;
    }
    .md\:max-w-512 {
        max-width: 51.2rem;
    }
    .md\:max-w-xs {
        max-width: 32rem;
    }
    .md\:max-w-sm {
        max-width: 48rem;
    }
    .md\:max-w-md {
        max-width: 64rem;
    }
    .md\:max-w-lg {
        max-width: 80rem;
    }
    .md\:max-w-xl {
        max-width: 96rem;
    }
    .md\:max-w-2xl {
        max-width: 112rem;
    }
    .md\:max-w-3xl {
        max-width: 128rem;
    }
    .md\:max-w-4xl {
        max-width: 144rem;
    }
    .md\:max-w-5xl {
        max-width: 160rem;
    }
    .md\:max-w-full {
        max-width: 100%;
    }
    .md\:min-h-4 {
        min-height: .4rem;
    }
    .md\:min-h-8 {
        min-height: .8rem;
    }
    .md\:min-h-12 {
        min-height: 1.2rem;
    }
    .md\:min-h-16 {
        min-height: 1.6rem;
    }
    .md\:min-h-20 {
        min-height: 2rem;
    }
    .md\:min-h-24 {
        min-height: 2.4rem;
    }
    .md\:min-h-28 {
        min-height: 2.8rem;
    }
    .md\:min-h-32 {
        min-height: 3.2rem;
    }
    .md\:min-h-36 {
        min-height: 3.6rem;
    }
    .md\:min-h-40 {
        min-height: 4rem;
    }
    .md\:min-h-44 {
        min-height: 4.4rem;
    }
    .md\:min-h-48 {
        min-height: 4.8rem;
    }
    .md\:min-h-52 {
        min-height: 5.2rem;
    }
    .md\:min-h-56 {
        min-height: 5.6rem;
    }
    .md\:min-h-60 {
        min-height: 6rem;
    }
    .md\:min-h-64 {
        min-height: 6.4rem;
    }
    .md\:min-h-68 {
        min-height: 6.8rem;
    }
    .md\:min-h-72 {
        min-height: 7.2rem;
    }
    .md\:min-h-76 {
        min-height: 7.6rem;
    }
    .md\:min-h-80 {
        min-height: 8rem;
    }
    .md\:min-h-84 {
        min-height: 8.4rem;
    }
    .md\:min-h-88 {
        min-height: 8.8rem;
    }
    .md\:min-h-92 {
        min-height: 9.2rem;
    }
    .md\:min-h-96 {
        min-height: 9.6rem;
    }
    .md\:min-h-128 {
        min-height: 12.8rem;
    }
    .md\:min-h-160 {
        min-height: 16rem;
    }
    .md\:min-h-192 {
        min-height: 19.2rem;
    }
    .md\:min-h-224 {
        min-height: 22.4rem;
    }
    .md\:min-h-256 {
        min-height: 25.6rem;
    }
    .md\:min-h-288 {
        min-height: 28.8rem;
    }
    .md\:min-h-320 {
        min-height: 32rem;
    }
    .md\:min-h-512 {
        min-height: 51.2rem;
    }
    .md\:min-h-auto {
        min-height: auto;
    }
    .md\:min-h-px {
        min-height: 1px;
    }
    .md\:min-h-full {
        min-height: 100%;
    }
    .md\:min-h-screen {
        min-height: 100vh;
    }
    .md\:min-w-0 {
        min-width: 0;
    }
    .md\:min-w-4 {
        min-width: .4rem;
    }
    .md\:min-w-8 {
        min-width: .8rem;
    }
    .md\:min-w-12 {
        min-width: 1.2rem;
    }
    .md\:min-w-16 {
        min-width: 1.6rem;
    }
    .md\:min-w-20 {
        min-width: 2rem;
    }
    .md\:min-w-24 {
        min-width: 2.4rem;
    }
    .md\:min-w-28 {
        min-width: 2.8rem;
    }
    .md\:min-w-32 {
        min-width: 3.2rem;
    }
    .md\:min-w-36 {
        min-width: 3.6rem;
    }
    .md\:min-w-40 {
        min-width: 4rem;
    }
    .md\:min-w-44 {
        min-width: 4.4rem;
    }
    .md\:min-w-48 {
        min-width: 4.8rem;
    }
    .md\:min-w-52 {
        min-width: 5.2rem;
    }
    .md\:min-w-56 {
        min-width: 5.6rem;
    }
    .md\:min-w-60 {
        min-width: 6rem;
    }
    .md\:min-w-64 {
        min-width: 6.4rem;
    }
    .md\:min-w-68 {
        min-width: 6.8rem;
    }
    .md\:min-w-72 {
        min-width: 7.2rem;
    }
    .md\:min-w-76 {
        min-width: 7.6rem;
    }
    .md\:min-w-80 {
        min-width: 8rem;
    }
    .md\:min-w-84 {
        min-width: 8.4rem;
    }
    .md\:min-w-88 {
        min-width: 8.8rem;
    }
    .md\:min-w-92 {
        min-width: 9.2rem;
    }
    .md\:min-w-96 {
        min-width: 9.6rem;
    }
    .md\:min-w-128 {
        min-width: 12.8rem;
    }
    .md\:min-w-160 {
        min-width: 16rem;
    }
    .md\:min-w-192 {
        min-width: 19.2rem;
    }
    .md\:min-w-224 {
        min-width: 22.4rem;
    }
    .md\:min-w-256 {
        min-width: 25.6rem;
    }
    .md\:min-w-288 {
        min-width: 28.8rem;
    }
    .md\:min-w-320 {
        min-width: 32rem;
    }
    .md\:min-w-512 {
        min-width: 51.2rem;
    }
    .md\:min-w-auto {
        min-width: auto;
    }
    .md\:min-w-px {
        min-width: 1px;
    }
    .md\:min-w-1\/2 {
        min-width: 50%;
    }
    .md\:min-w-1\/3 {
        min-width: 33.33333%;
    }
    .md\:min-w-2\/3 {
        min-width: 66.66667%;
    }
    .md\:min-w-1\/4 {
        min-width: 25%;
    }
    .md\:min-w-3\/4 {
        min-width: 75%;
    }
    .md\:min-w-1\/5 {
        min-width: 20%;
    }
    .md\:min-w-2\/5 {
        min-width: 40%;
    }
    .md\:min-w-3\/5 {
        min-width: 60%;
    }
    .md\:min-w-4\/5 {
        min-width: 80%;
    }
    .md\:min-w-1\/6 {
        min-width: 16.66667%;
    }
    .md\:min-w-5\/6 {
        min-width: 83.33333%;
    }
    .md\:min-w-full {
        min-width: 100%;
    }
    .md\:min-w-screen {
        min-width: 100vw;
    }
    .md\:-m-0 {
        margin: 0;
    }
    .md\:-m-4 {
        margin: -0.4rem;
    }
    .md\:-m-8 {
        margin: -0.8rem;
    }
    .md\:-m-12 {
        margin: -1.2rem;
    }
    .md\:-m-16 {
        margin: -1.6rem;
    }
    .md\:-m-20 {
        margin: -2rem;
    }
    .md\:-m-24 {
        margin: -2.4rem;
    }
    .md\:-m-28 {
        margin: -2.8rem;
    }
    .md\:-m-32 {
        margin: -3.2rem;
    }
    .md\:-m-36 {
        margin: -3.6rem;
    }
    .md\:-m-40 {
        margin: -4rem;
    }
    .md\:-m-44 {
        margin: -4.4rem;
    }
    .md\:-m-48 {
        margin: -4.8rem;
    }
    .md\:-m-52 {
        margin: -5.2rem;
    }
    .md\:-m-56 {
        margin: -5.6rem;
    }
    .md\:-m-60 {
        margin: -6rem;
    }
    .md\:-m-64 {
        margin: -6.4rem;
    }
    .md\:-m-68 {
        margin: -6.8rem;
    }
    .md\:-m-72 {
        margin: -7.2rem;
    }
    .md\:-m-76 {
        margin: -7.6rem;
    }
    .md\:-m-80 {
        margin: -8rem;
    }
    .md\:-m-84 {
        margin: -8.4rem;
    }
    .md\:-m-88 {
        margin: -8.8rem;
    }
    .md\:-m-92 {
        margin: -9.2rem;
    }
    .md\:-m-96 {
        margin: -9.6rem;
    }
    .md\:-m-128 {
        margin: -12.8rem;
    }
    .md\:-m-160 {
        margin: -16rem;
    }
    .md\:-m-192 {
        margin: -19.2rem;
    }
    .md\:-m-224 {
        margin: -22.4rem;
    }
    .md\:-m-256 {
        margin: -25.6rem;
    }
    .md\:-m-px {
        margin: -1px;
    }
    .md\:-my-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .md\:-mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .md\:-my-4 {
        margin-top: -0.4rem;
        margin-bottom: -0.4rem;
    }
    .md\:-mx-4 {
        margin-left: -0.4rem;
        margin-right: -0.4rem;
    }
    .md\:-my-8 {
        margin-top: -0.8rem;
        margin-bottom: -0.8rem;
    }
    .md\:-mx-8 {
        margin-left: -0.8rem;
        margin-right: -0.8rem;
    }
    .md\:-my-12 {
        margin-top: -1.2rem;
        margin-bottom: -1.2rem;
    }
    .md\:-mx-12 {
        margin-left: -1.2rem;
        margin-right: -1.2rem;
    }
    .md\:-my-16 {
        margin-top: -1.6rem;
        margin-bottom: -1.6rem;
    }
    .md\:-mx-16 {
        margin-left: -1.6rem;
        margin-right: -1.6rem;
    }
    .md\:-my-20 {
        margin-top: -2rem;
        margin-bottom: -2rem;
    }
    .md\:-mx-20 {
        margin-left: -2rem;
        margin-right: -2rem;
    }
    .md\:-my-24 {
        margin-top: -2.4rem;
        margin-bottom: -2.4rem;
    }
    .md\:-mx-24 {
        margin-left: -2.4rem;
        margin-right: -2.4rem;
    }
    .md\:-my-28 {
        margin-top: -2.8rem;
        margin-bottom: -2.8rem;
    }
    .md\:-mx-28 {
        margin-left: -2.8rem;
        margin-right: -2.8rem;
    }
    .md\:-my-32 {
        margin-top: -3.2rem;
        margin-bottom: -3.2rem;
    }
    .md\:-mx-32 {
        margin-left: -3.2rem;
        margin-right: -3.2rem;
    }
    .md\:-my-36 {
        margin-top: -3.6rem;
        margin-bottom: -3.6rem;
    }
    .md\:-mx-36 {
        margin-left: -3.6rem;
        margin-right: -3.6rem;
    }
    .md\:-my-40 {
        margin-top: -4rem;
        margin-bottom: -4rem;
    }
    .md\:-mx-40 {
        margin-left: -4rem;
        margin-right: -4rem;
    }
    .md\:-my-44 {
        margin-top: -4.4rem;
        margin-bottom: -4.4rem;
    }
    .md\:-mx-44 {
        margin-left: -4.4rem;
        margin-right: -4.4rem;
    }
    .md\:-my-48 {
        margin-top: -4.8rem;
        margin-bottom: -4.8rem;
    }
    .md\:-mx-48 {
        margin-left: -4.8rem;
        margin-right: -4.8rem;
    }
    .md\:-my-52 {
        margin-top: -5.2rem;
        margin-bottom: -5.2rem;
    }
    .md\:-mx-52 {
        margin-left: -5.2rem;
        margin-right: -5.2rem;
    }
    .md\:-my-56 {
        margin-top: -5.6rem;
        margin-bottom: -5.6rem;
    }
    .md\:-mx-56 {
        margin-left: -5.6rem;
        margin-right: -5.6rem;
    }
    .md\:-my-60 {
        margin-top: -6rem;
        margin-bottom: -6rem;
    }
    .md\:-mx-60 {
        margin-left: -6rem;
        margin-right: -6rem;
    }
    .md\:-my-64 {
        margin-top: -6.4rem;
        margin-bottom: -6.4rem;
    }
    .md\:-mx-64 {
        margin-left: -6.4rem;
        margin-right: -6.4rem;
    }
    .md\:-my-68 {
        margin-top: -6.8rem;
        margin-bottom: -6.8rem;
    }
    .md\:-mx-68 {
        margin-left: -6.8rem;
        margin-right: -6.8rem;
    }
    .md\:-my-72 {
        margin-top: -7.2rem;
        margin-bottom: -7.2rem;
    }
    .md\:-mx-72 {
        margin-left: -7.2rem;
        margin-right: -7.2rem;
    }
    .md\:-my-76 {
        margin-top: -7.6rem;
        margin-bottom: -7.6rem;
    }
    .md\:-mx-76 {
        margin-left: -7.6rem;
        margin-right: -7.6rem;
    }
    .md\:-my-80 {
        margin-top: -8rem;
        margin-bottom: -8rem;
    }
    .md\:-mx-80 {
        margin-left: -8rem;
        margin-right: -8rem;
    }
    .md\:-my-84 {
        margin-top: -8.4rem;
        margin-bottom: -8.4rem;
    }
    .md\:-mx-84 {
        margin-left: -8.4rem;
        margin-right: -8.4rem;
    }
    .md\:-my-88 {
        margin-top: -8.8rem;
        margin-bottom: -8.8rem;
    }
    .md\:-mx-88 {
        margin-left: -8.8rem;
        margin-right: -8.8rem;
    }
    .md\:-my-92 {
        margin-top: -9.2rem;
        margin-bottom: -9.2rem;
    }
    .md\:-mx-92 {
        margin-left: -9.2rem;
        margin-right: -9.2rem;
    }
    .md\:-my-96 {
        margin-top: -9.6rem;
        margin-bottom: -9.6rem;
    }
    .md\:-mx-96 {
        margin-left: -9.6rem;
        margin-right: -9.6rem;
    }
    .md\:-my-128 {
        margin-top: -12.8rem;
        margin-bottom: -12.8rem;
    }
    .md\:-mx-128 {
        margin-left: -12.8rem;
        margin-right: -12.8rem;
    }
    .md\:-my-160 {
        margin-top: -16rem;
        margin-bottom: -16rem;
    }
    .md\:-mx-160 {
        margin-left: -16rem;
        margin-right: -16rem;
    }
    .md\:-my-192 {
        margin-top: -19.2rem;
        margin-bottom: -19.2rem;
    }
    .md\:-mx-192 {
        margin-left: -19.2rem;
        margin-right: -19.2rem;
    }
    .md\:-my-224 {
        margin-top: -22.4rem;
        margin-bottom: -22.4rem;
    }
    .md\:-mx-224 {
        margin-left: -22.4rem;
        margin-right: -22.4rem;
    }
    .md\:-my-256 {
        margin-top: -25.6rem;
        margin-bottom: -25.6rem;
    }
    .md\:-mx-256 {
        margin-left: -25.6rem;
        margin-right: -25.6rem;
    }
    .md\:-my-px {
        margin-top: -1px;
        margin-bottom: -1px;
    }
    .md\:-mx-px {
        margin-left: -1px;
        margin-right: -1px;
    }
    .md\:-mt-0 {
        margin-top: 0;
    }
    .md\:-mr-0 {
        margin-right: 0;
    }
    .md\:-mb-0 {
        margin-bottom: 0;
    }
    .md\:-ml-0 {
        margin-left: 0;
    }
    .md\:-mt-4 {
        margin-top: -0.4rem;
    }
    .md\:-mr-4 {
        margin-right: -0.4rem;
    }
    .md\:-mb-4 {
        margin-bottom: -0.4rem;
    }
    .md\:-ml-4 {
        margin-left: -0.4rem;
    }
    .md\:-mt-8 {
        margin-top: -0.8rem;
    }
    .md\:-mr-8 {
        margin-right: -0.8rem;
    }
    .md\:-mb-8 {
        margin-bottom: -0.8rem;
    }
    .md\:-ml-8 {
        margin-left: -0.8rem;
    }
    .md\:-mt-12 {
        margin-top: -1.2rem;
    }
    .md\:-mr-12 {
        margin-right: -1.2rem;
    }
    .md\:-mb-12 {
        margin-bottom: -1.2rem;
    }
    .md\:-ml-12 {
        margin-left: -1.2rem;
    }
    .md\:-mt-16 {
        margin-top: -1.6rem;
    }
    .md\:-mr-16 {
        margin-right: -1.6rem;
    }
    .md\:-mb-16 {
        margin-bottom: -1.6rem;
    }
    .md\:-ml-16 {
        margin-left: -1.6rem;
    }
    .md\:-mt-20 {
        margin-top: -2rem;
    }
    .md\:-mr-20 {
        margin-right: -2rem;
    }
    .md\:-mb-20 {
        margin-bottom: -2rem;
    }
    .md\:-ml-20 {
        margin-left: -2rem;
    }
    .md\:-mt-24 {
        margin-top: -2.4rem;
    }
    .md\:-mr-24 {
        margin-right: -2.4rem;
    }
    .md\:-mb-24 {
        margin-bottom: -2.4rem;
    }
    .md\:-ml-24 {
        margin-left: -2.4rem;
    }
    .md\:-mt-28 {
        margin-top: -2.8rem;
    }
    .md\:-mr-28 {
        margin-right: -2.8rem;
    }
    .md\:-mb-28 {
        margin-bottom: -2.8rem;
    }
    .md\:-ml-28 {
        margin-left: -2.8rem;
    }
    .md\:-mt-32 {
        margin-top: -3.2rem;
    }
    .md\:-mr-32 {
        margin-right: -3.2rem;
    }
    .md\:-mb-32 {
        margin-bottom: -3.2rem;
    }
    .md\:-ml-32 {
        margin-left: -3.2rem;
    }
    .md\:-mt-36 {
        margin-top: -3.6rem;
    }
    .md\:-mr-36 {
        margin-right: -3.6rem;
    }
    .md\:-mb-36 {
        margin-bottom: -3.6rem;
    }
    .md\:-ml-36 {
        margin-left: -3.6rem;
    }
    .md\:-mt-40 {
        margin-top: -4rem;
    }
    .md\:-mr-40 {
        margin-right: -4rem;
    }
    .md\:-mb-40 {
        margin-bottom: -4rem;
    }
    .md\:-ml-40 {
        margin-left: -4rem;
    }
    .md\:-mt-44 {
        margin-top: -4.4rem;
    }
    .md\:-mr-44 {
        margin-right: -4.4rem;
    }
    .md\:-mb-44 {
        margin-bottom: -4.4rem;
    }
    .md\:-ml-44 {
        margin-left: -4.4rem;
    }
    .md\:-mt-48 {
        margin-top: -4.8rem;
    }
    .md\:-mr-48 {
        margin-right: -4.8rem;
    }
    .md\:-mb-48 {
        margin-bottom: -4.8rem;
    }
    .md\:-ml-48 {
        margin-left: -4.8rem;
    }
    .md\:-mt-52 {
        margin-top: -5.2rem;
    }
    .md\:-mr-52 {
        margin-right: -5.2rem;
    }
    .md\:-mb-52 {
        margin-bottom: -5.2rem;
    }
    .md\:-ml-52 {
        margin-left: -5.2rem;
    }
    .md\:-mt-56 {
        margin-top: -5.6rem;
    }
    .md\:-mr-56 {
        margin-right: -5.6rem;
    }
    .md\:-mb-56 {
        margin-bottom: -5.6rem;
    }
    .md\:-ml-56 {
        margin-left: -5.6rem;
    }
    .md\:-mt-60 {
        margin-top: -6rem;
    }
    .md\:-mr-60 {
        margin-right: -6rem;
    }
    .md\:-mb-60 {
        margin-bottom: -6rem;
    }
    .md\:-ml-60 {
        margin-left: -6rem;
    }
    .md\:-mt-64 {
        margin-top: -6.4rem;
    }
    .md\:-mr-64 {
        margin-right: -6.4rem;
    }
    .md\:-mb-64 {
        margin-bottom: -6.4rem;
    }
    .md\:-ml-64 {
        margin-left: -6.4rem;
    }
    .md\:-mt-68 {
        margin-top: -6.8rem;
    }
    .md\:-mr-68 {
        margin-right: -6.8rem;
    }
    .md\:-mb-68 {
        margin-bottom: -6.8rem;
    }
    .md\:-ml-68 {
        margin-left: -6.8rem;
    }
    .md\:-mt-72 {
        margin-top: -7.2rem;
    }
    .md\:-mr-72 {
        margin-right: -7.2rem;
    }
    .md\:-mb-72 {
        margin-bottom: -7.2rem;
    }
    .md\:-ml-72 {
        margin-left: -7.2rem;
    }
    .md\:-mt-76 {
        margin-top: -7.6rem;
    }
    .md\:-mr-76 {
        margin-right: -7.6rem;
    }
    .md\:-mb-76 {
        margin-bottom: -7.6rem;
    }
    .md\:-ml-76 {
        margin-left: -7.6rem;
    }
    .md\:-mt-80 {
        margin-top: -8rem;
    }
    .md\:-mr-80 {
        margin-right: -8rem;
    }
    .md\:-mb-80 {
        margin-bottom: -8rem;
    }
    .md\:-ml-80 {
        margin-left: -8rem;
    }
    .md\:-mt-84 {
        margin-top: -8.4rem;
    }
    .md\:-mr-84 {
        margin-right: -8.4rem;
    }
    .md\:-mb-84 {
        margin-bottom: -8.4rem;
    }
    .md\:-ml-84 {
        margin-left: -8.4rem;
    }
    .md\:-mt-88 {
        margin-top: -8.8rem;
    }
    .md\:-mr-88 {
        margin-right: -8.8rem;
    }
    .md\:-mb-88 {
        margin-bottom: -8.8rem;
    }
    .md\:-ml-88 {
        margin-left: -8.8rem;
    }
    .md\:-mt-92 {
        margin-top: -9.2rem;
    }
    .md\:-mr-92 {
        margin-right: -9.2rem;
    }
    .md\:-mb-92 {
        margin-bottom: -9.2rem;
    }
    .md\:-ml-92 {
        margin-left: -9.2rem;
    }
    .md\:-mt-96 {
        margin-top: -9.6rem;
    }
    .md\:-mr-96 {
        margin-right: -9.6rem;
    }
    .md\:-mb-96 {
        margin-bottom: -9.6rem;
    }
    .md\:-ml-96 {
        margin-left: -9.6rem;
    }
    .md\:-mt-128 {
        margin-top: -12.8rem;
    }
    .md\:-mr-128 {
        margin-right: -12.8rem;
    }
    .md\:-mb-128 {
        margin-bottom: -12.8rem;
    }
    .md\:-ml-128 {
        margin-left: -12.8rem;
    }
    .md\:-mt-160 {
        margin-top: -16rem;
    }
    .md\:-mr-160 {
        margin-right: -16rem;
    }
    .md\:-mb-160 {
        margin-bottom: -16rem;
    }
    .md\:-ml-160 {
        margin-left: -16rem;
    }
    .md\:-mt-192 {
        margin-top: -19.2rem;
    }
    .md\:-mr-192 {
        margin-right: -19.2rem;
    }
    .md\:-mb-192 {
        margin-bottom: -19.2rem;
    }
    .md\:-ml-192 {
        margin-left: -19.2rem;
    }
    .md\:-mt-224 {
        margin-top: -22.4rem;
    }
    .md\:-mr-224 {
        margin-right: -22.4rem;
    }
    .md\:-mb-224 {
        margin-bottom: -22.4rem;
    }
    .md\:-ml-224 {
        margin-left: -22.4rem;
    }
    .md\:-mt-256 {
        margin-top: -25.6rem;
    }
    .md\:-mr-256 {
        margin-right: -25.6rem;
    }
    .md\:-mb-256 {
        margin-bottom: -25.6rem;
    }
    .md\:-ml-256 {
        margin-left: -25.6rem;
    }
    .md\:-mt-px {
        margin-top: -1px;
    }
    .md\:-mr-px {
        margin-right: -1px;
    }
    .md\:-mb-px {
        margin-bottom: -1px;
    }
    .md\:-ml-px {
        margin-left: -1px;
    }
    .md\:opacity-0 {
        opacity: 0;
    }
    .md\:opacity-25 {
        opacity: .25;
    }
    .md\:opacity-50 {
        opacity: .5;
    }
    .md\:opacity-75 {
        opacity: .75;
    }
    .md\:opacity-100 {
        opacity: 1;
    }
    .md\:overflow-auto {
        overflow: auto;
    }
    .md\:overflow-hidden {
        overflow: hidden;
    }
    .md\:overflow-visible {
        overflow: visible;
    }
    .md\:overflow-scroll {
        overflow: scroll;
    }
    .md\:overflow-x-auto {
        overflow-x: auto;
    }
    .md\:overflow-y-auto {
        overflow-y: auto;
    }
    .md\:overflow-x-hidden {
        overflow-x: hidden;
    }
    .md\:overflow-y-hidden {
        overflow-y: hidden;
    }
    .md\:overflow-x-visible {
        overflow-x: visible;
    }
    .md\:overflow-y-visible {
        overflow-y: visible;
    }
    .md\:overflow-x-scroll {
        overflow-x: scroll;
    }
    .md\:overflow-y-scroll {
        overflow-y: scroll;
    }
    .md\:scrolling-touch {
        -webkit-overflow-scrolling: touch;
    }
    .md\:scrolling-auto {
        -webkit-overflow-scrolling: auto;
    }
    .md\:p-0 {
        padding: 0;
    }
    .md\:p-4 {
        padding: .4rem;
    }
    .md\:p-8 {
        padding: .8rem;
    }
    .md\:p-12 {
        padding: 1.2rem;
    }
    .md\:p-16 {
        padding: 1.6rem;
    }
    .md\:p-20 {
        padding: 2rem;
    }
    .md\:p-24 {
        padding: 2.4rem;
    }
    .md\:p-28 {
        padding: 2.8rem;
    }
    .md\:p-32 {
        padding: 3.2rem;
    }
    .md\:p-36 {
        padding: 3.6rem;
    }
    .md\:p-40 {
        padding: 4rem;
    }
    .md\:p-44 {
        padding: 4.4rem;
    }
    .md\:p-48 {
        padding: 4.8rem;
    }
    .md\:p-52 {
        padding: 5.2rem;
    }
    .md\:p-56 {
        padding: 5.6rem;
    }
    .md\:p-60 {
        padding: 6rem;
    }
    .md\:p-64 {
        padding: 6.4rem;
    }
    .md\:p-68 {
        padding: 6.8rem;
    }
    .md\:p-72 {
        padding: 7.2rem;
    }
    .md\:p-76 {
        padding: 7.6rem;
    }
    .md\:p-80 {
        padding: 8rem;
    }
    .md\:p-84 {
        padding: 8.4rem;
    }
    .md\:p-88 {
        padding: 8.8rem;
    }
    .md\:p-92 {
        padding: 9.2rem;
    }
    .md\:p-96 {
        padding: 9.6rem;
    }
    .md\:p-128 {
        padding: 12.8rem;
    }
    .md\:p-160 {
        padding: 16rem;
    }
    .md\:p-192 {
        padding: 19.2rem;
    }
    .md\:p-224 {
        padding: 22.4rem;
    }
    .md\:p-256 {
        padding: 25.6rem;
    }
    .md\:p-px {
        padding: 1px;
    }
    .md\:py-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .md\:px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .md\:py-4 {
        padding-top: .4rem;
        padding-bottom: .4rem;
    }
    .md\:px-4 {
        padding-left: .4rem;
        padding-right: .4rem;
    }
    .md\:py-8 {
        padding-top: .8rem;
        padding-bottom: .8rem;
    }
    .md\:px-8 {
        padding-left: .8rem;
        padding-right: .8rem;
    }
    .md\:py-12 {
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }
    .md\:px-12 {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
    .md\:py-16 {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
    .md\:px-16 {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
    .md\:py-20 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .md\:px-20 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .md\:py-24 {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }
    .md\:px-24 {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }
    .md\:py-28 {
        padding-top: 2.8rem;
        padding-bottom: 2.8rem;
    }
    .md\:px-28 {
        padding-left: 2.8rem;
        padding-right: 2.8rem;
    }
    .md\:py-32 {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
    }
    .md\:px-32 {
        padding-left: 3.2rem;
        padding-right: 3.2rem;
    }
    .md\:py-36 {
        padding-top: 3.6rem;
        padding-bottom: 3.6rem;
    }
    .md\:px-36 {
        padding-left: 3.6rem;
        padding-right: 3.6rem;
    }
    .md\:py-40 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .md\:px-40 {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .md\:py-44 {
        padding-top: 4.4rem;
        padding-bottom: 4.4rem;
    }
    .md\:px-44 {
        padding-left: 4.4rem;
        padding-right: 4.4rem;
    }
    .md\:py-48 {
        padding-top: 4.8rem;
        padding-bottom: 4.8rem;
    }
    .md\:px-48 {
        padding-left: 4.8rem;
        padding-right: 4.8rem;
    }
    .md\:py-52 {
        padding-top: 5.2rem;
        padding-bottom: 5.2rem;
    }
    .md\:px-52 {
        padding-left: 5.2rem;
        padding-right: 5.2rem;
    }
    .md\:py-56 {
        padding-top: 5.6rem;
        padding-bottom: 5.6rem;
    }
    .md\:px-56 {
        padding-left: 5.6rem;
        padding-right: 5.6rem;
    }
    .md\:py-60 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .md\:px-60 {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .md\:py-64 {
        padding-top: 6.4rem;
        padding-bottom: 6.4rem;
    }
    .md\:px-64 {
        padding-left: 6.4rem;
        padding-right: 6.4rem;
    }
    .md\:py-68 {
        padding-top: 6.8rem;
        padding-bottom: 6.8rem;
    }
    .md\:px-68 {
        padding-left: 6.8rem;
        padding-right: 6.8rem;
    }
    .md\:py-72 {
        padding-top: 7.2rem;
        padding-bottom: 7.2rem;
    }
    .md\:px-72 {
        padding-left: 7.2rem;
        padding-right: 7.2rem;
    }
    .md\:py-76 {
        padding-top: 7.6rem;
        padding-bottom: 7.6rem;
    }
    .md\:px-76 {
        padding-left: 7.6rem;
        padding-right: 7.6rem;
    }
    .md\:py-80 {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    .md\:px-80 {
        padding-left: 8rem;
        padding-right: 8rem;
    }
    .md\:py-84 {
        padding-top: 8.4rem;
        padding-bottom: 8.4rem;
    }
    .md\:px-84 {
        padding-left: 8.4rem;
        padding-right: 8.4rem;
    }
    .md\:py-88 {
        padding-top: 8.8rem;
        padding-bottom: 8.8rem;
    }
    .md\:px-88 {
        padding-left: 8.8rem;
        padding-right: 8.8rem;
    }
    .md\:py-92 {
        padding-top: 9.2rem;
        padding-bottom: 9.2rem;
    }
    .md\:px-92 {
        padding-left: 9.2rem;
        padding-right: 9.2rem;
    }
    .md\:py-96 {
        padding-top: 9.6rem;
        padding-bottom: 9.6rem;
    }
    .md\:px-96 {
        padding-left: 9.6rem;
        padding-right: 9.6rem;
    }
    .md\:py-128 {
        padding-top: 12.8rem;
        padding-bottom: 12.8rem;
    }
    .md\:px-128 {
        padding-left: 12.8rem;
        padding-right: 12.8rem;
    }
    .md\:py-160 {
        padding-top: 16rem;
        padding-bottom: 16rem;
    }
    .md\:px-160 {
        padding-left: 16rem;
        padding-right: 16rem;
    }
    .md\:py-192 {
        padding-top: 19.2rem;
        padding-bottom: 19.2rem;
    }
    .md\:px-192 {
        padding-left: 19.2rem;
        padding-right: 19.2rem;
    }
    .md\:py-224 {
        padding-top: 22.4rem;
        padding-bottom: 22.4rem;
    }
    .md\:px-224 {
        padding-left: 22.4rem;
        padding-right: 22.4rem;
    }
    .md\:py-256 {
        padding-top: 25.6rem;
        padding-bottom: 25.6rem;
    }
    .md\:px-256 {
        padding-left: 25.6rem;
        padding-right: 25.6rem;
    }
    .md\:py-px {
        padding-top: 1px;
        padding-bottom: 1px;
    }
    .md\:px-px {
        padding-left: 1px;
        padding-right: 1px;
    }
    .md\:pt-0 {
        padding-top: 0;
    }
    .md\:pr-0 {
        padding-right: 0;
    }
    .md\:pb-0 {
        padding-bottom: 0;
    }
    .md\:pl-0 {
        padding-left: 0;
    }
    .md\:pt-4 {
        padding-top: .4rem;
    }
    .md\:pr-4 {
        padding-right: .4rem;
    }
    .md\:pb-4 {
        padding-bottom: .4rem;
    }
    .md\:pl-4 {
        padding-left: .4rem;
    }
    .md\:pt-8 {
        padding-top: .8rem;
    }
    .md\:pr-8 {
        padding-right: .8rem;
    }
    .md\:pb-8 {
        padding-bottom: .8rem;
    }
    .md\:pl-8 {
        padding-left: .8rem;
    }
    .md\:pt-12 {
        padding-top: 1.2rem;
    }
    .md\:pr-12 {
        padding-right: 1.2rem;
    }
    .md\:pb-12 {
        padding-bottom: 1.2rem;
    }
    .md\:pl-12 {
        padding-left: 1.2rem;
    }
    .md\:pt-16 {
        padding-top: 1.6rem;
    }
    .md\:pr-16 {
        padding-right: 1.6rem;
    }
    .md\:pb-16 {
        padding-bottom: 1.6rem;
    }
    .md\:pl-16 {
        padding-left: 1.6rem;
    }
    .md\:pt-20 {
        padding-top: 2rem;
    }
    .md\:pr-20 {
        padding-right: 2rem;
    }
    .md\:pb-20 {
        padding-bottom: 2rem;
    }
    .md\:pl-20 {
        padding-left: 2rem;
    }
    .md\:pt-24 {
        padding-top: 2.4rem;
    }
    .md\:pr-24 {
        padding-right: 2.4rem;
    }
    .md\:pb-24 {
        padding-bottom: 2.4rem;
    }
    .md\:pl-24 {
        padding-left: 2.4rem;
    }
    .md\:pt-28 {
        padding-top: 2.8rem;
    }
    .md\:pr-28 {
        padding-right: 2.8rem;
    }
    .md\:pb-28 {
        padding-bottom: 2.8rem;
    }
    .md\:pl-28 {
        padding-left: 2.8rem;
    }
    .md\:pt-32 {
        padding-top: 3.2rem;
    }
    .md\:pr-32 {
        padding-right: 3.2rem;
    }
    .md\:pb-32 {
        padding-bottom: 3.2rem;
    }
    .md\:pl-32 {
        padding-left: 3.2rem;
    }
    .md\:pt-36 {
        padding-top: 3.6rem;
    }
    .md\:pr-36 {
        padding-right: 3.6rem;
    }
    .md\:pb-36 {
        padding-bottom: 3.6rem;
    }
    .md\:pl-36 {
        padding-left: 3.6rem;
    }
    .md\:pt-40 {
        padding-top: 4rem;
    }
    .md\:pr-40 {
        padding-right: 4rem;
    }
    .md\:pb-40 {
        padding-bottom: 4rem;
    }
    .md\:pl-40 {
        padding-left: 4rem;
    }
    .md\:pt-44 {
        padding-top: 4.4rem;
    }
    .md\:pr-44 {
        padding-right: 4.4rem;
    }
    .md\:pb-44 {
        padding-bottom: 4.4rem;
    }
    .md\:pl-44 {
        padding-left: 4.4rem;
    }
    .md\:pt-48 {
        padding-top: 4.8rem;
    }
    .md\:pr-48 {
        padding-right: 4.8rem;
    }
    .md\:pb-48 {
        padding-bottom: 4.8rem;
    }
    .md\:pl-48 {
        padding-left: 4.8rem;
    }
    .md\:pt-52 {
        padding-top: 5.2rem;
    }
    .md\:pr-52 {
        padding-right: 5.2rem;
    }
    .md\:pb-52 {
        padding-bottom: 5.2rem;
    }
    .md\:pl-52 {
        padding-left: 5.2rem;
    }
    .md\:pt-56 {
        padding-top: 5.6rem;
    }
    .md\:pr-56 {
        padding-right: 5.6rem;
    }
    .md\:pb-56 {
        padding-bottom: 5.6rem;
    }
    .md\:pl-56 {
        padding-left: 5.6rem;
    }
    .md\:pt-60 {
        padding-top: 6rem;
    }
    .md\:pr-60 {
        padding-right: 6rem;
    }
    .md\:pb-60 {
        padding-bottom: 6rem;
    }
    .md\:pl-60 {
        padding-left: 6rem;
    }
    .md\:pt-64 {
        padding-top: 6.4rem;
    }
    .md\:pr-64 {
        padding-right: 6.4rem;
    }
    .md\:pb-64 {
        padding-bottom: 6.4rem;
    }
    .md\:pl-64 {
        padding-left: 6.4rem;
    }
    .md\:pt-68 {
        padding-top: 6.8rem;
    }
    .md\:pr-68 {
        padding-right: 6.8rem;
    }
    .md\:pb-68 {
        padding-bottom: 6.8rem;
    }
    .md\:pl-68 {
        padding-left: 6.8rem;
    }
    .md\:pt-72 {
        padding-top: 7.2rem;
    }
    .md\:pr-72 {
        padding-right: 7.2rem;
    }
    .md\:pb-72 {
        padding-bottom: 7.2rem;
    }
    .md\:pl-72 {
        padding-left: 7.2rem;
    }
    .md\:pt-76 {
        padding-top: 7.6rem;
    }
    .md\:pr-76 {
        padding-right: 7.6rem;
    }
    .md\:pb-76 {
        padding-bottom: 7.6rem;
    }
    .md\:pl-76 {
        padding-left: 7.6rem;
    }
    .md\:pt-80 {
        padding-top: 8rem;
    }
    .md\:pr-80 {
        padding-right: 8rem;
    }
    .md\:pb-80 {
        padding-bottom: 8rem;
    }
    .md\:pl-80 {
        padding-left: 8rem;
    }
    .md\:pt-84 {
        padding-top: 8.4rem;
    }
    .md\:pr-84 {
        padding-right: 8.4rem;
    }
    .md\:pb-84 {
        padding-bottom: 8.4rem;
    }
    .md\:pl-84 {
        padding-left: 8.4rem;
    }
    .md\:pt-88 {
        padding-top: 8.8rem;
    }
    .md\:pr-88 {
        padding-right: 8.8rem;
    }
    .md\:pb-88 {
        padding-bottom: 8.8rem;
    }
    .md\:pl-88 {
        padding-left: 8.8rem;
    }
    .md\:pt-92 {
        padding-top: 9.2rem;
    }
    .md\:pr-92 {
        padding-right: 9.2rem;
    }
    .md\:pb-92 {
        padding-bottom: 9.2rem;
    }
    .md\:pl-92 {
        padding-left: 9.2rem;
    }
    .md\:pt-96 {
        padding-top: 9.6rem;
    }
    .md\:pr-96 {
        padding-right: 9.6rem;
    }
    .md\:pb-96 {
        padding-bottom: 9.6rem;
    }
    .md\:pl-96 {
        padding-left: 9.6rem;
    }
    .md\:pt-128 {
        padding-top: 12.8rem;
    }
    .md\:pr-128 {
        padding-right: 12.8rem;
    }
    .md\:pb-128 {
        padding-bottom: 12.8rem;
    }
    .md\:pl-128 {
        padding-left: 12.8rem;
    }
    .md\:pt-160 {
        padding-top: 16rem;
    }
    .md\:pr-160 {
        padding-right: 16rem;
    }
    .md\:pb-160 {
        padding-bottom: 16rem;
    }
    .md\:pl-160 {
        padding-left: 16rem;
    }
    .md\:pt-192 {
        padding-top: 19.2rem;
    }
    .md\:pr-192 {
        padding-right: 19.2rem;
    }
    .md\:pb-192 {
        padding-bottom: 19.2rem;
    }
    .md\:pl-192 {
        padding-left: 19.2rem;
    }
    .md\:pt-224 {
        padding-top: 22.4rem;
    }
    .md\:pr-224 {
        padding-right: 22.4rem;
    }
    .md\:pb-224 {
        padding-bottom: 22.4rem;
    }
    .md\:pl-224 {
        padding-left: 22.4rem;
    }
    .md\:pt-256 {
        padding-top: 25.6rem;
    }
    .md\:pr-256 {
        padding-right: 25.6rem;
    }
    .md\:pb-256 {
        padding-bottom: 25.6rem;
    }
    .md\:pl-256 {
        padding-left: 25.6rem;
    }
    .md\:pt-px {
        padding-top: 1px;
    }
    .md\:pr-px {
        padding-right: 1px;
    }
    .md\:pb-px {
        padding-bottom: 1px;
    }
    .md\:pl-px {
        padding-left: 1px;
    }
    .md\:pointer-events-none {
        pointer-events: none;
    }
    .md\:pointer-events-auto {
        pointer-events: auto;
    }
    .md\:static {
        position: static;
    }
    .md\:fixed {
        position: fixed;
    }
    .md\:absolute {
        position: absolute;
    }
    .md\:relative {
        position: relative;
    }
    .md\:sticky {
        position: -webkit-sticky;
        position: sticky;
    }
    .md\:pin-none {
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }
    .md\:pin {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .md\:pin-y {
        top: 0;
        bottom: 0;
    }
    .md\:pin-x {
        right: 0;
        left: 0;
    }
    .md\:pin-t {
        top: 0;
    }
    .md\:pin-r {
        right: 0;
    }
    .md\:pin-b {
        bottom: 0;
    }
    .md\:pin-l {
        left: 0;
    }
    .md\:resize-none {
        resize: none;
    }
    .md\:resize-y {
        resize: vertical;
    }
    .md\:resize-x {
        resize: horizontal;
    }
    .md\:resize {
        resize: both;
    }
    .md\:shadow {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    }
    .md\:shadow-md {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
    }
    .md\:shadow-lg {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    }
    .md\:shadow-inner {
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
    }
    .md\:shadow-none {
        box-shadow: none;
    }
    .md\:text-left {
        text-align: left;
    }
    .md\:text-center {
        text-align: center;
    }
    .md\:text-right {
        text-align: right;
    }
    .md\:text-justify {
        text-align: justify;
    }
    .md\:text-transparent {
        color: transparent;
    }
    .md\:text-black {
        color: #22292f;
    }
    .md\:text-grey-darkest {
        color: #3d4852;
    }
    .md\:text-grey-darker {
        color: #606f7b;
    }
    .md\:text-grey-dark {
        color: #8795a1;
    }
    .md\:text-grey {
        color: #b8c2cc;
    }
    .md\:text-grey-light {
        color: #dae1e7;
    }
    .md\:text-grey-lighter {
        color: #f1f5f8;
    }
    .md\:text-grey-lightest {
        color: #f8fafc;
    }
    .md\:text-white {
        color: #fff;
    }
    .md\:text-red-darkest {
        color: #3b0d0c;
    }
    .md\:text-red-darker {
        color: #621b18;
    }
    .md\:text-red-dark {
        color: #cc1f1a;
    }
    .md\:text-red {
        color: #e3342f;
    }
    .md\:text-red-light {
        color: #ef5753;
    }
    .md\:text-red-lighter {
        color: #f9acaa;
    }
    .md\:text-red-lightest {
        color: #fcebea;
    }
    .md\:text-orange-darkest {
        color: #462a16;
    }
    .md\:text-orange-darker {
        color: #613b1f;
    }
    .md\:text-orange-dark {
        color: #de751f;
    }
    .md\:text-orange {
        color: #f6993f;
    }
    .md\:text-orange-light {
        color: #faad63;
    }
    .md\:text-orange-lighter {
        color: #fcd9b6;
    }
    .md\:text-orange-lightest {
        color: #fff5eb;
    }
    .md\:text-yellow-darkest {
        color: #453411;
    }
    .md\:text-yellow-darker {
        color: #684f1d;
    }
    .md\:text-yellow-dark {
        color: #f2d024;
    }
    .md\:text-yellow {
        color: #ffed4a;
    }
    .md\:text-yellow-light {
        color: #fff382;
    }
    .md\:text-yellow-lighter {
        color: #fff9c2;
    }
    .md\:text-yellow-lightest {
        color: #fcfbeb;
    }
    .md\:text-green-darkest {
        color: #0f2f21;
    }
    .md\:text-green-darker {
        color: #1a4731;
    }
    .md\:text-green-dark {
        color: #1f9d55;
    }
    .md\:text-green {
        color: #38c172;
    }
    .md\:text-green-light {
        color: #51d88a;
    }
    .md\:text-green-lighter {
        color: #a2f5bf;
    }
    .md\:text-green-lightest {
        color: #e3fcec;
    }
    .md\:text-teal-darkest {
        color: #0d3331;
    }
    .md\:text-teal-darker {
        color: #20504f;
    }
    .md\:text-teal-dark {
        color: #38a89d;
    }
    .md\:text-teal {
        color: #4dc0b5;
    }
    .md\:text-teal-light {
        color: #64d5ca;
    }
    .md\:text-teal-lighter {
        color: #a0f0ed;
    }
    .md\:text-teal-lightest {
        color: #e8fffe;
    }
    .md\:text-blue-darkest {
        color: #12283a;
    }
    .md\:text-blue-darker {
        color: #1c3d5a;
    }
    .md\:text-blue-dark {
        color: #2779bd;
    }
    .md\:text-blue {
        color: #3490dc;
    }
    .md\:text-blue-light {
        color: #6cb2eb;
    }
    .md\:text-blue-lighter {
        color: #bcdefa;
    }
    .md\:text-blue-lightest {
        color: #eff8ff;
    }
    .md\:text-indigo-darkest {
        color: #191e38;
    }
    .md\:text-indigo-darker {
        color: #2f365f;
    }
    .md\:text-indigo-dark {
        color: #5661b3;
    }
    .md\:text-indigo {
        color: #6574cd;
    }
    .md\:text-indigo-light {
        color: #7886d7;
    }
    .md\:text-indigo-lighter {
        color: #b2b7ff;
    }
    .md\:text-indigo-lightest {
        color: #e6e8ff;
    }
    .md\:text-purple-darkest {
        color: #21183c;
    }
    .md\:text-purple-darker {
        color: #382b5f;
    }
    .md\:text-purple-dark {
        color: #794acf;
    }
    .md\:text-purple {
        color: #9561e2;
    }
    .md\:text-purple-light {
        color: #a779e9;
    }
    .md\:text-purple-lighter {
        color: #d6bbfc;
    }
    .md\:text-purple-lightest {
        color: #f3ebff;
    }
    .md\:text-pink-darkest {
        color: #451225;
    }
    .md\:text-pink-darker {
        color: #6f213f;
    }
    .md\:text-pink-dark {
        color: #eb5286;
    }
    .md\:text-pink {
        color: #f66d9b;
    }
    .md\:text-pink-light {
        color: #fa7ea8;
    }
    .md\:text-pink-lighter {
        color: #ffbbca;
    }
    .md\:text-pink-lightest {
        color: #ffebef;
    }
    .md\:hover\:text-transparent:hover {
        color: transparent;
    }
    .md\:hover\:text-black:hover {
        color: #22292f;
    }
    .md\:hover\:text-grey-darkest:hover {
        color: #3d4852;
    }
    .md\:hover\:text-grey-darker:hover {
        color: #606f7b;
    }
    .md\:hover\:text-grey-dark:hover {
        color: #8795a1;
    }
    .md\:hover\:text-grey:hover {
        color: #b8c2cc;
    }
    .md\:hover\:text-grey-light:hover {
        color: #dae1e7;
    }
    .md\:hover\:text-grey-lighter:hover {
        color: #f1f5f8;
    }
    .md\:hover\:text-grey-lightest:hover {
        color: #f8fafc;
    }
    .md\:hover\:text-white:hover {
        color: #fff;
    }
    .md\:hover\:text-red-darkest:hover {
        color: #3b0d0c;
    }
    .md\:hover\:text-red-darker:hover {
        color: #621b18;
    }
    .md\:hover\:text-red-dark:hover {
        color: #cc1f1a;
    }
    .md\:hover\:text-red:hover {
        color: #e3342f;
    }
    .md\:hover\:text-red-light:hover {
        color: #ef5753;
    }
    .md\:hover\:text-red-lighter:hover {
        color: #f9acaa;
    }
    .md\:hover\:text-red-lightest:hover {
        color: #fcebea;
    }
    .md\:hover\:text-orange-darkest:hover {
        color: #462a16;
    }
    .md\:hover\:text-orange-darker:hover {
        color: #613b1f;
    }
    .md\:hover\:text-orange-dark:hover {
        color: #de751f;
    }
    .md\:hover\:text-orange:hover {
        color: #f6993f;
    }
    .md\:hover\:text-orange-light:hover {
        color: #faad63;
    }
    .md\:hover\:text-orange-lighter:hover {
        color: #fcd9b6;
    }
    .md\:hover\:text-orange-lightest:hover {
        color: #fff5eb;
    }
    .md\:hover\:text-yellow-darkest:hover {
        color: #453411;
    }
    .md\:hover\:text-yellow-darker:hover {
        color: #684f1d;
    }
    .md\:hover\:text-yellow-dark:hover {
        color: #f2d024;
    }
    .md\:hover\:text-yellow:hover {
        color: #ffed4a;
    }
    .md\:hover\:text-yellow-light:hover {
        color: #fff382;
    }
    .md\:hover\:text-yellow-lighter:hover {
        color: #fff9c2;
    }
    .md\:hover\:text-yellow-lightest:hover {
        color: #fcfbeb;
    }
    .md\:hover\:text-green-darkest:hover {
        color: #0f2f21;
    }
    .md\:hover\:text-green-darker:hover {
        color: #1a4731;
    }
    .md\:hover\:text-green-dark:hover {
        color: #1f9d55;
    }
    .md\:hover\:text-green:hover {
        color: #38c172;
    }
    .md\:hover\:text-green-light:hover {
        color: #51d88a;
    }
    .md\:hover\:text-green-lighter:hover {
        color: #a2f5bf;
    }
    .md\:hover\:text-green-lightest:hover {
        color: #e3fcec;
    }
    .md\:hover\:text-teal-darkest:hover {
        color: #0d3331;
    }
    .md\:hover\:text-teal-darker:hover {
        color: #20504f;
    }
    .md\:hover\:text-teal-dark:hover {
        color: #38a89d;
    }
    .md\:hover\:text-teal:hover {
        color: #4dc0b5;
    }
    .md\:hover\:text-teal-light:hover {
        color: #64d5ca;
    }
    .md\:hover\:text-teal-lighter:hover {
        color: #a0f0ed;
    }
    .md\:hover\:text-teal-lightest:hover {
        color: #e8fffe;
    }
    .md\:hover\:text-blue-darkest:hover {
        color: #12283a;
    }
    .md\:hover\:text-blue-darker:hover {
        color: #1c3d5a;
    }
    .md\:hover\:text-blue-dark:hover {
        color: #2779bd;
    }
    .md\:hover\:text-blue:hover {
        color: #3490dc;
    }
    .md\:hover\:text-blue-light:hover {
        color: #6cb2eb;
    }
    .md\:hover\:text-blue-lighter:hover {
        color: #bcdefa;
    }
    .md\:hover\:text-blue-lightest:hover {
        color: #eff8ff;
    }
    .md\:hover\:text-indigo-darkest:hover {
        color: #191e38;
    }
    .md\:hover\:text-indigo-darker:hover {
        color: #2f365f;
    }
    .md\:hover\:text-indigo-dark:hover {
        color: #5661b3;
    }
    .md\:hover\:text-indigo:hover {
        color: #6574cd;
    }
    .md\:hover\:text-indigo-light:hover {
        color: #7886d7;
    }
    .md\:hover\:text-indigo-lighter:hover {
        color: #b2b7ff;
    }
    .md\:hover\:text-indigo-lightest:hover {
        color: #e6e8ff;
    }
    .md\:hover\:text-purple-darkest:hover {
        color: #21183c;
    }
    .md\:hover\:text-purple-darker:hover {
        color: #382b5f;
    }
    .md\:hover\:text-purple-dark:hover {
        color: #794acf;
    }
    .md\:hover\:text-purple:hover {
        color: #9561e2;
    }
    .md\:hover\:text-purple-light:hover {
        color: #a779e9;
    }
    .md\:hover\:text-purple-lighter:hover {
        color: #d6bbfc;
    }
    .md\:hover\:text-purple-lightest:hover {
        color: #f3ebff;
    }
    .md\:hover\:text-pink-darkest:hover {
        color: #451225;
    }
    .md\:hover\:text-pink-darker:hover {
        color: #6f213f;
    }
    .md\:hover\:text-pink-dark:hover {
        color: #eb5286;
    }
    .md\:hover\:text-pink:hover {
        color: #f66d9b;
    }
    .md\:hover\:text-pink-light:hover {
        color: #fa7ea8;
    }
    .md\:hover\:text-pink-lighter:hover {
        color: #ffbbca;
    }
    .md\:hover\:text-pink-lightest:hover {
        color: #ffebef;
    }
    .md\:text-10 {
        font-size: 1rem;
    }
    .md\:text-11 {
        font-size: 1.1rem;
    }
    .md\:text-12 {
        font-size: 1.2rem;
    }
    .md\:text-13 {
        font-size: 1.3rem;
    }
    .md\:text-14 {
        font-size: 1.4rem;
    }
    .md\:text-15 {
        font-size: 1.5rem;
    }
    .md\:text-16 {
        font-size: 1.6rem;
    }
    .md\:text-17 {
        font-size: 1.7rem;
    }
    .md\:text-18 {
        font-size: 1.8rem;
    }
    .md\:text-19 {
        font-size: 1.9rem;
    }
    .md\:text-20 {
        font-size: 2rem;
    }
    .md\:text-24 {
        font-size: 2.4rem;
    }
    .md\:text-28 {
        font-size: 2.8rem;
    }
    .md\:text-32 {
        font-size: 3.2rem;
    }
    .md\:text-36 {
        font-size: 3.6rem;
    }
    .md\:text-40 {
        font-size: 4rem;
    }
    .md\:text-44 {
        font-size: 4.4rem;
    }
    .md\:text-48 {
        font-size: 4.8rem;
    }
    .md\:text-52 {
        font-size: 5.2rem;
    }
    .md\:text-56 {
        font-size: 5.6rem;
    }
    .md\:text-60 {
        font-size: 6rem;
    }
    .md\:text-64 {
        font-size: 6.4rem;
    }
    .md\:text-68 {
        font-size: 6.8rem;
    }
    .md\:text-72 {
        font-size: 7.2rem;
    }
    .md\:text-96 {
        font-size: 9.6rem;
    }
    .md\:text-128 {
        font-size: 12.8rem;
    }
    .md\:text-xs {
        font-size: 1.2rem;
    }
    .md\:text-sm {
        font-size: 2.4rem;
    }
    .md\:text-base {
        font-size: 1.6rem;
    }
    .md\:text-lg {
        font-size: 1.8rem;
    }
    .md\:text-xl {
        font-size: 2rem;
    }
    .md\:text-2xl {
        font-size: 2.4rem;
    }
    .md\:text-3xl {
        font-size: 3rem;
    }
    .md\:text-4xl {
        font-size: 3.6rem;
    }
    .md\:text-5xl {
        font-size: 4.8rem;
    }
    .md\:italic {
        font-style: italic;
    }
    .md\:roman {
        font-style: normal;
    }
    .md\:uppercase {
        text-transform: uppercase;
    }
    .md\:lowercase {
        text-transform: lowercase;
    }
    .md\:capitalize {
        text-transform: capitalize;
    }
    .md\:normal-case {
        text-transform: none;
    }
    .md\:underline {
        text-decoration: underline;
    }
    .md\:line-through {
        text-decoration: line-through;
    }
    .md\:no-underline {
        text-decoration: none;
    }
    .md\:antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .md\:subpixel-antialiased {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
    .md\:hover\:italic:hover {
        font-style: italic;
    }
    .md\:hover\:roman:hover {
        font-style: normal;
    }
    .md\:hover\:uppercase:hover {
        text-transform: uppercase;
    }
    .md\:hover\:lowercase:hover {
        text-transform: lowercase;
    }
    .md\:hover\:capitalize:hover {
        text-transform: capitalize;
    }
    .md\:hover\:normal-case:hover {
        text-transform: none;
    }
    .md\:hover\:underline:hover {
        text-decoration: underline;
    }
    .md\:hover\:line-through:hover {
        text-decoration: line-through;
    }
    .md\:hover\:no-underline:hover {
        text-decoration: none;
    }
    .md\:hover\:antialiased:hover {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .md\:hover\:subpixel-antialiased:hover {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
    .md\:tracking-tight {
        letter-spacing: -0.05em;
    }
    .md\:tracking-normal {
        letter-spacing: 0;
    }
    .md\:tracking-wide {
        letter-spacing: .05em;
    }
    .md\:select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .md\:select-text {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
    .md\:align-baseline {
        vertical-align: baseline;
    }
    .md\:align-top {
        vertical-align: top;
    }
    .md\:align-middle {
        vertical-align: middle;
    }
    .md\:align-bottom {
        vertical-align: bottom;
    }
    .md\:align-text-top {
        vertical-align: text-top;
    }
    .md\:align-text-bottom {
        vertical-align: text-bottom;
    }
    .md\:visible {
        visibility: visible;
    }
    .md\:invisible {
        visibility: hidden;
    }
    .md\:whitespace-normal {
        white-space: normal;
    }
    .md\:whitespace-no-wrap {
        white-space: nowrap;
    }
    .md\:whitespace-pre {
        white-space: pre;
    }
    .md\:whitespace-pre-line {
        white-space: pre-line;
    }
    .md\:whitespace-pre-wrap {
        white-space: pre-wrap;
    }
    .md\:break-words {
        word-wrap: break-word;
    }
    .md\:break-normal {
        word-wrap: normal;
    }
    .md\:truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .md\:w-0 {
        width: 0;
    }
    .md\:w-4 {
        width: .4rem;
    }
    .md\:w-8 {
        width: .8rem;
    }
    .md\:w-12 {
        width: 1.2rem;
    }
    .md\:w-16 {
        width: 1.6rem;
    }
    .md\:w-20 {
        width: 2rem;
    }
    .md\:w-24 {
        width: 2.4rem;
    }
    .md\:w-28 {
        width: 2.8rem;
    }
    .md\:w-32 {
        width: 3.2rem;
    }
    .md\:w-36 {
        width: 3.6rem;
    }
    .md\:w-40 {
        width: 4rem;
    }
    .md\:w-44 {
        width: 4.4rem;
    }
    .md\:w-48 {
        width: 4.8rem;
    }
    .md\:w-52 {
        width: 5.2rem;
    }
    .md\:w-56 {
        width: 5.6rem;
    }
    .md\:w-60 {
        width: 6rem;
    }
    .md\:w-64 {
        width: 6.4rem;
    }
    .md\:w-68 {
        width: 6.8rem;
    }
    .md\:w-72 {
        width: 7.2rem;
    }
    .md\:w-76 {
        width: 7.6rem;
    }
    .md\:w-80 {
        width: 8rem;
    }
    .md\:w-84 {
        width: 8.4rem;
    }
    .md\:w-88 {
        width: 8.8rem;
    }
    .md\:w-92 {
        width: 9.2rem;
    }
    .md\:w-96 {
        width: 9.6rem;
    }
    .md\:w-128 {
        width: 12.8rem;
    }
    .md\:w-160 {
        width: 16rem;
    }
    .md\:w-192 {
        width: 19.2rem;
    }
    .md\:w-224 {
        width: 22.4rem;
    }
    .md\:w-256 {
        width: 25.6rem;
    }
    .md\:w-288 {
        width: 28.8rem;
    }
    .md\:w-320 {
        width: 32rem;
    }
    .md\:w-512 {
        width: 51.2rem;
    }
    .md\:w-auto {
        width: auto;
    }
    .md\:w-px {
        width: 1px;
    }
    .md\:w-1\/2 {
        width: 50%;
    }
    .md\:w-1\/3 {
        width: 33.33333%;
    }
    .md\:w-2\/3 {
        width: 66.66667%;
    }
    .md\:w-1\/4 {
        width: 25%;
    }
    .md\:w-3\/4 {
        width: 75%;
    }
    .md\:w-1\/5 {
        width: 20%;
    }
    .md\:w-2\/5 {
        width: 40%;
    }
    .md\:w-3\/5 {
        width: 60%;
    }
    .md\:w-4\/5 {
        width: 80%;
    }
    .md\:w-1\/6 {
        width: 16.66667%;
    }
    .md\:w-5\/6 {
        width: 83.33333%;
    }
    .md\:w-full {
        width: 100%;
    }
    .md\:w-screen {
        width: 100vw;
    }
    .md\:z-0 {
        z-index: 0;
    }
    .md\:z-10 {
        z-index: 10;
    }
    .md\:z-20 {
        z-index: 20;
    }
    .md\:z-30 {
        z-index: 30;
    }
    .md\:z-40 {
        z-index: 40;
    }
    .md\:z-50 {
        z-index: 50;
    }
    .md\:z-auto {
        z-index: auto;
    }
}

@media (min-width: 1280px) {
    .lg\:list-reset {
        list-style: none;
        padding: 0;
    }
    .lg\:appearance-none {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .lg\:bg-fixed {
        background-attachment: fixed;
    }
    .lg\:bg-local {
        background-attachment: local;
    }
    .lg\:bg-scroll {
        background-attachment: scroll;
    }
    .lg\:bg-transparent {
        background-color: transparent;
    }
    .lg\:bg-black {
        background-color: #22292f;
    }
    .lg\:bg-grey-darkest {
        background-color: #3d4852;
    }
    .lg\:bg-grey-darker {
        background-color: #606f7b;
    }
    .lg\:bg-grey-dark {
        background-color: #8795a1;
    }
    .lg\:bg-grey {
        background-color: #b8c2cc;
    }
    .lg\:bg-grey-light {
        background-color: #dae1e7;
    }
    .lg\:bg-grey-lighter {
        background-color: #f1f5f8;
    }
    .lg\:bg-grey-lightest {
        background-color: #f8fafc;
    }
    .lg\:bg-white {
        background-color: #fff;
    }
    .lg\:bg-red-darkest {
        background-color: #3b0d0c;
    }
    .lg\:bg-red-darker {
        background-color: #621b18;
    }
    .lg\:bg-red-dark {
        background-color: #cc1f1a;
    }
    .lg\:bg-red {
        background-color: #e3342f;
    }
    .lg\:bg-red-light {
        background-color: #ef5753;
    }
    .lg\:bg-red-lighter {
        background-color: #f9acaa;
    }
    .lg\:bg-red-lightest {
        background-color: #fcebea;
    }
    .lg\:bg-orange-darkest {
        background-color: #462a16;
    }
    .lg\:bg-orange-darker {
        background-color: #613b1f;
    }
    .lg\:bg-orange-dark {
        background-color: #de751f;
    }
    .lg\:bg-orange {
        background-color: #f6993f;
    }
    .lg\:bg-orange-light {
        background-color: #faad63;
    }
    .lg\:bg-orange-lighter {
        background-color: #fcd9b6;
    }
    .lg\:bg-orange-lightest {
        background-color: #fff5eb;
    }
    .lg\:bg-yellow-darkest {
        background-color: #453411;
    }
    .lg\:bg-yellow-darker {
        background-color: #684f1d;
    }
    .lg\:bg-yellow-dark {
        background-color: #f2d024;
    }
    .lg\:bg-yellow {
        background-color: #ffed4a;
    }
    .lg\:bg-yellow-light {
        background-color: #fff382;
    }
    .lg\:bg-yellow-lighter {
        background-color: #fff9c2;
    }
    .lg\:bg-yellow-lightest {
        background-color: #fcfbeb;
    }
    .lg\:bg-green-darkest {
        background-color: #0f2f21;
    }
    .lg\:bg-green-darker {
        background-color: #1a4731;
    }
    .lg\:bg-green-dark {
        background-color: #1f9d55;
    }
    .lg\:bg-green {
        background-color: #38c172;
    }
    .lg\:bg-green-light {
        background-color: #51d88a;
    }
    .lg\:bg-green-lighter {
        background-color: #a2f5bf;
    }
    .lg\:bg-green-lightest {
        background-color: #e3fcec;
    }
    .lg\:bg-teal-darkest {
        background-color: #0d3331;
    }
    .lg\:bg-teal-darker {
        background-color: #20504f;
    }
    .lg\:bg-teal-dark {
        background-color: #38a89d;
    }
    .lg\:bg-teal {
        background-color: #4dc0b5;
    }
    .lg\:bg-teal-light {
        background-color: #64d5ca;
    }
    .lg\:bg-teal-lighter {
        background-color: #a0f0ed;
    }
    .lg\:bg-teal-lightest {
        background-color: #e8fffe;
    }
    .lg\:bg-blue-darkest {
        background-color: #12283a;
    }
    .lg\:bg-blue-darker {
        background-color: #1c3d5a;
    }
    .lg\:bg-blue-dark {
        background-color: #2779bd;
    }
    .lg\:bg-blue {
        background-color: #3490dc;
    }
    .lg\:bg-blue-light {
        background-color: #6cb2eb;
    }
    .lg\:bg-blue-lighter {
        background-color: #bcdefa;
    }
    .lg\:bg-blue-lightest {
        background-color: #eff8ff;
    }
    .lg\:bg-indigo-darkest {
        background-color: #191e38;
    }
    .lg\:bg-indigo-darker {
        background-color: #2f365f;
    }
    .lg\:bg-indigo-dark {
        background-color: #5661b3;
    }
    .lg\:bg-indigo {
        background-color: #6574cd;
    }
    .lg\:bg-indigo-light {
        background-color: #7886d7;
    }
    .lg\:bg-indigo-lighter {
        background-color: #b2b7ff;
    }
    .lg\:bg-indigo-lightest {
        background-color: #e6e8ff;
    }
    .lg\:bg-purple-darkest {
        background-color: #21183c;
    }
    .lg\:bg-purple-darker {
        background-color: #382b5f;
    }
    .lg\:bg-purple-dark {
        background-color: #794acf;
    }
    .lg\:bg-purple {
        background-color: #9561e2;
    }
    .lg\:bg-purple-light {
        background-color: #a779e9;
    }
    .lg\:bg-purple-lighter {
        background-color: #d6bbfc;
    }
    .lg\:bg-purple-lightest {
        background-color: #f3ebff;
    }
    .lg\:bg-pink-darkest {
        background-color: #451225;
    }
    .lg\:bg-pink-darker {
        background-color: #6f213f;
    }
    .lg\:bg-pink-dark {
        background-color: #eb5286;
    }
    .lg\:bg-pink {
        background-color: #f66d9b;
    }
    .lg\:bg-pink-light {
        background-color: #fa7ea8;
    }
    .lg\:bg-pink-lighter {
        background-color: #ffbbca;
    }
    .lg\:bg-pink-lightest {
        background-color: #ffebef;
    }
    .lg\:hover\:bg-transparent:hover {
        background-color: transparent;
    }
    .lg\:hover\:bg-black:hover {
        background-color: #22292f;
    }
    .lg\:hover\:bg-grey-darkest:hover {
        background-color: #3d4852;
    }
    .lg\:hover\:bg-grey-darker:hover {
        background-color: #606f7b;
    }
    .lg\:hover\:bg-grey-dark:hover {
        background-color: #8795a1;
    }
    .lg\:hover\:bg-grey:hover {
        background-color: #b8c2cc;
    }
    .lg\:hover\:bg-grey-light:hover {
        background-color: #dae1e7;
    }
    .lg\:hover\:bg-grey-lighter:hover {
        background-color: #f1f5f8;
    }
    .lg\:hover\:bg-grey-lightest:hover {
        background-color: #f8fafc;
    }
    .lg\:hover\:bg-white:hover {
        background-color: #fff;
    }
    .lg\:hover\:bg-red-darkest:hover {
        background-color: #3b0d0c;
    }
    .lg\:hover\:bg-red-darker:hover {
        background-color: #621b18;
    }
    .lg\:hover\:bg-red-dark:hover {
        background-color: #cc1f1a;
    }
    .lg\:hover\:bg-red:hover {
        background-color: #e3342f;
    }
    .lg\:hover\:bg-red-light:hover {
        background-color: #ef5753;
    }
    .lg\:hover\:bg-red-lighter:hover {
        background-color: #f9acaa;
    }
    .lg\:hover\:bg-red-lightest:hover {
        background-color: #fcebea;
    }
    .lg\:hover\:bg-orange-darkest:hover {
        background-color: #462a16;
    }
    .lg\:hover\:bg-orange-darker:hover {
        background-color: #613b1f;
    }
    .lg\:hover\:bg-orange-dark:hover {
        background-color: #de751f;
    }
    .lg\:hover\:bg-orange:hover {
        background-color: #f6993f;
    }
    .lg\:hover\:bg-orange-light:hover {
        background-color: #faad63;
    }
    .lg\:hover\:bg-orange-lighter:hover {
        background-color: #fcd9b6;
    }
    .lg\:hover\:bg-orange-lightest:hover {
        background-color: #fff5eb;
    }
    .lg\:hover\:bg-yellow-darkest:hover {
        background-color: #453411;
    }
    .lg\:hover\:bg-yellow-darker:hover {
        background-color: #684f1d;
    }
    .lg\:hover\:bg-yellow-dark:hover {
        background-color: #f2d024;
    }
    .lg\:hover\:bg-yellow:hover {
        background-color: #ffed4a;
    }
    .lg\:hover\:bg-yellow-light:hover {
        background-color: #fff382;
    }
    .lg\:hover\:bg-yellow-lighter:hover {
        background-color: #fff9c2;
    }
    .lg\:hover\:bg-yellow-lightest:hover {
        background-color: #fcfbeb;
    }
    .lg\:hover\:bg-green-darkest:hover {
        background-color: #0f2f21;
    }
    .lg\:hover\:bg-green-darker:hover {
        background-color: #1a4731;
    }
    .lg\:hover\:bg-green-dark:hover {
        background-color: #1f9d55;
    }
    .lg\:hover\:bg-green:hover {
        background-color: #38c172;
    }
    .lg\:hover\:bg-green-light:hover {
        background-color: #51d88a;
    }
    .lg\:hover\:bg-green-lighter:hover {
        background-color: #a2f5bf;
    }
    .lg\:hover\:bg-green-lightest:hover {
        background-color: #e3fcec;
    }
    .lg\:hover\:bg-teal-darkest:hover {
        background-color: #0d3331;
    }
    .lg\:hover\:bg-teal-darker:hover {
        background-color: #20504f;
    }
    .lg\:hover\:bg-teal-dark:hover {
        background-color: #38a89d;
    }
    .lg\:hover\:bg-teal:hover {
        background-color: #4dc0b5;
    }
    .lg\:hover\:bg-teal-light:hover {
        background-color: #64d5ca;
    }
    .lg\:hover\:bg-teal-lighter:hover {
        background-color: #a0f0ed;
    }
    .lg\:hover\:bg-teal-lightest:hover {
        background-color: #e8fffe;
    }
    .lg\:hover\:bg-blue-darkest:hover {
        background-color: #12283a;
    }
    .lg\:hover\:bg-blue-darker:hover {
        background-color: #1c3d5a;
    }
    .lg\:hover\:bg-blue-dark:hover {
        background-color: #2779bd;
    }
    .lg\:hover\:bg-blue:hover {
        background-color: #3490dc;
    }
    .lg\:hover\:bg-blue-light:hover {
        background-color: #6cb2eb;
    }
    .lg\:hover\:bg-blue-lighter:hover {
        background-color: #bcdefa;
    }
    .lg\:hover\:bg-blue-lightest:hover {
        background-color: #eff8ff;
    }
    .lg\:hover\:bg-indigo-darkest:hover {
        background-color: #191e38;
    }
    .lg\:hover\:bg-indigo-darker:hover {
        background-color: #2f365f;
    }
    .lg\:hover\:bg-indigo-dark:hover {
        background-color: #5661b3;
    }
    .lg\:hover\:bg-indigo:hover {
        background-color: #6574cd;
    }
    .lg\:hover\:bg-indigo-light:hover {
        background-color: #7886d7;
    }
    .lg\:hover\:bg-indigo-lighter:hover {
        background-color: #b2b7ff;
    }
    .lg\:hover\:bg-indigo-lightest:hover {
        background-color: #e6e8ff;
    }
    .lg\:hover\:bg-purple-darkest:hover {
        background-color: #21183c;
    }
    .lg\:hover\:bg-purple-darker:hover {
        background-color: #382b5f;
    }
    .lg\:hover\:bg-purple-dark:hover {
        background-color: #794acf;
    }
    .lg\:hover\:bg-purple:hover {
        background-color: #9561e2;
    }
    .lg\:hover\:bg-purple-light:hover {
        background-color: #a779e9;
    }
    .lg\:hover\:bg-purple-lighter:hover {
        background-color: #d6bbfc;
    }
    .lg\:hover\:bg-purple-lightest:hover {
        background-color: #f3ebff;
    }
    .lg\:hover\:bg-pink-darkest:hover {
        background-color: #451225;
    }
    .lg\:hover\:bg-pink-darker:hover {
        background-color: #6f213f;
    }
    .lg\:hover\:bg-pink-dark:hover {
        background-color: #eb5286;
    }
    .lg\:hover\:bg-pink:hover {
        background-color: #f66d9b;
    }
    .lg\:hover\:bg-pink-light:hover {
        background-color: #fa7ea8;
    }
    .lg\:hover\:bg-pink-lighter:hover {
        background-color: #ffbbca;
    }
    .lg\:hover\:bg-pink-lightest:hover {
        background-color: #ffebef;
    }
    .lg\:bg-bottom {
        background-position: bottom;
    }
    .lg\:bg-center {
        background-position: center;
    }
    .lg\:bg-left {
        background-position: left;
    }
    .lg\:bg-left-bottom {
        background-position: left bottom;
    }
    .lg\:bg-left-top {
        background-position: left top;
    }
    .lg\:bg-right {
        background-position: right;
    }
    .lg\:bg-right-bottom {
        background-position: right bottom;
    }
    .lg\:bg-right-top {
        background-position: right top;
    }
    .lg\:bg-top {
        background-position: top;
    }
    .lg\:bg-repeat {
        background-repeat: repeat;
    }
    .lg\:bg-no-repeat {
        background-repeat: no-repeat;
    }
    .lg\:bg-repeat-x {
        background-repeat: repeat-x;
    }
    .lg\:bg-repeat-y {
        background-repeat: repeat-y;
    }
    .lg\:bg-auto {
        background-size: auto;
    }
    .lg\:bg-cover {
        background-size: cover;
    }
    .lg\:bg-contain {
        background-size: contain;
    }
    .lg\:border-transparent {
        border-color: transparent;
    }
    .lg\:border-black {
        border-color: #22292f;
    }
    .lg\:border-grey-darkest {
        border-color: #3d4852;
    }
    .lg\:border-grey-darker {
        border-color: #606f7b;
    }
    .lg\:border-grey-dark {
        border-color: #8795a1;
    }
    .lg\:border-grey {
        border-color: #b8c2cc;
    }
    .lg\:border-grey-light {
        border-color: #dae1e7;
    }
    .lg\:border-grey-lighter {
        border-color: #f1f5f8;
    }
    .lg\:border-grey-lightest {
        border-color: #f8fafc;
    }
    .lg\:border-white {
        border-color: #fff;
    }
    .lg\:border-red-darkest {
        border-color: #3b0d0c;
    }
    .lg\:border-red-darker {
        border-color: #621b18;
    }
    .lg\:border-red-dark {
        border-color: #cc1f1a;
    }
    .lg\:border-red {
        border-color: #e3342f;
    }
    .lg\:border-red-light {
        border-color: #ef5753;
    }
    .lg\:border-red-lighter {
        border-color: #f9acaa;
    }
    .lg\:border-red-lightest {
        border-color: #fcebea;
    }
    .lg\:border-orange-darkest {
        border-color: #462a16;
    }
    .lg\:border-orange-darker {
        border-color: #613b1f;
    }
    .lg\:border-orange-dark {
        border-color: #de751f;
    }
    .lg\:border-orange {
        border-color: #f6993f;
    }
    .lg\:border-orange-light {
        border-color: #faad63;
    }
    .lg\:border-orange-lighter {
        border-color: #fcd9b6;
    }
    .lg\:border-orange-lightest {
        border-color: #fff5eb;
    }
    .lg\:border-yellow-darkest {
        border-color: #453411;
    }
    .lg\:border-yellow-darker {
        border-color: #684f1d;
    }
    .lg\:border-yellow-dark {
        border-color: #f2d024;
    }
    .lg\:border-yellow {
        border-color: #ffed4a;
    }
    .lg\:border-yellow-light {
        border-color: #fff382;
    }
    .lg\:border-yellow-lighter {
        border-color: #fff9c2;
    }
    .lg\:border-yellow-lightest {
        border-color: #fcfbeb;
    }
    .lg\:border-green-darkest {
        border-color: #0f2f21;
    }
    .lg\:border-green-darker {
        border-color: #1a4731;
    }
    .lg\:border-green-dark {
        border-color: #1f9d55;
    }
    .lg\:border-green {
        border-color: #38c172;
    }
    .lg\:border-green-light {
        border-color: #51d88a;
    }
    .lg\:border-green-lighter {
        border-color: #a2f5bf;
    }
    .lg\:border-green-lightest {
        border-color: #e3fcec;
    }
    .lg\:border-teal-darkest {
        border-color: #0d3331;
    }
    .lg\:border-teal-darker {
        border-color: #20504f;
    }
    .lg\:border-teal-dark {
        border-color: #38a89d;
    }
    .lg\:border-teal {
        border-color: #4dc0b5;
    }
    .lg\:border-teal-light {
        border-color: #64d5ca;
    }
    .lg\:border-teal-lighter {
        border-color: #a0f0ed;
    }
    .lg\:border-teal-lightest {
        border-color: #e8fffe;
    }
    .lg\:border-blue-darkest {
        border-color: #12283a;
    }
    .lg\:border-blue-darker {
        border-color: #1c3d5a;
    }
    .lg\:border-blue-dark {
        border-color: #2779bd;
    }
    .lg\:border-blue {
        border-color: #3490dc;
    }
    .lg\:border-blue-light {
        border-color: #6cb2eb;
    }
    .lg\:border-blue-lighter {
        border-color: #bcdefa;
    }
    .lg\:border-blue-lightest {
        border-color: #eff8ff;
    }
    .lg\:border-indigo-darkest {
        border-color: #191e38;
    }
    .lg\:border-indigo-darker {
        border-color: #2f365f;
    }
    .lg\:border-indigo-dark {
        border-color: #5661b3;
    }
    .lg\:border-indigo {
        border-color: #6574cd;
    }
    .lg\:border-indigo-light {
        border-color: #7886d7;
    }
    .lg\:border-indigo-lighter {
        border-color: #b2b7ff;
    }
    .lg\:border-indigo-lightest {
        border-color: #e6e8ff;
    }
    .lg\:border-purple-darkest {
        border-color: #21183c;
    }
    .lg\:border-purple-darker {
        border-color: #382b5f;
    }
    .lg\:border-purple-dark {
        border-color: #794acf;
    }
    .lg\:border-purple {
        border-color: #9561e2;
    }
    .lg\:border-purple-light {
        border-color: #a779e9;
    }
    .lg\:border-purple-lighter {
        border-color: #d6bbfc;
    }
    .lg\:border-purple-lightest {
        border-color: #f3ebff;
    }
    .lg\:border-pink-darkest {
        border-color: #451225;
    }
    .lg\:border-pink-darker {
        border-color: #6f213f;
    }
    .lg\:border-pink-dark {
        border-color: #eb5286;
    }
    .lg\:border-pink {
        border-color: #f66d9b;
    }
    .lg\:border-pink-light {
        border-color: #fa7ea8;
    }
    .lg\:border-pink-lighter {
        border-color: #ffbbca;
    }
    .lg\:border-pink-lightest {
        border-color: #ffebef;
    }
    .lg\:hover\:border-transparent:hover {
        border-color: transparent;
    }
    .lg\:hover\:border-black:hover {
        border-color: #22292f;
    }
    .lg\:hover\:border-grey-darkest:hover {
        border-color: #3d4852;
    }
    .lg\:hover\:border-grey-darker:hover {
        border-color: #606f7b;
    }
    .lg\:hover\:border-grey-dark:hover {
        border-color: #8795a1;
    }
    .lg\:hover\:border-grey:hover {
        border-color: #b8c2cc;
    }
    .lg\:hover\:border-grey-light:hover {
        border-color: #dae1e7;
    }
    .lg\:hover\:border-grey-lighter:hover {
        border-color: #f1f5f8;
    }
    .lg\:hover\:border-grey-lightest:hover {
        border-color: #f8fafc;
    }
    .lg\:hover\:border-white:hover {
        border-color: #fff;
    }
    .lg\:hover\:border-red-darkest:hover {
        border-color: #3b0d0c;
    }
    .lg\:hover\:border-red-darker:hover {
        border-color: #621b18;
    }
    .lg\:hover\:border-red-dark:hover {
        border-color: #cc1f1a;
    }
    .lg\:hover\:border-red:hover {
        border-color: #e3342f;
    }
    .lg\:hover\:border-red-light:hover {
        border-color: #ef5753;
    }
    .lg\:hover\:border-red-lighter:hover {
        border-color: #f9acaa;
    }
    .lg\:hover\:border-red-lightest:hover {
        border-color: #fcebea;
    }
    .lg\:hover\:border-orange-darkest:hover {
        border-color: #462a16;
    }
    .lg\:hover\:border-orange-darker:hover {
        border-color: #613b1f;
    }
    .lg\:hover\:border-orange-dark:hover {
        border-color: #de751f;
    }
    .lg\:hover\:border-orange:hover {
        border-color: #f6993f;
    }
    .lg\:hover\:border-orange-light:hover {
        border-color: #faad63;
    }
    .lg\:hover\:border-orange-lighter:hover {
        border-color: #fcd9b6;
    }
    .lg\:hover\:border-orange-lightest:hover {
        border-color: #fff5eb;
    }
    .lg\:hover\:border-yellow-darkest:hover {
        border-color: #453411;
    }
    .lg\:hover\:border-yellow-darker:hover {
        border-color: #684f1d;
    }
    .lg\:hover\:border-yellow-dark:hover {
        border-color: #f2d024;
    }
    .lg\:hover\:border-yellow:hover {
        border-color: #ffed4a;
    }
    .lg\:hover\:border-yellow-light:hover {
        border-color: #fff382;
    }
    .lg\:hover\:border-yellow-lighter:hover {
        border-color: #fff9c2;
    }
    .lg\:hover\:border-yellow-lightest:hover {
        border-color: #fcfbeb;
    }
    .lg\:hover\:border-green-darkest:hover {
        border-color: #0f2f21;
    }
    .lg\:hover\:border-green-darker:hover {
        border-color: #1a4731;
    }
    .lg\:hover\:border-green-dark:hover {
        border-color: #1f9d55;
    }
    .lg\:hover\:border-green:hover {
        border-color: #38c172;
    }
    .lg\:hover\:border-green-light:hover {
        border-color: #51d88a;
    }
    .lg\:hover\:border-green-lighter:hover {
        border-color: #a2f5bf;
    }
    .lg\:hover\:border-green-lightest:hover {
        border-color: #e3fcec;
    }
    .lg\:hover\:border-teal-darkest:hover {
        border-color: #0d3331;
    }
    .lg\:hover\:border-teal-darker:hover {
        border-color: #20504f;
    }
    .lg\:hover\:border-teal-dark:hover {
        border-color: #38a89d;
    }
    .lg\:hover\:border-teal:hover {
        border-color: #4dc0b5;
    }
    .lg\:hover\:border-teal-light:hover {
        border-color: #64d5ca;
    }
    .lg\:hover\:border-teal-lighter:hover {
        border-color: #a0f0ed;
    }
    .lg\:hover\:border-teal-lightest:hover {
        border-color: #e8fffe;
    }
    .lg\:hover\:border-blue-darkest:hover {
        border-color: #12283a;
    }
    .lg\:hover\:border-blue-darker:hover {
        border-color: #1c3d5a;
    }
    .lg\:hover\:border-blue-dark:hover {
        border-color: #2779bd;
    }
    .lg\:hover\:border-blue:hover {
        border-color: #3490dc;
    }
    .lg\:hover\:border-blue-light:hover {
        border-color: #6cb2eb;
    }
    .lg\:hover\:border-blue-lighter:hover {
        border-color: #bcdefa;
    }
    .lg\:hover\:border-blue-lightest:hover {
        border-color: #eff8ff;
    }
    .lg\:hover\:border-indigo-darkest:hover {
        border-color: #191e38;
    }
    .lg\:hover\:border-indigo-darker:hover {
        border-color: #2f365f;
    }
    .lg\:hover\:border-indigo-dark:hover {
        border-color: #5661b3;
    }
    .lg\:hover\:border-indigo:hover {
        border-color: #6574cd;
    }
    .lg\:hover\:border-indigo-light:hover {
        border-color: #7886d7;
    }
    .lg\:hover\:border-indigo-lighter:hover {
        border-color: #b2b7ff;
    }
    .lg\:hover\:border-indigo-lightest:hover {
        border-color: #e6e8ff;
    }
    .lg\:hover\:border-purple-darkest:hover {
        border-color: #21183c;
    }
    .lg\:hover\:border-purple-darker:hover {
        border-color: #382b5f;
    }
    .lg\:hover\:border-purple-dark:hover {
        border-color: #794acf;
    }
    .lg\:hover\:border-purple:hover {
        border-color: #9561e2;
    }
    .lg\:hover\:border-purple-light:hover {
        border-color: #a779e9;
    }
    .lg\:hover\:border-purple-lighter:hover {
        border-color: #d6bbfc;
    }
    .lg\:hover\:border-purple-lightest:hover {
        border-color: #f3ebff;
    }
    .lg\:hover\:border-pink-darkest:hover {
        border-color: #451225;
    }
    .lg\:hover\:border-pink-darker:hover {
        border-color: #6f213f;
    }
    .lg\:hover\:border-pink-dark:hover {
        border-color: #eb5286;
    }
    .lg\:hover\:border-pink:hover {
        border-color: #f66d9b;
    }
    .lg\:hover\:border-pink-light:hover {
        border-color: #fa7ea8;
    }
    .lg\:hover\:border-pink-lighter:hover {
        border-color: #ffbbca;
    }
    .lg\:hover\:border-pink-lightest:hover {
        border-color: #ffebef;
    }
    .lg\:rounded-none {
        border-radius: 0;
    }
    .lg\:rounded-sm {
        border-radius: .2rem;
    }
    .lg\:rounded {
        border-radius: .4rem;
    }
    .lg\:rounded-lg {
        border-radius: .8rem;
    }
    .lg\:rounded-full {
        border-radius: 9999px;
    }
    .lg\:rounded-t-none {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .lg\:rounded-r-none {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .lg\:rounded-b-none {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .lg\:rounded-l-none {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .lg\:rounded-t-sm {
        border-top-left-radius: .2rem;
        border-top-right-radius: .2rem;
    }
    .lg\:rounded-r-sm {
        border-top-right-radius: .2rem;
        border-bottom-right-radius: .2rem;
    }
    .lg\:rounded-b-sm {
        border-bottom-right-radius: .2rem;
        border-bottom-left-radius: .2rem;
    }
    .lg\:rounded-l-sm {
        border-top-left-radius: .2rem;
        border-bottom-left-radius: .2rem;
    }
    .lg\:rounded-t {
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
    }
    .lg\:rounded-r {
        border-top-right-radius: .4rem;
        border-bottom-right-radius: .4rem;
    }
    .lg\:rounded-b {
        border-bottom-right-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }
    .lg\:rounded-l {
        border-top-left-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }
    .lg\:rounded-t-lg {
        border-top-left-radius: .8rem;
        border-top-right-radius: .8rem;
    }
    .lg\:rounded-r-lg {
        border-top-right-radius: .8rem;
        border-bottom-right-radius: .8rem;
    }
    .lg\:rounded-b-lg {
        border-bottom-right-radius: .8rem;
        border-bottom-left-radius: .8rem;
    }
    .lg\:rounded-l-lg {
        border-top-left-radius: .8rem;
        border-bottom-left-radius: .8rem;
    }
    .lg\:rounded-t-full {
        border-top-left-radius: 9999px;
        border-top-right-radius: 9999px;
    }
    .lg\:rounded-r-full {
        border-top-right-radius: 9999px;
        border-bottom-right-radius: 9999px;
    }
    .lg\:rounded-b-full {
        border-bottom-right-radius: 9999px;
        border-bottom-left-radius: 9999px;
    }
    .lg\:rounded-l-full {
        border-top-left-radius: 9999px;
        border-bottom-left-radius: 9999px;
    }
    .lg\:rounded-tl-none {
        border-top-left-radius: 0;
    }
    .lg\:rounded-tr-none {
        border-top-right-radius: 0;
    }
    .lg\:rounded-br-none {
        border-bottom-right-radius: 0;
    }
    .lg\:rounded-bl-none {
        border-bottom-left-radius: 0;
    }
    .lg\:rounded-tl-sm {
        border-top-left-radius: .2rem;
    }
    .lg\:rounded-tr-sm {
        border-top-right-radius: .2rem;
    }
    .lg\:rounded-br-sm {
        border-bottom-right-radius: .2rem;
    }
    .lg\:rounded-bl-sm {
        border-bottom-left-radius: .2rem;
    }
    .lg\:rounded-tl {
        border-top-left-radius: .4rem;
    }
    .lg\:rounded-tr {
        border-top-right-radius: .4rem;
    }
    .lg\:rounded-br {
        border-bottom-right-radius: .4rem;
    }
    .lg\:rounded-bl {
        border-bottom-left-radius: .4rem;
    }
    .lg\:rounded-tl-lg {
        border-top-left-radius: .8rem;
    }
    .lg\:rounded-tr-lg {
        border-top-right-radius: .8rem;
    }
    .lg\:rounded-br-lg {
        border-bottom-right-radius: .8rem;
    }
    .lg\:rounded-bl-lg {
        border-bottom-left-radius: .8rem;
    }
    .lg\:rounded-tl-full {
        border-top-left-radius: 9999px;
    }
    .lg\:rounded-tr-full {
        border-top-right-radius: 9999px;
    }
    .lg\:rounded-br-full {
        border-bottom-right-radius: 9999px;
    }
    .lg\:rounded-bl-full {
        border-bottom-left-radius: 9999px;
    }
    .lg\:border-solid {
        border-style: solid;
    }
    .lg\:border-dashed {
        border-style: dashed;
    }
    .lg\:border-dotted {
        border-style: dotted;
    }
    .lg\:border-none {
        border-style: none;
    }
    .lg\:border-0 {
        border-width: 0;
    }
    .lg\:border-1 {
        border-width: 1px;
    }
    .lg\:border-2 {
        border-width: 2px;
    }
    .lg\:border-3 {
        border-width: 3px;
    }
    .lg\:border-4 {
        border-width: 4px;
    }
    .lg\:border-8 {
        border-width: 8px;
    }
    .lg\:border {
        border-width: 1px;
    }
    .lg\:border-t-0 {
        border-top-width: 0;
    }
    .lg\:border-r-0 {
        border-right-width: 0;
    }
    .lg\:border-b-0 {
        border-bottom-width: 0;
    }
    .lg\:border-l-0 {
        border-left-width: 0;
    }
    .lg\:border-t-1 {
        border-top-width: 1px;
    }
    .lg\:border-r-1 {
        border-right-width: 1px;
    }
    .lg\:border-b-1 {
        border-bottom-width: 1px;
    }
    .lg\:border-l-1 {
        border-left-width: 1px;
    }
    .lg\:border-t-2 {
        border-top-width: 2px;
    }
    .lg\:border-r-2 {
        border-right-width: 2px;
    }
    .lg\:border-b-2 {
        border-bottom-width: 2px;
    }
    .lg\:border-l-2 {
        border-left-width: 2px;
    }
    .lg\:border-t-3 {
        border-top-width: 3px;
    }
    .lg\:border-r-3 {
        border-right-width: 3px;
    }
    .lg\:border-b-3 {
        border-bottom-width: 3px;
    }
    .lg\:border-l-3 {
        border-left-width: 3px;
    }
    .lg\:border-t-4 {
        border-top-width: 4px;
    }
    .lg\:border-r-4 {
        border-right-width: 4px;
    }
    .lg\:border-b-4 {
        border-bottom-width: 4px;
    }
    .lg\:border-l-4 {
        border-left-width: 4px;
    }
    .lg\:border-t-8 {
        border-top-width: 8px;
    }
    .lg\:border-r-8 {
        border-right-width: 8px;
    }
    .lg\:border-b-8 {
        border-bottom-width: 8px;
    }
    .lg\:border-l-8 {
        border-left-width: 8px;
    }
    .lg\:border-t {
        border-top-width: 1px;
    }
    .lg\:border-r {
        border-right-width: 1px;
    }
    .lg\:border-b {
        border-bottom-width: 1px;
    }
    .lg\:border-l {
        border-left-width: 1px;
    }
    .lg\:cursor-auto {
        cursor: auto;
    }
    .lg\:cursor-default {
        cursor: default;
    }
    .lg\:cursor-pointer {
        cursor: pointer;
    }
    .lg\:cursor-wait {
        cursor: wait;
    }
    .lg\:cursor-move {
        cursor: move;
    }
    .lg\:cursor-not-allowed {
        cursor: not-allowed;
    }
    .lg\:block {
        display: block;
    }
    .lg\:inline-block {
        display: inline-block;
    }
    .lg\:inline {
        display: inline;
    }
    .lg\:table {
        display: table;
    }
    .lg\:table-row {
        display: table-row;
    }
    .lg\:table-cell {
        display: table-cell;
    }
    .lg\:hidden {
        display: none;
    }
    .lg\:flex {
        display: flex;
    }
    .lg\:inline-flex {
        display: inline-flex;
    }
    .lg\:flex-row {
        flex-direction: row;
    }
    .lg\:flex-row-reverse {
        flex-direction: row-reverse;
    }
    .lg\:flex-col {
        flex-direction: column;
    }
    .lg\:flex-col-reverse {
        flex-direction: column-reverse;
    }
    .lg\:flex-wrap {
        flex-wrap: wrap;
    }
    .lg\:flex-wrap-reverse {
        flex-wrap: wrap-reverse;
    }
    .lg\:flex-no-wrap {
        flex-wrap: nowrap;
    }
    .lg\:items-start {
        align-items: flex-start;
    }
    .lg\:items-end {
        align-items: flex-end;
    }
    .lg\:items-center {
        align-items: center;
    }
    .lg\:items-baseline {
        align-items: baseline;
    }
    .lg\:items-stretch {
        align-items: stretch;
    }
    .lg\:self-auto {
        align-self: auto;
    }
    .lg\:self-start {
        align-self: flex-start;
    }
    .lg\:self-end {
        align-self: flex-end;
    }
    .lg\:self-center {
        align-self: center;
    }
    .lg\:self-stretch {
        align-self: stretch;
    }
    .lg\:justify-start {
        justify-content: flex-start;
    }
    .lg\:justify-end {
        justify-content: flex-end;
    }
    .lg\:justify-center {
        justify-content: center;
    }
    .lg\:justify-between {
        justify-content: space-between;
    }
    .lg\:justify-around {
        justify-content: space-around;
    }
    .lg\:content-center {
        align-content: center;
    }
    .lg\:content-start {
        align-content: flex-start;
    }
    .lg\:content-end {
        align-content: flex-end;
    }
    .lg\:content-between {
        align-content: space-between;
    }
    .lg\:content-around {
        align-content: space-around;
    }
    .lg\:flex-1 {
        flex: 1;
    }
    .lg\:flex-auto {
        flex: auto;
    }
    .lg\:flex-initial {
        flex: initial;
    }
    .lg\:flex-none {
        flex: none;
    }
    .lg\:flex-grow {
        flex-grow: 1;
    }
    .lg\:flex-shrink {
        flex-shrink: 1;
    }
    .lg\:flex-no-grow {
        flex-grow: 0;
    }
    .lg\:flex-no-shrink {
        flex-shrink: 0;
    }
    .lg\:float-right {
        float: right;
    }
    .lg\:float-left {
        float: left;
    }
    .lg\:float-none {
        float: none;
    }
    .lg\:clearfix:after {
        content: "";
        display: table;
        clear: both;
    }
    .lg\:font-sans {
        font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    }
    .lg\:font-serif {
        font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
    }
    .lg\:font-mono {
        font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    }
    .lg\:font-100 {
        font-weight: 100;
    }
    .lg\:font-200 {
        font-weight: 200;
    }
    .lg\:font-300 {
        font-weight: 300;
    }
    .lg\:font-400 {
        font-weight: 400;
    }
    .lg\:font-500 {
        font-weight: 500;
    }
    .lg\:font-600 {
        font-weight: 600;
    }
    .lg\:font-700 {
        font-weight: 700;
    }
    .lg\:font-800 {
        font-weight: 800;
    }
    .lg\:font-900 {
        font-weight: 900;
    }
    .lg\:font-hairline {
        font-weight: 100;
    }
    .lg\:font-thin {
        font-weight: 200;
    }
    .lg\:font-light {
        font-weight: 300;
    }
    .lg\:font-normal {
        font-weight: 400;
    }
    .lg\:font-medium {
        font-weight: 500;
    }
    .lg\:font-semibold {
        font-weight: 600;
    }
    .lg\:font-bold {
        font-weight: 700;
    }
    .lg\:font-extrabold {
        font-weight: 800;
    }
    .lg\:font-black {
        font-weight: 900;
    }
    .lg\:hover\:font-100:hover {
        font-weight: 100;
    }
    .lg\:hover\:font-200:hover {
        font-weight: 200;
    }
    .lg\:hover\:font-300:hover {
        font-weight: 300;
    }
    .lg\:hover\:font-400:hover {
        font-weight: 400;
    }
    .lg\:hover\:font-500:hover {
        font-weight: 500;
    }
    .lg\:hover\:font-600:hover {
        font-weight: 600;
    }
    .lg\:hover\:font-700:hover {
        font-weight: 700;
    }
    .lg\:hover\:font-800:hover {
        font-weight: 800;
    }
    .lg\:hover\:font-900:hover {
        font-weight: 900;
    }
    .lg\:hover\:font-hairline:hover {
        font-weight: 100;
    }
    .lg\:hover\:font-thin:hover {
        font-weight: 200;
    }
    .lg\:hover\:font-light:hover {
        font-weight: 300;
    }
    .lg\:hover\:font-normal:hover {
        font-weight: 400;
    }
    .lg\:hover\:font-medium:hover {
        font-weight: 500;
    }
    .lg\:hover\:font-semibold:hover {
        font-weight: 600;
    }
    .lg\:hover\:font-bold:hover {
        font-weight: 700;
    }
    .lg\:hover\:font-extrabold:hover {
        font-weight: 800;
    }
    .lg\:hover\:font-black:hover {
        font-weight: 900;
    }
    .lg\:h-4 {
        height: .4rem;
    }
    .lg\:h-8 {
        height: .8rem;
    }
    .lg\:h-12 {
        height: 1.2rem;
    }
    .lg\:h-16 {
        height: 1.6rem;
    }
    .lg\:h-20 {
        height: 2rem;
    }
    .lg\:h-24 {
        height: 2.4rem;
    }
    .lg\:h-28 {
        height: 2.8rem;
    }
    .lg\:h-32 {
        height: 3.2rem;
    }
    .lg\:h-36 {
        height: 3.6rem;
    }
    .lg\:h-40 {
        height: 4rem;
    }
    .lg\:h-44 {
        height: 4.4rem;
    }
    .lg\:h-48 {
        height: 4.8rem;
    }
    .lg\:h-52 {
        height: 5.2rem;
    }
    .lg\:h-56 {
        height: 5.6rem;
    }
    .lg\:h-60 {
        height: 6rem;
    }
    .lg\:h-64 {
        height: 6.4rem;
    }
    .lg\:h-68 {
        height: 6.8rem;
    }
    .lg\:h-72 {
        height: 7.2rem;
    }
    .lg\:h-76 {
        height: 7.6rem;
    }
    .lg\:h-80 {
        height: 8rem;
    }
    .lg\:h-84 {
        height: 8.4rem;
    }
    .lg\:h-88 {
        height: 8.8rem;
    }
    .lg\:h-92 {
        height: 9.2rem;
    }
    .lg\:h-96 {
        height: 9.6rem;
    }
    .lg\:h-128 {
        height: 12.8rem;
    }
    .lg\:h-160 {
        height: 16rem;
    }
    .lg\:h-192 {
        height: 19.2rem;
    }
    .lg\:h-224 {
        height: 22.4rem;
    }
    .lg\:h-256 {
        height: 25.6rem;
    }
    .lg\:h-288 {
        height: 28.8rem;
    }
    .lg\:h-320 {
        height: 32rem;
    }
    .lg\:h-512 {
        height: 51.2rem;
    }
    .lg\:h-auto {
        height: auto;
    }
    .lg\:h-px {
        height: 1px;
    }
    .lg\:h-full {
        height: 100%;
    }
    .lg\:h-screen {
        height: 100vh;
    }
    .lg\:leading-none {
        line-height: 1;
    }
    .lg\:leading-tight {
        line-height: 1.25;
    }
    .lg\:leading-normal {
        line-height: 1.5;
    }
    .lg\:leading-loose {
        line-height: 2;
    }
    .lg\:m-0 {
        margin: 0;
    }
    .lg\:m-4 {
        margin: .4rem;
    }
    .lg\:m-8 {
        margin: .8rem;
    }
    .lg\:m-12 {
        margin: 1.2rem;
    }
    .lg\:m-16 {
        margin: 1.6rem;
    }
    .lg\:m-20 {
        margin: 2rem;
    }
    .lg\:m-24 {
        margin: 2.4rem;
    }
    .lg\:m-28 {
        margin: 2.8rem;
    }
    .lg\:m-32 {
        margin: 3.2rem;
    }
    .lg\:m-36 {
        margin: 3.6rem;
    }
    .lg\:m-40 {
        margin: 4rem;
    }
    .lg\:m-44 {
        margin: 4.4rem;
    }
    .lg\:m-48 {
        margin: 4.8rem;
    }
    .lg\:m-52 {
        margin: 5.2rem;
    }
    .lg\:m-56 {
        margin: 5.6rem;
    }
    .lg\:m-60 {
        margin: 6rem;
    }
    .lg\:m-64 {
        margin: 6.4rem;
    }
    .lg\:m-68 {
        margin: 6.8rem;
    }
    .lg\:m-72 {
        margin: 7.2rem;
    }
    .lg\:m-76 {
        margin: 7.6rem;
    }
    .lg\:m-80 {
        margin: 8rem;
    }
    .lg\:m-84 {
        margin: 8.4rem;
    }
    .lg\:m-88 {
        margin: 8.8rem;
    }
    .lg\:m-92 {
        margin: 9.2rem;
    }
    .lg\:m-96 {
        margin: 9.6rem;
    }
    .lg\:m-128 {
        margin: 12.8rem;
    }
    .lg\:m-160 {
        margin: 16rem;
    }
    .lg\:m-192 {
        margin: 19.2rem;
    }
    .lg\:m-224 {
        margin: 22.4rem;
    }
    .lg\:m-256 {
        margin: 25.6rem;
    }
    .lg\:m-auto {
        margin: auto;
    }
    .lg\:m-px {
        margin: 1px;
    }
    .lg\:my-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .lg\:mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .lg\:my-4 {
        margin-top: .4rem;
        margin-bottom: .4rem;
    }
    .lg\:mx-4 {
        margin-left: .4rem;
        margin-right: .4rem;
    }
    .lg\:my-8 {
        margin-top: .8rem;
        margin-bottom: .8rem;
    }
    .lg\:mx-8 {
        margin-left: .8rem;
        margin-right: .8rem;
    }
    .lg\:my-12 {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }
    .lg\:mx-12 {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
    }
    .lg\:my-16 {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }
    .lg\:mx-16 {
        margin-left: 1.6rem;
        margin-right: 1.6rem;
    }
    .lg\:my-20 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .lg\:mx-20 {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .lg\:my-24 {
        margin-top: 2.4rem;
        margin-bottom: 2.4rem;
    }
    .lg\:mx-24 {
        margin-left: 2.4rem;
        margin-right: 2.4rem;
    }
    .lg\:my-28 {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
    }
    .lg\:mx-28 {
        margin-left: 2.8rem;
        margin-right: 2.8rem;
    }
    .lg\:my-32 {
        margin-top: 3.2rem;
        margin-bottom: 3.2rem;
    }
    .lg\:mx-32 {
        margin-left: 3.2rem;
        margin-right: 3.2rem;
    }
    .lg\:my-36 {
        margin-top: 3.6rem;
        margin-bottom: 3.6rem;
    }
    .lg\:mx-36 {
        margin-left: 3.6rem;
        margin-right: 3.6rem;
    }
    .lg\:my-40 {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .lg\:mx-40 {
        margin-left: 4rem;
        margin-right: 4rem;
    }
    .lg\:my-44 {
        margin-top: 4.4rem;
        margin-bottom: 4.4rem;
    }
    .lg\:mx-44 {
        margin-left: 4.4rem;
        margin-right: 4.4rem;
    }
    .lg\:my-48 {
        margin-top: 4.8rem;
        margin-bottom: 4.8rem;
    }
    .lg\:mx-48 {
        margin-left: 4.8rem;
        margin-right: 4.8rem;
    }
    .lg\:my-52 {
        margin-top: 5.2rem;
        margin-bottom: 5.2rem;
    }
    .lg\:mx-52 {
        margin-left: 5.2rem;
        margin-right: 5.2rem;
    }
    .lg\:my-56 {
        margin-top: 5.6rem;
        margin-bottom: 5.6rem;
    }
    .lg\:mx-56 {
        margin-left: 5.6rem;
        margin-right: 5.6rem;
    }
    .lg\:my-60 {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
    .lg\:mx-60 {
        margin-left: 6rem;
        margin-right: 6rem;
    }
    .lg\:my-64 {
        margin-top: 6.4rem;
        margin-bottom: 6.4rem;
    }
    .lg\:mx-64 {
        margin-left: 6.4rem;
        margin-right: 6.4rem;
    }
    .lg\:my-68 {
        margin-top: 6.8rem;
        margin-bottom: 6.8rem;
    }
    .lg\:mx-68 {
        margin-left: 6.8rem;
        margin-right: 6.8rem;
    }
    .lg\:my-72 {
        margin-top: 7.2rem;
        margin-bottom: 7.2rem;
    }
    .lg\:mx-72 {
        margin-left: 7.2rem;
        margin-right: 7.2rem;
    }
    .lg\:my-76 {
        margin-top: 7.6rem;
        margin-bottom: 7.6rem;
    }
    .lg\:mx-76 {
        margin-left: 7.6rem;
        margin-right: 7.6rem;
    }
    .lg\:my-80 {
        margin-top: 8rem;
        margin-bottom: 8rem;
    }
    .lg\:mx-80 {
        margin-left: 8rem;
        margin-right: 8rem;
    }
    .lg\:my-84 {
        margin-top: 8.4rem;
        margin-bottom: 8.4rem;
    }
    .lg\:mx-84 {
        margin-left: 8.4rem;
        margin-right: 8.4rem;
    }
    .lg\:my-88 {
        margin-top: 8.8rem;
        margin-bottom: 8.8rem;
    }
    .lg\:mx-88 {
        margin-left: 8.8rem;
        margin-right: 8.8rem;
    }
    .lg\:my-92 {
        margin-top: 9.2rem;
        margin-bottom: 9.2rem;
    }
    .lg\:mx-92 {
        margin-left: 9.2rem;
        margin-right: 9.2rem;
    }
    .lg\:my-96 {
        margin-top: 9.6rem;
        margin-bottom: 9.6rem;
    }
    .lg\:mx-96 {
        margin-left: 9.6rem;
        margin-right: 9.6rem;
    }
    .lg\:my-128 {
        margin-top: 12.8rem;
        margin-bottom: 12.8rem;
    }
    .lg\:mx-128 {
        margin-left: 12.8rem;
        margin-right: 12.8rem;
    }
    .lg\:my-160 {
        margin-top: 16rem;
        margin-bottom: 16rem;
    }
    .lg\:mx-160 {
        margin-left: 16rem;
        margin-right: 16rem;
    }
    .lg\:my-192 {
        margin-top: 19.2rem;
        margin-bottom: 19.2rem;
    }
    .lg\:mx-192 {
        margin-left: 19.2rem;
        margin-right: 19.2rem;
    }
    .lg\:my-224 {
        margin-top: 22.4rem;
        margin-bottom: 22.4rem;
    }
    .lg\:mx-224 {
        margin-left: 22.4rem;
        margin-right: 22.4rem;
    }
    .lg\:my-256 {
        margin-top: 25.6rem;
        margin-bottom: 25.6rem;
    }
    .lg\:mx-256 {
        margin-left: 25.6rem;
        margin-right: 25.6rem;
    }
    .lg\:my-auto {
        margin-top: auto;
        margin-bottom: auto;
    }
    .lg\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .lg\:my-px {
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .lg\:mx-px {
        margin-left: 1px;
        margin-right: 1px;
    }
    .lg\:mt-0 {
        margin-top: 0;
    }
    .lg\:mr-0 {
        margin-right: 0;
    }
    .lg\:mb-0 {
        margin-bottom: 0;
    }
    .lg\:ml-0 {
        margin-left: 0;
    }
    .lg\:mt-4 {
        margin-top: .4rem;
    }
    .lg\:mr-4 {
        margin-right: .4rem;
    }
    .lg\:mb-4 {
        margin-bottom: .4rem;
    }
    .lg\:ml-4 {
        margin-left: .4rem;
    }
    .lg\:mt-8 {
        margin-top: .8rem;
    }
    .lg\:mr-8 {
        margin-right: .8rem;
    }
    .lg\:mb-8 {
        margin-bottom: .8rem;
    }
    .lg\:ml-8 {
        margin-left: .8rem;
    }
    .lg\:mt-12 {
        margin-top: 1.2rem;
    }
    .lg\:mr-12 {
        margin-right: 1.2rem;
    }
    .lg\:mb-12 {
        margin-bottom: 1.2rem;
    }
    .lg\:ml-12 {
        margin-left: 1.2rem;
    }
    .lg\:mt-16 {
        margin-top: 1.6rem;
    }
    .lg\:mr-16 {
        margin-right: 1.6rem;
    }
    .lg\:mb-16 {
        margin-bottom: 1.6rem;
    }
    .lg\:ml-16 {
        margin-left: 1.6rem;
    }
    .lg\:mt-20 {
        margin-top: 2rem;
    }
    .lg\:mr-20 {
        margin-right: 2rem;
    }
    .lg\:mb-20 {
        margin-bottom: 2rem;
    }
    .lg\:ml-20 {
        margin-left: 2rem;
    }
    .lg\:mt-24 {
        margin-top: 2.4rem;
    }
    .lg\:mr-24 {
        margin-right: 2.4rem;
    }
    .lg\:mb-24 {
        margin-bottom: 2.4rem;
    }
    .lg\:ml-24 {
        margin-left: 2.4rem;
    }
    .lg\:mt-28 {
        margin-top: 2.8rem;
    }
    .lg\:mr-28 {
        margin-right: 2.8rem;
    }
    .lg\:mb-28 {
        margin-bottom: 2.8rem;
    }
    .lg\:ml-28 {
        margin-left: 2.8rem;
    }
    .lg\:mt-32 {
        margin-top: 3.2rem;
    }
    .lg\:mr-32 {
        margin-right: 3.2rem;
    }
    .lg\:mb-32 {
        margin-bottom: 3.2rem;
    }
    .lg\:ml-32 {
        margin-left: 3.2rem;
    }
    .lg\:mt-36 {
        margin-top: 3.6rem;
    }
    .lg\:mr-36 {
        margin-right: 3.6rem;
    }
    .lg\:mb-36 {
        margin-bottom: 3.6rem;
    }
    .lg\:ml-36 {
        margin-left: 3.6rem;
    }
    .lg\:mt-40 {
        margin-top: 4rem;
    }
    .lg\:mr-40 {
        margin-right: 4rem;
    }
    .lg\:mb-40 {
        margin-bottom: 4rem;
    }
    .lg\:ml-40 {
        margin-left: 4rem;
    }
    .lg\:mt-44 {
        margin-top: 4.4rem;
    }
    .lg\:mr-44 {
        margin-right: 4.4rem;
    }
    .lg\:mb-44 {
        margin-bottom: 4.4rem;
    }
    .lg\:ml-44 {
        margin-left: 4.4rem;
    }
    .lg\:mt-48 {
        margin-top: 4.8rem;
    }
    .lg\:mr-48 {
        margin-right: 4.8rem;
    }
    .lg\:mb-48 {
        margin-bottom: 4.8rem;
    }
    .lg\:ml-48 {
        margin-left: 4.8rem;
    }
    .lg\:mt-52 {
        margin-top: 5.2rem;
    }
    .lg\:mr-52 {
        margin-right: 5.2rem;
    }
    .lg\:mb-52 {
        margin-bottom: 5.2rem;
    }
    .lg\:ml-52 {
        margin-left: 5.2rem;
    }
    .lg\:mt-56 {
        margin-top: 5.6rem;
    }
    .lg\:mr-56 {
        margin-right: 5.6rem;
    }
    .lg\:mb-56 {
        margin-bottom: 5.6rem;
    }
    .lg\:ml-56 {
        margin-left: 5.6rem;
    }
    .lg\:mt-60 {
        margin-top: 6rem;
    }
    .lg\:mr-60 {
        margin-right: 6rem;
    }
    .lg\:mb-60 {
        margin-bottom: 6rem;
    }
    .lg\:ml-60 {
        margin-left: 6rem;
    }
    .lg\:mt-64 {
        margin-top: 6.4rem;
    }
    .lg\:mr-64 {
        margin-right: 6.4rem;
    }
    .lg\:mb-64 {
        margin-bottom: 6.4rem;
    }
    .lg\:ml-64 {
        margin-left: 6.4rem;
    }
    .lg\:mt-68 {
        margin-top: 6.8rem;
    }
    .lg\:mr-68 {
        margin-right: 6.8rem;
    }
    .lg\:mb-68 {
        margin-bottom: 6.8rem;
    }
    .lg\:ml-68 {
        margin-left: 6.8rem;
    }
    .lg\:mt-72 {
        margin-top: 7.2rem;
    }
    .lg\:mr-72 {
        margin-right: 7.2rem;
    }
    .lg\:mb-72 {
        margin-bottom: 7.2rem;
    }
    .lg\:ml-72 {
        margin-left: 7.2rem;
    }
    .lg\:mt-76 {
        margin-top: 7.6rem;
    }
    .lg\:mr-76 {
        margin-right: 7.6rem;
    }
    .lg\:mb-76 {
        margin-bottom: 7.6rem;
    }
    .lg\:ml-76 {
        margin-left: 7.6rem;
    }
    .lg\:mt-80 {
        margin-top: 8rem;
    }
    .lg\:mr-80 {
        margin-right: 8rem;
    }
    .lg\:mb-80 {
        margin-bottom: 8rem;
    }
    .lg\:ml-80 {
        margin-left: 8rem;
    }
    .lg\:mt-84 {
        margin-top: 8.4rem;
    }
    .lg\:mr-84 {
        margin-right: 8.4rem;
    }
    .lg\:mb-84 {
        margin-bottom: 8.4rem;
    }
    .lg\:ml-84 {
        margin-left: 8.4rem;
    }
    .lg\:mt-88 {
        margin-top: 8.8rem;
    }
    .lg\:mr-88 {
        margin-right: 8.8rem;
    }
    .lg\:mb-88 {
        margin-bottom: 8.8rem;
    }
    .lg\:ml-88 {
        margin-left: 8.8rem;
    }
    .lg\:mt-92 {
        margin-top: 9.2rem;
    }
    .lg\:mr-92 {
        margin-right: 9.2rem;
    }
    .lg\:mb-92 {
        margin-bottom: 9.2rem;
    }
    .lg\:ml-92 {
        margin-left: 9.2rem;
    }
    .lg\:mt-96 {
        margin-top: 9.6rem;
    }
    .lg\:mr-96 {
        margin-right: 9.6rem;
    }
    .lg\:mb-96 {
        margin-bottom: 9.6rem;
    }
    .lg\:ml-96 {
        margin-left: 9.6rem;
    }
    .lg\:mt-128 {
        margin-top: 12.8rem;
    }
    .lg\:mr-128 {
        margin-right: 12.8rem;
    }
    .lg\:mb-128 {
        margin-bottom: 12.8rem;
    }
    .lg\:ml-128 {
        margin-left: 12.8rem;
    }
    .lg\:mt-160 {
        margin-top: 16rem;
    }
    .lg\:mr-160 {
        margin-right: 16rem;
    }
    .lg\:mb-160 {
        margin-bottom: 16rem;
    }
    .lg\:ml-160 {
        margin-left: 16rem;
    }
    .lg\:mt-192 {
        margin-top: 19.2rem;
    }
    .lg\:mr-192 {
        margin-right: 19.2rem;
    }
    .lg\:mb-192 {
        margin-bottom: 19.2rem;
    }
    .lg\:ml-192 {
        margin-left: 19.2rem;
    }
    .lg\:mt-224 {
        margin-top: 22.4rem;
    }
    .lg\:mr-224 {
        margin-right: 22.4rem;
    }
    .lg\:mb-224 {
        margin-bottom: 22.4rem;
    }
    .lg\:ml-224 {
        margin-left: 22.4rem;
    }
    .lg\:mt-256 {
        margin-top: 25.6rem;
    }
    .lg\:mr-256 {
        margin-right: 25.6rem;
    }
    .lg\:mb-256 {
        margin-bottom: 25.6rem;
    }
    .lg\:ml-256 {
        margin-left: 25.6rem;
    }
    .lg\:mt-auto {
        margin-top: auto;
    }
    .lg\:mr-auto {
        margin-right: auto;
    }
    .lg\:mb-auto {
        margin-bottom: auto;
    }
    .lg\:ml-auto {
        margin-left: auto;
    }
    .lg\:mt-px {
        margin-top: 1px;
    }
    .lg\:mr-px {
        margin-right: 1px;
    }
    .lg\:mb-px {
        margin-bottom: 1px;
    }
    .lg\:ml-px {
        margin-left: 1px;
    }
    .lg\:max-h-4 {
        max-height: .4rem;
    }
    .lg\:max-h-8 {
        max-height: .8rem;
    }
    .lg\:max-h-12 {
        max-height: 1.2rem;
    }
    .lg\:max-h-16 {
        max-height: 1.6rem;
    }
    .lg\:max-h-20 {
        max-height: 2rem;
    }
    .lg\:max-h-24 {
        max-height: 2.4rem;
    }
    .lg\:max-h-28 {
        max-height: 2.8rem;
    }
    .lg\:max-h-32 {
        max-height: 3.2rem;
    }
    .lg\:max-h-36 {
        max-height: 3.6rem;
    }
    .lg\:max-h-40 {
        max-height: 4rem;
    }
    .lg\:max-h-44 {
        max-height: 4.4rem;
    }
    .lg\:max-h-48 {
        max-height: 4.8rem;
    }
    .lg\:max-h-52 {
        max-height: 5.2rem;
    }
    .lg\:max-h-56 {
        max-height: 5.6rem;
    }
    .lg\:max-h-60 {
        max-height: 6rem;
    }
    .lg\:max-h-64 {
        max-height: 6.4rem;
    }
    .lg\:max-h-68 {
        max-height: 6.8rem;
    }
    .lg\:max-h-72 {
        max-height: 7.2rem;
    }
    .lg\:max-h-76 {
        max-height: 7.6rem;
    }
    .lg\:max-h-80 {
        max-height: 8rem;
    }
    .lg\:max-h-84 {
        max-height: 8.4rem;
    }
    .lg\:max-h-88 {
        max-height: 8.8rem;
    }
    .lg\:max-h-92 {
        max-height: 9.2rem;
    }
    .lg\:max-h-96 {
        max-height: 9.6rem;
    }
    .lg\:max-h-128 {
        max-height: 12.8rem;
    }
    .lg\:max-h-160 {
        max-height: 16rem;
    }
    .lg\:max-h-192 {
        max-height: 19.2rem;
    }
    .lg\:max-h-224 {
        max-height: 22.4rem;
    }
    .lg\:max-h-256 {
        max-height: 25.6rem;
    }
    .lg\:max-h-288 {
        max-height: 28.8rem;
    }
    .lg\:max-h-320 {
        max-height: 32rem;
    }
    .lg\:max-h-512 {
        max-height: 51.2rem;
    }
    .lg\:max-h-full {
        max-height: 100%;
    }
    .lg\:max-h-screen {
        max-height: 100vh;
    }
    .lg\:max-w-4 {
        max-width: .4rem;
    }
    .lg\:max-w-8 {
        max-width: .8rem;
    }
    .lg\:max-w-12 {
        max-width: 1.2rem;
    }
    .lg\:max-w-16 {
        max-width: 1.6rem;
    }
    .lg\:max-w-20 {
        max-width: 2rem;
    }
    .lg\:max-w-24 {
        max-width: 2.4rem;
    }
    .lg\:max-w-28 {
        max-width: 2.8rem;
    }
    .lg\:max-w-32 {
        max-width: 3.2rem;
    }
    .lg\:max-w-36 {
        max-width: 3.6rem;
    }
    .lg\:max-w-40 {
        max-width: 4rem;
    }
    .lg\:max-w-44 {
        max-width: 4.4rem;
    }
    .lg\:max-w-48 {
        max-width: 4.8rem;
    }
    .lg\:max-w-52 {
        max-width: 5.2rem;
    }
    .lg\:max-w-56 {
        max-width: 5.6rem;
    }
    .lg\:max-w-60 {
        max-width: 6rem;
    }
    .lg\:max-w-64 {
        max-width: 6.4rem;
    }
    .lg\:max-w-96 {
        max-width: 9.6rem;
    }
    .lg\:max-w-128 {
        max-width: 12.8rem;
    }
    .lg\:max-w-160 {
        max-width: 16rem;
    }
    .lg\:max-w-192 {
        max-width: 19.2rem;
    }
    .lg\:max-w-224 {
        max-width: 22.4rem;
    }
    .lg\:max-w-256 {
        max-width: 25.6rem;
    }
    .lg\:max-w-288 {
        max-width: 28.8rem;
    }
    .lg\:max-w-320 {
        max-width: 32rem;
    }
    .lg\:max-w-512 {
        max-width: 51.2rem;
    }
    .lg\:max-w-xs {
        max-width: 32rem;
    }
    .lg\:max-w-sm {
        max-width: 48rem;
    }
    .lg\:max-w-md {
        max-width: 64rem;
    }
    .lg\:max-w-lg {
        max-width: 80rem;
    }
    .lg\:max-w-xl {
        max-width: 96rem;
    }
    .lg\:max-w-2xl {
        max-width: 112rem;
    }
    .lg\:max-w-3xl {
        max-width: 128rem;
    }
    .lg\:max-w-4xl {
        max-width: 144rem;
    }
    .lg\:max-w-5xl {
        max-width: 160rem;
    }
    .lg\:max-w-full {
        max-width: 100%;
    }
    .lg\:min-h-4 {
        min-height: .4rem;
    }
    .lg\:min-h-8 {
        min-height: .8rem;
    }
    .lg\:min-h-12 {
        min-height: 1.2rem;
    }
    .lg\:min-h-16 {
        min-height: 1.6rem;
    }
    .lg\:min-h-20 {
        min-height: 2rem;
    }
    .lg\:min-h-24 {
        min-height: 2.4rem;
    }
    .lg\:min-h-28 {
        min-height: 2.8rem;
    }
    .lg\:min-h-32 {
        min-height: 3.2rem;
    }
    .lg\:min-h-36 {
        min-height: 3.6rem;
    }
    .lg\:min-h-40 {
        min-height: 4rem;
    }
    .lg\:min-h-44 {
        min-height: 4.4rem;
    }
    .lg\:min-h-48 {
        min-height: 4.8rem;
    }
    .lg\:min-h-52 {
        min-height: 5.2rem;
    }
    .lg\:min-h-56 {
        min-height: 5.6rem;
    }
    .lg\:min-h-60 {
        min-height: 6rem;
    }
    .lg\:min-h-64 {
        min-height: 6.4rem;
    }
    .lg\:min-h-68 {
        min-height: 6.8rem;
    }
    .lg\:min-h-72 {
        min-height: 7.2rem;
    }
    .lg\:min-h-76 {
        min-height: 7.6rem;
    }
    .lg\:min-h-80 {
        min-height: 8rem;
    }
    .lg\:min-h-84 {
        min-height: 8.4rem;
    }
    .lg\:min-h-88 {
        min-height: 8.8rem;
    }
    .lg\:min-h-92 {
        min-height: 9.2rem;
    }
    .lg\:min-h-96 {
        min-height: 9.6rem;
    }
    .lg\:min-h-128 {
        min-height: 12.8rem;
    }
    .lg\:min-h-160 {
        min-height: 16rem;
    }
    .lg\:min-h-192 {
        min-height: 19.2rem;
    }
    .lg\:min-h-224 {
        min-height: 22.4rem;
    }
    .lg\:min-h-256 {
        min-height: 25.6rem;
    }
    .lg\:min-h-288 {
        min-height: 28.8rem;
    }
    .lg\:min-h-320 {
        min-height: 32rem;
    }
    .lg\:min-h-512 {
        min-height: 51.2rem;
    }
    .lg\:min-h-auto {
        min-height: auto;
    }
    .lg\:min-h-px {
        min-height: 1px;
    }
    .lg\:min-h-full {
        min-height: 100%;
    }
    .lg\:min-h-screen {
        min-height: 100vh;
    }
    .lg\:min-w-0 {
        min-width: 0;
    }
    .lg\:min-w-4 {
        min-width: .4rem;
    }
    .lg\:min-w-8 {
        min-width: .8rem;
    }
    .lg\:min-w-12 {
        min-width: 1.2rem;
    }
    .lg\:min-w-16 {
        min-width: 1.6rem;
    }
    .lg\:min-w-20 {
        min-width: 2rem;
    }
    .lg\:min-w-24 {
        min-width: 2.4rem;
    }
    .lg\:min-w-28 {
        min-width: 2.8rem;
    }
    .lg\:min-w-32 {
        min-width: 3.2rem;
    }
    .lg\:min-w-36 {
        min-width: 3.6rem;
    }
    .lg\:min-w-40 {
        min-width: 4rem;
    }
    .lg\:min-w-44 {
        min-width: 4.4rem;
    }
    .lg\:min-w-48 {
        min-width: 4.8rem;
    }
    .lg\:min-w-52 {
        min-width: 5.2rem;
    }
    .lg\:min-w-56 {
        min-width: 5.6rem;
    }
    .lg\:min-w-60 {
        min-width: 6rem;
    }
    .lg\:min-w-64 {
        min-width: 6.4rem;
    }
    .lg\:min-w-68 {
        min-width: 6.8rem;
    }
    .lg\:min-w-72 {
        min-width: 7.2rem;
    }
    .lg\:min-w-76 {
        min-width: 7.6rem;
    }
    .lg\:min-w-80 {
        min-width: 8rem;
    }
    .lg\:min-w-84 {
        min-width: 8.4rem;
    }
    .lg\:min-w-88 {
        min-width: 8.8rem;
    }
    .lg\:min-w-92 {
        min-width: 9.2rem;
    }
    .lg\:min-w-96 {
        min-width: 9.6rem;
    }
    .lg\:min-w-128 {
        min-width: 12.8rem;
    }
    .lg\:min-w-160 {
        min-width: 16rem;
    }
    .lg\:min-w-192 {
        min-width: 19.2rem;
    }
    .lg\:min-w-224 {
        min-width: 22.4rem;
    }
    .lg\:min-w-256 {
        min-width: 25.6rem;
    }
    .lg\:min-w-288 {
        min-width: 28.8rem;
    }
    .lg\:min-w-320 {
        min-width: 32rem;
    }
    .lg\:min-w-512 {
        min-width: 51.2rem;
    }
    .lg\:min-w-auto {
        min-width: auto;
    }
    .lg\:min-w-px {
        min-width: 1px;
    }
    .lg\:min-w-1\/2 {
        min-width: 50%;
    }
    .lg\:min-w-1\/3 {
        min-width: 33.33333%;
    }
    .lg\:min-w-2\/3 {
        min-width: 66.66667%;
    }
    .lg\:min-w-1\/4 {
        min-width: 25%;
    }
    .lg\:min-w-3\/4 {
        min-width: 75%;
    }
    .lg\:min-w-1\/5 {
        min-width: 20%;
    }
    .lg\:min-w-2\/5 {
        min-width: 40%;
    }
    .lg\:min-w-3\/5 {
        min-width: 60%;
    }
    .lg\:min-w-4\/5 {
        min-width: 80%;
    }
    .lg\:min-w-1\/6 {
        min-width: 16.66667%;
    }
    .lg\:min-w-5\/6 {
        min-width: 83.33333%;
    }
    .lg\:min-w-full {
        min-width: 100%;
    }
    .lg\:min-w-screen {
        min-width: 100vw;
    }
    .lg\:-m-0 {
        margin: 0;
    }
    .lg\:-m-4 {
        margin: -0.4rem;
    }
    .lg\:-m-8 {
        margin: -0.8rem;
    }
    .lg\:-m-12 {
        margin: -1.2rem;
    }
    .lg\:-m-16 {
        margin: -1.6rem;
    }
    .lg\:-m-20 {
        margin: -2rem;
    }
    .lg\:-m-24 {
        margin: -2.4rem;
    }
    .lg\:-m-28 {
        margin: -2.8rem;
    }
    .lg\:-m-32 {
        margin: -3.2rem;
    }
    .lg\:-m-36 {
        margin: -3.6rem;
    }
    .lg\:-m-40 {
        margin: -4rem;
    }
    .lg\:-m-44 {
        margin: -4.4rem;
    }
    .lg\:-m-48 {
        margin: -4.8rem;
    }
    .lg\:-m-52 {
        margin: -5.2rem;
    }
    .lg\:-m-56 {
        margin: -5.6rem;
    }
    .lg\:-m-60 {
        margin: -6rem;
    }
    .lg\:-m-64 {
        margin: -6.4rem;
    }
    .lg\:-m-68 {
        margin: -6.8rem;
    }
    .lg\:-m-72 {
        margin: -7.2rem;
    }
    .lg\:-m-76 {
        margin: -7.6rem;
    }
    .lg\:-m-80 {
        margin: -8rem;
    }
    .lg\:-m-84 {
        margin: -8.4rem;
    }
    .lg\:-m-88 {
        margin: -8.8rem;
    }
    .lg\:-m-92 {
        margin: -9.2rem;
    }
    .lg\:-m-96 {
        margin: -9.6rem;
    }
    .lg\:-m-128 {
        margin: -12.8rem;
    }
    .lg\:-m-160 {
        margin: -16rem;
    }
    .lg\:-m-192 {
        margin: -19.2rem;
    }
    .lg\:-m-224 {
        margin: -22.4rem;
    }
    .lg\:-m-256 {
        margin: -25.6rem;
    }
    .lg\:-m-px {
        margin: -1px;
    }
    .lg\:-my-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .lg\:-mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .lg\:-my-4 {
        margin-top: -0.4rem;
        margin-bottom: -0.4rem;
    }
    .lg\:-mx-4 {
        margin-left: -0.4rem;
        margin-right: -0.4rem;
    }
    .lg\:-my-8 {
        margin-top: -0.8rem;
        margin-bottom: -0.8rem;
    }
    .lg\:-mx-8 {
        margin-left: -0.8rem;
        margin-right: -0.8rem;
    }
    .lg\:-my-12 {
        margin-top: -1.2rem;
        margin-bottom: -1.2rem;
    }
    .lg\:-mx-12 {
        margin-left: -1.2rem;
        margin-right: -1.2rem;
    }
    .lg\:-my-16 {
        margin-top: -1.6rem;
        margin-bottom: -1.6rem;
    }
    .lg\:-mx-16 {
        margin-left: -1.6rem;
        margin-right: -1.6rem;
    }
    .lg\:-my-20 {
        margin-top: -2rem;
        margin-bottom: -2rem;
    }
    .lg\:-mx-20 {
        margin-left: -2rem;
        margin-right: -2rem;
    }
    .lg\:-my-24 {
        margin-top: -2.4rem;
        margin-bottom: -2.4rem;
    }
    .lg\:-mx-24 {
        margin-left: -2.4rem;
        margin-right: -2.4rem;
    }
    .lg\:-my-28 {
        margin-top: -2.8rem;
        margin-bottom: -2.8rem;
    }
    .lg\:-mx-28 {
        margin-left: -2.8rem;
        margin-right: -2.8rem;
    }
    .lg\:-my-32 {
        margin-top: -3.2rem;
        margin-bottom: -3.2rem;
    }
    .lg\:-mx-32 {
        margin-left: -3.2rem;
        margin-right: -3.2rem;
    }
    .lg\:-my-36 {
        margin-top: -3.6rem;
        margin-bottom: -3.6rem;
    }
    .lg\:-mx-36 {
        margin-left: -3.6rem;
        margin-right: -3.6rem;
    }
    .lg\:-my-40 {
        margin-top: -4rem;
        margin-bottom: -4rem;
    }
    .lg\:-mx-40 {
        margin-left: -4rem;
        margin-right: -4rem;
    }
    .lg\:-my-44 {
        margin-top: -4.4rem;
        margin-bottom: -4.4rem;
    }
    .lg\:-mx-44 {
        margin-left: -4.4rem;
        margin-right: -4.4rem;
    }
    .lg\:-my-48 {
        margin-top: -4.8rem;
        margin-bottom: -4.8rem;
    }
    .lg\:-mx-48 {
        margin-left: -4.8rem;
        margin-right: -4.8rem;
    }
    .lg\:-my-52 {
        margin-top: -5.2rem;
        margin-bottom: -5.2rem;
    }
    .lg\:-mx-52 {
        margin-left: -5.2rem;
        margin-right: -5.2rem;
    }
    .lg\:-my-56 {
        margin-top: -5.6rem;
        margin-bottom: -5.6rem;
    }
    .lg\:-mx-56 {
        margin-left: -5.6rem;
        margin-right: -5.6rem;
    }
    .lg\:-my-60 {
        margin-top: -6rem;
        margin-bottom: -6rem;
    }
    .lg\:-mx-60 {
        margin-left: -6rem;
        margin-right: -6rem;
    }
    .lg\:-my-64 {
        margin-top: -6.4rem;
        margin-bottom: -6.4rem;
    }
    .lg\:-mx-64 {
        margin-left: -6.4rem;
        margin-right: -6.4rem;
    }
    .lg\:-my-68 {
        margin-top: -6.8rem;
        margin-bottom: -6.8rem;
    }
    .lg\:-mx-68 {
        margin-left: -6.8rem;
        margin-right: -6.8rem;
    }
    .lg\:-my-72 {
        margin-top: -7.2rem;
        margin-bottom: -7.2rem;
    }
    .lg\:-mx-72 {
        margin-left: -7.2rem;
        margin-right: -7.2rem;
    }
    .lg\:-my-76 {
        margin-top: -7.6rem;
        margin-bottom: -7.6rem;
    }
    .lg\:-mx-76 {
        margin-left: -7.6rem;
        margin-right: -7.6rem;
    }
    .lg\:-my-80 {
        margin-top: -8rem;
        margin-bottom: -8rem;
    }
    .lg\:-mx-80 {
        margin-left: -8rem;
        margin-right: -8rem;
    }
    .lg\:-my-84 {
        margin-top: -8.4rem;
        margin-bottom: -8.4rem;
    }
    .lg\:-mx-84 {
        margin-left: -8.4rem;
        margin-right: -8.4rem;
    }
    .lg\:-my-88 {
        margin-top: -8.8rem;
        margin-bottom: -8.8rem;
    }
    .lg\:-mx-88 {
        margin-left: -8.8rem;
        margin-right: -8.8rem;
    }
    .lg\:-my-92 {
        margin-top: -9.2rem;
        margin-bottom: -9.2rem;
    }
    .lg\:-mx-92 {
        margin-left: -9.2rem;
        margin-right: -9.2rem;
    }
    .lg\:-my-96 {
        margin-top: -9.6rem;
        margin-bottom: -9.6rem;
    }
    .lg\:-mx-96 {
        margin-left: -9.6rem;
        margin-right: -9.6rem;
    }
    .lg\:-my-128 {
        margin-top: -12.8rem;
        margin-bottom: -12.8rem;
    }
    .lg\:-mx-128 {
        margin-left: -12.8rem;
        margin-right: -12.8rem;
    }
    .lg\:-my-160 {
        margin-top: -16rem;
        margin-bottom: -16rem;
    }
    .lg\:-mx-160 {
        margin-left: -16rem;
        margin-right: -16rem;
    }
    .lg\:-my-192 {
        margin-top: -19.2rem;
        margin-bottom: -19.2rem;
    }
    .lg\:-mx-192 {
        margin-left: -19.2rem;
        margin-right: -19.2rem;
    }
    .lg\:-my-224 {
        margin-top: -22.4rem;
        margin-bottom: -22.4rem;
    }
    .lg\:-mx-224 {
        margin-left: -22.4rem;
        margin-right: -22.4rem;
    }
    .lg\:-my-256 {
        margin-top: -25.6rem;
        margin-bottom: -25.6rem;
    }
    .lg\:-mx-256 {
        margin-left: -25.6rem;
        margin-right: -25.6rem;
    }
    .lg\:-my-px {
        margin-top: -1px;
        margin-bottom: -1px;
    }
    .lg\:-mx-px {
        margin-left: -1px;
        margin-right: -1px;
    }
    .lg\:-mt-0 {
        margin-top: 0;
    }
    .lg\:-mr-0 {
        margin-right: 0;
    }
    .lg\:-mb-0 {
        margin-bottom: 0;
    }
    .lg\:-ml-0 {
        margin-left: 0;
    }
    .lg\:-mt-4 {
        margin-top: -0.4rem;
    }
    .lg\:-mr-4 {
        margin-right: -0.4rem;
    }
    .lg\:-mb-4 {
        margin-bottom: -0.4rem;
    }
    .lg\:-ml-4 {
        margin-left: -0.4rem;
    }
    .lg\:-mt-8 {
        margin-top: -0.8rem;
    }
    .lg\:-mr-8 {
        margin-right: -0.8rem;
    }
    .lg\:-mb-8 {
        margin-bottom: -0.8rem;
    }
    .lg\:-ml-8 {
        margin-left: -0.8rem;
    }
    .lg\:-mt-12 {
        margin-top: -1.2rem;
    }
    .lg\:-mr-12 {
        margin-right: -1.2rem;
    }
    .lg\:-mb-12 {
        margin-bottom: -1.2rem;
    }
    .lg\:-ml-12 {
        margin-left: -1.2rem;
    }
    .lg\:-mt-16 {
        margin-top: -1.6rem;
    }
    .lg\:-mr-16 {
        margin-right: -1.6rem;
    }
    .lg\:-mb-16 {
        margin-bottom: -1.6rem;
    }
    .lg\:-ml-16 {
        margin-left: -1.6rem;
    }
    .lg\:-mt-20 {
        margin-top: -2rem;
    }
    .lg\:-mr-20 {
        margin-right: -2rem;
    }
    .lg\:-mb-20 {
        margin-bottom: -2rem;
    }
    .lg\:-ml-20 {
        margin-left: -2rem;
    }
    .lg\:-mt-24 {
        margin-top: -2.4rem;
    }
    .lg\:-mr-24 {
        margin-right: -2.4rem;
    }
    .lg\:-mb-24 {
        margin-bottom: -2.4rem;
    }
    .lg\:-ml-24 {
        margin-left: -2.4rem;
    }
    .lg\:-mt-28 {
        margin-top: -2.8rem;
    }
    .lg\:-mr-28 {
        margin-right: -2.8rem;
    }
    .lg\:-mb-28 {
        margin-bottom: -2.8rem;
    }
    .lg\:-ml-28 {
        margin-left: -2.8rem;
    }
    .lg\:-mt-32 {
        margin-top: -3.2rem;
    }
    .lg\:-mr-32 {
        margin-right: -3.2rem;
    }
    .lg\:-mb-32 {
        margin-bottom: -3.2rem;
    }
    .lg\:-ml-32 {
        margin-left: -3.2rem;
    }
    .lg\:-mt-36 {
        margin-top: -3.6rem;
    }
    .lg\:-mr-36 {
        margin-right: -3.6rem;
    }
    .lg\:-mb-36 {
        margin-bottom: -3.6rem;
    }
    .lg\:-ml-36 {
        margin-left: -3.6rem;
    }
    .lg\:-mt-40 {
        margin-top: -4rem;
    }
    .lg\:-mr-40 {
        margin-right: -4rem;
    }
    .lg\:-mb-40 {
        margin-bottom: -4rem;
    }
    .lg\:-ml-40 {
        margin-left: -4rem;
    }
    .lg\:-mt-44 {
        margin-top: -4.4rem;
    }
    .lg\:-mr-44 {
        margin-right: -4.4rem;
    }
    .lg\:-mb-44 {
        margin-bottom: -4.4rem;
    }
    .lg\:-ml-44 {
        margin-left: -4.4rem;
    }
    .lg\:-mt-48 {
        margin-top: -4.8rem;
    }
    .lg\:-mr-48 {
        margin-right: -4.8rem;
    }
    .lg\:-mb-48 {
        margin-bottom: -4.8rem;
    }
    .lg\:-ml-48 {
        margin-left: -4.8rem;
    }
    .lg\:-mt-52 {
        margin-top: -5.2rem;
    }
    .lg\:-mr-52 {
        margin-right: -5.2rem;
    }
    .lg\:-mb-52 {
        margin-bottom: -5.2rem;
    }
    .lg\:-ml-52 {
        margin-left: -5.2rem;
    }
    .lg\:-mt-56 {
        margin-top: -5.6rem;
    }
    .lg\:-mr-56 {
        margin-right: -5.6rem;
    }
    .lg\:-mb-56 {
        margin-bottom: -5.6rem;
    }
    .lg\:-ml-56 {
        margin-left: -5.6rem;
    }
    .lg\:-mt-60 {
        margin-top: -6rem;
    }
    .lg\:-mr-60 {
        margin-right: -6rem;
    }
    .lg\:-mb-60 {
        margin-bottom: -6rem;
    }
    .lg\:-ml-60 {
        margin-left: -6rem;
    }
    .lg\:-mt-64 {
        margin-top: -6.4rem;
    }
    .lg\:-mr-64 {
        margin-right: -6.4rem;
    }
    .lg\:-mb-64 {
        margin-bottom: -6.4rem;
    }
    .lg\:-ml-64 {
        margin-left: -6.4rem;
    }
    .lg\:-mt-68 {
        margin-top: -6.8rem;
    }
    .lg\:-mr-68 {
        margin-right: -6.8rem;
    }
    .lg\:-mb-68 {
        margin-bottom: -6.8rem;
    }
    .lg\:-ml-68 {
        margin-left: -6.8rem;
    }
    .lg\:-mt-72 {
        margin-top: -7.2rem;
    }
    .lg\:-mr-72 {
        margin-right: -7.2rem;
    }
    .lg\:-mb-72 {
        margin-bottom: -7.2rem;
    }
    .lg\:-ml-72 {
        margin-left: -7.2rem;
    }
    .lg\:-mt-76 {
        margin-top: -7.6rem;
    }
    .lg\:-mr-76 {
        margin-right: -7.6rem;
    }
    .lg\:-mb-76 {
        margin-bottom: -7.6rem;
    }
    .lg\:-ml-76 {
        margin-left: -7.6rem;
    }
    .lg\:-mt-80 {
        margin-top: -8rem;
    }
    .lg\:-mr-80 {
        margin-right: -8rem;
    }
    .lg\:-mb-80 {
        margin-bottom: -8rem;
    }
    .lg\:-ml-80 {
        margin-left: -8rem;
    }
    .lg\:-mt-84 {
        margin-top: -8.4rem;
    }
    .lg\:-mr-84 {
        margin-right: -8.4rem;
    }
    .lg\:-mb-84 {
        margin-bottom: -8.4rem;
    }
    .lg\:-ml-84 {
        margin-left: -8.4rem;
    }
    .lg\:-mt-88 {
        margin-top: -8.8rem;
    }
    .lg\:-mr-88 {
        margin-right: -8.8rem;
    }
    .lg\:-mb-88 {
        margin-bottom: -8.8rem;
    }
    .lg\:-ml-88 {
        margin-left: -8.8rem;
    }
    .lg\:-mt-92 {
        margin-top: -9.2rem;
    }
    .lg\:-mr-92 {
        margin-right: -9.2rem;
    }
    .lg\:-mb-92 {
        margin-bottom: -9.2rem;
    }
    .lg\:-ml-92 {
        margin-left: -9.2rem;
    }
    .lg\:-mt-96 {
        margin-top: -9.6rem;
    }
    .lg\:-mr-96 {
        margin-right: -9.6rem;
    }
    .lg\:-mb-96 {
        margin-bottom: -9.6rem;
    }
    .lg\:-ml-96 {
        margin-left: -9.6rem;
    }
    .lg\:-mt-128 {
        margin-top: -12.8rem;
    }
    .lg\:-mr-128 {
        margin-right: -12.8rem;
    }
    .lg\:-mb-128 {
        margin-bottom: -12.8rem;
    }
    .lg\:-ml-128 {
        margin-left: -12.8rem;
    }
    .lg\:-mt-160 {
        margin-top: -16rem;
    }
    .lg\:-mr-160 {
        margin-right: -16rem;
    }
    .lg\:-mb-160 {
        margin-bottom: -16rem;
    }
    .lg\:-ml-160 {
        margin-left: -16rem;
    }
    .lg\:-mt-192 {
        margin-top: -19.2rem;
    }
    .lg\:-mr-192 {
        margin-right: -19.2rem;
    }
    .lg\:-mb-192 {
        margin-bottom: -19.2rem;
    }
    .lg\:-ml-192 {
        margin-left: -19.2rem;
    }
    .lg\:-mt-224 {
        margin-top: -22.4rem;
    }
    .lg\:-mr-224 {
        margin-right: -22.4rem;
    }
    .lg\:-mb-224 {
        margin-bottom: -22.4rem;
    }
    .lg\:-ml-224 {
        margin-left: -22.4rem;
    }
    .lg\:-mt-256 {
        margin-top: -25.6rem;
    }
    .lg\:-mr-256 {
        margin-right: -25.6rem;
    }
    .lg\:-mb-256 {
        margin-bottom: -25.6rem;
    }
    .lg\:-ml-256 {
        margin-left: -25.6rem;
    }
    .lg\:-mt-px {
        margin-top: -1px;
    }
    .lg\:-mr-px {
        margin-right: -1px;
    }
    .lg\:-mb-px {
        margin-bottom: -1px;
    }
    .lg\:-ml-px {
        margin-left: -1px;
    }
    .lg\:opacity-0 {
        opacity: 0;
    }
    .lg\:opacity-25 {
        opacity: .25;
    }
    .lg\:opacity-50 {
        opacity: .5;
    }
    .lg\:opacity-75 {
        opacity: .75;
    }
    .lg\:opacity-100 {
        opacity: 1;
    }
    .lg\:overflow-auto {
        overflow: auto;
    }
    .lg\:overflow-hidden {
        overflow: hidden;
    }
    .lg\:overflow-visible {
        overflow: visible;
    }
    .lg\:overflow-scroll {
        overflow: scroll;
    }
    .lg\:overflow-x-auto {
        overflow-x: auto;
    }
    .lg\:overflow-y-auto {
        overflow-y: auto;
    }
    .lg\:overflow-x-hidden {
        overflow-x: hidden;
    }
    .lg\:overflow-y-hidden {
        overflow-y: hidden;
    }
    .lg\:overflow-x-visible {
        overflow-x: visible;
    }
    .lg\:overflow-y-visible {
        overflow-y: visible;
    }
    .lg\:overflow-x-scroll {
        overflow-x: scroll;
    }
    .lg\:overflow-y-scroll {
        overflow-y: scroll;
    }
    .lg\:scrolling-touch {
        -webkit-overflow-scrolling: touch;
    }
    .lg\:scrolling-auto {
        -webkit-overflow-scrolling: auto;
    }
    .lg\:p-0 {
        padding: 0;
    }
    .lg\:p-4 {
        padding: .4rem;
    }
    .lg\:p-8 {
        padding: .8rem;
    }
    .lg\:p-12 {
        padding: 1.2rem;
    }
    .lg\:p-16 {
        padding: 1.6rem;
    }
    .lg\:p-20 {
        padding: 2rem;
    }
    .lg\:p-24 {
        padding: 2.4rem;
    }
    .lg\:p-28 {
        padding: 2.8rem;
    }
    .lg\:p-32 {
        padding: 3.2rem;
    }
    .lg\:p-36 {
        padding: 3.6rem;
    }
    .lg\:p-40 {
        padding: 4rem;
    }
    .lg\:p-44 {
        padding: 4.4rem;
    }
    .lg\:p-48 {
        padding: 4.8rem;
    }
    .lg\:p-52 {
        padding: 5.2rem;
    }
    .lg\:p-56 {
        padding: 5.6rem;
    }
    .lg\:p-60 {
        padding: 6rem;
    }
    .lg\:p-64 {
        padding: 6.4rem;
    }
    .lg\:p-68 {
        padding: 6.8rem;
    }
    .lg\:p-72 {
        padding: 7.2rem;
    }
    .lg\:p-76 {
        padding: 7.6rem;
    }
    .lg\:p-80 {
        padding: 8rem;
    }
    .lg\:p-84 {
        padding: 8.4rem;
    }
    .lg\:p-88 {
        padding: 8.8rem;
    }
    .lg\:p-92 {
        padding: 9.2rem;
    }
    .lg\:p-96 {
        padding: 9.6rem;
    }
    .lg\:p-128 {
        padding: 12.8rem;
    }
    .lg\:p-160 {
        padding: 16rem;
    }
    .lg\:p-192 {
        padding: 19.2rem;
    }
    .lg\:p-224 {
        padding: 22.4rem;
    }
    .lg\:p-256 {
        padding: 25.6rem;
    }
    .lg\:p-px {
        padding: 1px;
    }
    .lg\:py-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .lg\:px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .lg\:py-4 {
        padding-top: .4rem;
        padding-bottom: .4rem;
    }
    .lg\:px-4 {
        padding-left: .4rem;
        padding-right: .4rem;
    }
    .lg\:py-8 {
        padding-top: .8rem;
        padding-bottom: .8rem;
    }
    .lg\:px-8 {
        padding-left: .8rem;
        padding-right: .8rem;
    }
    .lg\:py-12 {
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }
    .lg\:px-12 {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
    .lg\:py-16 {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
    .lg\:px-16 {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
    .lg\:py-20 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .lg\:px-20 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .lg\:py-24 {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }
    .lg\:px-24 {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }
    .lg\:py-28 {
        padding-top: 2.8rem;
        padding-bottom: 2.8rem;
    }
    .lg\:px-28 {
        padding-left: 2.8rem;
        padding-right: 2.8rem;
    }
    .lg\:py-32 {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
    }
    .lg\:px-32 {
        padding-left: 3.2rem;
        padding-right: 3.2rem;
    }
    .lg\:py-36 {
        padding-top: 3.6rem;
        padding-bottom: 3.6rem;
    }
    .lg\:px-36 {
        padding-left: 3.6rem;
        padding-right: 3.6rem;
    }
    .lg\:py-40 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .lg\:px-40 {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .lg\:py-44 {
        padding-top: 4.4rem;
        padding-bottom: 4.4rem;
    }
    .lg\:px-44 {
        padding-left: 4.4rem;
        padding-right: 4.4rem;
    }
    .lg\:py-48 {
        padding-top: 4.8rem;
        padding-bottom: 4.8rem;
    }
    .lg\:px-48 {
        padding-left: 4.8rem;
        padding-right: 4.8rem;
    }
    .lg\:py-52 {
        padding-top: 5.2rem;
        padding-bottom: 5.2rem;
    }
    .lg\:px-52 {
        padding-left: 5.2rem;
        padding-right: 5.2rem;
    }
    .lg\:py-56 {
        padding-top: 5.6rem;
        padding-bottom: 5.6rem;
    }
    .lg\:px-56 {
        padding-left: 5.6rem;
        padding-right: 5.6rem;
    }
    .lg\:py-60 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .lg\:px-60 {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .lg\:py-64 {
        padding-top: 6.4rem;
        padding-bottom: 6.4rem;
    }
    .lg\:px-64 {
        padding-left: 6.4rem;
        padding-right: 6.4rem;
    }
    .lg\:py-68 {
        padding-top: 6.8rem;
        padding-bottom: 6.8rem;
    }
    .lg\:px-68 {
        padding-left: 6.8rem;
        padding-right: 6.8rem;
    }
    .lg\:py-72 {
        padding-top: 7.2rem;
        padding-bottom: 7.2rem;
    }
    .lg\:px-72 {
        padding-left: 7.2rem;
        padding-right: 7.2rem;
    }
    .lg\:py-76 {
        padding-top: 7.6rem;
        padding-bottom: 7.6rem;
    }
    .lg\:px-76 {
        padding-left: 7.6rem;
        padding-right: 7.6rem;
    }
    .lg\:py-80 {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    .lg\:px-80 {
        padding-left: 8rem;
        padding-right: 8rem;
    }
    .lg\:py-84 {
        padding-top: 8.4rem;
        padding-bottom: 8.4rem;
    }
    .lg\:px-84 {
        padding-left: 8.4rem;
        padding-right: 8.4rem;
    }
    .lg\:py-88 {
        padding-top: 8.8rem;
        padding-bottom: 8.8rem;
    }
    .lg\:px-88 {
        padding-left: 8.8rem;
        padding-right: 8.8rem;
    }
    .lg\:py-92 {
        padding-top: 9.2rem;
        padding-bottom: 9.2rem;
    }
    .lg\:px-92 {
        padding-left: 9.2rem;
        padding-right: 9.2rem;
    }
    .lg\:py-96 {
        padding-top: 9.6rem;
        padding-bottom: 9.6rem;
    }
    .lg\:px-96 {
        padding-left: 9.6rem;
        padding-right: 9.6rem;
    }
    .lg\:py-128 {
        padding-top: 12.8rem;
        padding-bottom: 12.8rem;
    }
    .lg\:px-128 {
        padding-left: 12.8rem;
        padding-right: 12.8rem;
    }
    .lg\:py-160 {
        padding-top: 16rem;
        padding-bottom: 16rem;
    }
    .lg\:px-160 {
        padding-left: 16rem;
        padding-right: 16rem;
    }
    .lg\:py-192 {
        padding-top: 19.2rem;
        padding-bottom: 19.2rem;
    }
    .lg\:px-192 {
        padding-left: 19.2rem;
        padding-right: 19.2rem;
    }
    .lg\:py-224 {
        padding-top: 22.4rem;
        padding-bottom: 22.4rem;
    }
    .lg\:px-224 {
        padding-left: 22.4rem;
        padding-right: 22.4rem;
    }
    .lg\:py-256 {
        padding-top: 25.6rem;
        padding-bottom: 25.6rem;
    }
    .lg\:px-256 {
        padding-left: 25.6rem;
        padding-right: 25.6rem;
    }
    .lg\:py-px {
        padding-top: 1px;
        padding-bottom: 1px;
    }
    .lg\:px-px {
        padding-left: 1px;
        padding-right: 1px;
    }
    .lg\:pt-0 {
        padding-top: 0;
    }
    .lg\:pr-0 {
        padding-right: 0;
    }
    .lg\:pb-0 {
        padding-bottom: 0;
    }
    .lg\:pl-0 {
        padding-left: 0;
    }
    .lg\:pt-4 {
        padding-top: .4rem;
    }
    .lg\:pr-4 {
        padding-right: .4rem;
    }
    .lg\:pb-4 {
        padding-bottom: .4rem;
    }
    .lg\:pl-4 {
        padding-left: .4rem;
    }
    .lg\:pt-8 {
        padding-top: .8rem;
    }
    .lg\:pr-8 {
        padding-right: .8rem;
    }
    .lg\:pb-8 {
        padding-bottom: .8rem;
    }
    .lg\:pl-8 {
        padding-left: .8rem;
    }
    .lg\:pt-12 {
        padding-top: 1.2rem;
    }
    .lg\:pr-12 {
        padding-right: 1.2rem;
    }
    .lg\:pb-12 {
        padding-bottom: 1.2rem;
    }
    .lg\:pl-12 {
        padding-left: 1.2rem;
    }
    .lg\:pt-16 {
        padding-top: 1.6rem;
    }
    .lg\:pr-16 {
        padding-right: 1.6rem;
    }
    .lg\:pb-16 {
        padding-bottom: 1.6rem;
    }
    .lg\:pl-16 {
        padding-left: 1.6rem;
    }
    .lg\:pt-20 {
        padding-top: 2rem;
    }
    .lg\:pr-20 {
        padding-right: 2rem;
    }
    .lg\:pb-20 {
        padding-bottom: 2rem;
    }
    .lg\:pl-20 {
        padding-left: 2rem;
    }
    .lg\:pt-24 {
        padding-top: 2.4rem;
    }
    .lg\:pr-24 {
        padding-right: 2.4rem;
    }
    .lg\:pb-24 {
        padding-bottom: 2.4rem;
    }
    .lg\:pl-24 {
        padding-left: 2.4rem;
    }
    .lg\:pt-28 {
        padding-top: 2.8rem;
    }
    .lg\:pr-28 {
        padding-right: 2.8rem;
    }
    .lg\:pb-28 {
        padding-bottom: 2.8rem;
    }
    .lg\:pl-28 {
        padding-left: 2.8rem;
    }
    .lg\:pt-32 {
        padding-top: 3.2rem;
    }
    .lg\:pr-32 {
        padding-right: 3.2rem;
    }
    .lg\:pb-32 {
        padding-bottom: 3.2rem;
    }
    .lg\:pl-32 {
        padding-left: 3.2rem;
    }
    .lg\:pt-36 {
        padding-top: 3.6rem;
    }
    .lg\:pr-36 {
        padding-right: 3.6rem;
    }
    .lg\:pb-36 {
        padding-bottom: 3.6rem;
    }
    .lg\:pl-36 {
        padding-left: 3.6rem;
    }
    .lg\:pt-40 {
        padding-top: 4rem;
    }
    .lg\:pr-40 {
        padding-right: 4rem;
    }
    .lg\:pb-40 {
        padding-bottom: 4rem;
    }
    .lg\:pl-40 {
        padding-left: 4rem;
    }
    .lg\:pt-44 {
        padding-top: 4.4rem;
    }
    .lg\:pr-44 {
        padding-right: 4.4rem;
    }
    .lg\:pb-44 {
        padding-bottom: 4.4rem;
    }
    .lg\:pl-44 {
        padding-left: 4.4rem;
    }
    .lg\:pt-48 {
        padding-top: 4.8rem;
    }
    .lg\:pr-48 {
        padding-right: 4.8rem;
    }
    .lg\:pb-48 {
        padding-bottom: 4.8rem;
    }
    .lg\:pl-48 {
        padding-left: 4.8rem;
    }
    .lg\:pt-52 {
        padding-top: 5.2rem;
    }
    .lg\:pr-52 {
        padding-right: 5.2rem;
    }
    .lg\:pb-52 {
        padding-bottom: 5.2rem;
    }
    .lg\:pl-52 {
        padding-left: 5.2rem;
    }
    .lg\:pt-56 {
        padding-top: 5.6rem;
    }
    .lg\:pr-56 {
        padding-right: 5.6rem;
    }
    .lg\:pb-56 {
        padding-bottom: 5.6rem;
    }
    .lg\:pl-56 {
        padding-left: 5.6rem;
    }
    .lg\:pt-60 {
        padding-top: 6rem;
    }
    .lg\:pr-60 {
        padding-right: 6rem;
    }
    .lg\:pb-60 {
        padding-bottom: 6rem;
    }
    .lg\:pl-60 {
        padding-left: 6rem;
    }
    .lg\:pt-64 {
        padding-top: 6.4rem;
    }
    .lg\:pr-64 {
        padding-right: 6.4rem;
    }
    .lg\:pb-64 {
        padding-bottom: 6.4rem;
    }
    .lg\:pl-64 {
        padding-left: 6.4rem;
    }
    .lg\:pt-68 {
        padding-top: 6.8rem;
    }
    .lg\:pr-68 {
        padding-right: 6.8rem;
    }
    .lg\:pb-68 {
        padding-bottom: 6.8rem;
    }
    .lg\:pl-68 {
        padding-left: 6.8rem;
    }
    .lg\:pt-72 {
        padding-top: 7.2rem;
    }
    .lg\:pr-72 {
        padding-right: 7.2rem;
    }
    .lg\:pb-72 {
        padding-bottom: 7.2rem;
    }
    .lg\:pl-72 {
        padding-left: 7.2rem;
    }
    .lg\:pt-76 {
        padding-top: 7.6rem;
    }
    .lg\:pr-76 {
        padding-right: 7.6rem;
    }
    .lg\:pb-76 {
        padding-bottom: 7.6rem;
    }
    .lg\:pl-76 {
        padding-left: 7.6rem;
    }
    .lg\:pt-80 {
        padding-top: 8rem;
    }
    .lg\:pr-80 {
        padding-right: 8rem;
    }
    .lg\:pb-80 {
        padding-bottom: 8rem;
    }
    .lg\:pl-80 {
        padding-left: 8rem;
    }
    .lg\:pt-84 {
        padding-top: 8.4rem;
    }
    .lg\:pr-84 {
        padding-right: 8.4rem;
    }
    .lg\:pb-84 {
        padding-bottom: 8.4rem;
    }
    .lg\:pl-84 {
        padding-left: 8.4rem;
    }
    .lg\:pt-88 {
        padding-top: 8.8rem;
    }
    .lg\:pr-88 {
        padding-right: 8.8rem;
    }
    .lg\:pb-88 {
        padding-bottom: 8.8rem;
    }
    .lg\:pl-88 {
        padding-left: 8.8rem;
    }
    .lg\:pt-92 {
        padding-top: 9.2rem;
    }
    .lg\:pr-92 {
        padding-right: 9.2rem;
    }
    .lg\:pb-92 {
        padding-bottom: 9.2rem;
    }
    .lg\:pl-92 {
        padding-left: 9.2rem;
    }
    .lg\:pt-96 {
        padding-top: 9.6rem;
    }
    .lg\:pr-96 {
        padding-right: 9.6rem;
    }
    .lg\:pb-96 {
        padding-bottom: 9.6rem;
    }
    .lg\:pl-96 {
        padding-left: 9.6rem;
    }
    .lg\:pt-128 {
        padding-top: 12.8rem;
    }
    .lg\:pr-128 {
        padding-right: 12.8rem;
    }
    .lg\:pb-128 {
        padding-bottom: 12.8rem;
    }
    .lg\:pl-128 {
        padding-left: 12.8rem;
    }
    .lg\:pt-160 {
        padding-top: 16rem;
    }
    .lg\:pr-160 {
        padding-right: 16rem;
    }
    .lg\:pb-160 {
        padding-bottom: 16rem;
    }
    .lg\:pl-160 {
        padding-left: 16rem;
    }
    .lg\:pt-192 {
        padding-top: 19.2rem;
    }
    .lg\:pr-192 {
        padding-right: 19.2rem;
    }
    .lg\:pb-192 {
        padding-bottom: 19.2rem;
    }
    .lg\:pl-192 {
        padding-left: 19.2rem;
    }
    .lg\:pt-224 {
        padding-top: 22.4rem;
    }
    .lg\:pr-224 {
        padding-right: 22.4rem;
    }
    .lg\:pb-224 {
        padding-bottom: 22.4rem;
    }
    .lg\:pl-224 {
        padding-left: 22.4rem;
    }
    .lg\:pt-256 {
        padding-top: 25.6rem;
    }
    .lg\:pr-256 {
        padding-right: 25.6rem;
    }
    .lg\:pb-256 {
        padding-bottom: 25.6rem;
    }
    .lg\:pl-256 {
        padding-left: 25.6rem;
    }
    .lg\:pt-px {
        padding-top: 1px;
    }
    .lg\:pr-px {
        padding-right: 1px;
    }
    .lg\:pb-px {
        padding-bottom: 1px;
    }
    .lg\:pl-px {
        padding-left: 1px;
    }
    .lg\:pointer-events-none {
        pointer-events: none;
    }
    .lg\:pointer-events-auto {
        pointer-events: auto;
    }
    .lg\:static {
        position: static;
    }
    .lg\:fixed {
        position: fixed;
    }
    .lg\:absolute {
        position: absolute;
    }
    .lg\:relative {
        position: relative;
    }
    .lg\:sticky {
        position: -webkit-sticky;
        position: sticky;
    }
    .lg\:pin-none {
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }
    .lg\:pin {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .lg\:pin-y {
        top: 0;
        bottom: 0;
    }
    .lg\:pin-x {
        right: 0;
        left: 0;
    }
    .lg\:pin-t {
        top: 0;
    }
    .lg\:pin-r {
        right: 0;
    }
    .lg\:pin-b {
        bottom: 0;
    }
    .lg\:pin-l {
        left: 0;
    }
    .lg\:resize-none {
        resize: none;
    }
    .lg\:resize-y {
        resize: vertical;
    }
    .lg\:resize-x {
        resize: horizontal;
    }
    .lg\:resize {
        resize: both;
    }
    .lg\:shadow {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    }
    .lg\:shadow-md {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
    }
    .lg\:shadow-lg {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    }
    .lg\:shadow-inner {
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
    }
    .lg\:shadow-none {
        box-shadow: none;
    }
    .lg\:text-left {
        text-align: left;
    }
    .lg\:text-center {
        text-align: center;
    }
    .lg\:text-right {
        text-align: right;
    }
    .lg\:text-justify {
        text-align: justify;
    }
    .lg\:text-transparent {
        color: transparent;
    }
    .lg\:text-black {
        color: #22292f;
    }
    .lg\:text-grey-darkest {
        color: #3d4852;
    }
    .lg\:text-grey-darker {
        color: #606f7b;
    }
    .lg\:text-grey-dark {
        color: #8795a1;
    }
    .lg\:text-grey {
        color: #b8c2cc;
    }
    .lg\:text-grey-light {
        color: #dae1e7;
    }
    .lg\:text-grey-lighter {
        color: #f1f5f8;
    }
    .lg\:text-grey-lightest {
        color: #f8fafc;
    }
    .lg\:text-white {
        color: #fff;
    }
    .lg\:text-red-darkest {
        color: #3b0d0c;
    }
    .lg\:text-red-darker {
        color: #621b18;
    }
    .lg\:text-red-dark {
        color: #cc1f1a;
    }
    .lg\:text-red {
        color: #e3342f;
    }
    .lg\:text-red-light {
        color: #ef5753;
    }
    .lg\:text-red-lighter {
        color: #f9acaa;
    }
    .lg\:text-red-lightest {
        color: #fcebea;
    }
    .lg\:text-orange-darkest {
        color: #462a16;
    }
    .lg\:text-orange-darker {
        color: #613b1f;
    }
    .lg\:text-orange-dark {
        color: #de751f;
    }
    .lg\:text-orange {
        color: #f6993f;
    }
    .lg\:text-orange-light {
        color: #faad63;
    }
    .lg\:text-orange-lighter {
        color: #fcd9b6;
    }
    .lg\:text-orange-lightest {
        color: #fff5eb;
    }
    .lg\:text-yellow-darkest {
        color: #453411;
    }
    .lg\:text-yellow-darker {
        color: #684f1d;
    }
    .lg\:text-yellow-dark {
        color: #f2d024;
    }
    .lg\:text-yellow {
        color: #ffed4a;
    }
    .lg\:text-yellow-light {
        color: #fff382;
    }
    .lg\:text-yellow-lighter {
        color: #fff9c2;
    }
    .lg\:text-yellow-lightest {
        color: #fcfbeb;
    }
    .lg\:text-green-darkest {
        color: #0f2f21;
    }
    .lg\:text-green-darker {
        color: #1a4731;
    }
    .lg\:text-green-dark {
        color: #1f9d55;
    }
    .lg\:text-green {
        color: #38c172;
    }
    .lg\:text-green-light {
        color: #51d88a;
    }
    .lg\:text-green-lighter {
        color: #a2f5bf;
    }
    .lg\:text-green-lightest {
        color: #e3fcec;
    }
    .lg\:text-teal-darkest {
        color: #0d3331;
    }
    .lg\:text-teal-darker {
        color: #20504f;
    }
    .lg\:text-teal-dark {
        color: #38a89d;
    }
    .lg\:text-teal {
        color: #4dc0b5;
    }
    .lg\:text-teal-light {
        color: #64d5ca;
    }
    .lg\:text-teal-lighter {
        color: #a0f0ed;
    }
    .lg\:text-teal-lightest {
        color: #e8fffe;
    }
    .lg\:text-blue-darkest {
        color: #12283a;
    }
    .lg\:text-blue-darker {
        color: #1c3d5a;
    }
    .lg\:text-blue-dark {
        color: #2779bd;
    }
    .lg\:text-blue {
        color: #3490dc;
    }
    .lg\:text-blue-light {
        color: #6cb2eb;
    }
    .lg\:text-blue-lighter {
        color: #bcdefa;
    }
    .lg\:text-blue-lightest {
        color: #eff8ff;
    }
    .lg\:text-indigo-darkest {
        color: #191e38;
    }
    .lg\:text-indigo-darker {
        color: #2f365f;
    }
    .lg\:text-indigo-dark {
        color: #5661b3;
    }
    .lg\:text-indigo {
        color: #6574cd;
    }
    .lg\:text-indigo-light {
        color: #7886d7;
    }
    .lg\:text-indigo-lighter {
        color: #b2b7ff;
    }
    .lg\:text-indigo-lightest {
        color: #e6e8ff;
    }
    .lg\:text-purple-darkest {
        color: #21183c;
    }
    .lg\:text-purple-darker {
        color: #382b5f;
    }
    .lg\:text-purple-dark {
        color: #794acf;
    }
    .lg\:text-purple {
        color: #9561e2;
    }
    .lg\:text-purple-light {
        color: #a779e9;
    }
    .lg\:text-purple-lighter {
        color: #d6bbfc;
    }
    .lg\:text-purple-lightest {
        color: #f3ebff;
    }
    .lg\:text-pink-darkest {
        color: #451225;
    }
    .lg\:text-pink-darker {
        color: #6f213f;
    }
    .lg\:text-pink-dark {
        color: #eb5286;
    }
    .lg\:text-pink {
        color: #f66d9b;
    }
    .lg\:text-pink-light {
        color: #fa7ea8;
    }
    .lg\:text-pink-lighter {
        color: #ffbbca;
    }
    .lg\:text-pink-lightest {
        color: #ffebef;
    }
    .lg\:hover\:text-transparent:hover {
        color: transparent;
    }
    .lg\:hover\:text-black:hover {
        color: #22292f;
    }
    .lg\:hover\:text-grey-darkest:hover {
        color: #3d4852;
    }
    .lg\:hover\:text-grey-darker:hover {
        color: #606f7b;
    }
    .lg\:hover\:text-grey-dark:hover {
        color: #8795a1;
    }
    .lg\:hover\:text-grey:hover {
        color: #b8c2cc;
    }
    .lg\:hover\:text-grey-light:hover {
        color: #dae1e7;
    }
    .lg\:hover\:text-grey-lighter:hover {
        color: #f1f5f8;
    }
    .lg\:hover\:text-grey-lightest:hover {
        color: #f8fafc;
    }
    .lg\:hover\:text-white:hover {
        color: #fff;
    }
    .lg\:hover\:text-red-darkest:hover {
        color: #3b0d0c;
    }
    .lg\:hover\:text-red-darker:hover {
        color: #621b18;
    }
    .lg\:hover\:text-red-dark:hover {
        color: #cc1f1a;
    }
    .lg\:hover\:text-red:hover {
        color: #e3342f;
    }
    .lg\:hover\:text-red-light:hover {
        color: #ef5753;
    }
    .lg\:hover\:text-red-lighter:hover {
        color: #f9acaa;
    }
    .lg\:hover\:text-red-lightest:hover {
        color: #fcebea;
    }
    .lg\:hover\:text-orange-darkest:hover {
        color: #462a16;
    }
    .lg\:hover\:text-orange-darker:hover {
        color: #613b1f;
    }
    .lg\:hover\:text-orange-dark:hover {
        color: #de751f;
    }
    .lg\:hover\:text-orange:hover {
        color: #f6993f;
    }
    .lg\:hover\:text-orange-light:hover {
        color: #faad63;
    }
    .lg\:hover\:text-orange-lighter:hover {
        color: #fcd9b6;
    }
    .lg\:hover\:text-orange-lightest:hover {
        color: #fff5eb;
    }
    .lg\:hover\:text-yellow-darkest:hover {
        color: #453411;
    }
    .lg\:hover\:text-yellow-darker:hover {
        color: #684f1d;
    }
    .lg\:hover\:text-yellow-dark:hover {
        color: #f2d024;
    }
    .lg\:hover\:text-yellow:hover {
        color: #ffed4a;
    }
    .lg\:hover\:text-yellow-light:hover {
        color: #fff382;
    }
    .lg\:hover\:text-yellow-lighter:hover {
        color: #fff9c2;
    }
    .lg\:hover\:text-yellow-lightest:hover {
        color: #fcfbeb;
    }
    .lg\:hover\:text-green-darkest:hover {
        color: #0f2f21;
    }
    .lg\:hover\:text-green-darker:hover {
        color: #1a4731;
    }
    .lg\:hover\:text-green-dark:hover {
        color: #1f9d55;
    }
    .lg\:hover\:text-green:hover {
        color: #38c172;
    }
    .lg\:hover\:text-green-light:hover {
        color: #51d88a;
    }
    .lg\:hover\:text-green-lighter:hover {
        color: #a2f5bf;
    }
    .lg\:hover\:text-green-lightest:hover {
        color: #e3fcec;
    }
    .lg\:hover\:text-teal-darkest:hover {
        color: #0d3331;
    }
    .lg\:hover\:text-teal-darker:hover {
        color: #20504f;
    }
    .lg\:hover\:text-teal-dark:hover {
        color: #38a89d;
    }
    .lg\:hover\:text-teal:hover {
        color: #4dc0b5;
    }
    .lg\:hover\:text-teal-light:hover {
        color: #64d5ca;
    }
    .lg\:hover\:text-teal-lighter:hover {
        color: #a0f0ed;
    }
    .lg\:hover\:text-teal-lightest:hover {
        color: #e8fffe;
    }
    .lg\:hover\:text-blue-darkest:hover {
        color: #12283a;
    }
    .lg\:hover\:text-blue-darker:hover {
        color: #1c3d5a;
    }
    .lg\:hover\:text-blue-dark:hover {
        color: #2779bd;
    }
    .lg\:hover\:text-blue:hover {
        color: #3490dc;
    }
    .lg\:hover\:text-blue-light:hover {
        color: #6cb2eb;
    }
    .lg\:hover\:text-blue-lighter:hover {
        color: #bcdefa;
    }
    .lg\:hover\:text-blue-lightest:hover {
        color: #eff8ff;
    }
    .lg\:hover\:text-indigo-darkest:hover {
        color: #191e38;
    }
    .lg\:hover\:text-indigo-darker:hover {
        color: #2f365f;
    }
    .lg\:hover\:text-indigo-dark:hover {
        color: #5661b3;
    }
    .lg\:hover\:text-indigo:hover {
        color: #6574cd;
    }
    .lg\:hover\:text-indigo-light:hover {
        color: #7886d7;
    }
    .lg\:hover\:text-indigo-lighter:hover {
        color: #b2b7ff;
    }
    .lg\:hover\:text-indigo-lightest:hover {
        color: #e6e8ff;
    }
    .lg\:hover\:text-purple-darkest:hover {
        color: #21183c;
    }
    .lg\:hover\:text-purple-darker:hover {
        color: #382b5f;
    }
    .lg\:hover\:text-purple-dark:hover {
        color: #794acf;
    }
    .lg\:hover\:text-purple:hover {
        color: #9561e2;
    }
    .lg\:hover\:text-purple-light:hover {
        color: #a779e9;
    }
    .lg\:hover\:text-purple-lighter:hover {
        color: #d6bbfc;
    }
    .lg\:hover\:text-purple-lightest:hover {
        color: #f3ebff;
    }
    .lg\:hover\:text-pink-darkest:hover {
        color: #451225;
    }
    .lg\:hover\:text-pink-darker:hover {
        color: #6f213f;
    }
    .lg\:hover\:text-pink-dark:hover {
        color: #eb5286;
    }
    .lg\:hover\:text-pink:hover {
        color: #f66d9b;
    }
    .lg\:hover\:text-pink-light:hover {
        color: #fa7ea8;
    }
    .lg\:hover\:text-pink-lighter:hover {
        color: #ffbbca;
    }
    .lg\:hover\:text-pink-lightest:hover {
        color: #ffebef;
    }
    .lg\:text-10 {
        font-size: 1rem;
    }
    .lg\:text-11 {
        font-size: 1.1rem;
    }
    .lg\:text-12 {
        font-size: 1.2rem;
    }
    .lg\:text-13 {
        font-size: 1.3rem;
    }
    .lg\:text-14 {
        font-size: 1.4rem;
    }
    .lg\:text-15 {
        font-size: 1.5rem;
    }
    .lg\:text-16 {
        font-size: 1.6rem;
    }
    .lg\:text-17 {
        font-size: 1.7rem;
    }
    .lg\:text-18 {
        font-size: 1.8rem;
    }
    .lg\:text-19 {
        font-size: 1.9rem;
    }
    .lg\:text-20 {
        font-size: 2rem;
    }
    .lg\:text-24 {
        font-size: 2.4rem;
    }
    .lg\:text-28 {
        font-size: 2.8rem;
    }
    .lg\:text-32 {
        font-size: 3.2rem;
    }
    .lg\:text-36 {
        font-size: 3.6rem;
    }
    .lg\:text-40 {
        font-size: 4rem;
    }
    .lg\:text-44 {
        font-size: 4.4rem;
    }
    .lg\:text-48 {
        font-size: 4.8rem;
    }
    .lg\:text-52 {
        font-size: 5.2rem;
    }
    .lg\:text-56 {
        font-size: 5.6rem;
    }
    .lg\:text-60 {
        font-size: 6rem;
    }
    .lg\:text-64 {
        font-size: 6.4rem;
    }
    .lg\:text-68 {
        font-size: 6.8rem;
    }
    .lg\:text-72 {
        font-size: 7.2rem;
    }
    .lg\:text-96 {
        font-size: 9.6rem;
    }
    .lg\:text-128 {
        font-size: 12.8rem;
    }
    .lg\:text-xs {
        font-size: 1.2rem;
    }
    .lg\:text-sm {
        font-size: 2.4rem;
    }
    .lg\:text-base {
        font-size: 1.6rem;
    }
    .lg\:text-lg {
        font-size: 1.8rem;
    }
    .lg\:text-xl {
        font-size: 2rem;
    }
    .lg\:text-2xl {
        font-size: 2.4rem;
    }
    .lg\:text-3xl {
        font-size: 3rem;
    }
    .lg\:text-4xl {
        font-size: 3.6rem;
    }
    .lg\:text-5xl {
        font-size: 4.8rem;
    }
    .lg\:italic {
        font-style: italic;
    }
    .lg\:roman {
        font-style: normal;
    }
    .lg\:uppercase {
        text-transform: uppercase;
    }
    .lg\:lowercase {
        text-transform: lowercase;
    }
    .lg\:capitalize {
        text-transform: capitalize;
    }
    .lg\:normal-case {
        text-transform: none;
    }
    .lg\:underline {
        text-decoration: underline;
    }
    .lg\:line-through {
        text-decoration: line-through;
    }
    .lg\:no-underline {
        text-decoration: none;
    }
    .lg\:antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .lg\:subpixel-antialiased {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
    .lg\:hover\:italic:hover {
        font-style: italic;
    }
    .lg\:hover\:roman:hover {
        font-style: normal;
    }
    .lg\:hover\:uppercase:hover {
        text-transform: uppercase;
    }
    .lg\:hover\:lowercase:hover {
        text-transform: lowercase;
    }
    .lg\:hover\:capitalize:hover {
        text-transform: capitalize;
    }
    .lg\:hover\:normal-case:hover {
        text-transform: none;
    }
    .lg\:hover\:underline:hover {
        text-decoration: underline;
    }
    .lg\:hover\:line-through:hover {
        text-decoration: line-through;
    }
    .lg\:hover\:no-underline:hover {
        text-decoration: none;
    }
    .lg\:hover\:antialiased:hover {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .lg\:hover\:subpixel-antialiased:hover {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
    .lg\:tracking-tight {
        letter-spacing: -0.05em;
    }
    .lg\:tracking-normal {
        letter-spacing: 0;
    }
    .lg\:tracking-wide {
        letter-spacing: .05em;
    }
    .lg\:select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .lg\:select-text {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
    .lg\:align-baseline {
        vertical-align: baseline;
    }
    .lg\:align-top {
        vertical-align: top;
    }
    .lg\:align-middle {
        vertical-align: middle;
    }
    .lg\:align-bottom {
        vertical-align: bottom;
    }
    .lg\:align-text-top {
        vertical-align: text-top;
    }
    .lg\:align-text-bottom {
        vertical-align: text-bottom;
    }
    .lg\:visible {
        visibility: visible;
    }
    .lg\:invisible {
        visibility: hidden;
    }
    .lg\:whitespace-normal {
        white-space: normal;
    }
    .lg\:whitespace-no-wrap {
        white-space: nowrap;
    }
    .lg\:whitespace-pre {
        white-space: pre;
    }
    .lg\:whitespace-pre-line {
        white-space: pre-line;
    }
    .lg\:whitespace-pre-wrap {
        white-space: pre-wrap;
    }
    .lg\:break-words {
        word-wrap: break-word;
    }
    .lg\:break-normal {
        word-wrap: normal;
    }
    .lg\:truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .lg\:w-0 {
        width: 0;
    }
    .lg\:w-4 {
        width: .4rem;
    }
    .lg\:w-8 {
        width: .8rem;
    }
    .lg\:w-12 {
        width: 1.2rem;
    }
    .lg\:w-16 {
        width: 1.6rem;
    }
    .lg\:w-20 {
        width: 2rem;
    }
    .lg\:w-24 {
        width: 2.4rem;
    }
    .lg\:w-28 {
        width: 2.8rem;
    }
    .lg\:w-32 {
        width: 3.2rem;
    }
    .lg\:w-36 {
        width: 3.6rem;
    }
    .lg\:w-40 {
        width: 4rem;
    }
    .lg\:w-44 {
        width: 4.4rem;
    }
    .lg\:w-48 {
        width: 4.8rem;
    }
    .lg\:w-52 {
        width: 5.2rem;
    }
    .lg\:w-56 {
        width: 5.6rem;
    }
    .lg\:w-60 {
        width: 6rem;
    }
    .lg\:w-64 {
        width: 6.4rem;
    }
    .lg\:w-68 {
        width: 6.8rem;
    }
    .lg\:w-72 {
        width: 7.2rem;
    }
    .lg\:w-76 {
        width: 7.6rem;
    }
    .lg\:w-80 {
        width: 8rem;
    }
    .lg\:w-84 {
        width: 8.4rem;
    }
    .lg\:w-88 {
        width: 8.8rem;
    }
    .lg\:w-92 {
        width: 9.2rem;
    }
    .lg\:w-96 {
        width: 9.6rem;
    }
    .lg\:w-128 {
        width: 12.8rem;
    }
    .lg\:w-160 {
        width: 16rem;
    }
    .lg\:w-192 {
        width: 19.2rem;
    }
    .lg\:w-224 {
        width: 22.4rem;
    }
    .lg\:w-256 {
        width: 25.6rem;
    }
    .lg\:w-288 {
        width: 28.8rem;
    }
    .lg\:w-320 {
        width: 32rem;
    }
    .lg\:w-512 {
        width: 51.2rem;
    }
    .lg\:w-auto {
        width: auto;
    }
    .lg\:w-px {
        width: 1px;
    }
    .lg\:w-1\/2 {
        width: 50%;
    }
    .lg\:w-1\/3 {
        width: 33.33333%;
    }
    .lg\:w-2\/3 {
        width: 66.66667%;
    }
    .lg\:w-1\/4 {
        width: 25%;
    }
    .lg\:w-3\/4 {
        width: 75%;
    }
    .lg\:w-1\/5 {
        width: 20%;
    }
    .lg\:w-2\/5 {
        width: 40%;
    }
    .lg\:w-3\/5 {
        width: 60%;
    }
    .lg\:w-4\/5 {
        width: 80%;
    }
    .lg\:w-1\/6 {
        width: 16.66667%;
    }
    .lg\:w-5\/6 {
        width: 83.33333%;
    }
    .lg\:w-full {
        width: 100%;
    }
    .lg\:w-screen {
        width: 100vw;
    }
    .lg\:z-0 {
        z-index: 0;
    }
    .lg\:z-10 {
        z-index: 10;
    }
    .lg\:z-20 {
        z-index: 20;
    }
    .lg\:z-30 {
        z-index: 30;
    }
    .lg\:z-40 {
        z-index: 40;
    }
    .lg\:z-50 {
        z-index: 50;
    }
    .lg\:z-auto {
        z-index: auto;
    }
}

@media (min-width: 1920px) {
    .xl\:list-reset {
        list-style: none;
        padding: 0;
    }
    .xl\:appearance-none {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .xl\:bg-fixed {
        background-attachment: fixed;
    }
    .xl\:bg-local {
        background-attachment: local;
    }
    .xl\:bg-scroll {
        background-attachment: scroll;
    }
    .xl\:bg-transparent {
        background-color: transparent;
    }
    .xl\:bg-black {
        background-color: #22292f;
    }
    .xl\:bg-grey-darkest {
        background-color: #3d4852;
    }
    .xl\:bg-grey-darker {
        background-color: #606f7b;
    }
    .xl\:bg-grey-dark {
        background-color: #8795a1;
    }
    .xl\:bg-grey {
        background-color: #b8c2cc;
    }
    .xl\:bg-grey-light {
        background-color: #dae1e7;
    }
    .xl\:bg-grey-lighter {
        background-color: #f1f5f8;
    }
    .xl\:bg-grey-lightest {
        background-color: #f8fafc;
    }
    .xl\:bg-white {
        background-color: #fff;
    }
    .xl\:bg-red-darkest {
        background-color: #3b0d0c;
    }
    .xl\:bg-red-darker {
        background-color: #621b18;
    }
    .xl\:bg-red-dark {
        background-color: #cc1f1a;
    }
    .xl\:bg-red {
        background-color: #e3342f;
    }
    .xl\:bg-red-light {
        background-color: #ef5753;
    }
    .xl\:bg-red-lighter {
        background-color: #f9acaa;
    }
    .xl\:bg-red-lightest {
        background-color: #fcebea;
    }
    .xl\:bg-orange-darkest {
        background-color: #462a16;
    }
    .xl\:bg-orange-darker {
        background-color: #613b1f;
    }
    .xl\:bg-orange-dark {
        background-color: #de751f;
    }
    .xl\:bg-orange {
        background-color: #f6993f;
    }
    .xl\:bg-orange-light {
        background-color: #faad63;
    }
    .xl\:bg-orange-lighter {
        background-color: #fcd9b6;
    }
    .xl\:bg-orange-lightest {
        background-color: #fff5eb;
    }
    .xl\:bg-yellow-darkest {
        background-color: #453411;
    }
    .xl\:bg-yellow-darker {
        background-color: #684f1d;
    }
    .xl\:bg-yellow-dark {
        background-color: #f2d024;
    }
    .xl\:bg-yellow {
        background-color: #ffed4a;
    }
    .xl\:bg-yellow-light {
        background-color: #fff382;
    }
    .xl\:bg-yellow-lighter {
        background-color: #fff9c2;
    }
    .xl\:bg-yellow-lightest {
        background-color: #fcfbeb;
    }
    .xl\:bg-green-darkest {
        background-color: #0f2f21;
    }
    .xl\:bg-green-darker {
        background-color: #1a4731;
    }
    .xl\:bg-green-dark {
        background-color: #1f9d55;
    }
    .xl\:bg-green {
        background-color: #38c172;
    }
    .xl\:bg-green-light {
        background-color: #51d88a;
    }
    .xl\:bg-green-lighter {
        background-color: #a2f5bf;
    }
    .xl\:bg-green-lightest {
        background-color: #e3fcec;
    }
    .xl\:bg-teal-darkest {
        background-color: #0d3331;
    }
    .xl\:bg-teal-darker {
        background-color: #20504f;
    }
    .xl\:bg-teal-dark {
        background-color: #38a89d;
    }
    .xl\:bg-teal {
        background-color: #4dc0b5;
    }
    .xl\:bg-teal-light {
        background-color: #64d5ca;
    }
    .xl\:bg-teal-lighter {
        background-color: #a0f0ed;
    }
    .xl\:bg-teal-lightest {
        background-color: #e8fffe;
    }
    .xl\:bg-blue-darkest {
        background-color: #12283a;
    }
    .xl\:bg-blue-darker {
        background-color: #1c3d5a;
    }
    .xl\:bg-blue-dark {
        background-color: #2779bd;
    }
    .xl\:bg-blue {
        background-color: #3490dc;
    }
    .xl\:bg-blue-light {
        background-color: #6cb2eb;
    }
    .xl\:bg-blue-lighter {
        background-color: #bcdefa;
    }
    .xl\:bg-blue-lightest {
        background-color: #eff8ff;
    }
    .xl\:bg-indigo-darkest {
        background-color: #191e38;
    }
    .xl\:bg-indigo-darker {
        background-color: #2f365f;
    }
    .xl\:bg-indigo-dark {
        background-color: #5661b3;
    }
    .xl\:bg-indigo {
        background-color: #6574cd;
    }
    .xl\:bg-indigo-light {
        background-color: #7886d7;
    }
    .xl\:bg-indigo-lighter {
        background-color: #b2b7ff;
    }
    .xl\:bg-indigo-lightest {
        background-color: #e6e8ff;
    }
    .xl\:bg-purple-darkest {
        background-color: #21183c;
    }
    .xl\:bg-purple-darker {
        background-color: #382b5f;
    }
    .xl\:bg-purple-dark {
        background-color: #794acf;
    }
    .xl\:bg-purple {
        background-color: #9561e2;
    }
    .xl\:bg-purple-light {
        background-color: #a779e9;
    }
    .xl\:bg-purple-lighter {
        background-color: #d6bbfc;
    }
    .xl\:bg-purple-lightest {
        background-color: #f3ebff;
    }
    .xl\:bg-pink-darkest {
        background-color: #451225;
    }
    .xl\:bg-pink-darker {
        background-color: #6f213f;
    }
    .xl\:bg-pink-dark {
        background-color: #eb5286;
    }
    .xl\:bg-pink {
        background-color: #f66d9b;
    }
    .xl\:bg-pink-light {
        background-color: #fa7ea8;
    }
    .xl\:bg-pink-lighter {
        background-color: #ffbbca;
    }
    .xl\:bg-pink-lightest {
        background-color: #ffebef;
    }
    .xl\:hover\:bg-transparent:hover {
        background-color: transparent;
    }
    .xl\:hover\:bg-black:hover {
        background-color: #22292f;
    }
    .xl\:hover\:bg-grey-darkest:hover {
        background-color: #3d4852;
    }
    .xl\:hover\:bg-grey-darker:hover {
        background-color: #606f7b;
    }
    .xl\:hover\:bg-grey-dark:hover {
        background-color: #8795a1;
    }
    .xl\:hover\:bg-grey:hover {
        background-color: #b8c2cc;
    }
    .xl\:hover\:bg-grey-light:hover {
        background-color: #dae1e7;
    }
    .xl\:hover\:bg-grey-lighter:hover {
        background-color: #f1f5f8;
    }
    .xl\:hover\:bg-grey-lightest:hover {
        background-color: #f8fafc;
    }
    .xl\:hover\:bg-white:hover {
        background-color: #fff;
    }
    .xl\:hover\:bg-red-darkest:hover {
        background-color: #3b0d0c;
    }
    .xl\:hover\:bg-red-darker:hover {
        background-color: #621b18;
    }
    .xl\:hover\:bg-red-dark:hover {
        background-color: #cc1f1a;
    }
    .xl\:hover\:bg-red:hover {
        background-color: #e3342f;
    }
    .xl\:hover\:bg-red-light:hover {
        background-color: #ef5753;
    }
    .xl\:hover\:bg-red-lighter:hover {
        background-color: #f9acaa;
    }
    .xl\:hover\:bg-red-lightest:hover {
        background-color: #fcebea;
    }
    .xl\:hover\:bg-orange-darkest:hover {
        background-color: #462a16;
    }
    .xl\:hover\:bg-orange-darker:hover {
        background-color: #613b1f;
    }
    .xl\:hover\:bg-orange-dark:hover {
        background-color: #de751f;
    }
    .xl\:hover\:bg-orange:hover {
        background-color: #f6993f;
    }
    .xl\:hover\:bg-orange-light:hover {
        background-color: #faad63;
    }
    .xl\:hover\:bg-orange-lighter:hover {
        background-color: #fcd9b6;
    }
    .xl\:hover\:bg-orange-lightest:hover {
        background-color: #fff5eb;
    }
    .xl\:hover\:bg-yellow-darkest:hover {
        background-color: #453411;
    }
    .xl\:hover\:bg-yellow-darker:hover {
        background-color: #684f1d;
    }
    .xl\:hover\:bg-yellow-dark:hover {
        background-color: #f2d024;
    }
    .xl\:hover\:bg-yellow:hover {
        background-color: #ffed4a;
    }
    .xl\:hover\:bg-yellow-light:hover {
        background-color: #fff382;
    }
    .xl\:hover\:bg-yellow-lighter:hover {
        background-color: #fff9c2;
    }
    .xl\:hover\:bg-yellow-lightest:hover {
        background-color: #fcfbeb;
    }
    .xl\:hover\:bg-green-darkest:hover {
        background-color: #0f2f21;
    }
    .xl\:hover\:bg-green-darker:hover {
        background-color: #1a4731;
    }
    .xl\:hover\:bg-green-dark:hover {
        background-color: #1f9d55;
    }
    .xl\:hover\:bg-green:hover {
        background-color: #38c172;
    }
    .xl\:hover\:bg-green-light:hover {
        background-color: #51d88a;
    }
    .xl\:hover\:bg-green-lighter:hover {
        background-color: #a2f5bf;
    }
    .xl\:hover\:bg-green-lightest:hover {
        background-color: #e3fcec;
    }
    .xl\:hover\:bg-teal-darkest:hover {
        background-color: #0d3331;
    }
    .xl\:hover\:bg-teal-darker:hover {
        background-color: #20504f;
    }
    .xl\:hover\:bg-teal-dark:hover {
        background-color: #38a89d;
    }
    .xl\:hover\:bg-teal:hover {
        background-color: #4dc0b5;
    }
    .xl\:hover\:bg-teal-light:hover {
        background-color: #64d5ca;
    }
    .xl\:hover\:bg-teal-lighter:hover {
        background-color: #a0f0ed;
    }
    .xl\:hover\:bg-teal-lightest:hover {
        background-color: #e8fffe;
    }
    .xl\:hover\:bg-blue-darkest:hover {
        background-color: #12283a;
    }
    .xl\:hover\:bg-blue-darker:hover {
        background-color: #1c3d5a;
    }
    .xl\:hover\:bg-blue-dark:hover {
        background-color: #2779bd;
    }
    .xl\:hover\:bg-blue:hover {
        background-color: #3490dc;
    }
    .xl\:hover\:bg-blue-light:hover {
        background-color: #6cb2eb;
    }
    .xl\:hover\:bg-blue-lighter:hover {
        background-color: #bcdefa;
    }
    .xl\:hover\:bg-blue-lightest:hover {
        background-color: #eff8ff;
    }
    .xl\:hover\:bg-indigo-darkest:hover {
        background-color: #191e38;
    }
    .xl\:hover\:bg-indigo-darker:hover {
        background-color: #2f365f;
    }
    .xl\:hover\:bg-indigo-dark:hover {
        background-color: #5661b3;
    }
    .xl\:hover\:bg-indigo:hover {
        background-color: #6574cd;
    }
    .xl\:hover\:bg-indigo-light:hover {
        background-color: #7886d7;
    }
    .xl\:hover\:bg-indigo-lighter:hover {
        background-color: #b2b7ff;
    }
    .xl\:hover\:bg-indigo-lightest:hover {
        background-color: #e6e8ff;
    }
    .xl\:hover\:bg-purple-darkest:hover {
        background-color: #21183c;
    }
    .xl\:hover\:bg-purple-darker:hover {
        background-color: #382b5f;
    }
    .xl\:hover\:bg-purple-dark:hover {
        background-color: #794acf;
    }
    .xl\:hover\:bg-purple:hover {
        background-color: #9561e2;
    }
    .xl\:hover\:bg-purple-light:hover {
        background-color: #a779e9;
    }
    .xl\:hover\:bg-purple-lighter:hover {
        background-color: #d6bbfc;
    }
    .xl\:hover\:bg-purple-lightest:hover {
        background-color: #f3ebff;
    }
    .xl\:hover\:bg-pink-darkest:hover {
        background-color: #451225;
    }
    .xl\:hover\:bg-pink-darker:hover {
        background-color: #6f213f;
    }
    .xl\:hover\:bg-pink-dark:hover {
        background-color: #eb5286;
    }
    .xl\:hover\:bg-pink:hover {
        background-color: #f66d9b;
    }
    .xl\:hover\:bg-pink-light:hover {
        background-color: #fa7ea8;
    }
    .xl\:hover\:bg-pink-lighter:hover {
        background-color: #ffbbca;
    }
    .xl\:hover\:bg-pink-lightest:hover {
        background-color: #ffebef;
    }
    .xl\:bg-bottom {
        background-position: bottom;
    }
    .xl\:bg-center {
        background-position: center;
    }
    .xl\:bg-left {
        background-position: left;
    }
    .xl\:bg-left-bottom {
        background-position: left bottom;
    }
    .xl\:bg-left-top {
        background-position: left top;
    }
    .xl\:bg-right {
        background-position: right;
    }
    .xl\:bg-right-bottom {
        background-position: right bottom;
    }
    .xl\:bg-right-top {
        background-position: right top;
    }
    .xl\:bg-top {
        background-position: top;
    }
    .xl\:bg-repeat {
        background-repeat: repeat;
    }
    .xl\:bg-no-repeat {
        background-repeat: no-repeat;
    }
    .xl\:bg-repeat-x {
        background-repeat: repeat-x;
    }
    .xl\:bg-repeat-y {
        background-repeat: repeat-y;
    }
    .xl\:bg-auto {
        background-size: auto;
    }
    .xl\:bg-cover {
        background-size: cover;
    }
    .xl\:bg-contain {
        background-size: contain;
    }
    .xl\:border-transparent {
        border-color: transparent;
    }
    .xl\:border-black {
        border-color: #22292f;
    }
    .xl\:border-grey-darkest {
        border-color: #3d4852;
    }
    .xl\:border-grey-darker {
        border-color: #606f7b;
    }
    .xl\:border-grey-dark {
        border-color: #8795a1;
    }
    .xl\:border-grey {
        border-color: #b8c2cc;
    }
    .xl\:border-grey-light {
        border-color: #dae1e7;
    }
    .xl\:border-grey-lighter {
        border-color: #f1f5f8;
    }
    .xl\:border-grey-lightest {
        border-color: #f8fafc;
    }
    .xl\:border-white {
        border-color: #fff;
    }
    .xl\:border-red-darkest {
        border-color: #3b0d0c;
    }
    .xl\:border-red-darker {
        border-color: #621b18;
    }
    .xl\:border-red-dark {
        border-color: #cc1f1a;
    }
    .xl\:border-red {
        border-color: #e3342f;
    }
    .xl\:border-red-light {
        border-color: #ef5753;
    }
    .xl\:border-red-lighter {
        border-color: #f9acaa;
    }
    .xl\:border-red-lightest {
        border-color: #fcebea;
    }
    .xl\:border-orange-darkest {
        border-color: #462a16;
    }
    .xl\:border-orange-darker {
        border-color: #613b1f;
    }
    .xl\:border-orange-dark {
        border-color: #de751f;
    }
    .xl\:border-orange {
        border-color: #f6993f;
    }
    .xl\:border-orange-light {
        border-color: #faad63;
    }
    .xl\:border-orange-lighter {
        border-color: #fcd9b6;
    }
    .xl\:border-orange-lightest {
        border-color: #fff5eb;
    }
    .xl\:border-yellow-darkest {
        border-color: #453411;
    }
    .xl\:border-yellow-darker {
        border-color: #684f1d;
    }
    .xl\:border-yellow-dark {
        border-color: #f2d024;
    }
    .xl\:border-yellow {
        border-color: #ffed4a;
    }
    .xl\:border-yellow-light {
        border-color: #fff382;
    }
    .xl\:border-yellow-lighter {
        border-color: #fff9c2;
    }
    .xl\:border-yellow-lightest {
        border-color: #fcfbeb;
    }
    .xl\:border-green-darkest {
        border-color: #0f2f21;
    }
    .xl\:border-green-darker {
        border-color: #1a4731;
    }
    .xl\:border-green-dark {
        border-color: #1f9d55;
    }
    .xl\:border-green {
        border-color: #38c172;
    }
    .xl\:border-green-light {
        border-color: #51d88a;
    }
    .xl\:border-green-lighter {
        border-color: #a2f5bf;
    }
    .xl\:border-green-lightest {
        border-color: #e3fcec;
    }
    .xl\:border-teal-darkest {
        border-color: #0d3331;
    }
    .xl\:border-teal-darker {
        border-color: #20504f;
    }
    .xl\:border-teal-dark {
        border-color: #38a89d;
    }
    .xl\:border-teal {
        border-color: #4dc0b5;
    }
    .xl\:border-teal-light {
        border-color: #64d5ca;
    }
    .xl\:border-teal-lighter {
        border-color: #a0f0ed;
    }
    .xl\:border-teal-lightest {
        border-color: #e8fffe;
    }
    .xl\:border-blue-darkest {
        border-color: #12283a;
    }
    .xl\:border-blue-darker {
        border-color: #1c3d5a;
    }
    .xl\:border-blue-dark {
        border-color: #2779bd;
    }
    .xl\:border-blue {
        border-color: #3490dc;
    }
    .xl\:border-blue-light {
        border-color: #6cb2eb;
    }
    .xl\:border-blue-lighter {
        border-color: #bcdefa;
    }
    .xl\:border-blue-lightest {
        border-color: #eff8ff;
    }
    .xl\:border-indigo-darkest {
        border-color: #191e38;
    }
    .xl\:border-indigo-darker {
        border-color: #2f365f;
    }
    .xl\:border-indigo-dark {
        border-color: #5661b3;
    }
    .xl\:border-indigo {
        border-color: #6574cd;
    }
    .xl\:border-indigo-light {
        border-color: #7886d7;
    }
    .xl\:border-indigo-lighter {
        border-color: #b2b7ff;
    }
    .xl\:border-indigo-lightest {
        border-color: #e6e8ff;
    }
    .xl\:border-purple-darkest {
        border-color: #21183c;
    }
    .xl\:border-purple-darker {
        border-color: #382b5f;
    }
    .xl\:border-purple-dark {
        border-color: #794acf;
    }
    .xl\:border-purple {
        border-color: #9561e2;
    }
    .xl\:border-purple-light {
        border-color: #a779e9;
    }
    .xl\:border-purple-lighter {
        border-color: #d6bbfc;
    }
    .xl\:border-purple-lightest {
        border-color: #f3ebff;
    }
    .xl\:border-pink-darkest {
        border-color: #451225;
    }
    .xl\:border-pink-darker {
        border-color: #6f213f;
    }
    .xl\:border-pink-dark {
        border-color: #eb5286;
    }
    .xl\:border-pink {
        border-color: #f66d9b;
    }
    .xl\:border-pink-light {
        border-color: #fa7ea8;
    }
    .xl\:border-pink-lighter {
        border-color: #ffbbca;
    }
    .xl\:border-pink-lightest {
        border-color: #ffebef;
    }
    .xl\:hover\:border-transparent:hover {
        border-color: transparent;
    }
    .xl\:hover\:border-black:hover {
        border-color: #22292f;
    }
    .xl\:hover\:border-grey-darkest:hover {
        border-color: #3d4852;
    }
    .xl\:hover\:border-grey-darker:hover {
        border-color: #606f7b;
    }
    .xl\:hover\:border-grey-dark:hover {
        border-color: #8795a1;
    }
    .xl\:hover\:border-grey:hover {
        border-color: #b8c2cc;
    }
    .xl\:hover\:border-grey-light:hover {
        border-color: #dae1e7;
    }
    .xl\:hover\:border-grey-lighter:hover {
        border-color: #f1f5f8;
    }
    .xl\:hover\:border-grey-lightest:hover {
        border-color: #f8fafc;
    }
    .xl\:hover\:border-white:hover {
        border-color: #fff;
    }
    .xl\:hover\:border-red-darkest:hover {
        border-color: #3b0d0c;
    }
    .xl\:hover\:border-red-darker:hover {
        border-color: #621b18;
    }
    .xl\:hover\:border-red-dark:hover {
        border-color: #cc1f1a;
    }
    .xl\:hover\:border-red:hover {
        border-color: #e3342f;
    }
    .xl\:hover\:border-red-light:hover {
        border-color: #ef5753;
    }
    .xl\:hover\:border-red-lighter:hover {
        border-color: #f9acaa;
    }
    .xl\:hover\:border-red-lightest:hover {
        border-color: #fcebea;
    }
    .xl\:hover\:border-orange-darkest:hover {
        border-color: #462a16;
    }
    .xl\:hover\:border-orange-darker:hover {
        border-color: #613b1f;
    }
    .xl\:hover\:border-orange-dark:hover {
        border-color: #de751f;
    }
    .xl\:hover\:border-orange:hover {
        border-color: #f6993f;
    }
    .xl\:hover\:border-orange-light:hover {
        border-color: #faad63;
    }
    .xl\:hover\:border-orange-lighter:hover {
        border-color: #fcd9b6;
    }
    .xl\:hover\:border-orange-lightest:hover {
        border-color: #fff5eb;
    }
    .xl\:hover\:border-yellow-darkest:hover {
        border-color: #453411;
    }
    .xl\:hover\:border-yellow-darker:hover {
        border-color: #684f1d;
    }
    .xl\:hover\:border-yellow-dark:hover {
        border-color: #f2d024;
    }
    .xl\:hover\:border-yellow:hover {
        border-color: #ffed4a;
    }
    .xl\:hover\:border-yellow-light:hover {
        border-color: #fff382;
    }
    .xl\:hover\:border-yellow-lighter:hover {
        border-color: #fff9c2;
    }
    .xl\:hover\:border-yellow-lightest:hover {
        border-color: #fcfbeb;
    }
    .xl\:hover\:border-green-darkest:hover {
        border-color: #0f2f21;
    }
    .xl\:hover\:border-green-darker:hover {
        border-color: #1a4731;
    }
    .xl\:hover\:border-green-dark:hover {
        border-color: #1f9d55;
    }
    .xl\:hover\:border-green:hover {
        border-color: #38c172;
    }
    .xl\:hover\:border-green-light:hover {
        border-color: #51d88a;
    }
    .xl\:hover\:border-green-lighter:hover {
        border-color: #a2f5bf;
    }
    .xl\:hover\:border-green-lightest:hover {
        border-color: #e3fcec;
    }
    .xl\:hover\:border-teal-darkest:hover {
        border-color: #0d3331;
    }
    .xl\:hover\:border-teal-darker:hover {
        border-color: #20504f;
    }
    .xl\:hover\:border-teal-dark:hover {
        border-color: #38a89d;
    }
    .xl\:hover\:border-teal:hover {
        border-color: #4dc0b5;
    }
    .xl\:hover\:border-teal-light:hover {
        border-color: #64d5ca;
    }
    .xl\:hover\:border-teal-lighter:hover {
        border-color: #a0f0ed;
    }
    .xl\:hover\:border-teal-lightest:hover {
        border-color: #e8fffe;
    }
    .xl\:hover\:border-blue-darkest:hover {
        border-color: #12283a;
    }
    .xl\:hover\:border-blue-darker:hover {
        border-color: #1c3d5a;
    }
    .xl\:hover\:border-blue-dark:hover {
        border-color: #2779bd;
    }
    .xl\:hover\:border-blue:hover {
        border-color: #3490dc;
    }
    .xl\:hover\:border-blue-light:hover {
        border-color: #6cb2eb;
    }
    .xl\:hover\:border-blue-lighter:hover {
        border-color: #bcdefa;
    }
    .xl\:hover\:border-blue-lightest:hover {
        border-color: #eff8ff;
    }
    .xl\:hover\:border-indigo-darkest:hover {
        border-color: #191e38;
    }
    .xl\:hover\:border-indigo-darker:hover {
        border-color: #2f365f;
    }
    .xl\:hover\:border-indigo-dark:hover {
        border-color: #5661b3;
    }
    .xl\:hover\:border-indigo:hover {
        border-color: #6574cd;
    }
    .xl\:hover\:border-indigo-light:hover {
        border-color: #7886d7;
    }
    .xl\:hover\:border-indigo-lighter:hover {
        border-color: #b2b7ff;
    }
    .xl\:hover\:border-indigo-lightest:hover {
        border-color: #e6e8ff;
    }
    .xl\:hover\:border-purple-darkest:hover {
        border-color: #21183c;
    }
    .xl\:hover\:border-purple-darker:hover {
        border-color: #382b5f;
    }
    .xl\:hover\:border-purple-dark:hover {
        border-color: #794acf;
    }
    .xl\:hover\:border-purple:hover {
        border-color: #9561e2;
    }
    .xl\:hover\:border-purple-light:hover {
        border-color: #a779e9;
    }
    .xl\:hover\:border-purple-lighter:hover {
        border-color: #d6bbfc;
    }
    .xl\:hover\:border-purple-lightest:hover {
        border-color: #f3ebff;
    }
    .xl\:hover\:border-pink-darkest:hover {
        border-color: #451225;
    }
    .xl\:hover\:border-pink-darker:hover {
        border-color: #6f213f;
    }
    .xl\:hover\:border-pink-dark:hover {
        border-color: #eb5286;
    }
    .xl\:hover\:border-pink:hover {
        border-color: #f66d9b;
    }
    .xl\:hover\:border-pink-light:hover {
        border-color: #fa7ea8;
    }
    .xl\:hover\:border-pink-lighter:hover {
        border-color: #ffbbca;
    }
    .xl\:hover\:border-pink-lightest:hover {
        border-color: #ffebef;
    }
    .xl\:rounded-none {
        border-radius: 0;
    }
    .xl\:rounded-sm {
        border-radius: .2rem;
    }
    .xl\:rounded {
        border-radius: .4rem;
    }
    .xl\:rounded-lg {
        border-radius: .8rem;
    }
    .xl\:rounded-full {
        border-radius: 9999px;
    }
    .xl\:rounded-t-none {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .xl\:rounded-r-none {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .xl\:rounded-b-none {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .xl\:rounded-l-none {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .xl\:rounded-t-sm {
        border-top-left-radius: .2rem;
        border-top-right-radius: .2rem;
    }
    .xl\:rounded-r-sm {
        border-top-right-radius: .2rem;
        border-bottom-right-radius: .2rem;
    }
    .xl\:rounded-b-sm {
        border-bottom-right-radius: .2rem;
        border-bottom-left-radius: .2rem;
    }
    .xl\:rounded-l-sm {
        border-top-left-radius: .2rem;
        border-bottom-left-radius: .2rem;
    }
    .xl\:rounded-t {
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
    }
    .xl\:rounded-r {
        border-top-right-radius: .4rem;
        border-bottom-right-radius: .4rem;
    }
    .xl\:rounded-b {
        border-bottom-right-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }
    .xl\:rounded-l {
        border-top-left-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }
    .xl\:rounded-t-lg {
        border-top-left-radius: .8rem;
        border-top-right-radius: .8rem;
    }
    .xl\:rounded-r-lg {
        border-top-right-radius: .8rem;
        border-bottom-right-radius: .8rem;
    }
    .xl\:rounded-b-lg {
        border-bottom-right-radius: .8rem;
        border-bottom-left-radius: .8rem;
    }
    .xl\:rounded-l-lg {
        border-top-left-radius: .8rem;
        border-bottom-left-radius: .8rem;
    }
    .xl\:rounded-t-full {
        border-top-left-radius: 9999px;
        border-top-right-radius: 9999px;
    }
    .xl\:rounded-r-full {
        border-top-right-radius: 9999px;
        border-bottom-right-radius: 9999px;
    }
    .xl\:rounded-b-full {
        border-bottom-right-radius: 9999px;
        border-bottom-left-radius: 9999px;
    }
    .xl\:rounded-l-full {
        border-top-left-radius: 9999px;
        border-bottom-left-radius: 9999px;
    }
    .xl\:rounded-tl-none {
        border-top-left-radius: 0;
    }
    .xl\:rounded-tr-none {
        border-top-right-radius: 0;
    }
    .xl\:rounded-br-none {
        border-bottom-right-radius: 0;
    }
    .xl\:rounded-bl-none {
        border-bottom-left-radius: 0;
    }
    .xl\:rounded-tl-sm {
        border-top-left-radius: .2rem;
    }
    .xl\:rounded-tr-sm {
        border-top-right-radius: .2rem;
    }
    .xl\:rounded-br-sm {
        border-bottom-right-radius: .2rem;
    }
    .xl\:rounded-bl-sm {
        border-bottom-left-radius: .2rem;
    }
    .xl\:rounded-tl {
        border-top-left-radius: .4rem;
    }
    .xl\:rounded-tr {
        border-top-right-radius: .4rem;
    }
    .xl\:rounded-br {
        border-bottom-right-radius: .4rem;
    }
    .xl\:rounded-bl {
        border-bottom-left-radius: .4rem;
    }
    .xl\:rounded-tl-lg {
        border-top-left-radius: .8rem;
    }
    .xl\:rounded-tr-lg {
        border-top-right-radius: .8rem;
    }
    .xl\:rounded-br-lg {
        border-bottom-right-radius: .8rem;
    }
    .xl\:rounded-bl-lg {
        border-bottom-left-radius: .8rem;
    }
    .xl\:rounded-tl-full {
        border-top-left-radius: 9999px;
    }
    .xl\:rounded-tr-full {
        border-top-right-radius: 9999px;
    }
    .xl\:rounded-br-full {
        border-bottom-right-radius: 9999px;
    }
    .xl\:rounded-bl-full {
        border-bottom-left-radius: 9999px;
    }
    .xl\:border-solid {
        border-style: solid;
    }
    .xl\:border-dashed {
        border-style: dashed;
    }
    .xl\:border-dotted {
        border-style: dotted;
    }
    .xl\:border-none {
        border-style: none;
    }
    .xl\:border-0 {
        border-width: 0;
    }
    .xl\:border-1 {
        border-width: 1px;
    }
    .xl\:border-2 {
        border-width: 2px;
    }
    .xl\:border-3 {
        border-width: 3px;
    }
    .xl\:border-4 {
        border-width: 4px;
    }
    .xl\:border-8 {
        border-width: 8px;
    }
    .xl\:border {
        border-width: 1px;
    }
    .xl\:border-t-0 {
        border-top-width: 0;
    }
    .xl\:border-r-0 {
        border-right-width: 0;
    }
    .xl\:border-b-0 {
        border-bottom-width: 0;
    }
    .xl\:border-l-0 {
        border-left-width: 0;
    }
    .xl\:border-t-1 {
        border-top-width: 1px;
    }
    .xl\:border-r-1 {
        border-right-width: 1px;
    }
    .xl\:border-b-1 {
        border-bottom-width: 1px;
    }
    .xl\:border-l-1 {
        border-left-width: 1px;
    }
    .xl\:border-t-2 {
        border-top-width: 2px;
    }
    .xl\:border-r-2 {
        border-right-width: 2px;
    }
    .xl\:border-b-2 {
        border-bottom-width: 2px;
    }
    .xl\:border-l-2 {
        border-left-width: 2px;
    }
    .xl\:border-t-3 {
        border-top-width: 3px;
    }
    .xl\:border-r-3 {
        border-right-width: 3px;
    }
    .xl\:border-b-3 {
        border-bottom-width: 3px;
    }
    .xl\:border-l-3 {
        border-left-width: 3px;
    }
    .xl\:border-t-4 {
        border-top-width: 4px;
    }
    .xl\:border-r-4 {
        border-right-width: 4px;
    }
    .xl\:border-b-4 {
        border-bottom-width: 4px;
    }
    .xl\:border-l-4 {
        border-left-width: 4px;
    }
    .xl\:border-t-8 {
        border-top-width: 8px;
    }
    .xl\:border-r-8 {
        border-right-width: 8px;
    }
    .xl\:border-b-8 {
        border-bottom-width: 8px;
    }
    .xl\:border-l-8 {
        border-left-width: 8px;
    }
    .xl\:border-t {
        border-top-width: 1px;
    }
    .xl\:border-r {
        border-right-width: 1px;
    }
    .xl\:border-b {
        border-bottom-width: 1px;
    }
    .xl\:border-l {
        border-left-width: 1px;
    }
    .xl\:cursor-auto {
        cursor: auto;
    }
    .xl\:cursor-default {
        cursor: default;
    }
    .xl\:cursor-pointer {
        cursor: pointer;
    }
    .xl\:cursor-wait {
        cursor: wait;
    }
    .xl\:cursor-move {
        cursor: move;
    }
    .xl\:cursor-not-allowed {
        cursor: not-allowed;
    }
    .xl\:block {
        display: block;
    }
    .xl\:inline-block {
        display: inline-block;
    }
    .xl\:inline {
        display: inline;
    }
    .xl\:table {
        display: table;
    }
    .xl\:table-row {
        display: table-row;
    }
    .xl\:table-cell {
        display: table-cell;
    }
    .xl\:hidden {
        display: none;
    }
    .xl\:flex {
        display: flex;
    }
    .xl\:inline-flex {
        display: inline-flex;
    }
    .xl\:flex-row {
        flex-direction: row;
    }
    .xl\:flex-row-reverse {
        flex-direction: row-reverse;
    }
    .xl\:flex-col {
        flex-direction: column;
    }
    .xl\:flex-col-reverse {
        flex-direction: column-reverse;
    }
    .xl\:flex-wrap {
        flex-wrap: wrap;
    }
    .xl\:flex-wrap-reverse {
        flex-wrap: wrap-reverse;
    }
    .xl\:flex-no-wrap {
        flex-wrap: nowrap;
    }
    .xl\:items-start {
        align-items: flex-start;
    }
    .xl\:items-end {
        align-items: flex-end;
    }
    .xl\:items-center {
        align-items: center;
    }
    .xl\:items-baseline {
        align-items: baseline;
    }
    .xl\:items-stretch {
        align-items: stretch;
    }
    .xl\:self-auto {
        align-self: auto;
    }
    .xl\:self-start {
        align-self: flex-start;
    }
    .xl\:self-end {
        align-self: flex-end;
    }
    .xl\:self-center {
        align-self: center;
    }
    .xl\:self-stretch {
        align-self: stretch;
    }
    .xl\:justify-start {
        justify-content: flex-start;
    }
    .xl\:justify-end {
        justify-content: flex-end;
    }
    .xl\:justify-center {
        justify-content: center;
    }
    .xl\:justify-between {
        justify-content: space-between;
    }
    .xl\:justify-around {
        justify-content: space-around;
    }
    .xl\:content-center {
        align-content: center;
    }
    .xl\:content-start {
        align-content: flex-start;
    }
    .xl\:content-end {
        align-content: flex-end;
    }
    .xl\:content-between {
        align-content: space-between;
    }
    .xl\:content-around {
        align-content: space-around;
    }
    .xl\:flex-1 {
        flex: 1;
    }
    .xl\:flex-auto {
        flex: auto;
    }
    .xl\:flex-initial {
        flex: initial;
    }
    .xl\:flex-none {
        flex: none;
    }
    .xl\:flex-grow {
        flex-grow: 1;
    }
    .xl\:flex-shrink {
        flex-shrink: 1;
    }
    .xl\:flex-no-grow {
        flex-grow: 0;
    }
    .xl\:flex-no-shrink {
        flex-shrink: 0;
    }
    .xl\:float-right {
        float: right;
    }
    .xl\:float-left {
        float: left;
    }
    .xl\:float-none {
        float: none;
    }
    .xl\:clearfix:after {
        content: "";
        display: table;
        clear: both;
    }
    .xl\:font-sans {
        font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    }
    .xl\:font-serif {
        font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
    }
    .xl\:font-mono {
        font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    }
    .xl\:font-100 {
        font-weight: 100;
    }
    .xl\:font-200 {
        font-weight: 200;
    }
    .xl\:font-300 {
        font-weight: 300;
    }
    .xl\:font-400 {
        font-weight: 400;
    }
    .xl\:font-500 {
        font-weight: 500;
    }
    .xl\:font-600 {
        font-weight: 600;
    }
    .xl\:font-700 {
        font-weight: 700;
    }
    .xl\:font-800 {
        font-weight: 800;
    }
    .xl\:font-900 {
        font-weight: 900;
    }
    .xl\:font-hairline {
        font-weight: 100;
    }
    .xl\:font-thin {
        font-weight: 200;
    }
    .xl\:font-light {
        font-weight: 300;
    }
    .xl\:font-normal {
        font-weight: 400;
    }
    .xl\:font-medium {
        font-weight: 500;
    }
    .xl\:font-semibold {
        font-weight: 600;
    }
    .xl\:font-bold {
        font-weight: 700;
    }
    .xl\:font-extrabold {
        font-weight: 800;
    }
    .xl\:font-black {
        font-weight: 900;
    }
    .xl\:hover\:font-100:hover {
        font-weight: 100;
    }
    .xl\:hover\:font-200:hover {
        font-weight: 200;
    }
    .xl\:hover\:font-300:hover {
        font-weight: 300;
    }
    .xl\:hover\:font-400:hover {
        font-weight: 400;
    }
    .xl\:hover\:font-500:hover {
        font-weight: 500;
    }
    .xl\:hover\:font-600:hover {
        font-weight: 600;
    }
    .xl\:hover\:font-700:hover {
        font-weight: 700;
    }
    .xl\:hover\:font-800:hover {
        font-weight: 800;
    }
    .xl\:hover\:font-900:hover {
        font-weight: 900;
    }
    .xl\:hover\:font-hairline:hover {
        font-weight: 100;
    }
    .xl\:hover\:font-thin:hover {
        font-weight: 200;
    }
    .xl\:hover\:font-light:hover {
        font-weight: 300;
    }
    .xl\:hover\:font-normal:hover {
        font-weight: 400;
    }
    .xl\:hover\:font-medium:hover {
        font-weight: 500;
    }
    .xl\:hover\:font-semibold:hover {
        font-weight: 600;
    }
    .xl\:hover\:font-bold:hover {
        font-weight: 700;
    }
    .xl\:hover\:font-extrabold:hover {
        font-weight: 800;
    }
    .xl\:hover\:font-black:hover {
        font-weight: 900;
    }
    .xl\:h-4 {
        height: .4rem;
    }
    .xl\:h-8 {
        height: .8rem;
    }
    .xl\:h-12 {
        height: 1.2rem;
    }
    .xl\:h-16 {
        height: 1.6rem;
    }
    .xl\:h-20 {
        height: 2rem;
    }
    .xl\:h-24 {
        height: 2.4rem;
    }
    .xl\:h-28 {
        height: 2.8rem;
    }
    .xl\:h-32 {
        height: 3.2rem;
    }
    .xl\:h-36 {
        height: 3.6rem;
    }
    .xl\:h-40 {
        height: 4rem;
    }
    .xl\:h-44 {
        height: 4.4rem;
    }
    .xl\:h-48 {
        height: 4.8rem;
    }
    .xl\:h-52 {
        height: 5.2rem;
    }
    .xl\:h-56 {
        height: 5.6rem;
    }
    .xl\:h-60 {
        height: 6rem;
    }
    .xl\:h-64 {
        height: 6.4rem;
    }
    .xl\:h-68 {
        height: 6.8rem;
    }
    .xl\:h-72 {
        height: 7.2rem;
    }
    .xl\:h-76 {
        height: 7.6rem;
    }
    .xl\:h-80 {
        height: 8rem;
    }
    .xl\:h-84 {
        height: 8.4rem;
    }
    .xl\:h-88 {
        height: 8.8rem;
    }
    .xl\:h-92 {
        height: 9.2rem;
    }
    .xl\:h-96 {
        height: 9.6rem;
    }
    .xl\:h-128 {
        height: 12.8rem;
    }
    .xl\:h-160 {
        height: 16rem;
    }
    .xl\:h-192 {
        height: 19.2rem;
    }
    .xl\:h-224 {
        height: 22.4rem;
    }
    .xl\:h-256 {
        height: 25.6rem;
    }
    .xl\:h-288 {
        height: 28.8rem;
    }
    .xl\:h-320 {
        height: 32rem;
    }
    .xl\:h-512 {
        height: 51.2rem;
    }
    .xl\:h-auto {
        height: auto;
    }
    .xl\:h-px {
        height: 1px;
    }
    .xl\:h-full {
        height: 100%;
    }
    .xl\:h-screen {
        height: 100vh;
    }
    .xl\:leading-none {
        line-height: 1;
    }
    .xl\:leading-tight {
        line-height: 1.25;
    }
    .xl\:leading-normal {
        line-height: 1.5;
    }
    .xl\:leading-loose {
        line-height: 2;
    }
    .xl\:m-0 {
        margin: 0;
    }
    .xl\:m-4 {
        margin: .4rem;
    }
    .xl\:m-8 {
        margin: .8rem;
    }
    .xl\:m-12 {
        margin: 1.2rem;
    }
    .xl\:m-16 {
        margin: 1.6rem;
    }
    .xl\:m-20 {
        margin: 2rem;
    }
    .xl\:m-24 {
        margin: 2.4rem;
    }
    .xl\:m-28 {
        margin: 2.8rem;
    }
    .xl\:m-32 {
        margin: 3.2rem;
    }
    .xl\:m-36 {
        margin: 3.6rem;
    }
    .xl\:m-40 {
        margin: 4rem;
    }
    .xl\:m-44 {
        margin: 4.4rem;
    }
    .xl\:m-48 {
        margin: 4.8rem;
    }
    .xl\:m-52 {
        margin: 5.2rem;
    }
    .xl\:m-56 {
        margin: 5.6rem;
    }
    .xl\:m-60 {
        margin: 6rem;
    }
    .xl\:m-64 {
        margin: 6.4rem;
    }
    .xl\:m-68 {
        margin: 6.8rem;
    }
    .xl\:m-72 {
        margin: 7.2rem;
    }
    .xl\:m-76 {
        margin: 7.6rem;
    }
    .xl\:m-80 {
        margin: 8rem;
    }
    .xl\:m-84 {
        margin: 8.4rem;
    }
    .xl\:m-88 {
        margin: 8.8rem;
    }
    .xl\:m-92 {
        margin: 9.2rem;
    }
    .xl\:m-96 {
        margin: 9.6rem;
    }
    .xl\:m-128 {
        margin: 12.8rem;
    }
    .xl\:m-160 {
        margin: 16rem;
    }
    .xl\:m-192 {
        margin: 19.2rem;
    }
    .xl\:m-224 {
        margin: 22.4rem;
    }
    .xl\:m-256 {
        margin: 25.6rem;
    }
    .xl\:m-auto {
        margin: auto;
    }
    .xl\:m-px {
        margin: 1px;
    }
    .xl\:my-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .xl\:mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .xl\:my-4 {
        margin-top: .4rem;
        margin-bottom: .4rem;
    }
    .xl\:mx-4 {
        margin-left: .4rem;
        margin-right: .4rem;
    }
    .xl\:my-8 {
        margin-top: .8rem;
        margin-bottom: .8rem;
    }
    .xl\:mx-8 {
        margin-left: .8rem;
        margin-right: .8rem;
    }
    .xl\:my-12 {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }
    .xl\:mx-12 {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
    }
    .xl\:my-16 {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }
    .xl\:mx-16 {
        margin-left: 1.6rem;
        margin-right: 1.6rem;
    }
    .xl\:my-20 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .xl\:mx-20 {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .xl\:my-24 {
        margin-top: 2.4rem;
        margin-bottom: 2.4rem;
    }
    .xl\:mx-24 {
        margin-left: 2.4rem;
        margin-right: 2.4rem;
    }
    .xl\:my-28 {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
    }
    .xl\:mx-28 {
        margin-left: 2.8rem;
        margin-right: 2.8rem;
    }
    .xl\:my-32 {
        margin-top: 3.2rem;
        margin-bottom: 3.2rem;
    }
    .xl\:mx-32 {
        margin-left: 3.2rem;
        margin-right: 3.2rem;
    }
    .xl\:my-36 {
        margin-top: 3.6rem;
        margin-bottom: 3.6rem;
    }
    .xl\:mx-36 {
        margin-left: 3.6rem;
        margin-right: 3.6rem;
    }
    .xl\:my-40 {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .xl\:mx-40 {
        margin-left: 4rem;
        margin-right: 4rem;
    }
    .xl\:my-44 {
        margin-top: 4.4rem;
        margin-bottom: 4.4rem;
    }
    .xl\:mx-44 {
        margin-left: 4.4rem;
        margin-right: 4.4rem;
    }
    .xl\:my-48 {
        margin-top: 4.8rem;
        margin-bottom: 4.8rem;
    }
    .xl\:mx-48 {
        margin-left: 4.8rem;
        margin-right: 4.8rem;
    }
    .xl\:my-52 {
        margin-top: 5.2rem;
        margin-bottom: 5.2rem;
    }
    .xl\:mx-52 {
        margin-left: 5.2rem;
        margin-right: 5.2rem;
    }
    .xl\:my-56 {
        margin-top: 5.6rem;
        margin-bottom: 5.6rem;
    }
    .xl\:mx-56 {
        margin-left: 5.6rem;
        margin-right: 5.6rem;
    }
    .xl\:my-60 {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
    .xl\:mx-60 {
        margin-left: 6rem;
        margin-right: 6rem;
    }
    .xl\:my-64 {
        margin-top: 6.4rem;
        margin-bottom: 6.4rem;
    }
    .xl\:mx-64 {
        margin-left: 6.4rem;
        margin-right: 6.4rem;
    }
    .xl\:my-68 {
        margin-top: 6.8rem;
        margin-bottom: 6.8rem;
    }
    .xl\:mx-68 {
        margin-left: 6.8rem;
        margin-right: 6.8rem;
    }
    .xl\:my-72 {
        margin-top: 7.2rem;
        margin-bottom: 7.2rem;
    }
    .xl\:mx-72 {
        margin-left: 7.2rem;
        margin-right: 7.2rem;
    }
    .xl\:my-76 {
        margin-top: 7.6rem;
        margin-bottom: 7.6rem;
    }
    .xl\:mx-76 {
        margin-left: 7.6rem;
        margin-right: 7.6rem;
    }
    .xl\:my-80 {
        margin-top: 8rem;
        margin-bottom: 8rem;
    }
    .xl\:mx-80 {
        margin-left: 8rem;
        margin-right: 8rem;
    }
    .xl\:my-84 {
        margin-top: 8.4rem;
        margin-bottom: 8.4rem;
    }
    .xl\:mx-84 {
        margin-left: 8.4rem;
        margin-right: 8.4rem;
    }
    .xl\:my-88 {
        margin-top: 8.8rem;
        margin-bottom: 8.8rem;
    }
    .xl\:mx-88 {
        margin-left: 8.8rem;
        margin-right: 8.8rem;
    }
    .xl\:my-92 {
        margin-top: 9.2rem;
        margin-bottom: 9.2rem;
    }
    .xl\:mx-92 {
        margin-left: 9.2rem;
        margin-right: 9.2rem;
    }
    .xl\:my-96 {
        margin-top: 9.6rem;
        margin-bottom: 9.6rem;
    }
    .xl\:mx-96 {
        margin-left: 9.6rem;
        margin-right: 9.6rem;
    }
    .xl\:my-128 {
        margin-top: 12.8rem;
        margin-bottom: 12.8rem;
    }
    .xl\:mx-128 {
        margin-left: 12.8rem;
        margin-right: 12.8rem;
    }
    .xl\:my-160 {
        margin-top: 16rem;
        margin-bottom: 16rem;
    }
    .xl\:mx-160 {
        margin-left: 16rem;
        margin-right: 16rem;
    }
    .xl\:my-192 {
        margin-top: 19.2rem;
        margin-bottom: 19.2rem;
    }
    .xl\:mx-192 {
        margin-left: 19.2rem;
        margin-right: 19.2rem;
    }
    .xl\:my-224 {
        margin-top: 22.4rem;
        margin-bottom: 22.4rem;
    }
    .xl\:mx-224 {
        margin-left: 22.4rem;
        margin-right: 22.4rem;
    }
    .xl\:my-256 {
        margin-top: 25.6rem;
        margin-bottom: 25.6rem;
    }
    .xl\:mx-256 {
        margin-left: 25.6rem;
        margin-right: 25.6rem;
    }
    .xl\:my-auto {
        margin-top: auto;
        margin-bottom: auto;
    }
    .xl\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .xl\:my-px {
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .xl\:mx-px {
        margin-left: 1px;
        margin-right: 1px;
    }
    .xl\:mt-0 {
        margin-top: 0;
    }
    .xl\:mr-0 {
        margin-right: 0;
    }
    .xl\:mb-0 {
        margin-bottom: 0;
    }
    .xl\:ml-0 {
        margin-left: 0;
    }
    .xl\:mt-4 {
        margin-top: .4rem;
    }
    .xl\:mr-4 {
        margin-right: .4rem;
    }
    .xl\:mb-4 {
        margin-bottom: .4rem;
    }
    .xl\:ml-4 {
        margin-left: .4rem;
    }
    .xl\:mt-8 {
        margin-top: .8rem;
    }
    .xl\:mr-8 {
        margin-right: .8rem;
    }
    .xl\:mb-8 {
        margin-bottom: .8rem;
    }
    .xl\:ml-8 {
        margin-left: .8rem;
    }
    .xl\:mt-12 {
        margin-top: 1.2rem;
    }
    .xl\:mr-12 {
        margin-right: 1.2rem;
    }
    .xl\:mb-12 {
        margin-bottom: 1.2rem;
    }
    .xl\:ml-12 {
        margin-left: 1.2rem;
    }
    .xl\:mt-16 {
        margin-top: 1.6rem;
    }
    .xl\:mr-16 {
        margin-right: 1.6rem;
    }
    .xl\:mb-16 {
        margin-bottom: 1.6rem;
    }
    .xl\:ml-16 {
        margin-left: 1.6rem;
    }
    .xl\:mt-20 {
        margin-top: 2rem;
    }
    .xl\:mr-20 {
        margin-right: 2rem;
    }
    .xl\:mb-20 {
        margin-bottom: 2rem;
    }
    .xl\:ml-20 {
        margin-left: 2rem;
    }
    .xl\:mt-24 {
        margin-top: 2.4rem;
    }
    .xl\:mr-24 {
        margin-right: 2.4rem;
    }
    .xl\:mb-24 {
        margin-bottom: 2.4rem;
    }
    .xl\:ml-24 {
        margin-left: 2.4rem;
    }
    .xl\:mt-28 {
        margin-top: 2.8rem;
    }
    .xl\:mr-28 {
        margin-right: 2.8rem;
    }
    .xl\:mb-28 {
        margin-bottom: 2.8rem;
    }
    .xl\:ml-28 {
        margin-left: 2.8rem;
    }
    .xl\:mt-32 {
        margin-top: 3.2rem;
    }
    .xl\:mr-32 {
        margin-right: 3.2rem;
    }
    .xl\:mb-32 {
        margin-bottom: 3.2rem;
    }
    .xl\:ml-32 {
        margin-left: 3.2rem;
    }
    .xl\:mt-36 {
        margin-top: 3.6rem;
    }
    .xl\:mr-36 {
        margin-right: 3.6rem;
    }
    .xl\:mb-36 {
        margin-bottom: 3.6rem;
    }
    .xl\:ml-36 {
        margin-left: 3.6rem;
    }
    .xl\:mt-40 {
        margin-top: 4rem;
    }
    .xl\:mr-40 {
        margin-right: 4rem;
    }
    .xl\:mb-40 {
        margin-bottom: 4rem;
    }
    .xl\:ml-40 {
        margin-left: 4rem;
    }
    .xl\:mt-44 {
        margin-top: 4.4rem;
    }
    .xl\:mr-44 {
        margin-right: 4.4rem;
    }
    .xl\:mb-44 {
        margin-bottom: 4.4rem;
    }
    .xl\:ml-44 {
        margin-left: 4.4rem;
    }
    .xl\:mt-48 {
        margin-top: 4.8rem;
    }
    .xl\:mr-48 {
        margin-right: 4.8rem;
    }
    .xl\:mb-48 {
        margin-bottom: 4.8rem;
    }
    .xl\:ml-48 {
        margin-left: 4.8rem;
    }
    .xl\:mt-52 {
        margin-top: 5.2rem;
    }
    .xl\:mr-52 {
        margin-right: 5.2rem;
    }
    .xl\:mb-52 {
        margin-bottom: 5.2rem;
    }
    .xl\:ml-52 {
        margin-left: 5.2rem;
    }
    .xl\:mt-56 {
        margin-top: 5.6rem;
    }
    .xl\:mr-56 {
        margin-right: 5.6rem;
    }
    .xl\:mb-56 {
        margin-bottom: 5.6rem;
    }
    .xl\:ml-56 {
        margin-left: 5.6rem;
    }
    .xl\:mt-60 {
        margin-top: 6rem;
    }
    .xl\:mr-60 {
        margin-right: 6rem;
    }
    .xl\:mb-60 {
        margin-bottom: 6rem;
    }
    .xl\:ml-60 {
        margin-left: 6rem;
    }
    .xl\:mt-64 {
        margin-top: 6.4rem;
    }
    .xl\:mr-64 {
        margin-right: 6.4rem;
    }
    .xl\:mb-64 {
        margin-bottom: 6.4rem;
    }
    .xl\:ml-64 {
        margin-left: 6.4rem;
    }
    .xl\:mt-68 {
        margin-top: 6.8rem;
    }
    .xl\:mr-68 {
        margin-right: 6.8rem;
    }
    .xl\:mb-68 {
        margin-bottom: 6.8rem;
    }
    .xl\:ml-68 {
        margin-left: 6.8rem;
    }
    .xl\:mt-72 {
        margin-top: 7.2rem;
    }
    .xl\:mr-72 {
        margin-right: 7.2rem;
    }
    .xl\:mb-72 {
        margin-bottom: 7.2rem;
    }
    .xl\:ml-72 {
        margin-left: 7.2rem;
    }
    .xl\:mt-76 {
        margin-top: 7.6rem;
    }
    .xl\:mr-76 {
        margin-right: 7.6rem;
    }
    .xl\:mb-76 {
        margin-bottom: 7.6rem;
    }
    .xl\:ml-76 {
        margin-left: 7.6rem;
    }
    .xl\:mt-80 {
        margin-top: 8rem;
    }
    .xl\:mr-80 {
        margin-right: 8rem;
    }
    .xl\:mb-80 {
        margin-bottom: 8rem;
    }
    .xl\:ml-80 {
        margin-left: 8rem;
    }
    .xl\:mt-84 {
        margin-top: 8.4rem;
    }
    .xl\:mr-84 {
        margin-right: 8.4rem;
    }
    .xl\:mb-84 {
        margin-bottom: 8.4rem;
    }
    .xl\:ml-84 {
        margin-left: 8.4rem;
    }
    .xl\:mt-88 {
        margin-top: 8.8rem;
    }
    .xl\:mr-88 {
        margin-right: 8.8rem;
    }
    .xl\:mb-88 {
        margin-bottom: 8.8rem;
    }
    .xl\:ml-88 {
        margin-left: 8.8rem;
    }
    .xl\:mt-92 {
        margin-top: 9.2rem;
    }
    .xl\:mr-92 {
        margin-right: 9.2rem;
    }
    .xl\:mb-92 {
        margin-bottom: 9.2rem;
    }
    .xl\:ml-92 {
        margin-left: 9.2rem;
    }
    .xl\:mt-96 {
        margin-top: 9.6rem;
    }
    .xl\:mr-96 {
        margin-right: 9.6rem;
    }
    .xl\:mb-96 {
        margin-bottom: 9.6rem;
    }
    .xl\:ml-96 {
        margin-left: 9.6rem;
    }
    .xl\:mt-128 {
        margin-top: 12.8rem;
    }
    .xl\:mr-128 {
        margin-right: 12.8rem;
    }
    .xl\:mb-128 {
        margin-bottom: 12.8rem;
    }
    .xl\:ml-128 {
        margin-left: 12.8rem;
    }
    .xl\:mt-160 {
        margin-top: 16rem;
    }
    .xl\:mr-160 {
        margin-right: 16rem;
    }
    .xl\:mb-160 {
        margin-bottom: 16rem;
    }
    .xl\:ml-160 {
        margin-left: 16rem;
    }
    .xl\:mt-192 {
        margin-top: 19.2rem;
    }
    .xl\:mr-192 {
        margin-right: 19.2rem;
    }
    .xl\:mb-192 {
        margin-bottom: 19.2rem;
    }
    .xl\:ml-192 {
        margin-left: 19.2rem;
    }
    .xl\:mt-224 {
        margin-top: 22.4rem;
    }
    .xl\:mr-224 {
        margin-right: 22.4rem;
    }
    .xl\:mb-224 {
        margin-bottom: 22.4rem;
    }
    .xl\:ml-224 {
        margin-left: 22.4rem;
    }
    .xl\:mt-256 {
        margin-top: 25.6rem;
    }
    .xl\:mr-256 {
        margin-right: 25.6rem;
    }
    .xl\:mb-256 {
        margin-bottom: 25.6rem;
    }
    .xl\:ml-256 {
        margin-left: 25.6rem;
    }
    .xl\:mt-auto {
        margin-top: auto;
    }
    .xl\:mr-auto {
        margin-right: auto;
    }
    .xl\:mb-auto {
        margin-bottom: auto;
    }
    .xl\:ml-auto {
        margin-left: auto;
    }
    .xl\:mt-px {
        margin-top: 1px;
    }
    .xl\:mr-px {
        margin-right: 1px;
    }
    .xl\:mb-px {
        margin-bottom: 1px;
    }
    .xl\:ml-px {
        margin-left: 1px;
    }
    .xl\:max-h-4 {
        max-height: .4rem;
    }
    .xl\:max-h-8 {
        max-height: .8rem;
    }
    .xl\:max-h-12 {
        max-height: 1.2rem;
    }
    .xl\:max-h-16 {
        max-height: 1.6rem;
    }
    .xl\:max-h-20 {
        max-height: 2rem;
    }
    .xl\:max-h-24 {
        max-height: 2.4rem;
    }
    .xl\:max-h-28 {
        max-height: 2.8rem;
    }
    .xl\:max-h-32 {
        max-height: 3.2rem;
    }
    .xl\:max-h-36 {
        max-height: 3.6rem;
    }
    .xl\:max-h-40 {
        max-height: 4rem;
    }
    .xl\:max-h-44 {
        max-height: 4.4rem;
    }
    .xl\:max-h-48 {
        max-height: 4.8rem;
    }
    .xl\:max-h-52 {
        max-height: 5.2rem;
    }
    .xl\:max-h-56 {
        max-height: 5.6rem;
    }
    .xl\:max-h-60 {
        max-height: 6rem;
    }
    .xl\:max-h-64 {
        max-height: 6.4rem;
    }
    .xl\:max-h-68 {
        max-height: 6.8rem;
    }
    .xl\:max-h-72 {
        max-height: 7.2rem;
    }
    .xl\:max-h-76 {
        max-height: 7.6rem;
    }
    .xl\:max-h-80 {
        max-height: 8rem;
    }
    .xl\:max-h-84 {
        max-height: 8.4rem;
    }
    .xl\:max-h-88 {
        max-height: 8.8rem;
    }
    .xl\:max-h-92 {
        max-height: 9.2rem;
    }
    .xl\:max-h-96 {
        max-height: 9.6rem;
    }
    .xl\:max-h-128 {
        max-height: 12.8rem;
    }
    .xl\:max-h-160 {
        max-height: 16rem;
    }
    .xl\:max-h-192 {
        max-height: 19.2rem;
    }
    .xl\:max-h-224 {
        max-height: 22.4rem;
    }
    .xl\:max-h-256 {
        max-height: 25.6rem;
    }
    .xl\:max-h-288 {
        max-height: 28.8rem;
    }
    .xl\:max-h-320 {
        max-height: 32rem;
    }
    .xl\:max-h-512 {
        max-height: 51.2rem;
    }
    .xl\:max-h-full {
        max-height: 100%;
    }
    .xl\:max-h-screen {
        max-height: 100vh;
    }
    .xl\:max-w-4 {
        max-width: .4rem;
    }
    .xl\:max-w-8 {
        max-width: .8rem;
    }
    .xl\:max-w-12 {
        max-width: 1.2rem;
    }
    .xl\:max-w-16 {
        max-width: 1.6rem;
    }
    .xl\:max-w-20 {
        max-width: 2rem;
    }
    .xl\:max-w-24 {
        max-width: 2.4rem;
    }
    .xl\:max-w-28 {
        max-width: 2.8rem;
    }
    .xl\:max-w-32 {
        max-width: 3.2rem;
    }
    .xl\:max-w-36 {
        max-width: 3.6rem;
    }
    .xl\:max-w-40 {
        max-width: 4rem;
    }
    .xl\:max-w-44 {
        max-width: 4.4rem;
    }
    .xl\:max-w-48 {
        max-width: 4.8rem;
    }
    .xl\:max-w-52 {
        max-width: 5.2rem;
    }
    .xl\:max-w-56 {
        max-width: 5.6rem;
    }
    .xl\:max-w-60 {
        max-width: 6rem;
    }
    .xl\:max-w-64 {
        max-width: 6.4rem;
    }
    .xl\:max-w-96 {
        max-width: 9.6rem;
    }
    .xl\:max-w-128 {
        max-width: 12.8rem;
    }
    .xl\:max-w-160 {
        max-width: 16rem;
    }
    .xl\:max-w-192 {
        max-width: 19.2rem;
    }
    .xl\:max-w-224 {
        max-width: 22.4rem;
    }
    .xl\:max-w-256 {
        max-width: 25.6rem;
    }
    .xl\:max-w-288 {
        max-width: 28.8rem;
    }
    .xl\:max-w-320 {
        max-width: 32rem;
    }
    .xl\:max-w-512 {
        max-width: 51.2rem;
    }
    .xl\:max-w-xs {
        max-width: 32rem;
    }
    .xl\:max-w-sm {
        max-width: 48rem;
    }
    .xl\:max-w-md {
        max-width: 64rem;
    }
    .xl\:max-w-lg {
        max-width: 80rem;
    }
    .xl\:max-w-xl {
        max-width: 96rem;
    }
    .xl\:max-w-2xl {
        max-width: 112rem;
    }
    .xl\:max-w-3xl {
        max-width: 128rem;
    }
    .xl\:max-w-4xl {
        max-width: 144rem;
    }
    .xl\:max-w-5xl {
        max-width: 160rem;
    }
    .xl\:max-w-full {
        max-width: 100%;
    }
    .xl\:min-h-4 {
        min-height: .4rem;
    }
    .xl\:min-h-8 {
        min-height: .8rem;
    }
    .xl\:min-h-12 {
        min-height: 1.2rem;
    }
    .xl\:min-h-16 {
        min-height: 1.6rem;
    }
    .xl\:min-h-20 {
        min-height: 2rem;
    }
    .xl\:min-h-24 {
        min-height: 2.4rem;
    }
    .xl\:min-h-28 {
        min-height: 2.8rem;
    }
    .xl\:min-h-32 {
        min-height: 3.2rem;
    }
    .xl\:min-h-36 {
        min-height: 3.6rem;
    }
    .xl\:min-h-40 {
        min-height: 4rem;
    }
    .xl\:min-h-44 {
        min-height: 4.4rem;
    }
    .xl\:min-h-48 {
        min-height: 4.8rem;
    }
    .xl\:min-h-52 {
        min-height: 5.2rem;
    }
    .xl\:min-h-56 {
        min-height: 5.6rem;
    }
    .xl\:min-h-60 {
        min-height: 6rem;
    }
    .xl\:min-h-64 {
        min-height: 6.4rem;
    }
    .xl\:min-h-68 {
        min-height: 6.8rem;
    }
    .xl\:min-h-72 {
        min-height: 7.2rem;
    }
    .xl\:min-h-76 {
        min-height: 7.6rem;
    }
    .xl\:min-h-80 {
        min-height: 8rem;
    }
    .xl\:min-h-84 {
        min-height: 8.4rem;
    }
    .xl\:min-h-88 {
        min-height: 8.8rem;
    }
    .xl\:min-h-92 {
        min-height: 9.2rem;
    }
    .xl\:min-h-96 {
        min-height: 9.6rem;
    }
    .xl\:min-h-128 {
        min-height: 12.8rem;
    }
    .xl\:min-h-160 {
        min-height: 16rem;
    }
    .xl\:min-h-192 {
        min-height: 19.2rem;
    }
    .xl\:min-h-224 {
        min-height: 22.4rem;
    }
    .xl\:min-h-256 {
        min-height: 25.6rem;
    }
    .xl\:min-h-288 {
        min-height: 28.8rem;
    }
    .xl\:min-h-320 {
        min-height: 32rem;
    }
    .xl\:min-h-512 {
        min-height: 51.2rem;
    }
    .xl\:min-h-auto {
        min-height: auto;
    }
    .xl\:min-h-px {
        min-height: 1px;
    }
    .xl\:min-h-full {
        min-height: 100%;
    }
    .xl\:min-h-screen {
        min-height: 100vh;
    }
    .xl\:min-w-0 {
        min-width: 0;
    }
    .xl\:min-w-4 {
        min-width: .4rem;
    }
    .xl\:min-w-8 {
        min-width: .8rem;
    }
    .xl\:min-w-12 {
        min-width: 1.2rem;
    }
    .xl\:min-w-16 {
        min-width: 1.6rem;
    }
    .xl\:min-w-20 {
        min-width: 2rem;
    }
    .xl\:min-w-24 {
        min-width: 2.4rem;
    }
    .xl\:min-w-28 {
        min-width: 2.8rem;
    }
    .xl\:min-w-32 {
        min-width: 3.2rem;
    }
    .xl\:min-w-36 {
        min-width: 3.6rem;
    }
    .xl\:min-w-40 {
        min-width: 4rem;
    }
    .xl\:min-w-44 {
        min-width: 4.4rem;
    }
    .xl\:min-w-48 {
        min-width: 4.8rem;
    }
    .xl\:min-w-52 {
        min-width: 5.2rem;
    }
    .xl\:min-w-56 {
        min-width: 5.6rem;
    }
    .xl\:min-w-60 {
        min-width: 6rem;
    }
    .xl\:min-w-64 {
        min-width: 6.4rem;
    }
    .xl\:min-w-68 {
        min-width: 6.8rem;
    }
    .xl\:min-w-72 {
        min-width: 7.2rem;
    }
    .xl\:min-w-76 {
        min-width: 7.6rem;
    }
    .xl\:min-w-80 {
        min-width: 8rem;
    }
    .xl\:min-w-84 {
        min-width: 8.4rem;
    }
    .xl\:min-w-88 {
        min-width: 8.8rem;
    }
    .xl\:min-w-92 {
        min-width: 9.2rem;
    }
    .xl\:min-w-96 {
        min-width: 9.6rem;
    }
    .xl\:min-w-128 {
        min-width: 12.8rem;
    }
    .xl\:min-w-160 {
        min-width: 16rem;
    }
    .xl\:min-w-192 {
        min-width: 19.2rem;
    }
    .xl\:min-w-224 {
        min-width: 22.4rem;
    }
    .xl\:min-w-256 {
        min-width: 25.6rem;
    }
    .xl\:min-w-288 {
        min-width: 28.8rem;
    }
    .xl\:min-w-320 {
        min-width: 32rem;
    }
    .xl\:min-w-512 {
        min-width: 51.2rem;
    }
    .xl\:min-w-auto {
        min-width: auto;
    }
    .xl\:min-w-px {
        min-width: 1px;
    }
    .xl\:min-w-1\/2 {
        min-width: 50%;
    }
    .xl\:min-w-1\/3 {
        min-width: 33.33333%;
    }
    .xl\:min-w-2\/3 {
        min-width: 66.66667%;
    }
    .xl\:min-w-1\/4 {
        min-width: 25%;
    }
    .xl\:min-w-3\/4 {
        min-width: 75%;
    }
    .xl\:min-w-1\/5 {
        min-width: 20%;
    }
    .xl\:min-w-2\/5 {
        min-width: 40%;
    }
    .xl\:min-w-3\/5 {
        min-width: 60%;
    }
    .xl\:min-w-4\/5 {
        min-width: 80%;
    }
    .xl\:min-w-1\/6 {
        min-width: 16.66667%;
    }
    .xl\:min-w-5\/6 {
        min-width: 83.33333%;
    }
    .xl\:min-w-full {
        min-width: 100%;
    }
    .xl\:min-w-screen {
        min-width: 100vw;
    }
    .xl\:-m-0 {
        margin: 0;
    }
    .xl\:-m-4 {
        margin: -0.4rem;
    }
    .xl\:-m-8 {
        margin: -0.8rem;
    }
    .xl\:-m-12 {
        margin: -1.2rem;
    }
    .xl\:-m-16 {
        margin: -1.6rem;
    }
    .xl\:-m-20 {
        margin: -2rem;
    }
    .xl\:-m-24 {
        margin: -2.4rem;
    }
    .xl\:-m-28 {
        margin: -2.8rem;
    }
    .xl\:-m-32 {
        margin: -3.2rem;
    }
    .xl\:-m-36 {
        margin: -3.6rem;
    }
    .xl\:-m-40 {
        margin: -4rem;
    }
    .xl\:-m-44 {
        margin: -4.4rem;
    }
    .xl\:-m-48 {
        margin: -4.8rem;
    }
    .xl\:-m-52 {
        margin: -5.2rem;
    }
    .xl\:-m-56 {
        margin: -5.6rem;
    }
    .xl\:-m-60 {
        margin: -6rem;
    }
    .xl\:-m-64 {
        margin: -6.4rem;
    }
    .xl\:-m-68 {
        margin: -6.8rem;
    }
    .xl\:-m-72 {
        margin: -7.2rem;
    }
    .xl\:-m-76 {
        margin: -7.6rem;
    }
    .xl\:-m-80 {
        margin: -8rem;
    }
    .xl\:-m-84 {
        margin: -8.4rem;
    }
    .xl\:-m-88 {
        margin: -8.8rem;
    }
    .xl\:-m-92 {
        margin: -9.2rem;
    }
    .xl\:-m-96 {
        margin: -9.6rem;
    }
    .xl\:-m-128 {
        margin: -12.8rem;
    }
    .xl\:-m-160 {
        margin: -16rem;
    }
    .xl\:-m-192 {
        margin: -19.2rem;
    }
    .xl\:-m-224 {
        margin: -22.4rem;
    }
    .xl\:-m-256 {
        margin: -25.6rem;
    }
    .xl\:-m-px {
        margin: -1px;
    }
    .xl\:-my-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .xl\:-mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .xl\:-my-4 {
        margin-top: -0.4rem;
        margin-bottom: -0.4rem;
    }
    .xl\:-mx-4 {
        margin-left: -0.4rem;
        margin-right: -0.4rem;
    }
    .xl\:-my-8 {
        margin-top: -0.8rem;
        margin-bottom: -0.8rem;
    }
    .xl\:-mx-8 {
        margin-left: -0.8rem;
        margin-right: -0.8rem;
    }
    .xl\:-my-12 {
        margin-top: -1.2rem;
        margin-bottom: -1.2rem;
    }
    .xl\:-mx-12 {
        margin-left: -1.2rem;
        margin-right: -1.2rem;
    }
    .xl\:-my-16 {
        margin-top: -1.6rem;
        margin-bottom: -1.6rem;
    }
    .xl\:-mx-16 {
        margin-left: -1.6rem;
        margin-right: -1.6rem;
    }
    .xl\:-my-20 {
        margin-top: -2rem;
        margin-bottom: -2rem;
    }
    .xl\:-mx-20 {
        margin-left: -2rem;
        margin-right: -2rem;
    }
    .xl\:-my-24 {
        margin-top: -2.4rem;
        margin-bottom: -2.4rem;
    }
    .xl\:-mx-24 {
        margin-left: -2.4rem;
        margin-right: -2.4rem;
    }
    .xl\:-my-28 {
        margin-top: -2.8rem;
        margin-bottom: -2.8rem;
    }
    .xl\:-mx-28 {
        margin-left: -2.8rem;
        margin-right: -2.8rem;
    }
    .xl\:-my-32 {
        margin-top: -3.2rem;
        margin-bottom: -3.2rem;
    }
    .xl\:-mx-32 {
        margin-left: -3.2rem;
        margin-right: -3.2rem;
    }
    .xl\:-my-36 {
        margin-top: -3.6rem;
        margin-bottom: -3.6rem;
    }
    .xl\:-mx-36 {
        margin-left: -3.6rem;
        margin-right: -3.6rem;
    }
    .xl\:-my-40 {
        margin-top: -4rem;
        margin-bottom: -4rem;
    }
    .xl\:-mx-40 {
        margin-left: -4rem;
        margin-right: -4rem;
    }
    .xl\:-my-44 {
        margin-top: -4.4rem;
        margin-bottom: -4.4rem;
    }
    .xl\:-mx-44 {
        margin-left: -4.4rem;
        margin-right: -4.4rem;
    }
    .xl\:-my-48 {
        margin-top: -4.8rem;
        margin-bottom: -4.8rem;
    }
    .xl\:-mx-48 {
        margin-left: -4.8rem;
        margin-right: -4.8rem;
    }
    .xl\:-my-52 {
        margin-top: -5.2rem;
        margin-bottom: -5.2rem;
    }
    .xl\:-mx-52 {
        margin-left: -5.2rem;
        margin-right: -5.2rem;
    }
    .xl\:-my-56 {
        margin-top: -5.6rem;
        margin-bottom: -5.6rem;
    }
    .xl\:-mx-56 {
        margin-left: -5.6rem;
        margin-right: -5.6rem;
    }
    .xl\:-my-60 {
        margin-top: -6rem;
        margin-bottom: -6rem;
    }
    .xl\:-mx-60 {
        margin-left: -6rem;
        margin-right: -6rem;
    }
    .xl\:-my-64 {
        margin-top: -6.4rem;
        margin-bottom: -6.4rem;
    }
    .xl\:-mx-64 {
        margin-left: -6.4rem;
        margin-right: -6.4rem;
    }
    .xl\:-my-68 {
        margin-top: -6.8rem;
        margin-bottom: -6.8rem;
    }
    .xl\:-mx-68 {
        margin-left: -6.8rem;
        margin-right: -6.8rem;
    }
    .xl\:-my-72 {
        margin-top: -7.2rem;
        margin-bottom: -7.2rem;
    }
    .xl\:-mx-72 {
        margin-left: -7.2rem;
        margin-right: -7.2rem;
    }
    .xl\:-my-76 {
        margin-top: -7.6rem;
        margin-bottom: -7.6rem;
    }
    .xl\:-mx-76 {
        margin-left: -7.6rem;
        margin-right: -7.6rem;
    }
    .xl\:-my-80 {
        margin-top: -8rem;
        margin-bottom: -8rem;
    }
    .xl\:-mx-80 {
        margin-left: -8rem;
        margin-right: -8rem;
    }
    .xl\:-my-84 {
        margin-top: -8.4rem;
        margin-bottom: -8.4rem;
    }
    .xl\:-mx-84 {
        margin-left: -8.4rem;
        margin-right: -8.4rem;
    }
    .xl\:-my-88 {
        margin-top: -8.8rem;
        margin-bottom: -8.8rem;
    }
    .xl\:-mx-88 {
        margin-left: -8.8rem;
        margin-right: -8.8rem;
    }
    .xl\:-my-92 {
        margin-top: -9.2rem;
        margin-bottom: -9.2rem;
    }
    .xl\:-mx-92 {
        margin-left: -9.2rem;
        margin-right: -9.2rem;
    }
    .xl\:-my-96 {
        margin-top: -9.6rem;
        margin-bottom: -9.6rem;
    }
    .xl\:-mx-96 {
        margin-left: -9.6rem;
        margin-right: -9.6rem;
    }
    .xl\:-my-128 {
        margin-top: -12.8rem;
        margin-bottom: -12.8rem;
    }
    .xl\:-mx-128 {
        margin-left: -12.8rem;
        margin-right: -12.8rem;
    }
    .xl\:-my-160 {
        margin-top: -16rem;
        margin-bottom: -16rem;
    }
    .xl\:-mx-160 {
        margin-left: -16rem;
        margin-right: -16rem;
    }
    .xl\:-my-192 {
        margin-top: -19.2rem;
        margin-bottom: -19.2rem;
    }
    .xl\:-mx-192 {
        margin-left: -19.2rem;
        margin-right: -19.2rem;
    }
    .xl\:-my-224 {
        margin-top: -22.4rem;
        margin-bottom: -22.4rem;
    }
    .xl\:-mx-224 {
        margin-left: -22.4rem;
        margin-right: -22.4rem;
    }
    .xl\:-my-256 {
        margin-top: -25.6rem;
        margin-bottom: -25.6rem;
    }
    .xl\:-mx-256 {
        margin-left: -25.6rem;
        margin-right: -25.6rem;
    }
    .xl\:-my-px {
        margin-top: -1px;
        margin-bottom: -1px;
    }
    .xl\:-mx-px {
        margin-left: -1px;
        margin-right: -1px;
    }
    .xl\:-mt-0 {
        margin-top: 0;
    }
    .xl\:-mr-0 {
        margin-right: 0;
    }
    .xl\:-mb-0 {
        margin-bottom: 0;
    }
    .xl\:-ml-0 {
        margin-left: 0;
    }
    .xl\:-mt-4 {
        margin-top: -0.4rem;
    }
    .xl\:-mr-4 {
        margin-right: -0.4rem;
    }
    .xl\:-mb-4 {
        margin-bottom: -0.4rem;
    }
    .xl\:-ml-4 {
        margin-left: -0.4rem;
    }
    .xl\:-mt-8 {
        margin-top: -0.8rem;
    }
    .xl\:-mr-8 {
        margin-right: -0.8rem;
    }
    .xl\:-mb-8 {
        margin-bottom: -0.8rem;
    }
    .xl\:-ml-8 {
        margin-left: -0.8rem;
    }
    .xl\:-mt-12 {
        margin-top: -1.2rem;
    }
    .xl\:-mr-12 {
        margin-right: -1.2rem;
    }
    .xl\:-mb-12 {
        margin-bottom: -1.2rem;
    }
    .xl\:-ml-12 {
        margin-left: -1.2rem;
    }
    .xl\:-mt-16 {
        margin-top: -1.6rem;
    }
    .xl\:-mr-16 {
        margin-right: -1.6rem;
    }
    .xl\:-mb-16 {
        margin-bottom: -1.6rem;
    }
    .xl\:-ml-16 {
        margin-left: -1.6rem;
    }
    .xl\:-mt-20 {
        margin-top: -2rem;
    }
    .xl\:-mr-20 {
        margin-right: -2rem;
    }
    .xl\:-mb-20 {
        margin-bottom: -2rem;
    }
    .xl\:-ml-20 {
        margin-left: -2rem;
    }
    .xl\:-mt-24 {
        margin-top: -2.4rem;
    }
    .xl\:-mr-24 {
        margin-right: -2.4rem;
    }
    .xl\:-mb-24 {
        margin-bottom: -2.4rem;
    }
    .xl\:-ml-24 {
        margin-left: -2.4rem;
    }
    .xl\:-mt-28 {
        margin-top: -2.8rem;
    }
    .xl\:-mr-28 {
        margin-right: -2.8rem;
    }
    .xl\:-mb-28 {
        margin-bottom: -2.8rem;
    }
    .xl\:-ml-28 {
        margin-left: -2.8rem;
    }
    .xl\:-mt-32 {
        margin-top: -3.2rem;
    }
    .xl\:-mr-32 {
        margin-right: -3.2rem;
    }
    .xl\:-mb-32 {
        margin-bottom: -3.2rem;
    }
    .xl\:-ml-32 {
        margin-left: -3.2rem;
    }
    .xl\:-mt-36 {
        margin-top: -3.6rem;
    }
    .xl\:-mr-36 {
        margin-right: -3.6rem;
    }
    .xl\:-mb-36 {
        margin-bottom: -3.6rem;
    }
    .xl\:-ml-36 {
        margin-left: -3.6rem;
    }
    .xl\:-mt-40 {
        margin-top: -4rem;
    }
    .xl\:-mr-40 {
        margin-right: -4rem;
    }
    .xl\:-mb-40 {
        margin-bottom: -4rem;
    }
    .xl\:-ml-40 {
        margin-left: -4rem;
    }
    .xl\:-mt-44 {
        margin-top: -4.4rem;
    }
    .xl\:-mr-44 {
        margin-right: -4.4rem;
    }
    .xl\:-mb-44 {
        margin-bottom: -4.4rem;
    }
    .xl\:-ml-44 {
        margin-left: -4.4rem;
    }
    .xl\:-mt-48 {
        margin-top: -4.8rem;
    }
    .xl\:-mr-48 {
        margin-right: -4.8rem;
    }
    .xl\:-mb-48 {
        margin-bottom: -4.8rem;
    }
    .xl\:-ml-48 {
        margin-left: -4.8rem;
    }
    .xl\:-mt-52 {
        margin-top: -5.2rem;
    }
    .xl\:-mr-52 {
        margin-right: -5.2rem;
    }
    .xl\:-mb-52 {
        margin-bottom: -5.2rem;
    }
    .xl\:-ml-52 {
        margin-left: -5.2rem;
    }
    .xl\:-mt-56 {
        margin-top: -5.6rem;
    }
    .xl\:-mr-56 {
        margin-right: -5.6rem;
    }
    .xl\:-mb-56 {
        margin-bottom: -5.6rem;
    }
    .xl\:-ml-56 {
        margin-left: -5.6rem;
    }
    .xl\:-mt-60 {
        margin-top: -6rem;
    }
    .xl\:-mr-60 {
        margin-right: -6rem;
    }
    .xl\:-mb-60 {
        margin-bottom: -6rem;
    }
    .xl\:-ml-60 {
        margin-left: -6rem;
    }
    .xl\:-mt-64 {
        margin-top: -6.4rem;
    }
    .xl\:-mr-64 {
        margin-right: -6.4rem;
    }
    .xl\:-mb-64 {
        margin-bottom: -6.4rem;
    }
    .xl\:-ml-64 {
        margin-left: -6.4rem;
    }
    .xl\:-mt-68 {
        margin-top: -6.8rem;
    }
    .xl\:-mr-68 {
        margin-right: -6.8rem;
    }
    .xl\:-mb-68 {
        margin-bottom: -6.8rem;
    }
    .xl\:-ml-68 {
        margin-left: -6.8rem;
    }
    .xl\:-mt-72 {
        margin-top: -7.2rem;
    }
    .xl\:-mr-72 {
        margin-right: -7.2rem;
    }
    .xl\:-mb-72 {
        margin-bottom: -7.2rem;
    }
    .xl\:-ml-72 {
        margin-left: -7.2rem;
    }
    .xl\:-mt-76 {
        margin-top: -7.6rem;
    }
    .xl\:-mr-76 {
        margin-right: -7.6rem;
    }
    .xl\:-mb-76 {
        margin-bottom: -7.6rem;
    }
    .xl\:-ml-76 {
        margin-left: -7.6rem;
    }
    .xl\:-mt-80 {
        margin-top: -8rem;
    }
    .xl\:-mr-80 {
        margin-right: -8rem;
    }
    .xl\:-mb-80 {
        margin-bottom: -8rem;
    }
    .xl\:-ml-80 {
        margin-left: -8rem;
    }
    .xl\:-mt-84 {
        margin-top: -8.4rem;
    }
    .xl\:-mr-84 {
        margin-right: -8.4rem;
    }
    .xl\:-mb-84 {
        margin-bottom: -8.4rem;
    }
    .xl\:-ml-84 {
        margin-left: -8.4rem;
    }
    .xl\:-mt-88 {
        margin-top: -8.8rem;
    }
    .xl\:-mr-88 {
        margin-right: -8.8rem;
    }
    .xl\:-mb-88 {
        margin-bottom: -8.8rem;
    }
    .xl\:-ml-88 {
        margin-left: -8.8rem;
    }
    .xl\:-mt-92 {
        margin-top: -9.2rem;
    }
    .xl\:-mr-92 {
        margin-right: -9.2rem;
    }
    .xl\:-mb-92 {
        margin-bottom: -9.2rem;
    }
    .xl\:-ml-92 {
        margin-left: -9.2rem;
    }
    .xl\:-mt-96 {
        margin-top: -9.6rem;
    }
    .xl\:-mr-96 {
        margin-right: -9.6rem;
    }
    .xl\:-mb-96 {
        margin-bottom: -9.6rem;
    }
    .xl\:-ml-96 {
        margin-left: -9.6rem;
    }
    .xl\:-mt-128 {
        margin-top: -12.8rem;
    }
    .xl\:-mr-128 {
        margin-right: -12.8rem;
    }
    .xl\:-mb-128 {
        margin-bottom: -12.8rem;
    }
    .xl\:-ml-128 {
        margin-left: -12.8rem;
    }
    .xl\:-mt-160 {
        margin-top: -16rem;
    }
    .xl\:-mr-160 {
        margin-right: -16rem;
    }
    .xl\:-mb-160 {
        margin-bottom: -16rem;
    }
    .xl\:-ml-160 {
        margin-left: -16rem;
    }
    .xl\:-mt-192 {
        margin-top: -19.2rem;
    }
    .xl\:-mr-192 {
        margin-right: -19.2rem;
    }
    .xl\:-mb-192 {
        margin-bottom: -19.2rem;
    }
    .xl\:-ml-192 {
        margin-left: -19.2rem;
    }
    .xl\:-mt-224 {
        margin-top: -22.4rem;
    }
    .xl\:-mr-224 {
        margin-right: -22.4rem;
    }
    .xl\:-mb-224 {
        margin-bottom: -22.4rem;
    }
    .xl\:-ml-224 {
        margin-left: -22.4rem;
    }
    .xl\:-mt-256 {
        margin-top: -25.6rem;
    }
    .xl\:-mr-256 {
        margin-right: -25.6rem;
    }
    .xl\:-mb-256 {
        margin-bottom: -25.6rem;
    }
    .xl\:-ml-256 {
        margin-left: -25.6rem;
    }
    .xl\:-mt-px {
        margin-top: -1px;
    }
    .xl\:-mr-px {
        margin-right: -1px;
    }
    .xl\:-mb-px {
        margin-bottom: -1px;
    }
    .xl\:-ml-px {
        margin-left: -1px;
    }
    .xl\:opacity-0 {
        opacity: 0;
    }
    .xl\:opacity-25 {
        opacity: .25;
    }
    .xl\:opacity-50 {
        opacity: .5;
    }
    .xl\:opacity-75 {
        opacity: .75;
    }
    .xl\:opacity-100 {
        opacity: 1;
    }
    .xl\:overflow-auto {
        overflow: auto;
    }
    .xl\:overflow-hidden {
        overflow: hidden;
    }
    .xl\:overflow-visible {
        overflow: visible;
    }
    .xl\:overflow-scroll {
        overflow: scroll;
    }
    .xl\:overflow-x-auto {
        overflow-x: auto;
    }
    .xl\:overflow-y-auto {
        overflow-y: auto;
    }
    .xl\:overflow-x-hidden {
        overflow-x: hidden;
    }
    .xl\:overflow-y-hidden {
        overflow-y: hidden;
    }
    .xl\:overflow-x-visible {
        overflow-x: visible;
    }
    .xl\:overflow-y-visible {
        overflow-y: visible;
    }
    .xl\:overflow-x-scroll {
        overflow-x: scroll;
    }
    .xl\:overflow-y-scroll {
        overflow-y: scroll;
    }
    .xl\:scrolling-touch {
        -webkit-overflow-scrolling: touch;
    }
    .xl\:scrolling-auto {
        -webkit-overflow-scrolling: auto;
    }
    .xl\:p-0 {
        padding: 0;
    }
    .xl\:p-4 {
        padding: .4rem;
    }
    .xl\:p-8 {
        padding: .8rem;
    }
    .xl\:p-12 {
        padding: 1.2rem;
    }
    .xl\:p-16 {
        padding: 1.6rem;
    }
    .xl\:p-20 {
        padding: 2rem;
    }
    .xl\:p-24 {
        padding: 2.4rem;
    }
    .xl\:p-28 {
        padding: 2.8rem;
    }
    .xl\:p-32 {
        padding: 3.2rem;
    }
    .xl\:p-36 {
        padding: 3.6rem;
    }
    .xl\:p-40 {
        padding: 4rem;
    }
    .xl\:p-44 {
        padding: 4.4rem;
    }
    .xl\:p-48 {
        padding: 4.8rem;
    }
    .xl\:p-52 {
        padding: 5.2rem;
    }
    .xl\:p-56 {
        padding: 5.6rem;
    }
    .xl\:p-60 {
        padding: 6rem;
    }
    .xl\:p-64 {
        padding: 6.4rem;
    }
    .xl\:p-68 {
        padding: 6.8rem;
    }
    .xl\:p-72 {
        padding: 7.2rem;
    }
    .xl\:p-76 {
        padding: 7.6rem;
    }
    .xl\:p-80 {
        padding: 8rem;
    }
    .xl\:p-84 {
        padding: 8.4rem;
    }
    .xl\:p-88 {
        padding: 8.8rem;
    }
    .xl\:p-92 {
        padding: 9.2rem;
    }
    .xl\:p-96 {
        padding: 9.6rem;
    }
    .xl\:p-128 {
        padding: 12.8rem;
    }
    .xl\:p-160 {
        padding: 16rem;
    }
    .xl\:p-192 {
        padding: 19.2rem;
    }
    .xl\:p-224 {
        padding: 22.4rem;
    }
    .xl\:p-256 {
        padding: 25.6rem;
    }
    .xl\:p-px {
        padding: 1px;
    }
    .xl\:py-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .xl\:px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .xl\:py-4 {
        padding-top: .4rem;
        padding-bottom: .4rem;
    }
    .xl\:px-4 {
        padding-left: .4rem;
        padding-right: .4rem;
    }
    .xl\:py-8 {
        padding-top: .8rem;
        padding-bottom: .8rem;
    }
    .xl\:px-8 {
        padding-left: .8rem;
        padding-right: .8rem;
    }
    .xl\:py-12 {
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }
    .xl\:px-12 {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
    .xl\:py-16 {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
    .xl\:px-16 {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
    .xl\:py-20 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .xl\:px-20 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .xl\:py-24 {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }
    .xl\:px-24 {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }
    .xl\:py-28 {
        padding-top: 2.8rem;
        padding-bottom: 2.8rem;
    }
    .xl\:px-28 {
        padding-left: 2.8rem;
        padding-right: 2.8rem;
    }
    .xl\:py-32 {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
    }
    .xl\:px-32 {
        padding-left: 3.2rem;
        padding-right: 3.2rem;
    }
    .xl\:py-36 {
        padding-top: 3.6rem;
        padding-bottom: 3.6rem;
    }
    .xl\:px-36 {
        padding-left: 3.6rem;
        padding-right: 3.6rem;
    }
    .xl\:py-40 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .xl\:px-40 {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .xl\:py-44 {
        padding-top: 4.4rem;
        padding-bottom: 4.4rem;
    }
    .xl\:px-44 {
        padding-left: 4.4rem;
        padding-right: 4.4rem;
    }
    .xl\:py-48 {
        padding-top: 4.8rem;
        padding-bottom: 4.8rem;
    }
    .xl\:px-48 {
        padding-left: 4.8rem;
        padding-right: 4.8rem;
    }
    .xl\:py-52 {
        padding-top: 5.2rem;
        padding-bottom: 5.2rem;
    }
    .xl\:px-52 {
        padding-left: 5.2rem;
        padding-right: 5.2rem;
    }
    .xl\:py-56 {
        padding-top: 5.6rem;
        padding-bottom: 5.6rem;
    }
    .xl\:px-56 {
        padding-left: 5.6rem;
        padding-right: 5.6rem;
    }
    .xl\:py-60 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .xl\:px-60 {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .xl\:py-64 {
        padding-top: 6.4rem;
        padding-bottom: 6.4rem;
    }
    .xl\:px-64 {
        padding-left: 6.4rem;
        padding-right: 6.4rem;
    }
    .xl\:py-68 {
        padding-top: 6.8rem;
        padding-bottom: 6.8rem;
    }
    .xl\:px-68 {
        padding-left: 6.8rem;
        padding-right: 6.8rem;
    }
    .xl\:py-72 {
        padding-top: 7.2rem;
        padding-bottom: 7.2rem;
    }
    .xl\:px-72 {
        padding-left: 7.2rem;
        padding-right: 7.2rem;
    }
    .xl\:py-76 {
        padding-top: 7.6rem;
        padding-bottom: 7.6rem;
    }
    .xl\:px-76 {
        padding-left: 7.6rem;
        padding-right: 7.6rem;
    }
    .xl\:py-80 {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    .xl\:px-80 {
        padding-left: 8rem;
        padding-right: 8rem;
    }
    .xl\:py-84 {
        padding-top: 8.4rem;
        padding-bottom: 8.4rem;
    }
    .xl\:px-84 {
        padding-left: 8.4rem;
        padding-right: 8.4rem;
    }
    .xl\:py-88 {
        padding-top: 8.8rem;
        padding-bottom: 8.8rem;
    }
    .xl\:px-88 {
        padding-left: 8.8rem;
        padding-right: 8.8rem;
    }
    .xl\:py-92 {
        padding-top: 9.2rem;
        padding-bottom: 9.2rem;
    }
    .xl\:px-92 {
        padding-left: 9.2rem;
        padding-right: 9.2rem;
    }
    .xl\:py-96 {
        padding-top: 9.6rem;
        padding-bottom: 9.6rem;
    }
    .xl\:px-96 {
        padding-left: 9.6rem;
        padding-right: 9.6rem;
    }
    .xl\:py-128 {
        padding-top: 12.8rem;
        padding-bottom: 12.8rem;
    }
    .xl\:px-128 {
        padding-left: 12.8rem;
        padding-right: 12.8rem;
    }
    .xl\:py-160 {
        padding-top: 16rem;
        padding-bottom: 16rem;
    }
    .xl\:px-160 {
        padding-left: 16rem;
        padding-right: 16rem;
    }
    .xl\:py-192 {
        padding-top: 19.2rem;
        padding-bottom: 19.2rem;
    }
    .xl\:px-192 {
        padding-left: 19.2rem;
        padding-right: 19.2rem;
    }
    .xl\:py-224 {
        padding-top: 22.4rem;
        padding-bottom: 22.4rem;
    }
    .xl\:px-224 {
        padding-left: 22.4rem;
        padding-right: 22.4rem;
    }
    .xl\:py-256 {
        padding-top: 25.6rem;
        padding-bottom: 25.6rem;
    }
    .xl\:px-256 {
        padding-left: 25.6rem;
        padding-right: 25.6rem;
    }
    .xl\:py-px {
        padding-top: 1px;
        padding-bottom: 1px;
    }
    .xl\:px-px {
        padding-left: 1px;
        padding-right: 1px;
    }
    .xl\:pt-0 {
        padding-top: 0;
    }
    .xl\:pr-0 {
        padding-right: 0;
    }
    .xl\:pb-0 {
        padding-bottom: 0;
    }
    .xl\:pl-0 {
        padding-left: 0;
    }
    .xl\:pt-4 {
        padding-top: .4rem;
    }
    .xl\:pr-4 {
        padding-right: .4rem;
    }
    .xl\:pb-4 {
        padding-bottom: .4rem;
    }
    .xl\:pl-4 {
        padding-left: .4rem;
    }
    .xl\:pt-8 {
        padding-top: .8rem;
    }
    .xl\:pr-8 {
        padding-right: .8rem;
    }
    .xl\:pb-8 {
        padding-bottom: .8rem;
    }
    .xl\:pl-8 {
        padding-left: .8rem;
    }
    .xl\:pt-12 {
        padding-top: 1.2rem;
    }
    .xl\:pr-12 {
        padding-right: 1.2rem;
    }
    .xl\:pb-12 {
        padding-bottom: 1.2rem;
    }
    .xl\:pl-12 {
        padding-left: 1.2rem;
    }
    .xl\:pt-16 {
        padding-top: 1.6rem;
    }
    .xl\:pr-16 {
        padding-right: 1.6rem;
    }
    .xl\:pb-16 {
        padding-bottom: 1.6rem;
    }
    .xl\:pl-16 {
        padding-left: 1.6rem;
    }
    .xl\:pt-20 {
        padding-top: 2rem;
    }
    .xl\:pr-20 {
        padding-right: 2rem;
    }
    .xl\:pb-20 {
        padding-bottom: 2rem;
    }
    .xl\:pl-20 {
        padding-left: 2rem;
    }
    .xl\:pt-24 {
        padding-top: 2.4rem;
    }
    .xl\:pr-24 {
        padding-right: 2.4rem;
    }
    .xl\:pb-24 {
        padding-bottom: 2.4rem;
    }
    .xl\:pl-24 {
        padding-left: 2.4rem;
    }
    .xl\:pt-28 {
        padding-top: 2.8rem;
    }
    .xl\:pr-28 {
        padding-right: 2.8rem;
    }
    .xl\:pb-28 {
        padding-bottom: 2.8rem;
    }
    .xl\:pl-28 {
        padding-left: 2.8rem;
    }
    .xl\:pt-32 {
        padding-top: 3.2rem;
    }
    .xl\:pr-32 {
        padding-right: 3.2rem;
    }
    .xl\:pb-32 {
        padding-bottom: 3.2rem;
    }
    .xl\:pl-32 {
        padding-left: 3.2rem;
    }
    .xl\:pt-36 {
        padding-top: 3.6rem;
    }
    .xl\:pr-36 {
        padding-right: 3.6rem;
    }
    .xl\:pb-36 {
        padding-bottom: 3.6rem;
    }
    .xl\:pl-36 {
        padding-left: 3.6rem;
    }
    .xl\:pt-40 {
        padding-top: 4rem;
    }
    .xl\:pr-40 {
        padding-right: 4rem;
    }
    .xl\:pb-40 {
        padding-bottom: 4rem;
    }
    .xl\:pl-40 {
        padding-left: 4rem;
    }
    .xl\:pt-44 {
        padding-top: 4.4rem;
    }
    .xl\:pr-44 {
        padding-right: 4.4rem;
    }
    .xl\:pb-44 {
        padding-bottom: 4.4rem;
    }
    .xl\:pl-44 {
        padding-left: 4.4rem;
    }
    .xl\:pt-48 {
        padding-top: 4.8rem;
    }
    .xl\:pr-48 {
        padding-right: 4.8rem;
    }
    .xl\:pb-48 {
        padding-bottom: 4.8rem;
    }
    .xl\:pl-48 {
        padding-left: 4.8rem;
    }
    .xl\:pt-52 {
        padding-top: 5.2rem;
    }
    .xl\:pr-52 {
        padding-right: 5.2rem;
    }
    .xl\:pb-52 {
        padding-bottom: 5.2rem;
    }
    .xl\:pl-52 {
        padding-left: 5.2rem;
    }
    .xl\:pt-56 {
        padding-top: 5.6rem;
    }
    .xl\:pr-56 {
        padding-right: 5.6rem;
    }
    .xl\:pb-56 {
        padding-bottom: 5.6rem;
    }
    .xl\:pl-56 {
        padding-left: 5.6rem;
    }
    .xl\:pt-60 {
        padding-top: 6rem;
    }
    .xl\:pr-60 {
        padding-right: 6rem;
    }
    .xl\:pb-60 {
        padding-bottom: 6rem;
    }
    .xl\:pl-60 {
        padding-left: 6rem;
    }
    .xl\:pt-64 {
        padding-top: 6.4rem;
    }
    .xl\:pr-64 {
        padding-right: 6.4rem;
    }
    .xl\:pb-64 {
        padding-bottom: 6.4rem;
    }
    .xl\:pl-64 {
        padding-left: 6.4rem;
    }
    .xl\:pt-68 {
        padding-top: 6.8rem;
    }
    .xl\:pr-68 {
        padding-right: 6.8rem;
    }
    .xl\:pb-68 {
        padding-bottom: 6.8rem;
    }
    .xl\:pl-68 {
        padding-left: 6.8rem;
    }
    .xl\:pt-72 {
        padding-top: 7.2rem;
    }
    .xl\:pr-72 {
        padding-right: 7.2rem;
    }
    .xl\:pb-72 {
        padding-bottom: 7.2rem;
    }
    .xl\:pl-72 {
        padding-left: 7.2rem;
    }
    .xl\:pt-76 {
        padding-top: 7.6rem;
    }
    .xl\:pr-76 {
        padding-right: 7.6rem;
    }
    .xl\:pb-76 {
        padding-bottom: 7.6rem;
    }
    .xl\:pl-76 {
        padding-left: 7.6rem;
    }
    .xl\:pt-80 {
        padding-top: 8rem;
    }
    .xl\:pr-80 {
        padding-right: 8rem;
    }
    .xl\:pb-80 {
        padding-bottom: 8rem;
    }
    .xl\:pl-80 {
        padding-left: 8rem;
    }
    .xl\:pt-84 {
        padding-top: 8.4rem;
    }
    .xl\:pr-84 {
        padding-right: 8.4rem;
    }
    .xl\:pb-84 {
        padding-bottom: 8.4rem;
    }
    .xl\:pl-84 {
        padding-left: 8.4rem;
    }
    .xl\:pt-88 {
        padding-top: 8.8rem;
    }
    .xl\:pr-88 {
        padding-right: 8.8rem;
    }
    .xl\:pb-88 {
        padding-bottom: 8.8rem;
    }
    .xl\:pl-88 {
        padding-left: 8.8rem;
    }
    .xl\:pt-92 {
        padding-top: 9.2rem;
    }
    .xl\:pr-92 {
        padding-right: 9.2rem;
    }
    .xl\:pb-92 {
        padding-bottom: 9.2rem;
    }
    .xl\:pl-92 {
        padding-left: 9.2rem;
    }
    .xl\:pt-96 {
        padding-top: 9.6rem;
    }
    .xl\:pr-96 {
        padding-right: 9.6rem;
    }
    .xl\:pb-96 {
        padding-bottom: 9.6rem;
    }
    .xl\:pl-96 {
        padding-left: 9.6rem;
    }
    .xl\:pt-128 {
        padding-top: 12.8rem;
    }
    .xl\:pr-128 {
        padding-right: 12.8rem;
    }
    .xl\:pb-128 {
        padding-bottom: 12.8rem;
    }
    .xl\:pl-128 {
        padding-left: 12.8rem;
    }
    .xl\:pt-160 {
        padding-top: 16rem;
    }
    .xl\:pr-160 {
        padding-right: 16rem;
    }
    .xl\:pb-160 {
        padding-bottom: 16rem;
    }
    .xl\:pl-160 {
        padding-left: 16rem;
    }
    .xl\:pt-192 {
        padding-top: 19.2rem;
    }
    .xl\:pr-192 {
        padding-right: 19.2rem;
    }
    .xl\:pb-192 {
        padding-bottom: 19.2rem;
    }
    .xl\:pl-192 {
        padding-left: 19.2rem;
    }
    .xl\:pt-224 {
        padding-top: 22.4rem;
    }
    .xl\:pr-224 {
        padding-right: 22.4rem;
    }
    .xl\:pb-224 {
        padding-bottom: 22.4rem;
    }
    .xl\:pl-224 {
        padding-left: 22.4rem;
    }
    .xl\:pt-256 {
        padding-top: 25.6rem;
    }
    .xl\:pr-256 {
        padding-right: 25.6rem;
    }
    .xl\:pb-256 {
        padding-bottom: 25.6rem;
    }
    .xl\:pl-256 {
        padding-left: 25.6rem;
    }
    .xl\:pt-px {
        padding-top: 1px;
    }
    .xl\:pr-px {
        padding-right: 1px;
    }
    .xl\:pb-px {
        padding-bottom: 1px;
    }
    .xl\:pl-px {
        padding-left: 1px;
    }
    .xl\:pointer-events-none {
        pointer-events: none;
    }
    .xl\:pointer-events-auto {
        pointer-events: auto;
    }
    .xl\:static {
        position: static;
    }
    .xl\:fixed {
        position: fixed;
    }
    .xl\:absolute {
        position: absolute;
    }
    .xl\:relative {
        position: relative;
    }
    .xl\:sticky {
        position: -webkit-sticky;
        position: sticky;
    }
    .xl\:pin-none {
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }
    .xl\:pin {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .xl\:pin-y {
        top: 0;
        bottom: 0;
    }
    .xl\:pin-x {
        right: 0;
        left: 0;
    }
    .xl\:pin-t {
        top: 0;
    }
    .xl\:pin-r {
        right: 0;
    }
    .xl\:pin-b {
        bottom: 0;
    }
    .xl\:pin-l {
        left: 0;
    }
    .xl\:resize-none {
        resize: none;
    }
    .xl\:resize-y {
        resize: vertical;
    }
    .xl\:resize-x {
        resize: horizontal;
    }
    .xl\:resize {
        resize: both;
    }
    .xl\:shadow {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    }
    .xl\:shadow-md {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
    }
    .xl\:shadow-lg {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    }
    .xl\:shadow-inner {
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
    }
    .xl\:shadow-none {
        box-shadow: none;
    }
    .xl\:text-left {
        text-align: left;
    }
    .xl\:text-center {
        text-align: center;
    }
    .xl\:text-right {
        text-align: right;
    }
    .xl\:text-justify {
        text-align: justify;
    }
    .xl\:text-transparent {
        color: transparent;
    }
    .xl\:text-black {
        color: #22292f;
    }
    .xl\:text-grey-darkest {
        color: #3d4852;
    }
    .xl\:text-grey-darker {
        color: #606f7b;
    }
    .xl\:text-grey-dark {
        color: #8795a1;
    }
    .xl\:text-grey {
        color: #b8c2cc;
    }
    .xl\:text-grey-light {
        color: #dae1e7;
    }
    .xl\:text-grey-lighter {
        color: #f1f5f8;
    }
    .xl\:text-grey-lightest {
        color: #f8fafc;
    }
    .xl\:text-white {
        color: #fff;
    }
    .xl\:text-red-darkest {
        color: #3b0d0c;
    }
    .xl\:text-red-darker {
        color: #621b18;
    }
    .xl\:text-red-dark {
        color: #cc1f1a;
    }
    .xl\:text-red {
        color: #e3342f;
    }
    .xl\:text-red-light {
        color: #ef5753;
    }
    .xl\:text-red-lighter {
        color: #f9acaa;
    }
    .xl\:text-red-lightest {
        color: #fcebea;
    }
    .xl\:text-orange-darkest {
        color: #462a16;
    }
    .xl\:text-orange-darker {
        color: #613b1f;
    }
    .xl\:text-orange-dark {
        color: #de751f;
    }
    .xl\:text-orange {
        color: #f6993f;
    }
    .xl\:text-orange-light {
        color: #faad63;
    }
    .xl\:text-orange-lighter {
        color: #fcd9b6;
    }
    .xl\:text-orange-lightest {
        color: #fff5eb;
    }
    .xl\:text-yellow-darkest {
        color: #453411;
    }
    .xl\:text-yellow-darker {
        color: #684f1d;
    }
    .xl\:text-yellow-dark {
        color: #f2d024;
    }
    .xl\:text-yellow {
        color: #ffed4a;
    }
    .xl\:text-yellow-light {
        color: #fff382;
    }
    .xl\:text-yellow-lighter {
        color: #fff9c2;
    }
    .xl\:text-yellow-lightest {
        color: #fcfbeb;
    }
    .xl\:text-green-darkest {
        color: #0f2f21;
    }
    .xl\:text-green-darker {
        color: #1a4731;
    }
    .xl\:text-green-dark {
        color: #1f9d55;
    }
    .xl\:text-green {
        color: #38c172;
    }
    .xl\:text-green-light {
        color: #51d88a;
    }
    .xl\:text-green-lighter {
        color: #a2f5bf;
    }
    .xl\:text-green-lightest {
        color: #e3fcec;
    }
    .xl\:text-teal-darkest {
        color: #0d3331;
    }
    .xl\:text-teal-darker {
        color: #20504f;
    }
    .xl\:text-teal-dark {
        color: #38a89d;
    }
    .xl\:text-teal {
        color: #4dc0b5;
    }
    .xl\:text-teal-light {
        color: #64d5ca;
    }
    .xl\:text-teal-lighter {
        color: #a0f0ed;
    }
    .xl\:text-teal-lightest {
        color: #e8fffe;
    }
    .xl\:text-blue-darkest {
        color: #12283a;
    }
    .xl\:text-blue-darker {
        color: #1c3d5a;
    }
    .xl\:text-blue-dark {
        color: #2779bd;
    }
    .xl\:text-blue {
        color: #3490dc;
    }
    .xl\:text-blue-light {
        color: #6cb2eb;
    }
    .xl\:text-blue-lighter {
        color: #bcdefa;
    }
    .xl\:text-blue-lightest {
        color: #eff8ff;
    }
    .xl\:text-indigo-darkest {
        color: #191e38;
    }
    .xl\:text-indigo-darker {
        color: #2f365f;
    }
    .xl\:text-indigo-dark {
        color: #5661b3;
    }
    .xl\:text-indigo {
        color: #6574cd;
    }
    .xl\:text-indigo-light {
        color: #7886d7;
    }
    .xl\:text-indigo-lighter {
        color: #b2b7ff;
    }
    .xl\:text-indigo-lightest {
        color: #e6e8ff;
    }
    .xl\:text-purple-darkest {
        color: #21183c;
    }
    .xl\:text-purple-darker {
        color: #382b5f;
    }
    .xl\:text-purple-dark {
        color: #794acf;
    }
    .xl\:text-purple {
        color: #9561e2;
    }
    .xl\:text-purple-light {
        color: #a779e9;
    }
    .xl\:text-purple-lighter {
        color: #d6bbfc;
    }
    .xl\:text-purple-lightest {
        color: #f3ebff;
    }
    .xl\:text-pink-darkest {
        color: #451225;
    }
    .xl\:text-pink-darker {
        color: #6f213f;
    }
    .xl\:text-pink-dark {
        color: #eb5286;
    }
    .xl\:text-pink {
        color: #f66d9b;
    }
    .xl\:text-pink-light {
        color: #fa7ea8;
    }
    .xl\:text-pink-lighter {
        color: #ffbbca;
    }
    .xl\:text-pink-lightest {
        color: #ffebef;
    }
    .xl\:hover\:text-transparent:hover {
        color: transparent;
    }
    .xl\:hover\:text-black:hover {
        color: #22292f;
    }
    .xl\:hover\:text-grey-darkest:hover {
        color: #3d4852;
    }
    .xl\:hover\:text-grey-darker:hover {
        color: #606f7b;
    }
    .xl\:hover\:text-grey-dark:hover {
        color: #8795a1;
    }
    .xl\:hover\:text-grey:hover {
        color: #b8c2cc;
    }
    .xl\:hover\:text-grey-light:hover {
        color: #dae1e7;
    }
    .xl\:hover\:text-grey-lighter:hover {
        color: #f1f5f8;
    }
    .xl\:hover\:text-grey-lightest:hover {
        color: #f8fafc;
    }
    .xl\:hover\:text-white:hover {
        color: #fff;
    }
    .xl\:hover\:text-red-darkest:hover {
        color: #3b0d0c;
    }
    .xl\:hover\:text-red-darker:hover {
        color: #621b18;
    }
    .xl\:hover\:text-red-dark:hover {
        color: #cc1f1a;
    }
    .xl\:hover\:text-red:hover {
        color: #e3342f;
    }
    .xl\:hover\:text-red-light:hover {
        color: #ef5753;
    }
    .xl\:hover\:text-red-lighter:hover {
        color: #f9acaa;
    }
    .xl\:hover\:text-red-lightest:hover {
        color: #fcebea;
    }
    .xl\:hover\:text-orange-darkest:hover {
        color: #462a16;
    }
    .xl\:hover\:text-orange-darker:hover {
        color: #613b1f;
    }
    .xl\:hover\:text-orange-dark:hover {
        color: #de751f;
    }
    .xl\:hover\:text-orange:hover {
        color: #f6993f;
    }
    .xl\:hover\:text-orange-light:hover {
        color: #faad63;
    }
    .xl\:hover\:text-orange-lighter:hover {
        color: #fcd9b6;
    }
    .xl\:hover\:text-orange-lightest:hover {
        color: #fff5eb;
    }
    .xl\:hover\:text-yellow-darkest:hover {
        color: #453411;
    }
    .xl\:hover\:text-yellow-darker:hover {
        color: #684f1d;
    }
    .xl\:hover\:text-yellow-dark:hover {
        color: #f2d024;
    }
    .xl\:hover\:text-yellow:hover {
        color: #ffed4a;
    }
    .xl\:hover\:text-yellow-light:hover {
        color: #fff382;
    }
    .xl\:hover\:text-yellow-lighter:hover {
        color: #fff9c2;
    }
    .xl\:hover\:text-yellow-lightest:hover {
        color: #fcfbeb;
    }
    .xl\:hover\:text-green-darkest:hover {
        color: #0f2f21;
    }
    .xl\:hover\:text-green-darker:hover {
        color: #1a4731;
    }
    .xl\:hover\:text-green-dark:hover {
        color: #1f9d55;
    }
    .xl\:hover\:text-green:hover {
        color: #38c172;
    }
    .xl\:hover\:text-green-light:hover {
        color: #51d88a;
    }
    .xl\:hover\:text-green-lighter:hover {
        color: #a2f5bf;
    }
    .xl\:hover\:text-green-lightest:hover {
        color: #e3fcec;
    }
    .xl\:hover\:text-teal-darkest:hover {
        color: #0d3331;
    }
    .xl\:hover\:text-teal-darker:hover {
        color: #20504f;
    }
    .xl\:hover\:text-teal-dark:hover {
        color: #38a89d;
    }
    .xl\:hover\:text-teal:hover {
        color: #4dc0b5;
    }
    .xl\:hover\:text-teal-light:hover {
        color: #64d5ca;
    }
    .xl\:hover\:text-teal-lighter:hover {
        color: #a0f0ed;
    }
    .xl\:hover\:text-teal-lightest:hover {
        color: #e8fffe;
    }
    .xl\:hover\:text-blue-darkest:hover {
        color: #12283a;
    }
    .xl\:hover\:text-blue-darker:hover {
        color: #1c3d5a;
    }
    .xl\:hover\:text-blue-dark:hover {
        color: #2779bd;
    }
    .xl\:hover\:text-blue:hover {
        color: #3490dc;
    }
    .xl\:hover\:text-blue-light:hover {
        color: #6cb2eb;
    }
    .xl\:hover\:text-blue-lighter:hover {
        color: #bcdefa;
    }
    .xl\:hover\:text-blue-lightest:hover {
        color: #eff8ff;
    }
    .xl\:hover\:text-indigo-darkest:hover {
        color: #191e38;
    }
    .xl\:hover\:text-indigo-darker:hover {
        color: #2f365f;
    }
    .xl\:hover\:text-indigo-dark:hover {
        color: #5661b3;
    }
    .xl\:hover\:text-indigo:hover {
        color: #6574cd;
    }
    .xl\:hover\:text-indigo-light:hover {
        color: #7886d7;
    }
    .xl\:hover\:text-indigo-lighter:hover {
        color: #b2b7ff;
    }
    .xl\:hover\:text-indigo-lightest:hover {
        color: #e6e8ff;
    }
    .xl\:hover\:text-purple-darkest:hover {
        color: #21183c;
    }
    .xl\:hover\:text-purple-darker:hover {
        color: #382b5f;
    }
    .xl\:hover\:text-purple-dark:hover {
        color: #794acf;
    }
    .xl\:hover\:text-purple:hover {
        color: #9561e2;
    }
    .xl\:hover\:text-purple-light:hover {
        color: #a779e9;
    }
    .xl\:hover\:text-purple-lighter:hover {
        color: #d6bbfc;
    }
    .xl\:hover\:text-purple-lightest:hover {
        color: #f3ebff;
    }
    .xl\:hover\:text-pink-darkest:hover {
        color: #451225;
    }
    .xl\:hover\:text-pink-darker:hover {
        color: #6f213f;
    }
    .xl\:hover\:text-pink-dark:hover {
        color: #eb5286;
    }
    .xl\:hover\:text-pink:hover {
        color: #f66d9b;
    }
    .xl\:hover\:text-pink-light:hover {
        color: #fa7ea8;
    }
    .xl\:hover\:text-pink-lighter:hover {
        color: #ffbbca;
    }
    .xl\:hover\:text-pink-lightest:hover {
        color: #ffebef;
    }
    .xl\:text-10 {
        font-size: 1rem;
    }
    .xl\:text-11 {
        font-size: 1.1rem;
    }
    .xl\:text-12 {
        font-size: 1.2rem;
    }
    .xl\:text-13 {
        font-size: 1.3rem;
    }
    .xl\:text-14 {
        font-size: 1.4rem;
    }
    .xl\:text-15 {
        font-size: 1.5rem;
    }
    .xl\:text-16 {
        font-size: 1.6rem;
    }
    .xl\:text-17 {
        font-size: 1.7rem;
    }
    .xl\:text-18 {
        font-size: 1.8rem;
    }
    .xl\:text-19 {
        font-size: 1.9rem;
    }
    .xl\:text-20 {
        font-size: 2rem;
    }
    .xl\:text-24 {
        font-size: 2.4rem;
    }
    .xl\:text-28 {
        font-size: 2.8rem;
    }
    .xl\:text-32 {
        font-size: 3.2rem;
    }
    .xl\:text-36 {
        font-size: 3.6rem;
    }
    .xl\:text-40 {
        font-size: 4rem;
    }
    .xl\:text-44 {
        font-size: 4.4rem;
    }
    .xl\:text-48 {
        font-size: 4.8rem;
    }
    .xl\:text-52 {
        font-size: 5.2rem;
    }
    .xl\:text-56 {
        font-size: 5.6rem;
    }
    .xl\:text-60 {
        font-size: 6rem;
    }
    .xl\:text-64 {
        font-size: 6.4rem;
    }
    .xl\:text-68 {
        font-size: 6.8rem;
    }
    .xl\:text-72 {
        font-size: 7.2rem;
    }
    .xl\:text-96 {
        font-size: 9.6rem;
    }
    .xl\:text-128 {
        font-size: 12.8rem;
    }
    .xl\:text-xs {
        font-size: 1.2rem;
    }
    .xl\:text-sm {
        font-size: 2.4rem;
    }
    .xl\:text-base {
        font-size: 1.6rem;
    }
    .xl\:text-lg {
        font-size: 1.8rem;
    }
    .xl\:text-xl {
        font-size: 2rem;
    }
    .xl\:text-2xl {
        font-size: 2.4rem;
    }
    .xl\:text-3xl {
        font-size: 3rem;
    }
    .xl\:text-4xl {
        font-size: 3.6rem;
    }
    .xl\:text-5xl {
        font-size: 4.8rem;
    }
    .xl\:italic {
        font-style: italic;
    }
    .xl\:roman {
        font-style: normal;
    }
    .xl\:uppercase {
        text-transform: uppercase;
    }
    .xl\:lowercase {
        text-transform: lowercase;
    }
    .xl\:capitalize {
        text-transform: capitalize;
    }
    .xl\:normal-case {
        text-transform: none;
    }
    .xl\:underline {
        text-decoration: underline;
    }
    .xl\:line-through {
        text-decoration: line-through;
    }
    .xl\:no-underline {
        text-decoration: none;
    }
    .xl\:antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .xl\:subpixel-antialiased {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
    .xl\:hover\:italic:hover {
        font-style: italic;
    }
    .xl\:hover\:roman:hover {
        font-style: normal;
    }
    .xl\:hover\:uppercase:hover {
        text-transform: uppercase;
    }
    .xl\:hover\:lowercase:hover {
        text-transform: lowercase;
    }
    .xl\:hover\:capitalize:hover {
        text-transform: capitalize;
    }
    .xl\:hover\:normal-case:hover {
        text-transform: none;
    }
    .xl\:hover\:underline:hover {
        text-decoration: underline;
    }
    .xl\:hover\:line-through:hover {
        text-decoration: line-through;
    }
    .xl\:hover\:no-underline:hover {
        text-decoration: none;
    }
    .xl\:hover\:antialiased:hover {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .xl\:hover\:subpixel-antialiased:hover {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
    .xl\:tracking-tight {
        letter-spacing: -0.05em;
    }
    .xl\:tracking-normal {
        letter-spacing: 0;
    }
    .xl\:tracking-wide {
        letter-spacing: .05em;
    }
    .xl\:select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .xl\:select-text {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
    .xl\:align-baseline {
        vertical-align: baseline;
    }
    .xl\:align-top {
        vertical-align: top;
    }
    .xl\:align-middle {
        vertical-align: middle;
    }
    .xl\:align-bottom {
        vertical-align: bottom;
    }
    .xl\:align-text-top {
        vertical-align: text-top;
    }
    .xl\:align-text-bottom {
        vertical-align: text-bottom;
    }
    .xl\:visible {
        visibility: visible;
    }
    .xl\:invisible {
        visibility: hidden;
    }
    .xl\:whitespace-normal {
        white-space: normal;
    }
    .xl\:whitespace-no-wrap {
        white-space: nowrap;
    }
    .xl\:whitespace-pre {
        white-space: pre;
    }
    .xl\:whitespace-pre-line {
        white-space: pre-line;
    }
    .xl\:whitespace-pre-wrap {
        white-space: pre-wrap;
    }
    .xl\:break-words {
        word-wrap: break-word;
    }
    .xl\:break-normal {
        word-wrap: normal;
    }
    .xl\:truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .xl\:w-0 {
        width: 0;
    }
    .xl\:w-4 {
        width: .4rem;
    }
    .xl\:w-8 {
        width: .8rem;
    }
    .xl\:w-12 {
        width: 1.2rem;
    }
    .xl\:w-16 {
        width: 1.6rem;
    }
    .xl\:w-20 {
        width: 2rem;
    }
    .xl\:w-24 {
        width: 2.4rem;
    }
    .xl\:w-28 {
        width: 2.8rem;
    }
    .xl\:w-32 {
        width: 3.2rem;
    }
    .xl\:w-36 {
        width: 3.6rem;
    }
    .xl\:w-40 {
        width: 4rem;
    }
    .xl\:w-44 {
        width: 4.4rem;
    }
    .xl\:w-48 {
        width: 4.8rem;
    }
    .xl\:w-52 {
        width: 5.2rem;
    }
    .xl\:w-56 {
        width: 5.6rem;
    }
    .xl\:w-60 {
        width: 6rem;
    }
    .xl\:w-64 {
        width: 6.4rem;
    }
    .xl\:w-68 {
        width: 6.8rem;
    }
    .xl\:w-72 {
        width: 7.2rem;
    }
    .xl\:w-76 {
        width: 7.6rem;
    }
    .xl\:w-80 {
        width: 8rem;
    }
    .xl\:w-84 {
        width: 8.4rem;
    }
    .xl\:w-88 {
        width: 8.8rem;
    }
    .xl\:w-92 {
        width: 9.2rem;
    }
    .xl\:w-96 {
        width: 9.6rem;
    }
    .xl\:w-128 {
        width: 12.8rem;
    }
    .xl\:w-160 {
        width: 16rem;
    }
    .xl\:w-192 {
        width: 19.2rem;
    }
    .xl\:w-224 {
        width: 22.4rem;
    }
    .xl\:w-256 {
        width: 25.6rem;
    }
    .xl\:w-288 {
        width: 28.8rem;
    }
    .xl\:w-320 {
        width: 32rem;
    }
    .xl\:w-512 {
        width: 51.2rem;
    }
    .xl\:w-auto {
        width: auto;
    }
    .xl\:w-px {
        width: 1px;
    }
    .xl\:w-1\/2 {
        width: 50%;
    }
    .xl\:w-1\/3 {
        width: 33.33333%;
    }
    .xl\:w-2\/3 {
        width: 66.66667%;
    }
    .xl\:w-1\/4 {
        width: 25%;
    }
    .xl\:w-3\/4 {
        width: 75%;
    }
    .xl\:w-1\/5 {
        width: 20%;
    }
    .xl\:w-2\/5 {
        width: 40%;
    }
    .xl\:w-3\/5 {
        width: 60%;
    }
    .xl\:w-4\/5 {
        width: 80%;
    }
    .xl\:w-1\/6 {
        width: 16.66667%;
    }
    .xl\:w-5\/6 {
        width: 83.33333%;
    }
    .xl\:w-full {
        width: 100%;
    }
    .xl\:w-screen {
        width: 100vw;
    }
    .xl\:z-0 {
        z-index: 0;
    }
    .xl\:z-10 {
        z-index: 10;
    }
    .xl\:z-20 {
        z-index: 20;
    }
    .xl\:z-30 {
        z-index: 30;
    }
    .xl\:z-40 {
        z-index: 40;
    }
    .xl\:z-50 {
        z-index: 50;
    }
    .xl\:z-auto {
        z-index: auto;
    }
}

@media print {
    .print\:list-reset {
        list-style: none;
        padding: 0;
    }
    .print\:appearance-none {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .print\:bg-fixed {
        background-attachment: fixed;
    }
    .print\:bg-local {
        background-attachment: local;
    }
    .print\:bg-scroll {
        background-attachment: scroll;
    }
    .print\:bg-transparent {
        background-color: transparent;
    }
    .print\:bg-black {
        background-color: #22292f;
    }
    .print\:bg-grey-darkest {
        background-color: #3d4852;
    }
    .print\:bg-grey-darker {
        background-color: #606f7b;
    }
    .print\:bg-grey-dark {
        background-color: #8795a1;
    }
    .print\:bg-grey {
        background-color: #b8c2cc;
    }
    .print\:bg-grey-light {
        background-color: #dae1e7;
    }
    .print\:bg-grey-lighter {
        background-color: #f1f5f8;
    }
    .print\:bg-grey-lightest {
        background-color: #f8fafc;
    }
    .print\:bg-white {
        background-color: #fff;
    }
    .print\:bg-red-darkest {
        background-color: #3b0d0c;
    }
    .print\:bg-red-darker {
        background-color: #621b18;
    }
    .print\:bg-red-dark {
        background-color: #cc1f1a;
    }
    .print\:bg-red {
        background-color: #e3342f;
    }
    .print\:bg-red-light {
        background-color: #ef5753;
    }
    .print\:bg-red-lighter {
        background-color: #f9acaa;
    }
    .print\:bg-red-lightest {
        background-color: #fcebea;
    }
    .print\:bg-orange-darkest {
        background-color: #462a16;
    }
    .print\:bg-orange-darker {
        background-color: #613b1f;
    }
    .print\:bg-orange-dark {
        background-color: #de751f;
    }
    .print\:bg-orange {
        background-color: #f6993f;
    }
    .print\:bg-orange-light {
        background-color: #faad63;
    }
    .print\:bg-orange-lighter {
        background-color: #fcd9b6;
    }
    .print\:bg-orange-lightest {
        background-color: #fff5eb;
    }
    .print\:bg-yellow-darkest {
        background-color: #453411;
    }
    .print\:bg-yellow-darker {
        background-color: #684f1d;
    }
    .print\:bg-yellow-dark {
        background-color: #f2d024;
    }
    .print\:bg-yellow {
        background-color: #ffed4a;
    }
    .print\:bg-yellow-light {
        background-color: #fff382;
    }
    .print\:bg-yellow-lighter {
        background-color: #fff9c2;
    }
    .print\:bg-yellow-lightest {
        background-color: #fcfbeb;
    }
    .print\:bg-green-darkest {
        background-color: #0f2f21;
    }
    .print\:bg-green-darker {
        background-color: #1a4731;
    }
    .print\:bg-green-dark {
        background-color: #1f9d55;
    }
    .print\:bg-green {
        background-color: #38c172;
    }
    .print\:bg-green-light {
        background-color: #51d88a;
    }
    .print\:bg-green-lighter {
        background-color: #a2f5bf;
    }
    .print\:bg-green-lightest {
        background-color: #e3fcec;
    }
    .print\:bg-teal-darkest {
        background-color: #0d3331;
    }
    .print\:bg-teal-darker {
        background-color: #20504f;
    }
    .print\:bg-teal-dark {
        background-color: #38a89d;
    }
    .print\:bg-teal {
        background-color: #4dc0b5;
    }
    .print\:bg-teal-light {
        background-color: #64d5ca;
    }
    .print\:bg-teal-lighter {
        background-color: #a0f0ed;
    }
    .print\:bg-teal-lightest {
        background-color: #e8fffe;
    }
    .print\:bg-blue-darkest {
        background-color: #12283a;
    }
    .print\:bg-blue-darker {
        background-color: #1c3d5a;
    }
    .print\:bg-blue-dark {
        background-color: #2779bd;
    }
    .print\:bg-blue {
        background-color: #3490dc;
    }
    .print\:bg-blue-light {
        background-color: #6cb2eb;
    }
    .print\:bg-blue-lighter {
        background-color: #bcdefa;
    }
    .print\:bg-blue-lightest {
        background-color: #eff8ff;
    }
    .print\:bg-indigo-darkest {
        background-color: #191e38;
    }
    .print\:bg-indigo-darker {
        background-color: #2f365f;
    }
    .print\:bg-indigo-dark {
        background-color: #5661b3;
    }
    .print\:bg-indigo {
        background-color: #6574cd;
    }
    .print\:bg-indigo-light {
        background-color: #7886d7;
    }
    .print\:bg-indigo-lighter {
        background-color: #b2b7ff;
    }
    .print\:bg-indigo-lightest {
        background-color: #e6e8ff;
    }
    .print\:bg-purple-darkest {
        background-color: #21183c;
    }
    .print\:bg-purple-darker {
        background-color: #382b5f;
    }
    .print\:bg-purple-dark {
        background-color: #794acf;
    }
    .print\:bg-purple {
        background-color: #9561e2;
    }
    .print\:bg-purple-light {
        background-color: #a779e9;
    }
    .print\:bg-purple-lighter {
        background-color: #d6bbfc;
    }
    .print\:bg-purple-lightest {
        background-color: #f3ebff;
    }
    .print\:bg-pink-darkest {
        background-color: #451225;
    }
    .print\:bg-pink-darker {
        background-color: #6f213f;
    }
    .print\:bg-pink-dark {
        background-color: #eb5286;
    }
    .print\:bg-pink {
        background-color: #f66d9b;
    }
    .print\:bg-pink-light {
        background-color: #fa7ea8;
    }
    .print\:bg-pink-lighter {
        background-color: #ffbbca;
    }
    .print\:bg-pink-lightest {
        background-color: #ffebef;
    }
    .print\:hover\:bg-transparent:hover {
        background-color: transparent;
    }
    .print\:hover\:bg-black:hover {
        background-color: #22292f;
    }
    .print\:hover\:bg-grey-darkest:hover {
        background-color: #3d4852;
    }
    .print\:hover\:bg-grey-darker:hover {
        background-color: #606f7b;
    }
    .print\:hover\:bg-grey-dark:hover {
        background-color: #8795a1;
    }
    .print\:hover\:bg-grey:hover {
        background-color: #b8c2cc;
    }
    .print\:hover\:bg-grey-light:hover {
        background-color: #dae1e7;
    }
    .print\:hover\:bg-grey-lighter:hover {
        background-color: #f1f5f8;
    }
    .print\:hover\:bg-grey-lightest:hover {
        background-color: #f8fafc;
    }
    .print\:hover\:bg-white:hover {
        background-color: #fff;
    }
    .print\:hover\:bg-red-darkest:hover {
        background-color: #3b0d0c;
    }
    .print\:hover\:bg-red-darker:hover {
        background-color: #621b18;
    }
    .print\:hover\:bg-red-dark:hover {
        background-color: #cc1f1a;
    }
    .print\:hover\:bg-red:hover {
        background-color: #e3342f;
    }
    .print\:hover\:bg-red-light:hover {
        background-color: #ef5753;
    }
    .print\:hover\:bg-red-lighter:hover {
        background-color: #f9acaa;
    }
    .print\:hover\:bg-red-lightest:hover {
        background-color: #fcebea;
    }
    .print\:hover\:bg-orange-darkest:hover {
        background-color: #462a16;
    }
    .print\:hover\:bg-orange-darker:hover {
        background-color: #613b1f;
    }
    .print\:hover\:bg-orange-dark:hover {
        background-color: #de751f;
    }
    .print\:hover\:bg-orange:hover {
        background-color: #f6993f;
    }
    .print\:hover\:bg-orange-light:hover {
        background-color: #faad63;
    }
    .print\:hover\:bg-orange-lighter:hover {
        background-color: #fcd9b6;
    }
    .print\:hover\:bg-orange-lightest:hover {
        background-color: #fff5eb;
    }
    .print\:hover\:bg-yellow-darkest:hover {
        background-color: #453411;
    }
    .print\:hover\:bg-yellow-darker:hover {
        background-color: #684f1d;
    }
    .print\:hover\:bg-yellow-dark:hover {
        background-color: #f2d024;
    }
    .print\:hover\:bg-yellow:hover {
        background-color: #ffed4a;
    }
    .print\:hover\:bg-yellow-light:hover {
        background-color: #fff382;
    }
    .print\:hover\:bg-yellow-lighter:hover {
        background-color: #fff9c2;
    }
    .print\:hover\:bg-yellow-lightest:hover {
        background-color: #fcfbeb;
    }
    .print\:hover\:bg-green-darkest:hover {
        background-color: #0f2f21;
    }
    .print\:hover\:bg-green-darker:hover {
        background-color: #1a4731;
    }
    .print\:hover\:bg-green-dark:hover {
        background-color: #1f9d55;
    }
    .print\:hover\:bg-green:hover {
        background-color: #38c172;
    }
    .print\:hover\:bg-green-light:hover {
        background-color: #51d88a;
    }
    .print\:hover\:bg-green-lighter:hover {
        background-color: #a2f5bf;
    }
    .print\:hover\:bg-green-lightest:hover {
        background-color: #e3fcec;
    }
    .print\:hover\:bg-teal-darkest:hover {
        background-color: #0d3331;
    }
    .print\:hover\:bg-teal-darker:hover {
        background-color: #20504f;
    }
    .print\:hover\:bg-teal-dark:hover {
        background-color: #38a89d;
    }
    .print\:hover\:bg-teal:hover {
        background-color: #4dc0b5;
    }
    .print\:hover\:bg-teal-light:hover {
        background-color: #64d5ca;
    }
    .print\:hover\:bg-teal-lighter:hover {
        background-color: #a0f0ed;
    }
    .print\:hover\:bg-teal-lightest:hover {
        background-color: #e8fffe;
    }
    .print\:hover\:bg-blue-darkest:hover {
        background-color: #12283a;
    }
    .print\:hover\:bg-blue-darker:hover {
        background-color: #1c3d5a;
    }
    .print\:hover\:bg-blue-dark:hover {
        background-color: #2779bd;
    }
    .print\:hover\:bg-blue:hover {
        background-color: #3490dc;
    }
    .print\:hover\:bg-blue-light:hover {
        background-color: #6cb2eb;
    }
    .print\:hover\:bg-blue-lighter:hover {
        background-color: #bcdefa;
    }
    .print\:hover\:bg-blue-lightest:hover {
        background-color: #eff8ff;
    }
    .print\:hover\:bg-indigo-darkest:hover {
        background-color: #191e38;
    }
    .print\:hover\:bg-indigo-darker:hover {
        background-color: #2f365f;
    }
    .print\:hover\:bg-indigo-dark:hover {
        background-color: #5661b3;
    }
    .print\:hover\:bg-indigo:hover {
        background-color: #6574cd;
    }
    .print\:hover\:bg-indigo-light:hover {
        background-color: #7886d7;
    }
    .print\:hover\:bg-indigo-lighter:hover {
        background-color: #b2b7ff;
    }
    .print\:hover\:bg-indigo-lightest:hover {
        background-color: #e6e8ff;
    }
    .print\:hover\:bg-purple-darkest:hover {
        background-color: #21183c;
    }
    .print\:hover\:bg-purple-darker:hover {
        background-color: #382b5f;
    }
    .print\:hover\:bg-purple-dark:hover {
        background-color: #794acf;
    }
    .print\:hover\:bg-purple:hover {
        background-color: #9561e2;
    }
    .print\:hover\:bg-purple-light:hover {
        background-color: #a779e9;
    }
    .print\:hover\:bg-purple-lighter:hover {
        background-color: #d6bbfc;
    }
    .print\:hover\:bg-purple-lightest:hover {
        background-color: #f3ebff;
    }
    .print\:hover\:bg-pink-darkest:hover {
        background-color: #451225;
    }
    .print\:hover\:bg-pink-darker:hover {
        background-color: #6f213f;
    }
    .print\:hover\:bg-pink-dark:hover {
        background-color: #eb5286;
    }
    .print\:hover\:bg-pink:hover {
        background-color: #f66d9b;
    }
    .print\:hover\:bg-pink-light:hover {
        background-color: #fa7ea8;
    }
    .print\:hover\:bg-pink-lighter:hover {
        background-color: #ffbbca;
    }
    .print\:hover\:bg-pink-lightest:hover {
        background-color: #ffebef;
    }
    .print\:bg-bottom {
        background-position: bottom;
    }
    .print\:bg-center {
        background-position: center;
    }
    .print\:bg-left {
        background-position: left;
    }
    .print\:bg-left-bottom {
        background-position: left bottom;
    }
    .print\:bg-left-top {
        background-position: left top;
    }
    .print\:bg-right {
        background-position: right;
    }
    .print\:bg-right-bottom {
        background-position: right bottom;
    }
    .print\:bg-right-top {
        background-position: right top;
    }
    .print\:bg-top {
        background-position: top;
    }
    .print\:bg-repeat {
        background-repeat: repeat;
    }
    .print\:bg-no-repeat {
        background-repeat: no-repeat;
    }
    .print\:bg-repeat-x {
        background-repeat: repeat-x;
    }
    .print\:bg-repeat-y {
        background-repeat: repeat-y;
    }
    .print\:bg-auto {
        background-size: auto;
    }
    .print\:bg-cover {
        background-size: cover;
    }
    .print\:bg-contain {
        background-size: contain;
    }
    .print\:border-transparent {
        border-color: transparent;
    }
    .print\:border-black {
        border-color: #22292f;
    }
    .print\:border-grey-darkest {
        border-color: #3d4852;
    }
    .print\:border-grey-darker {
        border-color: #606f7b;
    }
    .print\:border-grey-dark {
        border-color: #8795a1;
    }
    .print\:border-grey {
        border-color: #b8c2cc;
    }
    .print\:border-grey-light {
        border-color: #dae1e7;
    }
    .print\:border-grey-lighter {
        border-color: #f1f5f8;
    }
    .print\:border-grey-lightest {
        border-color: #f8fafc;
    }
    .print\:border-white {
        border-color: #fff;
    }
    .print\:border-red-darkest {
        border-color: #3b0d0c;
    }
    .print\:border-red-darker {
        border-color: #621b18;
    }
    .print\:border-red-dark {
        border-color: #cc1f1a;
    }
    .print\:border-red {
        border-color: #e3342f;
    }
    .print\:border-red-light {
        border-color: #ef5753;
    }
    .print\:border-red-lighter {
        border-color: #f9acaa;
    }
    .print\:border-red-lightest {
        border-color: #fcebea;
    }
    .print\:border-orange-darkest {
        border-color: #462a16;
    }
    .print\:border-orange-darker {
        border-color: #613b1f;
    }
    .print\:border-orange-dark {
        border-color: #de751f;
    }
    .print\:border-orange {
        border-color: #f6993f;
    }
    .print\:border-orange-light {
        border-color: #faad63;
    }
    .print\:border-orange-lighter {
        border-color: #fcd9b6;
    }
    .print\:border-orange-lightest {
        border-color: #fff5eb;
    }
    .print\:border-yellow-darkest {
        border-color: #453411;
    }
    .print\:border-yellow-darker {
        border-color: #684f1d;
    }
    .print\:border-yellow-dark {
        border-color: #f2d024;
    }
    .print\:border-yellow {
        border-color: #ffed4a;
    }
    .print\:border-yellow-light {
        border-color: #fff382;
    }
    .print\:border-yellow-lighter {
        border-color: #fff9c2;
    }
    .print\:border-yellow-lightest {
        border-color: #fcfbeb;
    }
    .print\:border-green-darkest {
        border-color: #0f2f21;
    }
    .print\:border-green-darker {
        border-color: #1a4731;
    }
    .print\:border-green-dark {
        border-color: #1f9d55;
    }
    .print\:border-green {
        border-color: #38c172;
    }
    .print\:border-green-light {
        border-color: #51d88a;
    }
    .print\:border-green-lighter {
        border-color: #a2f5bf;
    }
    .print\:border-green-lightest {
        border-color: #e3fcec;
    }
    .print\:border-teal-darkest {
        border-color: #0d3331;
    }
    .print\:border-teal-darker {
        border-color: #20504f;
    }
    .print\:border-teal-dark {
        border-color: #38a89d;
    }
    .print\:border-teal {
        border-color: #4dc0b5;
    }
    .print\:border-teal-light {
        border-color: #64d5ca;
    }
    .print\:border-teal-lighter {
        border-color: #a0f0ed;
    }
    .print\:border-teal-lightest {
        border-color: #e8fffe;
    }
    .print\:border-blue-darkest {
        border-color: #12283a;
    }
    .print\:border-blue-darker {
        border-color: #1c3d5a;
    }
    .print\:border-blue-dark {
        border-color: #2779bd;
    }
    .print\:border-blue {
        border-color: #3490dc;
    }
    .print\:border-blue-light {
        border-color: #6cb2eb;
    }
    .print\:border-blue-lighter {
        border-color: #bcdefa;
    }
    .print\:border-blue-lightest {
        border-color: #eff8ff;
    }
    .print\:border-indigo-darkest {
        border-color: #191e38;
    }
    .print\:border-indigo-darker {
        border-color: #2f365f;
    }
    .print\:border-indigo-dark {
        border-color: #5661b3;
    }
    .print\:border-indigo {
        border-color: #6574cd;
    }
    .print\:border-indigo-light {
        border-color: #7886d7;
    }
    .print\:border-indigo-lighter {
        border-color: #b2b7ff;
    }
    .print\:border-indigo-lightest {
        border-color: #e6e8ff;
    }
    .print\:border-purple-darkest {
        border-color: #21183c;
    }
    .print\:border-purple-darker {
        border-color: #382b5f;
    }
    .print\:border-purple-dark {
        border-color: #794acf;
    }
    .print\:border-purple {
        border-color: #9561e2;
    }
    .print\:border-purple-light {
        border-color: #a779e9;
    }
    .print\:border-purple-lighter {
        border-color: #d6bbfc;
    }
    .print\:border-purple-lightest {
        border-color: #f3ebff;
    }
    .print\:border-pink-darkest {
        border-color: #451225;
    }
    .print\:border-pink-darker {
        border-color: #6f213f;
    }
    .print\:border-pink-dark {
        border-color: #eb5286;
    }
    .print\:border-pink {
        border-color: #f66d9b;
    }
    .print\:border-pink-light {
        border-color: #fa7ea8;
    }
    .print\:border-pink-lighter {
        border-color: #ffbbca;
    }
    .print\:border-pink-lightest {
        border-color: #ffebef;
    }
    .print\:hover\:border-transparent:hover {
        border-color: transparent;
    }
    .print\:hover\:border-black:hover {
        border-color: #22292f;
    }
    .print\:hover\:border-grey-darkest:hover {
        border-color: #3d4852;
    }
    .print\:hover\:border-grey-darker:hover {
        border-color: #606f7b;
    }
    .print\:hover\:border-grey-dark:hover {
        border-color: #8795a1;
    }
    .print\:hover\:border-grey:hover {
        border-color: #b8c2cc;
    }
    .print\:hover\:border-grey-light:hover {
        border-color: #dae1e7;
    }
    .print\:hover\:border-grey-lighter:hover {
        border-color: #f1f5f8;
    }
    .print\:hover\:border-grey-lightest:hover {
        border-color: #f8fafc;
    }
    .print\:hover\:border-white:hover {
        border-color: #fff;
    }
    .print\:hover\:border-red-darkest:hover {
        border-color: #3b0d0c;
    }
    .print\:hover\:border-red-darker:hover {
        border-color: #621b18;
    }
    .print\:hover\:border-red-dark:hover {
        border-color: #cc1f1a;
    }
    .print\:hover\:border-red:hover {
        border-color: #e3342f;
    }
    .print\:hover\:border-red-light:hover {
        border-color: #ef5753;
    }
    .print\:hover\:border-red-lighter:hover {
        border-color: #f9acaa;
    }
    .print\:hover\:border-red-lightest:hover {
        border-color: #fcebea;
    }
    .print\:hover\:border-orange-darkest:hover {
        border-color: #462a16;
    }
    .print\:hover\:border-orange-darker:hover {
        border-color: #613b1f;
    }
    .print\:hover\:border-orange-dark:hover {
        border-color: #de751f;
    }
    .print\:hover\:border-orange:hover {
        border-color: #f6993f;
    }
    .print\:hover\:border-orange-light:hover {
        border-color: #faad63;
    }
    .print\:hover\:border-orange-lighter:hover {
        border-color: #fcd9b6;
    }
    .print\:hover\:border-orange-lightest:hover {
        border-color: #fff5eb;
    }
    .print\:hover\:border-yellow-darkest:hover {
        border-color: #453411;
    }
    .print\:hover\:border-yellow-darker:hover {
        border-color: #684f1d;
    }
    .print\:hover\:border-yellow-dark:hover {
        border-color: #f2d024;
    }
    .print\:hover\:border-yellow:hover {
        border-color: #ffed4a;
    }
    .print\:hover\:border-yellow-light:hover {
        border-color: #fff382;
    }
    .print\:hover\:border-yellow-lighter:hover {
        border-color: #fff9c2;
    }
    .print\:hover\:border-yellow-lightest:hover {
        border-color: #fcfbeb;
    }
    .print\:hover\:border-green-darkest:hover {
        border-color: #0f2f21;
    }
    .print\:hover\:border-green-darker:hover {
        border-color: #1a4731;
    }
    .print\:hover\:border-green-dark:hover {
        border-color: #1f9d55;
    }
    .print\:hover\:border-green:hover {
        border-color: #38c172;
    }
    .print\:hover\:border-green-light:hover {
        border-color: #51d88a;
    }
    .print\:hover\:border-green-lighter:hover {
        border-color: #a2f5bf;
    }
    .print\:hover\:border-green-lightest:hover {
        border-color: #e3fcec;
    }
    .print\:hover\:border-teal-darkest:hover {
        border-color: #0d3331;
    }
    .print\:hover\:border-teal-darker:hover {
        border-color: #20504f;
    }
    .print\:hover\:border-teal-dark:hover {
        border-color: #38a89d;
    }
    .print\:hover\:border-teal:hover {
        border-color: #4dc0b5;
    }
    .print\:hover\:border-teal-light:hover {
        border-color: #64d5ca;
    }
    .print\:hover\:border-teal-lighter:hover {
        border-color: #a0f0ed;
    }
    .print\:hover\:border-teal-lightest:hover {
        border-color: #e8fffe;
    }
    .print\:hover\:border-blue-darkest:hover {
        border-color: #12283a;
    }
    .print\:hover\:border-blue-darker:hover {
        border-color: #1c3d5a;
    }
    .print\:hover\:border-blue-dark:hover {
        border-color: #2779bd;
    }
    .print\:hover\:border-blue:hover {
        border-color: #3490dc;
    }
    .print\:hover\:border-blue-light:hover {
        border-color: #6cb2eb;
    }
    .print\:hover\:border-blue-lighter:hover {
        border-color: #bcdefa;
    }
    .print\:hover\:border-blue-lightest:hover {
        border-color: #eff8ff;
    }
    .print\:hover\:border-indigo-darkest:hover {
        border-color: #191e38;
    }
    .print\:hover\:border-indigo-darker:hover {
        border-color: #2f365f;
    }
    .print\:hover\:border-indigo-dark:hover {
        border-color: #5661b3;
    }
    .print\:hover\:border-indigo:hover {
        border-color: #6574cd;
    }
    .print\:hover\:border-indigo-light:hover {
        border-color: #7886d7;
    }
    .print\:hover\:border-indigo-lighter:hover {
        border-color: #b2b7ff;
    }
    .print\:hover\:border-indigo-lightest:hover {
        border-color: #e6e8ff;
    }
    .print\:hover\:border-purple-darkest:hover {
        border-color: #21183c;
    }
    .print\:hover\:border-purple-darker:hover {
        border-color: #382b5f;
    }
    .print\:hover\:border-purple-dark:hover {
        border-color: #794acf;
    }
    .print\:hover\:border-purple:hover {
        border-color: #9561e2;
    }
    .print\:hover\:border-purple-light:hover {
        border-color: #a779e9;
    }
    .print\:hover\:border-purple-lighter:hover {
        border-color: #d6bbfc;
    }
    .print\:hover\:border-purple-lightest:hover {
        border-color: #f3ebff;
    }
    .print\:hover\:border-pink-darkest:hover {
        border-color: #451225;
    }
    .print\:hover\:border-pink-darker:hover {
        border-color: #6f213f;
    }
    .print\:hover\:border-pink-dark:hover {
        border-color: #eb5286;
    }
    .print\:hover\:border-pink:hover {
        border-color: #f66d9b;
    }
    .print\:hover\:border-pink-light:hover {
        border-color: #fa7ea8;
    }
    .print\:hover\:border-pink-lighter:hover {
        border-color: #ffbbca;
    }
    .print\:hover\:border-pink-lightest:hover {
        border-color: #ffebef;
    }
    .print\:rounded-none {
        border-radius: 0;
    }
    .print\:rounded-sm {
        border-radius: .2rem;
    }
    .print\:rounded {
        border-radius: .4rem;
    }
    .print\:rounded-lg {
        border-radius: .8rem;
    }
    .print\:rounded-full {
        border-radius: 9999px;
    }
    .print\:rounded-t-none {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .print\:rounded-r-none {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .print\:rounded-b-none {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    .print\:rounded-l-none {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .print\:rounded-t-sm {
        border-top-left-radius: .2rem;
        border-top-right-radius: .2rem;
    }
    .print\:rounded-r-sm {
        border-top-right-radius: .2rem;
        border-bottom-right-radius: .2rem;
    }
    .print\:rounded-b-sm {
        border-bottom-right-radius: .2rem;
        border-bottom-left-radius: .2rem;
    }
    .print\:rounded-l-sm {
        border-top-left-radius: .2rem;
        border-bottom-left-radius: .2rem;
    }
    .print\:rounded-t {
        border-top-left-radius: .4rem;
        border-top-right-radius: .4rem;
    }
    .print\:rounded-r {
        border-top-right-radius: .4rem;
        border-bottom-right-radius: .4rem;
    }
    .print\:rounded-b {
        border-bottom-right-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }
    .print\:rounded-l {
        border-top-left-radius: .4rem;
        border-bottom-left-radius: .4rem;
    }
    .print\:rounded-t-lg {
        border-top-left-radius: .8rem;
        border-top-right-radius: .8rem;
    }
    .print\:rounded-r-lg {
        border-top-right-radius: .8rem;
        border-bottom-right-radius: .8rem;
    }
    .print\:rounded-b-lg {
        border-bottom-right-radius: .8rem;
        border-bottom-left-radius: .8rem;
    }
    .print\:rounded-l-lg {
        border-top-left-radius: .8rem;
        border-bottom-left-radius: .8rem;
    }
    .print\:rounded-t-full {
        border-top-left-radius: 9999px;
        border-top-right-radius: 9999px;
    }
    .print\:rounded-r-full {
        border-top-right-radius: 9999px;
        border-bottom-right-radius: 9999px;
    }
    .print\:rounded-b-full {
        border-bottom-right-radius: 9999px;
        border-bottom-left-radius: 9999px;
    }
    .print\:rounded-l-full {
        border-top-left-radius: 9999px;
        border-bottom-left-radius: 9999px;
    }
    .print\:rounded-tl-none {
        border-top-left-radius: 0;
    }
    .print\:rounded-tr-none {
        border-top-right-radius: 0;
    }
    .print\:rounded-br-none {
        border-bottom-right-radius: 0;
    }
    .print\:rounded-bl-none {
        border-bottom-left-radius: 0;
    }
    .print\:rounded-tl-sm {
        border-top-left-radius: .2rem;
    }
    .print\:rounded-tr-sm {
        border-top-right-radius: .2rem;
    }
    .print\:rounded-br-sm {
        border-bottom-right-radius: .2rem;
    }
    .print\:rounded-bl-sm {
        border-bottom-left-radius: .2rem;
    }
    .print\:rounded-tl {
        border-top-left-radius: .4rem;
    }
    .print\:rounded-tr {
        border-top-right-radius: .4rem;
    }
    .print\:rounded-br {
        border-bottom-right-radius: .4rem;
    }
    .print\:rounded-bl {
        border-bottom-left-radius: .4rem;
    }
    .print\:rounded-tl-lg {
        border-top-left-radius: .8rem;
    }
    .print\:rounded-tr-lg {
        border-top-right-radius: .8rem;
    }
    .print\:rounded-br-lg {
        border-bottom-right-radius: .8rem;
    }
    .print\:rounded-bl-lg {
        border-bottom-left-radius: .8rem;
    }
    .print\:rounded-tl-full {
        border-top-left-radius: 9999px;
    }
    .print\:rounded-tr-full {
        border-top-right-radius: 9999px;
    }
    .print\:rounded-br-full {
        border-bottom-right-radius: 9999px;
    }
    .print\:rounded-bl-full {
        border-bottom-left-radius: 9999px;
    }
    .print\:border-solid {
        border-style: solid;
    }
    .print\:border-dashed {
        border-style: dashed;
    }
    .print\:border-dotted {
        border-style: dotted;
    }
    .print\:border-none {
        border-style: none;
    }
    .print\:border-0 {
        border-width: 0;
    }
    .print\:border-1 {
        border-width: 1px;
    }
    .print\:border-2 {
        border-width: 2px;
    }
    .print\:border-3 {
        border-width: 3px;
    }
    .print\:border-4 {
        border-width: 4px;
    }
    .print\:border-8 {
        border-width: 8px;
    }
    .print\:border {
        border-width: 1px;
    }
    .print\:border-t-0 {
        border-top-width: 0;
    }
    .print\:border-r-0 {
        border-right-width: 0;
    }
    .print\:border-b-0 {
        border-bottom-width: 0;
    }
    .print\:border-l-0 {
        border-left-width: 0;
    }
    .print\:border-t-1 {
        border-top-width: 1px;
    }
    .print\:border-r-1 {
        border-right-width: 1px;
    }
    .print\:border-b-1 {
        border-bottom-width: 1px;
    }
    .print\:border-l-1 {
        border-left-width: 1px;
    }
    .print\:border-t-2 {
        border-top-width: 2px;
    }
    .print\:border-r-2 {
        border-right-width: 2px;
    }
    .print\:border-b-2 {
        border-bottom-width: 2px;
    }
    .print\:border-l-2 {
        border-left-width: 2px;
    }
    .print\:border-t-3 {
        border-top-width: 3px;
    }
    .print\:border-r-3 {
        border-right-width: 3px;
    }
    .print\:border-b-3 {
        border-bottom-width: 3px;
    }
    .print\:border-l-3 {
        border-left-width: 3px;
    }
    .print\:border-t-4 {
        border-top-width: 4px;
    }
    .print\:border-r-4 {
        border-right-width: 4px;
    }
    .print\:border-b-4 {
        border-bottom-width: 4px;
    }
    .print\:border-l-4 {
        border-left-width: 4px;
    }
    .print\:border-t-8 {
        border-top-width: 8px;
    }
    .print\:border-r-8 {
        border-right-width: 8px;
    }
    .print\:border-b-8 {
        border-bottom-width: 8px;
    }
    .print\:border-l-8 {
        border-left-width: 8px;
    }
    .print\:border-t {
        border-top-width: 1px;
    }
    .print\:border-r {
        border-right-width: 1px;
    }
    .print\:border-b {
        border-bottom-width: 1px;
    }
    .print\:border-l {
        border-left-width: 1px;
    }
    .print\:cursor-auto {
        cursor: auto;
    }
    .print\:cursor-default {
        cursor: default;
    }
    .print\:cursor-pointer {
        cursor: pointer;
    }
    .print\:cursor-wait {
        cursor: wait;
    }
    .print\:cursor-move {
        cursor: move;
    }
    .print\:cursor-not-allowed {
        cursor: not-allowed;
    }
    .print\:block {
        display: block;
    }
    .print\:inline-block {
        display: inline-block;
    }
    .print\:inline {
        display: inline;
    }
    .print\:table {
        display: table;
    }
    .print\:table-row {
        display: table-row;
    }
    .print\:table-cell {
        display: table-cell;
    }
    .print\:hidden {
        display: none;
    }
    .print\:flex {
        display: flex;
    }
    .print\:inline-flex {
        display: inline-flex;
    }
    .print\:flex-row {
        flex-direction: row;
    }
    .print\:flex-row-reverse {
        flex-direction: row-reverse;
    }
    .print\:flex-col {
        flex-direction: column;
    }
    .print\:flex-col-reverse {
        flex-direction: column-reverse;
    }
    .print\:flex-wrap {
        flex-wrap: wrap;
    }
    .print\:flex-wrap-reverse {
        flex-wrap: wrap-reverse;
    }
    .print\:flex-no-wrap {
        flex-wrap: nowrap;
    }
    .print\:items-start {
        align-items: flex-start;
    }
    .print\:items-end {
        align-items: flex-end;
    }
    .print\:items-center {
        align-items: center;
    }
    .print\:items-baseline {
        align-items: baseline;
    }
    .print\:items-stretch {
        align-items: stretch;
    }
    .print\:self-auto {
        align-self: auto;
    }
    .print\:self-start {
        align-self: flex-start;
    }
    .print\:self-end {
        align-self: flex-end;
    }
    .print\:self-center {
        align-self: center;
    }
    .print\:self-stretch {
        align-self: stretch;
    }
    .print\:justify-start {
        justify-content: flex-start;
    }
    .print\:justify-end {
        justify-content: flex-end;
    }
    .print\:justify-center {
        justify-content: center;
    }
    .print\:justify-between {
        justify-content: space-between;
    }
    .print\:justify-around {
        justify-content: space-around;
    }
    .print\:content-center {
        align-content: center;
    }
    .print\:content-start {
        align-content: flex-start;
    }
    .print\:content-end {
        align-content: flex-end;
    }
    .print\:content-between {
        align-content: space-between;
    }
    .print\:content-around {
        align-content: space-around;
    }
    .print\:flex-1 {
        flex: 1;
    }
    .print\:flex-auto {
        flex: auto;
    }
    .print\:flex-initial {
        flex: initial;
    }
    .print\:flex-none {
        flex: none;
    }
    .print\:flex-grow {
        flex-grow: 1;
    }
    .print\:flex-shrink {
        flex-shrink: 1;
    }
    .print\:flex-no-grow {
        flex-grow: 0;
    }
    .print\:flex-no-shrink {
        flex-shrink: 0;
    }
    .print\:float-right {
        float: right;
    }
    .print\:float-left {
        float: left;
    }
    .print\:float-none {
        float: none;
    }
    .print\:clearfix:after {
        content: "";
        display: table;
        clear: both;
    }
    .print\:font-sans {
        font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    }
    .print\:font-serif {
        font-family: Constantia, Lucida Bright, Lucidabright, Lucida Serif, Lucida, DejaVu Serif, Bitstream Vera Serif, Liberation Serif, Georgia, serif;
    }
    .print\:font-mono {
        font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    }
    .print\:font-100 {
        font-weight: 100;
    }
    .print\:font-200 {
        font-weight: 200;
    }
    .print\:font-300 {
        font-weight: 300;
    }
    .print\:font-400 {
        font-weight: 400;
    }
    .print\:font-500 {
        font-weight: 500;
    }
    .print\:font-600 {
        font-weight: 600;
    }
    .print\:font-700 {
        font-weight: 700;
    }
    .print\:font-800 {
        font-weight: 800;
    }
    .print\:font-900 {
        font-weight: 900;
    }
    .print\:font-hairline {
        font-weight: 100;
    }
    .print\:font-thin {
        font-weight: 200;
    }
    .print\:font-light {
        font-weight: 300;
    }
    .print\:font-normal {
        font-weight: 400;
    }
    .print\:font-medium {
        font-weight: 500;
    }
    .print\:font-semibold {
        font-weight: 600;
    }
    .print\:font-bold {
        font-weight: 700;
    }
    .print\:font-extrabold {
        font-weight: 800;
    }
    .print\:font-black {
        font-weight: 900;
    }
    .print\:hover\:font-100:hover {
        font-weight: 100;
    }
    .print\:hover\:font-200:hover {
        font-weight: 200;
    }
    .print\:hover\:font-300:hover {
        font-weight: 300;
    }
    .print\:hover\:font-400:hover {
        font-weight: 400;
    }
    .print\:hover\:font-500:hover {
        font-weight: 500;
    }
    .print\:hover\:font-600:hover {
        font-weight: 600;
    }
    .print\:hover\:font-700:hover {
        font-weight: 700;
    }
    .print\:hover\:font-800:hover {
        font-weight: 800;
    }
    .print\:hover\:font-900:hover {
        font-weight: 900;
    }
    .print\:hover\:font-hairline:hover {
        font-weight: 100;
    }
    .print\:hover\:font-thin:hover {
        font-weight: 200;
    }
    .print\:hover\:font-light:hover {
        font-weight: 300;
    }
    .print\:hover\:font-normal:hover {
        font-weight: 400;
    }
    .print\:hover\:font-medium:hover {
        font-weight: 500;
    }
    .print\:hover\:font-semibold:hover {
        font-weight: 600;
    }
    .print\:hover\:font-bold:hover {
        font-weight: 700;
    }
    .print\:hover\:font-extrabold:hover {
        font-weight: 800;
    }
    .print\:hover\:font-black:hover {
        font-weight: 900;
    }
    .print\:h-4 {
        height: .4rem;
    }
    .print\:h-8 {
        height: .8rem;
    }
    .print\:h-12 {
        height: 1.2rem;
    }
    .print\:h-16 {
        height: 1.6rem;
    }
    .print\:h-20 {
        height: 2rem;
    }
    .print\:h-24 {
        height: 2.4rem;
    }
    .print\:h-28 {
        height: 2.8rem;
    }
    .print\:h-32 {
        height: 3.2rem;
    }
    .print\:h-36 {
        height: 3.6rem;
    }
    .print\:h-40 {
        height: 4rem;
    }
    .print\:h-44 {
        height: 4.4rem;
    }
    .print\:h-48 {
        height: 4.8rem;
    }
    .print\:h-52 {
        height: 5.2rem;
    }
    .print\:h-56 {
        height: 5.6rem;
    }
    .print\:h-60 {
        height: 6rem;
    }
    .print\:h-64 {
        height: 6.4rem;
    }
    .print\:h-68 {
        height: 6.8rem;
    }
    .print\:h-72 {
        height: 7.2rem;
    }
    .print\:h-76 {
        height: 7.6rem;
    }
    .print\:h-80 {
        height: 8rem;
    }
    .print\:h-84 {
        height: 8.4rem;
    }
    .print\:h-88 {
        height: 8.8rem;
    }
    .print\:h-92 {
        height: 9.2rem;
    }
    .print\:h-96 {
        height: 9.6rem;
    }
    .print\:h-128 {
        height: 12.8rem;
    }
    .print\:h-160 {
        height: 16rem;
    }
    .print\:h-192 {
        height: 19.2rem;
    }
    .print\:h-224 {
        height: 22.4rem;
    }
    .print\:h-256 {
        height: 25.6rem;
    }
    .print\:h-288 {
        height: 28.8rem;
    }
    .print\:h-320 {
        height: 32rem;
    }
    .print\:h-512 {
        height: 51.2rem;
    }
    .print\:h-auto {
        height: auto;
    }
    .print\:h-px {
        height: 1px;
    }
    .print\:h-full {
        height: 100%;
    }
    .print\:h-screen {
        height: 100vh;
    }
    .print\:leading-none {
        line-height: 1;
    }
    .print\:leading-tight {
        line-height: 1.25;
    }
    .print\:leading-normal {
        line-height: 1.5;
    }
    .print\:leading-loose {
        line-height: 2;
    }
    .print\:m-0 {
        margin: 0;
    }
    .print\:m-4 {
        margin: .4rem;
    }
    .print\:m-8 {
        margin: .8rem;
    }
    .print\:m-12 {
        margin: 1.2rem;
    }
    .print\:m-16 {
        margin: 1.6rem;
    }
    .print\:m-20 {
        margin: 2rem;
    }
    .print\:m-24 {
        margin: 2.4rem;
    }
    .print\:m-28 {
        margin: 2.8rem;
    }
    .print\:m-32 {
        margin: 3.2rem;
    }
    .print\:m-36 {
        margin: 3.6rem;
    }
    .print\:m-40 {
        margin: 4rem;
    }
    .print\:m-44 {
        margin: 4.4rem;
    }
    .print\:m-48 {
        margin: 4.8rem;
    }
    .print\:m-52 {
        margin: 5.2rem;
    }
    .print\:m-56 {
        margin: 5.6rem;
    }
    .print\:m-60 {
        margin: 6rem;
    }
    .print\:m-64 {
        margin: 6.4rem;
    }
    .print\:m-68 {
        margin: 6.8rem;
    }
    .print\:m-72 {
        margin: 7.2rem;
    }
    .print\:m-76 {
        margin: 7.6rem;
    }
    .print\:m-80 {
        margin: 8rem;
    }
    .print\:m-84 {
        margin: 8.4rem;
    }
    .print\:m-88 {
        margin: 8.8rem;
    }
    .print\:m-92 {
        margin: 9.2rem;
    }
    .print\:m-96 {
        margin: 9.6rem;
    }
    .print\:m-128 {
        margin: 12.8rem;
    }
    .print\:m-160 {
        margin: 16rem;
    }
    .print\:m-192 {
        margin: 19.2rem;
    }
    .print\:m-224 {
        margin: 22.4rem;
    }
    .print\:m-256 {
        margin: 25.6rem;
    }
    .print\:m-auto {
        margin: auto;
    }
    .print\:m-px {
        margin: 1px;
    }
    .print\:my-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .print\:mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .print\:my-4 {
        margin-top: .4rem;
        margin-bottom: .4rem;
    }
    .print\:mx-4 {
        margin-left: .4rem;
        margin-right: .4rem;
    }
    .print\:my-8 {
        margin-top: .8rem;
        margin-bottom: .8rem;
    }
    .print\:mx-8 {
        margin-left: .8rem;
        margin-right: .8rem;
    }
    .print\:my-12 {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
    }
    .print\:mx-12 {
        margin-left: 1.2rem;
        margin-right: 1.2rem;
    }
    .print\:my-16 {
        margin-top: 1.6rem;
        margin-bottom: 1.6rem;
    }
    .print\:mx-16 {
        margin-left: 1.6rem;
        margin-right: 1.6rem;
    }
    .print\:my-20 {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
    .print\:mx-20 {
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .print\:my-24 {
        margin-top: 2.4rem;
        margin-bottom: 2.4rem;
    }
    .print\:mx-24 {
        margin-left: 2.4rem;
        margin-right: 2.4rem;
    }
    .print\:my-28 {
        margin-top: 2.8rem;
        margin-bottom: 2.8rem;
    }
    .print\:mx-28 {
        margin-left: 2.8rem;
        margin-right: 2.8rem;
    }
    .print\:my-32 {
        margin-top: 3.2rem;
        margin-bottom: 3.2rem;
    }
    .print\:mx-32 {
        margin-left: 3.2rem;
        margin-right: 3.2rem;
    }
    .print\:my-36 {
        margin-top: 3.6rem;
        margin-bottom: 3.6rem;
    }
    .print\:mx-36 {
        margin-left: 3.6rem;
        margin-right: 3.6rem;
    }
    .print\:my-40 {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .print\:mx-40 {
        margin-left: 4rem;
        margin-right: 4rem;
    }
    .print\:my-44 {
        margin-top: 4.4rem;
        margin-bottom: 4.4rem;
    }
    .print\:mx-44 {
        margin-left: 4.4rem;
        margin-right: 4.4rem;
    }
    .print\:my-48 {
        margin-top: 4.8rem;
        margin-bottom: 4.8rem;
    }
    .print\:mx-48 {
        margin-left: 4.8rem;
        margin-right: 4.8rem;
    }
    .print\:my-52 {
        margin-top: 5.2rem;
        margin-bottom: 5.2rem;
    }
    .print\:mx-52 {
        margin-left: 5.2rem;
        margin-right: 5.2rem;
    }
    .print\:my-56 {
        margin-top: 5.6rem;
        margin-bottom: 5.6rem;
    }
    .print\:mx-56 {
        margin-left: 5.6rem;
        margin-right: 5.6rem;
    }
    .print\:my-60 {
        margin-top: 6rem;
        margin-bottom: 6rem;
    }
    .print\:mx-60 {
        margin-left: 6rem;
        margin-right: 6rem;
    }
    .print\:my-64 {
        margin-top: 6.4rem;
        margin-bottom: 6.4rem;
    }
    .print\:mx-64 {
        margin-left: 6.4rem;
        margin-right: 6.4rem;
    }
    .print\:my-68 {
        margin-top: 6.8rem;
        margin-bottom: 6.8rem;
    }
    .print\:mx-68 {
        margin-left: 6.8rem;
        margin-right: 6.8rem;
    }
    .print\:my-72 {
        margin-top: 7.2rem;
        margin-bottom: 7.2rem;
    }
    .print\:mx-72 {
        margin-left: 7.2rem;
        margin-right: 7.2rem;
    }
    .print\:my-76 {
        margin-top: 7.6rem;
        margin-bottom: 7.6rem;
    }
    .print\:mx-76 {
        margin-left: 7.6rem;
        margin-right: 7.6rem;
    }
    .print\:my-80 {
        margin-top: 8rem;
        margin-bottom: 8rem;
    }
    .print\:mx-80 {
        margin-left: 8rem;
        margin-right: 8rem;
    }
    .print\:my-84 {
        margin-top: 8.4rem;
        margin-bottom: 8.4rem;
    }
    .print\:mx-84 {
        margin-left: 8.4rem;
        margin-right: 8.4rem;
    }
    .print\:my-88 {
        margin-top: 8.8rem;
        margin-bottom: 8.8rem;
    }
    .print\:mx-88 {
        margin-left: 8.8rem;
        margin-right: 8.8rem;
    }
    .print\:my-92 {
        margin-top: 9.2rem;
        margin-bottom: 9.2rem;
    }
    .print\:mx-92 {
        margin-left: 9.2rem;
        margin-right: 9.2rem;
    }
    .print\:my-96 {
        margin-top: 9.6rem;
        margin-bottom: 9.6rem;
    }
    .print\:mx-96 {
        margin-left: 9.6rem;
        margin-right: 9.6rem;
    }
    .print\:my-128 {
        margin-top: 12.8rem;
        margin-bottom: 12.8rem;
    }
    .print\:mx-128 {
        margin-left: 12.8rem;
        margin-right: 12.8rem;
    }
    .print\:my-160 {
        margin-top: 16rem;
        margin-bottom: 16rem;
    }
    .print\:mx-160 {
        margin-left: 16rem;
        margin-right: 16rem;
    }
    .print\:my-192 {
        margin-top: 19.2rem;
        margin-bottom: 19.2rem;
    }
    .print\:mx-192 {
        margin-left: 19.2rem;
        margin-right: 19.2rem;
    }
    .print\:my-224 {
        margin-top: 22.4rem;
        margin-bottom: 22.4rem;
    }
    .print\:mx-224 {
        margin-left: 22.4rem;
        margin-right: 22.4rem;
    }
    .print\:my-256 {
        margin-top: 25.6rem;
        margin-bottom: 25.6rem;
    }
    .print\:mx-256 {
        margin-left: 25.6rem;
        margin-right: 25.6rem;
    }
    .print\:my-auto {
        margin-top: auto;
        margin-bottom: auto;
    }
    .print\:mx-auto {
        margin-left: auto;
        margin-right: auto;
    }
    .print\:my-px {
        margin-top: 1px;
        margin-bottom: 1px;
    }
    .print\:mx-px {
        margin-left: 1px;
        margin-right: 1px;
    }
    .print\:mt-0 {
        margin-top: 0;
    }
    .print\:mr-0 {
        margin-right: 0;
    }
    .print\:mb-0 {
        margin-bottom: 0;
    }
    .print\:ml-0 {
        margin-left: 0;
    }
    .print\:mt-4 {
        margin-top: .4rem;
    }
    .print\:mr-4 {
        margin-right: .4rem;
    }
    .print\:mb-4 {
        margin-bottom: .4rem;
    }
    .print\:ml-4 {
        margin-left: .4rem;
    }
    .print\:mt-8 {
        margin-top: .8rem;
    }
    .print\:mr-8 {
        margin-right: .8rem;
    }
    .print\:mb-8 {
        margin-bottom: .8rem;
    }
    .print\:ml-8 {
        margin-left: .8rem;
    }
    .print\:mt-12 {
        margin-top: 1.2rem;
    }
    .print\:mr-12 {
        margin-right: 1.2rem;
    }
    .print\:mb-12 {
        margin-bottom: 1.2rem;
    }
    .print\:ml-12 {
        margin-left: 1.2rem;
    }
    .print\:mt-16 {
        margin-top: 1.6rem;
    }
    .print\:mr-16 {
        margin-right: 1.6rem;
    }
    .print\:mb-16 {
        margin-bottom: 1.6rem;
    }
    .print\:ml-16 {
        margin-left: 1.6rem;
    }
    .print\:mt-20 {
        margin-top: 2rem;
    }
    .print\:mr-20 {
        margin-right: 2rem;
    }
    .print\:mb-20 {
        margin-bottom: 2rem;
    }
    .print\:ml-20 {
        margin-left: 2rem;
    }
    .print\:mt-24 {
        margin-top: 2.4rem;
    }
    .print\:mr-24 {
        margin-right: 2.4rem;
    }
    .print\:mb-24 {
        margin-bottom: 2.4rem;
    }
    .print\:ml-24 {
        margin-left: 2.4rem;
    }
    .print\:mt-28 {
        margin-top: 2.8rem;
    }
    .print\:mr-28 {
        margin-right: 2.8rem;
    }
    .print\:mb-28 {
        margin-bottom: 2.8rem;
    }
    .print\:ml-28 {
        margin-left: 2.8rem;
    }
    .print\:mt-32 {
        margin-top: 3.2rem;
    }
    .print\:mr-32 {
        margin-right: 3.2rem;
    }
    .print\:mb-32 {
        margin-bottom: 3.2rem;
    }
    .print\:ml-32 {
        margin-left: 3.2rem;
    }
    .print\:mt-36 {
        margin-top: 3.6rem;
    }
    .print\:mr-36 {
        margin-right: 3.6rem;
    }
    .print\:mb-36 {
        margin-bottom: 3.6rem;
    }
    .print\:ml-36 {
        margin-left: 3.6rem;
    }
    .print\:mt-40 {
        margin-top: 4rem;
    }
    .print\:mr-40 {
        margin-right: 4rem;
    }
    .print\:mb-40 {
        margin-bottom: 4rem;
    }
    .print\:ml-40 {
        margin-left: 4rem;
    }
    .print\:mt-44 {
        margin-top: 4.4rem;
    }
    .print\:mr-44 {
        margin-right: 4.4rem;
    }
    .print\:mb-44 {
        margin-bottom: 4.4rem;
    }
    .print\:ml-44 {
        margin-left: 4.4rem;
    }
    .print\:mt-48 {
        margin-top: 4.8rem;
    }
    .print\:mr-48 {
        margin-right: 4.8rem;
    }
    .print\:mb-48 {
        margin-bottom: 4.8rem;
    }
    .print\:ml-48 {
        margin-left: 4.8rem;
    }
    .print\:mt-52 {
        margin-top: 5.2rem;
    }
    .print\:mr-52 {
        margin-right: 5.2rem;
    }
    .print\:mb-52 {
        margin-bottom: 5.2rem;
    }
    .print\:ml-52 {
        margin-left: 5.2rem;
    }
    .print\:mt-56 {
        margin-top: 5.6rem;
    }
    .print\:mr-56 {
        margin-right: 5.6rem;
    }
    .print\:mb-56 {
        margin-bottom: 5.6rem;
    }
    .print\:ml-56 {
        margin-left: 5.6rem;
    }
    .print\:mt-60 {
        margin-top: 6rem;
    }
    .print\:mr-60 {
        margin-right: 6rem;
    }
    .print\:mb-60 {
        margin-bottom: 6rem;
    }
    .print\:ml-60 {
        margin-left: 6rem;
    }
    .print\:mt-64 {
        margin-top: 6.4rem;
    }
    .print\:mr-64 {
        margin-right: 6.4rem;
    }
    .print\:mb-64 {
        margin-bottom: 6.4rem;
    }
    .print\:ml-64 {
        margin-left: 6.4rem;
    }
    .print\:mt-68 {
        margin-top: 6.8rem;
    }
    .print\:mr-68 {
        margin-right: 6.8rem;
    }
    .print\:mb-68 {
        margin-bottom: 6.8rem;
    }
    .print\:ml-68 {
        margin-left: 6.8rem;
    }
    .print\:mt-72 {
        margin-top: 7.2rem;
    }
    .print\:mr-72 {
        margin-right: 7.2rem;
    }
    .print\:mb-72 {
        margin-bottom: 7.2rem;
    }
    .print\:ml-72 {
        margin-left: 7.2rem;
    }
    .print\:mt-76 {
        margin-top: 7.6rem;
    }
    .print\:mr-76 {
        margin-right: 7.6rem;
    }
    .print\:mb-76 {
        margin-bottom: 7.6rem;
    }
    .print\:ml-76 {
        margin-left: 7.6rem;
    }
    .print\:mt-80 {
        margin-top: 8rem;
    }
    .print\:mr-80 {
        margin-right: 8rem;
    }
    .print\:mb-80 {
        margin-bottom: 8rem;
    }
    .print\:ml-80 {
        margin-left: 8rem;
    }
    .print\:mt-84 {
        margin-top: 8.4rem;
    }
    .print\:mr-84 {
        margin-right: 8.4rem;
    }
    .print\:mb-84 {
        margin-bottom: 8.4rem;
    }
    .print\:ml-84 {
        margin-left: 8.4rem;
    }
    .print\:mt-88 {
        margin-top: 8.8rem;
    }
    .print\:mr-88 {
        margin-right: 8.8rem;
    }
    .print\:mb-88 {
        margin-bottom: 8.8rem;
    }
    .print\:ml-88 {
        margin-left: 8.8rem;
    }
    .print\:mt-92 {
        margin-top: 9.2rem;
    }
    .print\:mr-92 {
        margin-right: 9.2rem;
    }
    .print\:mb-92 {
        margin-bottom: 9.2rem;
    }
    .print\:ml-92 {
        margin-left: 9.2rem;
    }
    .print\:mt-96 {
        margin-top: 9.6rem;
    }
    .print\:mr-96 {
        margin-right: 9.6rem;
    }
    .print\:mb-96 {
        margin-bottom: 9.6rem;
    }
    .print\:ml-96 {
        margin-left: 9.6rem;
    }
    .print\:mt-128 {
        margin-top: 12.8rem;
    }
    .print\:mr-128 {
        margin-right: 12.8rem;
    }
    .print\:mb-128 {
        margin-bottom: 12.8rem;
    }
    .print\:ml-128 {
        margin-left: 12.8rem;
    }
    .print\:mt-160 {
        margin-top: 16rem;
    }
    .print\:mr-160 {
        margin-right: 16rem;
    }
    .print\:mb-160 {
        margin-bottom: 16rem;
    }
    .print\:ml-160 {
        margin-left: 16rem;
    }
    .print\:mt-192 {
        margin-top: 19.2rem;
    }
    .print\:mr-192 {
        margin-right: 19.2rem;
    }
    .print\:mb-192 {
        margin-bottom: 19.2rem;
    }
    .print\:ml-192 {
        margin-left: 19.2rem;
    }
    .print\:mt-224 {
        margin-top: 22.4rem;
    }
    .print\:mr-224 {
        margin-right: 22.4rem;
    }
    .print\:mb-224 {
        margin-bottom: 22.4rem;
    }
    .print\:ml-224 {
        margin-left: 22.4rem;
    }
    .print\:mt-256 {
        margin-top: 25.6rem;
    }
    .print\:mr-256 {
        margin-right: 25.6rem;
    }
    .print\:mb-256 {
        margin-bottom: 25.6rem;
    }
    .print\:ml-256 {
        margin-left: 25.6rem;
    }
    .print\:mt-auto {
        margin-top: auto;
    }
    .print\:mr-auto {
        margin-right: auto;
    }
    .print\:mb-auto {
        margin-bottom: auto;
    }
    .print\:ml-auto {
        margin-left: auto;
    }
    .print\:mt-px {
        margin-top: 1px;
    }
    .print\:mr-px {
        margin-right: 1px;
    }
    .print\:mb-px {
        margin-bottom: 1px;
    }
    .print\:ml-px {
        margin-left: 1px;
    }
    .print\:max-h-4 {
        max-height: .4rem;
    }
    .print\:max-h-8 {
        max-height: .8rem;
    }
    .print\:max-h-12 {
        max-height: 1.2rem;
    }
    .print\:max-h-16 {
        max-height: 1.6rem;
    }
    .print\:max-h-20 {
        max-height: 2rem;
    }
    .print\:max-h-24 {
        max-height: 2.4rem;
    }
    .print\:max-h-28 {
        max-height: 2.8rem;
    }
    .print\:max-h-32 {
        max-height: 3.2rem;
    }
    .print\:max-h-36 {
        max-height: 3.6rem;
    }
    .print\:max-h-40 {
        max-height: 4rem;
    }
    .print\:max-h-44 {
        max-height: 4.4rem;
    }
    .print\:max-h-48 {
        max-height: 4.8rem;
    }
    .print\:max-h-52 {
        max-height: 5.2rem;
    }
    .print\:max-h-56 {
        max-height: 5.6rem;
    }
    .print\:max-h-60 {
        max-height: 6rem;
    }
    .print\:max-h-64 {
        max-height: 6.4rem;
    }
    .print\:max-h-68 {
        max-height: 6.8rem;
    }
    .print\:max-h-72 {
        max-height: 7.2rem;
    }
    .print\:max-h-76 {
        max-height: 7.6rem;
    }
    .print\:max-h-80 {
        max-height: 8rem;
    }
    .print\:max-h-84 {
        max-height: 8.4rem;
    }
    .print\:max-h-88 {
        max-height: 8.8rem;
    }
    .print\:max-h-92 {
        max-height: 9.2rem;
    }
    .print\:max-h-96 {
        max-height: 9.6rem;
    }
    .print\:max-h-128 {
        max-height: 12.8rem;
    }
    .print\:max-h-160 {
        max-height: 16rem;
    }
    .print\:max-h-192 {
        max-height: 19.2rem;
    }
    .print\:max-h-224 {
        max-height: 22.4rem;
    }
    .print\:max-h-256 {
        max-height: 25.6rem;
    }
    .print\:max-h-288 {
        max-height: 28.8rem;
    }
    .print\:max-h-320 {
        max-height: 32rem;
    }
    .print\:max-h-512 {
        max-height: 51.2rem;
    }
    .print\:max-h-full {
        max-height: 100%;
    }
    .print\:max-h-screen {
        max-height: 100vh;
    }
    .print\:max-w-4 {
        max-width: .4rem;
    }
    .print\:max-w-8 {
        max-width: .8rem;
    }
    .print\:max-w-12 {
        max-width: 1.2rem;
    }
    .print\:max-w-16 {
        max-width: 1.6rem;
    }
    .print\:max-w-20 {
        max-width: 2rem;
    }
    .print\:max-w-24 {
        max-width: 2.4rem;
    }
    .print\:max-w-28 {
        max-width: 2.8rem;
    }
    .print\:max-w-32 {
        max-width: 3.2rem;
    }
    .print\:max-w-36 {
        max-width: 3.6rem;
    }
    .print\:max-w-40 {
        max-width: 4rem;
    }
    .print\:max-w-44 {
        max-width: 4.4rem;
    }
    .print\:max-w-48 {
        max-width: 4.8rem;
    }
    .print\:max-w-52 {
        max-width: 5.2rem;
    }
    .print\:max-w-56 {
        max-width: 5.6rem;
    }
    .print\:max-w-60 {
        max-width: 6rem;
    }
    .print\:max-w-64 {
        max-width: 6.4rem;
    }
    .print\:max-w-96 {
        max-width: 9.6rem;
    }
    .print\:max-w-128 {
        max-width: 12.8rem;
    }
    .print\:max-w-160 {
        max-width: 16rem;
    }
    .print\:max-w-192 {
        max-width: 19.2rem;
    }
    .print\:max-w-224 {
        max-width: 22.4rem;
    }
    .print\:max-w-256 {
        max-width: 25.6rem;
    }
    .print\:max-w-288 {
        max-width: 28.8rem;
    }
    .print\:max-w-320 {
        max-width: 32rem;
    }
    .print\:max-w-512 {
        max-width: 51.2rem;
    }
    .print\:max-w-xs {
        max-width: 32rem;
    }
    .print\:max-w-sm {
        max-width: 48rem;
    }
    .print\:max-w-md {
        max-width: 64rem;
    }
    .print\:max-w-lg {
        max-width: 80rem;
    }
    .print\:max-w-xl {
        max-width: 96rem;
    }
    .print\:max-w-2xl {
        max-width: 112rem;
    }
    .print\:max-w-3xl {
        max-width: 128rem;
    }
    .print\:max-w-4xl {
        max-width: 144rem;
    }
    .print\:max-w-5xl {
        max-width: 160rem;
    }
    .print\:max-w-full {
        max-width: 100%;
    }
    .print\:min-h-4 {
        min-height: .4rem;
    }
    .print\:min-h-8 {
        min-height: .8rem;
    }
    .print\:min-h-12 {
        min-height: 1.2rem;
    }
    .print\:min-h-16 {
        min-height: 1.6rem;
    }
    .print\:min-h-20 {
        min-height: 2rem;
    }
    .print\:min-h-24 {
        min-height: 2.4rem;
    }
    .print\:min-h-28 {
        min-height: 2.8rem;
    }
    .print\:min-h-32 {
        min-height: 3.2rem;
    }
    .print\:min-h-36 {
        min-height: 3.6rem;
    }
    .print\:min-h-40 {
        min-height: 4rem;
    }
    .print\:min-h-44 {
        min-height: 4.4rem;
    }
    .print\:min-h-48 {
        min-height: 4.8rem;
    }
    .print\:min-h-52 {
        min-height: 5.2rem;
    }
    .print\:min-h-56 {
        min-height: 5.6rem;
    }
    .print\:min-h-60 {
        min-height: 6rem;
    }
    .print\:min-h-64 {
        min-height: 6.4rem;
    }
    .print\:min-h-68 {
        min-height: 6.8rem;
    }
    .print\:min-h-72 {
        min-height: 7.2rem;
    }
    .print\:min-h-76 {
        min-height: 7.6rem;
    }
    .print\:min-h-80 {
        min-height: 8rem;
    }
    .print\:min-h-84 {
        min-height: 8.4rem;
    }
    .print\:min-h-88 {
        min-height: 8.8rem;
    }
    .print\:min-h-92 {
        min-height: 9.2rem;
    }
    .print\:min-h-96 {
        min-height: 9.6rem;
    }
    .print\:min-h-128 {
        min-height: 12.8rem;
    }
    .print\:min-h-160 {
        min-height: 16rem;
    }
    .print\:min-h-192 {
        min-height: 19.2rem;
    }
    .print\:min-h-224 {
        min-height: 22.4rem;
    }
    .print\:min-h-256 {
        min-height: 25.6rem;
    }
    .print\:min-h-288 {
        min-height: 28.8rem;
    }
    .print\:min-h-320 {
        min-height: 32rem;
    }
    .print\:min-h-512 {
        min-height: 51.2rem;
    }
    .print\:min-h-auto {
        min-height: auto;
    }
    .print\:min-h-px {
        min-height: 1px;
    }
    .print\:min-h-full {
        min-height: 100%;
    }
    .print\:min-h-screen {
        min-height: 100vh;
    }
    .print\:min-w-0 {
        min-width: 0;
    }
    .print\:min-w-4 {
        min-width: .4rem;
    }
    .print\:min-w-8 {
        min-width: .8rem;
    }
    .print\:min-w-12 {
        min-width: 1.2rem;
    }
    .print\:min-w-16 {
        min-width: 1.6rem;
    }
    .print\:min-w-20 {
        min-width: 2rem;
    }
    .print\:min-w-24 {
        min-width: 2.4rem;
    }
    .print\:min-w-28 {
        min-width: 2.8rem;
    }
    .print\:min-w-32 {
        min-width: 3.2rem;
    }
    .print\:min-w-36 {
        min-width: 3.6rem;
    }
    .print\:min-w-40 {
        min-width: 4rem;
    }
    .print\:min-w-44 {
        min-width: 4.4rem;
    }
    .print\:min-w-48 {
        min-width: 4.8rem;
    }
    .print\:min-w-52 {
        min-width: 5.2rem;
    }
    .print\:min-w-56 {
        min-width: 5.6rem;
    }
    .print\:min-w-60 {
        min-width: 6rem;
    }
    .print\:min-w-64 {
        min-width: 6.4rem;
    }
    .print\:min-w-68 {
        min-width: 6.8rem;
    }
    .print\:min-w-72 {
        min-width: 7.2rem;
    }
    .print\:min-w-76 {
        min-width: 7.6rem;
    }
    .print\:min-w-80 {
        min-width: 8rem;
    }
    .print\:min-w-84 {
        min-width: 8.4rem;
    }
    .print\:min-w-88 {
        min-width: 8.8rem;
    }
    .print\:min-w-92 {
        min-width: 9.2rem;
    }
    .print\:min-w-96 {
        min-width: 9.6rem;
    }
    .print\:min-w-128 {
        min-width: 12.8rem;
    }
    .print\:min-w-160 {
        min-width: 16rem;
    }
    .print\:min-w-192 {
        min-width: 19.2rem;
    }
    .print\:min-w-224 {
        min-width: 22.4rem;
    }
    .print\:min-w-256 {
        min-width: 25.6rem;
    }
    .print\:min-w-288 {
        min-width: 28.8rem;
    }
    .print\:min-w-320 {
        min-width: 32rem;
    }
    .print\:min-w-512 {
        min-width: 51.2rem;
    }
    .print\:min-w-auto {
        min-width: auto;
    }
    .print\:min-w-px {
        min-width: 1px;
    }
    .print\:min-w-1\/2 {
        min-width: 50%;
    }
    .print\:min-w-1\/3 {
        min-width: 33.33333%;
    }
    .print\:min-w-2\/3 {
        min-width: 66.66667%;
    }
    .print\:min-w-1\/4 {
        min-width: 25%;
    }
    .print\:min-w-3\/4 {
        min-width: 75%;
    }
    .print\:min-w-1\/5 {
        min-width: 20%;
    }
    .print\:min-w-2\/5 {
        min-width: 40%;
    }
    .print\:min-w-3\/5 {
        min-width: 60%;
    }
    .print\:min-w-4\/5 {
        min-width: 80%;
    }
    .print\:min-w-1\/6 {
        min-width: 16.66667%;
    }
    .print\:min-w-5\/6 {
        min-width: 83.33333%;
    }
    .print\:min-w-full {
        min-width: 100%;
    }
    .print\:min-w-screen {
        min-width: 100vw;
    }
    .print\:-m-0 {
        margin: 0;
    }
    .print\:-m-4 {
        margin: -0.4rem;
    }
    .print\:-m-8 {
        margin: -0.8rem;
    }
    .print\:-m-12 {
        margin: -1.2rem;
    }
    .print\:-m-16 {
        margin: -1.6rem;
    }
    .print\:-m-20 {
        margin: -2rem;
    }
    .print\:-m-24 {
        margin: -2.4rem;
    }
    .print\:-m-28 {
        margin: -2.8rem;
    }
    .print\:-m-32 {
        margin: -3.2rem;
    }
    .print\:-m-36 {
        margin: -3.6rem;
    }
    .print\:-m-40 {
        margin: -4rem;
    }
    .print\:-m-44 {
        margin: -4.4rem;
    }
    .print\:-m-48 {
        margin: -4.8rem;
    }
    .print\:-m-52 {
        margin: -5.2rem;
    }
    .print\:-m-56 {
        margin: -5.6rem;
    }
    .print\:-m-60 {
        margin: -6rem;
    }
    .print\:-m-64 {
        margin: -6.4rem;
    }
    .print\:-m-68 {
        margin: -6.8rem;
    }
    .print\:-m-72 {
        margin: -7.2rem;
    }
    .print\:-m-76 {
        margin: -7.6rem;
    }
    .print\:-m-80 {
        margin: -8rem;
    }
    .print\:-m-84 {
        margin: -8.4rem;
    }
    .print\:-m-88 {
        margin: -8.8rem;
    }
    .print\:-m-92 {
        margin: -9.2rem;
    }
    .print\:-m-96 {
        margin: -9.6rem;
    }
    .print\:-m-128 {
        margin: -12.8rem;
    }
    .print\:-m-160 {
        margin: -16rem;
    }
    .print\:-m-192 {
        margin: -19.2rem;
    }
    .print\:-m-224 {
        margin: -22.4rem;
    }
    .print\:-m-256 {
        margin: -25.6rem;
    }
    .print\:-m-px {
        margin: -1px;
    }
    .print\:-my-0 {
        margin-top: 0;
        margin-bottom: 0;
    }
    .print\:-mx-0 {
        margin-left: 0;
        margin-right: 0;
    }
    .print\:-my-4 {
        margin-top: -0.4rem;
        margin-bottom: -0.4rem;
    }
    .print\:-mx-4 {
        margin-left: -0.4rem;
        margin-right: -0.4rem;
    }
    .print\:-my-8 {
        margin-top: -0.8rem;
        margin-bottom: -0.8rem;
    }
    .print\:-mx-8 {
        margin-left: -0.8rem;
        margin-right: -0.8rem;
    }
    .print\:-my-12 {
        margin-top: -1.2rem;
        margin-bottom: -1.2rem;
    }
    .print\:-mx-12 {
        margin-left: -1.2rem;
        margin-right: -1.2rem;
    }
    .print\:-my-16 {
        margin-top: -1.6rem;
        margin-bottom: -1.6rem;
    }
    .print\:-mx-16 {
        margin-left: -1.6rem;
        margin-right: -1.6rem;
    }
    .print\:-my-20 {
        margin-top: -2rem;
        margin-bottom: -2rem;
    }
    .print\:-mx-20 {
        margin-left: -2rem;
        margin-right: -2rem;
    }
    .print\:-my-24 {
        margin-top: -2.4rem;
        margin-bottom: -2.4rem;
    }
    .print\:-mx-24 {
        margin-left: -2.4rem;
        margin-right: -2.4rem;
    }
    .print\:-my-28 {
        margin-top: -2.8rem;
        margin-bottom: -2.8rem;
    }
    .print\:-mx-28 {
        margin-left: -2.8rem;
        margin-right: -2.8rem;
    }
    .print\:-my-32 {
        margin-top: -3.2rem;
        margin-bottom: -3.2rem;
    }
    .print\:-mx-32 {
        margin-left: -3.2rem;
        margin-right: -3.2rem;
    }
    .print\:-my-36 {
        margin-top: -3.6rem;
        margin-bottom: -3.6rem;
    }
    .print\:-mx-36 {
        margin-left: -3.6rem;
        margin-right: -3.6rem;
    }
    .print\:-my-40 {
        margin-top: -4rem;
        margin-bottom: -4rem;
    }
    .print\:-mx-40 {
        margin-left: -4rem;
        margin-right: -4rem;
    }
    .print\:-my-44 {
        margin-top: -4.4rem;
        margin-bottom: -4.4rem;
    }
    .print\:-mx-44 {
        margin-left: -4.4rem;
        margin-right: -4.4rem;
    }
    .print\:-my-48 {
        margin-top: -4.8rem;
        margin-bottom: -4.8rem;
    }
    .print\:-mx-48 {
        margin-left: -4.8rem;
        margin-right: -4.8rem;
    }
    .print\:-my-52 {
        margin-top: -5.2rem;
        margin-bottom: -5.2rem;
    }
    .print\:-mx-52 {
        margin-left: -5.2rem;
        margin-right: -5.2rem;
    }
    .print\:-my-56 {
        margin-top: -5.6rem;
        margin-bottom: -5.6rem;
    }
    .print\:-mx-56 {
        margin-left: -5.6rem;
        margin-right: -5.6rem;
    }
    .print\:-my-60 {
        margin-top: -6rem;
        margin-bottom: -6rem;
    }
    .print\:-mx-60 {
        margin-left: -6rem;
        margin-right: -6rem;
    }
    .print\:-my-64 {
        margin-top: -6.4rem;
        margin-bottom: -6.4rem;
    }
    .print\:-mx-64 {
        margin-left: -6.4rem;
        margin-right: -6.4rem;
    }
    .print\:-my-68 {
        margin-top: -6.8rem;
        margin-bottom: -6.8rem;
    }
    .print\:-mx-68 {
        margin-left: -6.8rem;
        margin-right: -6.8rem;
    }
    .print\:-my-72 {
        margin-top: -7.2rem;
        margin-bottom: -7.2rem;
    }
    .print\:-mx-72 {
        margin-left: -7.2rem;
        margin-right: -7.2rem;
    }
    .print\:-my-76 {
        margin-top: -7.6rem;
        margin-bottom: -7.6rem;
    }
    .print\:-mx-76 {
        margin-left: -7.6rem;
        margin-right: -7.6rem;
    }
    .print\:-my-80 {
        margin-top: -8rem;
        margin-bottom: -8rem;
    }
    .print\:-mx-80 {
        margin-left: -8rem;
        margin-right: -8rem;
    }
    .print\:-my-84 {
        margin-top: -8.4rem;
        margin-bottom: -8.4rem;
    }
    .print\:-mx-84 {
        margin-left: -8.4rem;
        margin-right: -8.4rem;
    }
    .print\:-my-88 {
        margin-top: -8.8rem;
        margin-bottom: -8.8rem;
    }
    .print\:-mx-88 {
        margin-left: -8.8rem;
        margin-right: -8.8rem;
    }
    .print\:-my-92 {
        margin-top: -9.2rem;
        margin-bottom: -9.2rem;
    }
    .print\:-mx-92 {
        margin-left: -9.2rem;
        margin-right: -9.2rem;
    }
    .print\:-my-96 {
        margin-top: -9.6rem;
        margin-bottom: -9.6rem;
    }
    .print\:-mx-96 {
        margin-left: -9.6rem;
        margin-right: -9.6rem;
    }
    .print\:-my-128 {
        margin-top: -12.8rem;
        margin-bottom: -12.8rem;
    }
    .print\:-mx-128 {
        margin-left: -12.8rem;
        margin-right: -12.8rem;
    }
    .print\:-my-160 {
        margin-top: -16rem;
        margin-bottom: -16rem;
    }
    .print\:-mx-160 {
        margin-left: -16rem;
        margin-right: -16rem;
    }
    .print\:-my-192 {
        margin-top: -19.2rem;
        margin-bottom: -19.2rem;
    }
    .print\:-mx-192 {
        margin-left: -19.2rem;
        margin-right: -19.2rem;
    }
    .print\:-my-224 {
        margin-top: -22.4rem;
        margin-bottom: -22.4rem;
    }
    .print\:-mx-224 {
        margin-left: -22.4rem;
        margin-right: -22.4rem;
    }
    .print\:-my-256 {
        margin-top: -25.6rem;
        margin-bottom: -25.6rem;
    }
    .print\:-mx-256 {
        margin-left: -25.6rem;
        margin-right: -25.6rem;
    }
    .print\:-my-px {
        margin-top: -1px;
        margin-bottom: -1px;
    }
    .print\:-mx-px {
        margin-left: -1px;
        margin-right: -1px;
    }
    .print\:-mt-0 {
        margin-top: 0;
    }
    .print\:-mr-0 {
        margin-right: 0;
    }
    .print\:-mb-0 {
        margin-bottom: 0;
    }
    .print\:-ml-0 {
        margin-left: 0;
    }
    .print\:-mt-4 {
        margin-top: -0.4rem;
    }
    .print\:-mr-4 {
        margin-right: -0.4rem;
    }
    .print\:-mb-4 {
        margin-bottom: -0.4rem;
    }
    .print\:-ml-4 {
        margin-left: -0.4rem;
    }
    .print\:-mt-8 {
        margin-top: -0.8rem;
    }
    .print\:-mr-8 {
        margin-right: -0.8rem;
    }
    .print\:-mb-8 {
        margin-bottom: -0.8rem;
    }
    .print\:-ml-8 {
        margin-left: -0.8rem;
    }
    .print\:-mt-12 {
        margin-top: -1.2rem;
    }
    .print\:-mr-12 {
        margin-right: -1.2rem;
    }
    .print\:-mb-12 {
        margin-bottom: -1.2rem;
    }
    .print\:-ml-12 {
        margin-left: -1.2rem;
    }
    .print\:-mt-16 {
        margin-top: -1.6rem;
    }
    .print\:-mr-16 {
        margin-right: -1.6rem;
    }
    .print\:-mb-16 {
        margin-bottom: -1.6rem;
    }
    .print\:-ml-16 {
        margin-left: -1.6rem;
    }
    .print\:-mt-20 {
        margin-top: -2rem;
    }
    .print\:-mr-20 {
        margin-right: -2rem;
    }
    .print\:-mb-20 {
        margin-bottom: -2rem;
    }
    .print\:-ml-20 {
        margin-left: -2rem;
    }
    .print\:-mt-24 {
        margin-top: -2.4rem;
    }
    .print\:-mr-24 {
        margin-right: -2.4rem;
    }
    .print\:-mb-24 {
        margin-bottom: -2.4rem;
    }
    .print\:-ml-24 {
        margin-left: -2.4rem;
    }
    .print\:-mt-28 {
        margin-top: -2.8rem;
    }
    .print\:-mr-28 {
        margin-right: -2.8rem;
    }
    .print\:-mb-28 {
        margin-bottom: -2.8rem;
    }
    .print\:-ml-28 {
        margin-left: -2.8rem;
    }
    .print\:-mt-32 {
        margin-top: -3.2rem;
    }
    .print\:-mr-32 {
        margin-right: -3.2rem;
    }
    .print\:-mb-32 {
        margin-bottom: -3.2rem;
    }
    .print\:-ml-32 {
        margin-left: -3.2rem;
    }
    .print\:-mt-36 {
        margin-top: -3.6rem;
    }
    .print\:-mr-36 {
        margin-right: -3.6rem;
    }
    .print\:-mb-36 {
        margin-bottom: -3.6rem;
    }
    .print\:-ml-36 {
        margin-left: -3.6rem;
    }
    .print\:-mt-40 {
        margin-top: -4rem;
    }
    .print\:-mr-40 {
        margin-right: -4rem;
    }
    .print\:-mb-40 {
        margin-bottom: -4rem;
    }
    .print\:-ml-40 {
        margin-left: -4rem;
    }
    .print\:-mt-44 {
        margin-top: -4.4rem;
    }
    .print\:-mr-44 {
        margin-right: -4.4rem;
    }
    .print\:-mb-44 {
        margin-bottom: -4.4rem;
    }
    .print\:-ml-44 {
        margin-left: -4.4rem;
    }
    .print\:-mt-48 {
        margin-top: -4.8rem;
    }
    .print\:-mr-48 {
        margin-right: -4.8rem;
    }
    .print\:-mb-48 {
        margin-bottom: -4.8rem;
    }
    .print\:-ml-48 {
        margin-left: -4.8rem;
    }
    .print\:-mt-52 {
        margin-top: -5.2rem;
    }
    .print\:-mr-52 {
        margin-right: -5.2rem;
    }
    .print\:-mb-52 {
        margin-bottom: -5.2rem;
    }
    .print\:-ml-52 {
        margin-left: -5.2rem;
    }
    .print\:-mt-56 {
        margin-top: -5.6rem;
    }
    .print\:-mr-56 {
        margin-right: -5.6rem;
    }
    .print\:-mb-56 {
        margin-bottom: -5.6rem;
    }
    .print\:-ml-56 {
        margin-left: -5.6rem;
    }
    .print\:-mt-60 {
        margin-top: -6rem;
    }
    .print\:-mr-60 {
        margin-right: -6rem;
    }
    .print\:-mb-60 {
        margin-bottom: -6rem;
    }
    .print\:-ml-60 {
        margin-left: -6rem;
    }
    .print\:-mt-64 {
        margin-top: -6.4rem;
    }
    .print\:-mr-64 {
        margin-right: -6.4rem;
    }
    .print\:-mb-64 {
        margin-bottom: -6.4rem;
    }
    .print\:-ml-64 {
        margin-left: -6.4rem;
    }
    .print\:-mt-68 {
        margin-top: -6.8rem;
    }
    .print\:-mr-68 {
        margin-right: -6.8rem;
    }
    .print\:-mb-68 {
        margin-bottom: -6.8rem;
    }
    .print\:-ml-68 {
        margin-left: -6.8rem;
    }
    .print\:-mt-72 {
        margin-top: -7.2rem;
    }
    .print\:-mr-72 {
        margin-right: -7.2rem;
    }
    .print\:-mb-72 {
        margin-bottom: -7.2rem;
    }
    .print\:-ml-72 {
        margin-left: -7.2rem;
    }
    .print\:-mt-76 {
        margin-top: -7.6rem;
    }
    .print\:-mr-76 {
        margin-right: -7.6rem;
    }
    .print\:-mb-76 {
        margin-bottom: -7.6rem;
    }
    .print\:-ml-76 {
        margin-left: -7.6rem;
    }
    .print\:-mt-80 {
        margin-top: -8rem;
    }
    .print\:-mr-80 {
        margin-right: -8rem;
    }
    .print\:-mb-80 {
        margin-bottom: -8rem;
    }
    .print\:-ml-80 {
        margin-left: -8rem;
    }
    .print\:-mt-84 {
        margin-top: -8.4rem;
    }
    .print\:-mr-84 {
        margin-right: -8.4rem;
    }
    .print\:-mb-84 {
        margin-bottom: -8.4rem;
    }
    .print\:-ml-84 {
        margin-left: -8.4rem;
    }
    .print\:-mt-88 {
        margin-top: -8.8rem;
    }
    .print\:-mr-88 {
        margin-right: -8.8rem;
    }
    .print\:-mb-88 {
        margin-bottom: -8.8rem;
    }
    .print\:-ml-88 {
        margin-left: -8.8rem;
    }
    .print\:-mt-92 {
        margin-top: -9.2rem;
    }
    .print\:-mr-92 {
        margin-right: -9.2rem;
    }
    .print\:-mb-92 {
        margin-bottom: -9.2rem;
    }
    .print\:-ml-92 {
        margin-left: -9.2rem;
    }
    .print\:-mt-96 {
        margin-top: -9.6rem;
    }
    .print\:-mr-96 {
        margin-right: -9.6rem;
    }
    .print\:-mb-96 {
        margin-bottom: -9.6rem;
    }
    .print\:-ml-96 {
        margin-left: -9.6rem;
    }
    .print\:-mt-128 {
        margin-top: -12.8rem;
    }
    .print\:-mr-128 {
        margin-right: -12.8rem;
    }
    .print\:-mb-128 {
        margin-bottom: -12.8rem;
    }
    .print\:-ml-128 {
        margin-left: -12.8rem;
    }
    .print\:-mt-160 {
        margin-top: -16rem;
    }
    .print\:-mr-160 {
        margin-right: -16rem;
    }
    .print\:-mb-160 {
        margin-bottom: -16rem;
    }
    .print\:-ml-160 {
        margin-left: -16rem;
    }
    .print\:-mt-192 {
        margin-top: -19.2rem;
    }
    .print\:-mr-192 {
        margin-right: -19.2rem;
    }
    .print\:-mb-192 {
        margin-bottom: -19.2rem;
    }
    .print\:-ml-192 {
        margin-left: -19.2rem;
    }
    .print\:-mt-224 {
        margin-top: -22.4rem;
    }
    .print\:-mr-224 {
        margin-right: -22.4rem;
    }
    .print\:-mb-224 {
        margin-bottom: -22.4rem;
    }
    .print\:-ml-224 {
        margin-left: -22.4rem;
    }
    .print\:-mt-256 {
        margin-top: -25.6rem;
    }
    .print\:-mr-256 {
        margin-right: -25.6rem;
    }
    .print\:-mb-256 {
        margin-bottom: -25.6rem;
    }
    .print\:-ml-256 {
        margin-left: -25.6rem;
    }
    .print\:-mt-px {
        margin-top: -1px;
    }
    .print\:-mr-px {
        margin-right: -1px;
    }
    .print\:-mb-px {
        margin-bottom: -1px;
    }
    .print\:-ml-px {
        margin-left: -1px;
    }
    .print\:opacity-0 {
        opacity: 0;
    }
    .print\:opacity-25 {
        opacity: .25;
    }
    .print\:opacity-50 {
        opacity: .5;
    }
    .print\:opacity-75 {
        opacity: .75;
    }
    .print\:opacity-100 {
        opacity: 1;
    }
    .print\:overflow-auto {
        overflow: auto;
    }
    .print\:overflow-hidden {
        overflow: hidden;
    }
    .print\:overflow-visible {
        overflow: visible;
    }
    .print\:overflow-scroll {
        overflow: scroll;
    }
    .print\:overflow-x-auto {
        overflow-x: auto;
    }
    .print\:overflow-y-auto {
        overflow-y: auto;
    }
    .print\:overflow-x-hidden {
        overflow-x: hidden;
    }
    .print\:overflow-y-hidden {
        overflow-y: hidden;
    }
    .print\:overflow-x-visible {
        overflow-x: visible;
    }
    .print\:overflow-y-visible {
        overflow-y: visible;
    }
    .print\:overflow-x-scroll {
        overflow-x: scroll;
    }
    .print\:overflow-y-scroll {
        overflow-y: scroll;
    }
    .print\:scrolling-touch {
        -webkit-overflow-scrolling: touch;
    }
    .print\:scrolling-auto {
        -webkit-overflow-scrolling: auto;
    }
    .print\:p-0 {
        padding: 0;
    }
    .print\:p-4 {
        padding: .4rem;
    }
    .print\:p-8 {
        padding: .8rem;
    }
    .print\:p-12 {
        padding: 1.2rem;
    }
    .print\:p-16 {
        padding: 1.6rem;
    }
    .print\:p-20 {
        padding: 2rem;
    }
    .print\:p-24 {
        padding: 2.4rem;
    }
    .print\:p-28 {
        padding: 2.8rem;
    }
    .print\:p-32 {
        padding: 3.2rem;
    }
    .print\:p-36 {
        padding: 3.6rem;
    }
    .print\:p-40 {
        padding: 4rem;
    }
    .print\:p-44 {
        padding: 4.4rem;
    }
    .print\:p-48 {
        padding: 4.8rem;
    }
    .print\:p-52 {
        padding: 5.2rem;
    }
    .print\:p-56 {
        padding: 5.6rem;
    }
    .print\:p-60 {
        padding: 6rem;
    }
    .print\:p-64 {
        padding: 6.4rem;
    }
    .print\:p-68 {
        padding: 6.8rem;
    }
    .print\:p-72 {
        padding: 7.2rem;
    }
    .print\:p-76 {
        padding: 7.6rem;
    }
    .print\:p-80 {
        padding: 8rem;
    }
    .print\:p-84 {
        padding: 8.4rem;
    }
    .print\:p-88 {
        padding: 8.8rem;
    }
    .print\:p-92 {
        padding: 9.2rem;
    }
    .print\:p-96 {
        padding: 9.6rem;
    }
    .print\:p-128 {
        padding: 12.8rem;
    }
    .print\:p-160 {
        padding: 16rem;
    }
    .print\:p-192 {
        padding: 19.2rem;
    }
    .print\:p-224 {
        padding: 22.4rem;
    }
    .print\:p-256 {
        padding: 25.6rem;
    }
    .print\:p-px {
        padding: 1px;
    }
    .print\:py-0 {
        padding-top: 0;
        padding-bottom: 0;
    }
    .print\:px-0 {
        padding-left: 0;
        padding-right: 0;
    }
    .print\:py-4 {
        padding-top: .4rem;
        padding-bottom: .4rem;
    }
    .print\:px-4 {
        padding-left: .4rem;
        padding-right: .4rem;
    }
    .print\:py-8 {
        padding-top: .8rem;
        padding-bottom: .8rem;
    }
    .print\:px-8 {
        padding-left: .8rem;
        padding-right: .8rem;
    }
    .print\:py-12 {
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
    }
    .print\:px-12 {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }
    .print\:py-16 {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
    }
    .print\:px-16 {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
    .print\:py-20 {
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
    .print\:px-20 {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .print\:py-24 {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }
    .print\:px-24 {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }
    .print\:py-28 {
        padding-top: 2.8rem;
        padding-bottom: 2.8rem;
    }
    .print\:px-28 {
        padding-left: 2.8rem;
        padding-right: 2.8rem;
    }
    .print\:py-32 {
        padding-top: 3.2rem;
        padding-bottom: 3.2rem;
    }
    .print\:px-32 {
        padding-left: 3.2rem;
        padding-right: 3.2rem;
    }
    .print\:py-36 {
        padding-top: 3.6rem;
        padding-bottom: 3.6rem;
    }
    .print\:px-36 {
        padding-left: 3.6rem;
        padding-right: 3.6rem;
    }
    .print\:py-40 {
        padding-top: 4rem;
        padding-bottom: 4rem;
    }
    .print\:px-40 {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .print\:py-44 {
        padding-top: 4.4rem;
        padding-bottom: 4.4rem;
    }
    .print\:px-44 {
        padding-left: 4.4rem;
        padding-right: 4.4rem;
    }
    .print\:py-48 {
        padding-top: 4.8rem;
        padding-bottom: 4.8rem;
    }
    .print\:px-48 {
        padding-left: 4.8rem;
        padding-right: 4.8rem;
    }
    .print\:py-52 {
        padding-top: 5.2rem;
        padding-bottom: 5.2rem;
    }
    .print\:px-52 {
        padding-left: 5.2rem;
        padding-right: 5.2rem;
    }
    .print\:py-56 {
        padding-top: 5.6rem;
        padding-bottom: 5.6rem;
    }
    .print\:px-56 {
        padding-left: 5.6rem;
        padding-right: 5.6rem;
    }
    .print\:py-60 {
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
    .print\:px-60 {
        padding-left: 6rem;
        padding-right: 6rem;
    }
    .print\:py-64 {
        padding-top: 6.4rem;
        padding-bottom: 6.4rem;
    }
    .print\:px-64 {
        padding-left: 6.4rem;
        padding-right: 6.4rem;
    }
    .print\:py-68 {
        padding-top: 6.8rem;
        padding-bottom: 6.8rem;
    }
    .print\:px-68 {
        padding-left: 6.8rem;
        padding-right: 6.8rem;
    }
    .print\:py-72 {
        padding-top: 7.2rem;
        padding-bottom: 7.2rem;
    }
    .print\:px-72 {
        padding-left: 7.2rem;
        padding-right: 7.2rem;
    }
    .print\:py-76 {
        padding-top: 7.6rem;
        padding-bottom: 7.6rem;
    }
    .print\:px-76 {
        padding-left: 7.6rem;
        padding-right: 7.6rem;
    }
    .print\:py-80 {
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    .print\:px-80 {
        padding-left: 8rem;
        padding-right: 8rem;
    }
    .print\:py-84 {
        padding-top: 8.4rem;
        padding-bottom: 8.4rem;
    }
    .print\:px-84 {
        padding-left: 8.4rem;
        padding-right: 8.4rem;
    }
    .print\:py-88 {
        padding-top: 8.8rem;
        padding-bottom: 8.8rem;
    }
    .print\:px-88 {
        padding-left: 8.8rem;
        padding-right: 8.8rem;
    }
    .print\:py-92 {
        padding-top: 9.2rem;
        padding-bottom: 9.2rem;
    }
    .print\:px-92 {
        padding-left: 9.2rem;
        padding-right: 9.2rem;
    }
    .print\:py-96 {
        padding-top: 9.6rem;
        padding-bottom: 9.6rem;
    }
    .print\:px-96 {
        padding-left: 9.6rem;
        padding-right: 9.6rem;
    }
    .print\:py-128 {
        padding-top: 12.8rem;
        padding-bottom: 12.8rem;
    }
    .print\:px-128 {
        padding-left: 12.8rem;
        padding-right: 12.8rem;
    }
    .print\:py-160 {
        padding-top: 16rem;
        padding-bottom: 16rem;
    }
    .print\:px-160 {
        padding-left: 16rem;
        padding-right: 16rem;
    }
    .print\:py-192 {
        padding-top: 19.2rem;
        padding-bottom: 19.2rem;
    }
    .print\:px-192 {
        padding-left: 19.2rem;
        padding-right: 19.2rem;
    }
    .print\:py-224 {
        padding-top: 22.4rem;
        padding-bottom: 22.4rem;
    }
    .print\:px-224 {
        padding-left: 22.4rem;
        padding-right: 22.4rem;
    }
    .print\:py-256 {
        padding-top: 25.6rem;
        padding-bottom: 25.6rem;
    }
    .print\:px-256 {
        padding-left: 25.6rem;
        padding-right: 25.6rem;
    }
    .print\:py-px {
        padding-top: 1px;
        padding-bottom: 1px;
    }
    .print\:px-px {
        padding-left: 1px;
        padding-right: 1px;
    }
    .print\:pt-0 {
        padding-top: 0;
    }
    .print\:pr-0 {
        padding-right: 0;
    }
    .print\:pb-0 {
        padding-bottom: 0;
    }
    .print\:pl-0 {
        padding-left: 0;
    }
    .print\:pt-4 {
        padding-top: .4rem;
    }
    .print\:pr-4 {
        padding-right: .4rem;
    }
    .print\:pb-4 {
        padding-bottom: .4rem;
    }
    .print\:pl-4 {
        padding-left: .4rem;
    }
    .print\:pt-8 {
        padding-top: .8rem;
    }
    .print\:pr-8 {
        padding-right: .8rem;
    }
    .print\:pb-8 {
        padding-bottom: .8rem;
    }
    .print\:pl-8 {
        padding-left: .8rem;
    }
    .print\:pt-12 {
        padding-top: 1.2rem;
    }
    .print\:pr-12 {
        padding-right: 1.2rem;
    }
    .print\:pb-12 {
        padding-bottom: 1.2rem;
    }
    .print\:pl-12 {
        padding-left: 1.2rem;
    }
    .print\:pt-16 {
        padding-top: 1.6rem;
    }
    .print\:pr-16 {
        padding-right: 1.6rem;
    }
    .print\:pb-16 {
        padding-bottom: 1.6rem;
    }
    .print\:pl-16 {
        padding-left: 1.6rem;
    }
    .print\:pt-20 {
        padding-top: 2rem;
    }
    .print\:pr-20 {
        padding-right: 2rem;
    }
    .print\:pb-20 {
        padding-bottom: 2rem;
    }
    .print\:pl-20 {
        padding-left: 2rem;
    }
    .print\:pt-24 {
        padding-top: 2.4rem;
    }
    .print\:pr-24 {
        padding-right: 2.4rem;
    }
    .print\:pb-24 {
        padding-bottom: 2.4rem;
    }
    .print\:pl-24 {
        padding-left: 2.4rem;
    }
    .print\:pt-28 {
        padding-top: 2.8rem;
    }
    .print\:pr-28 {
        padding-right: 2.8rem;
    }
    .print\:pb-28 {
        padding-bottom: 2.8rem;
    }
    .print\:pl-28 {
        padding-left: 2.8rem;
    }
    .print\:pt-32 {
        padding-top: 3.2rem;
    }
    .print\:pr-32 {
        padding-right: 3.2rem;
    }
    .print\:pb-32 {
        padding-bottom: 3.2rem;
    }
    .print\:pl-32 {
        padding-left: 3.2rem;
    }
    .print\:pt-36 {
        padding-top: 3.6rem;
    }
    .print\:pr-36 {
        padding-right: 3.6rem;
    }
    .print\:pb-36 {
        padding-bottom: 3.6rem;
    }
    .print\:pl-36 {
        padding-left: 3.6rem;
    }
    .print\:pt-40 {
        padding-top: 4rem;
    }
    .print\:pr-40 {
        padding-right: 4rem;
    }
    .print\:pb-40 {
        padding-bottom: 4rem;
    }
    .print\:pl-40 {
        padding-left: 4rem;
    }
    .print\:pt-44 {
        padding-top: 4.4rem;
    }
    .print\:pr-44 {
        padding-right: 4.4rem;
    }
    .print\:pb-44 {
        padding-bottom: 4.4rem;
    }
    .print\:pl-44 {
        padding-left: 4.4rem;
    }
    .print\:pt-48 {
        padding-top: 4.8rem;
    }
    .print\:pr-48 {
        padding-right: 4.8rem;
    }
    .print\:pb-48 {
        padding-bottom: 4.8rem;
    }
    .print\:pl-48 {
        padding-left: 4.8rem;
    }
    .print\:pt-52 {
        padding-top: 5.2rem;
    }
    .print\:pr-52 {
        padding-right: 5.2rem;
    }
    .print\:pb-52 {
        padding-bottom: 5.2rem;
    }
    .print\:pl-52 {
        padding-left: 5.2rem;
    }
    .print\:pt-56 {
        padding-top: 5.6rem;
    }
    .print\:pr-56 {
        padding-right: 5.6rem;
    }
    .print\:pb-56 {
        padding-bottom: 5.6rem;
    }
    .print\:pl-56 {
        padding-left: 5.6rem;
    }
    .print\:pt-60 {
        padding-top: 6rem;
    }
    .print\:pr-60 {
        padding-right: 6rem;
    }
    .print\:pb-60 {
        padding-bottom: 6rem;
    }
    .print\:pl-60 {
        padding-left: 6rem;
    }
    .print\:pt-64 {
        padding-top: 6.4rem;
    }
    .print\:pr-64 {
        padding-right: 6.4rem;
    }
    .print\:pb-64 {
        padding-bottom: 6.4rem;
    }
    .print\:pl-64 {
        padding-left: 6.4rem;
    }
    .print\:pt-68 {
        padding-top: 6.8rem;
    }
    .print\:pr-68 {
        padding-right: 6.8rem;
    }
    .print\:pb-68 {
        padding-bottom: 6.8rem;
    }
    .print\:pl-68 {
        padding-left: 6.8rem;
    }
    .print\:pt-72 {
        padding-top: 7.2rem;
    }
    .print\:pr-72 {
        padding-right: 7.2rem;
    }
    .print\:pb-72 {
        padding-bottom: 7.2rem;
    }
    .print\:pl-72 {
        padding-left: 7.2rem;
    }
    .print\:pt-76 {
        padding-top: 7.6rem;
    }
    .print\:pr-76 {
        padding-right: 7.6rem;
    }
    .print\:pb-76 {
        padding-bottom: 7.6rem;
    }
    .print\:pl-76 {
        padding-left: 7.6rem;
    }
    .print\:pt-80 {
        padding-top: 8rem;
    }
    .print\:pr-80 {
        padding-right: 8rem;
    }
    .print\:pb-80 {
        padding-bottom: 8rem;
    }
    .print\:pl-80 {
        padding-left: 8rem;
    }
    .print\:pt-84 {
        padding-top: 8.4rem;
    }
    .print\:pr-84 {
        padding-right: 8.4rem;
    }
    .print\:pb-84 {
        padding-bottom: 8.4rem;
    }
    .print\:pl-84 {
        padding-left: 8.4rem;
    }
    .print\:pt-88 {
        padding-top: 8.8rem;
    }
    .print\:pr-88 {
        padding-right: 8.8rem;
    }
    .print\:pb-88 {
        padding-bottom: 8.8rem;
    }
    .print\:pl-88 {
        padding-left: 8.8rem;
    }
    .print\:pt-92 {
        padding-top: 9.2rem;
    }
    .print\:pr-92 {
        padding-right: 9.2rem;
    }
    .print\:pb-92 {
        padding-bottom: 9.2rem;
    }
    .print\:pl-92 {
        padding-left: 9.2rem;
    }
    .print\:pt-96 {
        padding-top: 9.6rem;
    }
    .print\:pr-96 {
        padding-right: 9.6rem;
    }
    .print\:pb-96 {
        padding-bottom: 9.6rem;
    }
    .print\:pl-96 {
        padding-left: 9.6rem;
    }
    .print\:pt-128 {
        padding-top: 12.8rem;
    }
    .print\:pr-128 {
        padding-right: 12.8rem;
    }
    .print\:pb-128 {
        padding-bottom: 12.8rem;
    }
    .print\:pl-128 {
        padding-left: 12.8rem;
    }
    .print\:pt-160 {
        padding-top: 16rem;
    }
    .print\:pr-160 {
        padding-right: 16rem;
    }
    .print\:pb-160 {
        padding-bottom: 16rem;
    }
    .print\:pl-160 {
        padding-left: 16rem;
    }
    .print\:pt-192 {
        padding-top: 19.2rem;
    }
    .print\:pr-192 {
        padding-right: 19.2rem;
    }
    .print\:pb-192 {
        padding-bottom: 19.2rem;
    }
    .print\:pl-192 {
        padding-left: 19.2rem;
    }
    .print\:pt-224 {
        padding-top: 22.4rem;
    }
    .print\:pr-224 {
        padding-right: 22.4rem;
    }
    .print\:pb-224 {
        padding-bottom: 22.4rem;
    }
    .print\:pl-224 {
        padding-left: 22.4rem;
    }
    .print\:pt-256 {
        padding-top: 25.6rem;
    }
    .print\:pr-256 {
        padding-right: 25.6rem;
    }
    .print\:pb-256 {
        padding-bottom: 25.6rem;
    }
    .print\:pl-256 {
        padding-left: 25.6rem;
    }
    .print\:pt-px {
        padding-top: 1px;
    }
    .print\:pr-px {
        padding-right: 1px;
    }
    .print\:pb-px {
        padding-bottom: 1px;
    }
    .print\:pl-px {
        padding-left: 1px;
    }
    .print\:pointer-events-none {
        pointer-events: none;
    }
    .print\:pointer-events-auto {
        pointer-events: auto;
    }
    .print\:static {
        position: static;
    }
    .print\:fixed {
        position: fixed;
    }
    .print\:absolute {
        position: absolute;
    }
    .print\:relative {
        position: relative;
    }
    .print\:sticky {
        position: -webkit-sticky;
        position: sticky;
    }
    .print\:pin-none {
        top: auto;
        right: auto;
        bottom: auto;
        left: auto;
    }
    .print\:pin {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .print\:pin-y {
        top: 0;
        bottom: 0;
    }
    .print\:pin-x {
        right: 0;
        left: 0;
    }
    .print\:pin-t {
        top: 0;
    }
    .print\:pin-r {
        right: 0;
    }
    .print\:pin-b {
        bottom: 0;
    }
    .print\:pin-l {
        left: 0;
    }
    .print\:resize-none {
        resize: none;
    }
    .print\:resize-y {
        resize: vertical;
    }
    .print\:resize-x {
        resize: horizontal;
    }
    .print\:resize {
        resize: both;
    }
    .print\:shadow {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
    }
    .print\:shadow-md {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
    }
    .print\:shadow-lg {
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .11), 0 5px 15px 0 rgba(0, 0, 0, .08);
    }
    .print\:shadow-inner {
        box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, .06);
    }
    .print\:shadow-none {
        box-shadow: none;
    }
    .print\:text-left {
        text-align: left;
    }
    .print\:text-center {
        text-align: center;
    }
    .print\:text-right {
        text-align: right;
    }
    .print\:text-justify {
        text-align: justify;
    }
    .print\:text-transparent {
        color: transparent;
    }
    .print\:text-black {
        color: #22292f;
    }
    .print\:text-grey-darkest {
        color: #3d4852;
    }
    .print\:text-grey-darker {
        color: #606f7b;
    }
    .print\:text-grey-dark {
        color: #8795a1;
    }
    .print\:text-grey {
        color: #b8c2cc;
    }
    .print\:text-grey-light {
        color: #dae1e7;
    }
    .print\:text-grey-lighter {
        color: #f1f5f8;
    }
    .print\:text-grey-lightest {
        color: #f8fafc;
    }
    .print\:text-white {
        color: #fff;
    }
    .print\:text-red-darkest {
        color: #3b0d0c;
    }
    .print\:text-red-darker {
        color: #621b18;
    }
    .print\:text-red-dark {
        color: #cc1f1a;
    }
    .print\:text-red {
        color: #e3342f;
    }
    .print\:text-red-light {
        color: #ef5753;
    }
    .print\:text-red-lighter {
        color: #f9acaa;
    }
    .print\:text-red-lightest {
        color: #fcebea;
    }
    .print\:text-orange-darkest {
        color: #462a16;
    }
    .print\:text-orange-darker {
        color: #613b1f;
    }
    .print\:text-orange-dark {
        color: #de751f;
    }
    .print\:text-orange {
        color: #f6993f;
    }
    .print\:text-orange-light {
        color: #faad63;
    }
    .print\:text-orange-lighter {
        color: #fcd9b6;
    }
    .print\:text-orange-lightest {
        color: #fff5eb;
    }
    .print\:text-yellow-darkest {
        color: #453411;
    }
    .print\:text-yellow-darker {
        color: #684f1d;
    }
    .print\:text-yellow-dark {
        color: #f2d024;
    }
    .print\:text-yellow {
        color: #ffed4a;
    }
    .print\:text-yellow-light {
        color: #fff382;
    }
    .print\:text-yellow-lighter {
        color: #fff9c2;
    }
    .print\:text-yellow-lightest {
        color: #fcfbeb;
    }
    .print\:text-green-darkest {
        color: #0f2f21;
    }
    .print\:text-green-darker {
        color: #1a4731;
    }
    .print\:text-green-dark {
        color: #1f9d55;
    }
    .print\:text-green {
        color: #38c172;
    }
    .print\:text-green-light {
        color: #51d88a;
    }
    .print\:text-green-lighter {
        color: #a2f5bf;
    }
    .print\:text-green-lightest {
        color: #e3fcec;
    }
    .print\:text-teal-darkest {
        color: #0d3331;
    }
    .print\:text-teal-darker {
        color: #20504f;
    }
    .print\:text-teal-dark {
        color: #38a89d;
    }
    .print\:text-teal {
        color: #4dc0b5;
    }
    .print\:text-teal-light {
        color: #64d5ca;
    }
    .print\:text-teal-lighter {
        color: #a0f0ed;
    }
    .print\:text-teal-lightest {
        color: #e8fffe;
    }
    .print\:text-blue-darkest {
        color: #12283a;
    }
    .print\:text-blue-darker {
        color: #1c3d5a;
    }
    .print\:text-blue-dark {
        color: #2779bd;
    }
    .print\:text-blue {
        color: #3490dc;
    }
    .print\:text-blue-light {
        color: #6cb2eb;
    }
    .print\:text-blue-lighter {
        color: #bcdefa;
    }
    .print\:text-blue-lightest {
        color: #eff8ff;
    }
    .print\:text-indigo-darkest {
        color: #191e38;
    }
    .print\:text-indigo-darker {
        color: #2f365f;
    }
    .print\:text-indigo-dark {
        color: #5661b3;
    }
    .print\:text-indigo {
        color: #6574cd;
    }
    .print\:text-indigo-light {
        color: #7886d7;
    }
    .print\:text-indigo-lighter {
        color: #b2b7ff;
    }
    .print\:text-indigo-lightest {
        color: #e6e8ff;
    }
    .print\:text-purple-darkest {
        color: #21183c;
    }
    .print\:text-purple-darker {
        color: #382b5f;
    }
    .print\:text-purple-dark {
        color: #794acf;
    }
    .print\:text-purple {
        color: #9561e2;
    }
    .print\:text-purple-light {
        color: #a779e9;
    }
    .print\:text-purple-lighter {
        color: #d6bbfc;
    }
    .print\:text-purple-lightest {
        color: #f3ebff;
    }
    .print\:text-pink-darkest {
        color: #451225;
    }
    .print\:text-pink-darker {
        color: #6f213f;
    }
    .print\:text-pink-dark {
        color: #eb5286;
    }
    .print\:text-pink {
        color: #f66d9b;
    }
    .print\:text-pink-light {
        color: #fa7ea8;
    }
    .print\:text-pink-lighter {
        color: #ffbbca;
    }
    .print\:text-pink-lightest {
        color: #ffebef;
    }
    .print\:hover\:text-transparent:hover {
        color: transparent;
    }
    .print\:hover\:text-black:hover {
        color: #22292f;
    }
    .print\:hover\:text-grey-darkest:hover {
        color: #3d4852;
    }
    .print\:hover\:text-grey-darker:hover {
        color: #606f7b;
    }
    .print\:hover\:text-grey-dark:hover {
        color: #8795a1;
    }
    .print\:hover\:text-grey:hover {
        color: #b8c2cc;
    }
    .print\:hover\:text-grey-light:hover {
        color: #dae1e7;
    }
    .print\:hover\:text-grey-lighter:hover {
        color: #f1f5f8;
    }
    .print\:hover\:text-grey-lightest:hover {
        color: #f8fafc;
    }
    .print\:hover\:text-white:hover {
        color: #fff;
    }
    .print\:hover\:text-red-darkest:hover {
        color: #3b0d0c;
    }
    .print\:hover\:text-red-darker:hover {
        color: #621b18;
    }
    .print\:hover\:text-red-dark:hover {
        color: #cc1f1a;
    }
    .print\:hover\:text-red:hover {
        color: #e3342f;
    }
    .print\:hover\:text-red-light:hover {
        color: #ef5753;
    }
    .print\:hover\:text-red-lighter:hover {
        color: #f9acaa;
    }
    .print\:hover\:text-red-lightest:hover {
        color: #fcebea;
    }
    .print\:hover\:text-orange-darkest:hover {
        color: #462a16;
    }
    .print\:hover\:text-orange-darker:hover {
        color: #613b1f;
    }
    .print\:hover\:text-orange-dark:hover {
        color: #de751f;
    }
    .print\:hover\:text-orange:hover {
        color: #f6993f;
    }
    .print\:hover\:text-orange-light:hover {
        color: #faad63;
    }
    .print\:hover\:text-orange-lighter:hover {
        color: #fcd9b6;
    }
    .print\:hover\:text-orange-lightest:hover {
        color: #fff5eb;
    }
    .print\:hover\:text-yellow-darkest:hover {
        color: #453411;
    }
    .print\:hover\:text-yellow-darker:hover {
        color: #684f1d;
    }
    .print\:hover\:text-yellow-dark:hover {
        color: #f2d024;
    }
    .print\:hover\:text-yellow:hover {
        color: #ffed4a;
    }
    .print\:hover\:text-yellow-light:hover {
        color: #fff382;
    }
    .print\:hover\:text-yellow-lighter:hover {
        color: #fff9c2;
    }
    .print\:hover\:text-yellow-lightest:hover {
        color: #fcfbeb;
    }
    .print\:hover\:text-green-darkest:hover {
        color: #0f2f21;
    }
    .print\:hover\:text-green-darker:hover {
        color: #1a4731;
    }
    .print\:hover\:text-green-dark:hover {
        color: #1f9d55;
    }
    .print\:hover\:text-green:hover {
        color: #38c172;
    }
    .print\:hover\:text-green-light:hover {
        color: #51d88a;
    }
    .print\:hover\:text-green-lighter:hover {
        color: #a2f5bf;
    }
    .print\:hover\:text-green-lightest:hover {
        color: #e3fcec;
    }
    .print\:hover\:text-teal-darkest:hover {
        color: #0d3331;
    }
    .print\:hover\:text-teal-darker:hover {
        color: #20504f;
    }
    .print\:hover\:text-teal-dark:hover {
        color: #38a89d;
    }
    .print\:hover\:text-teal:hover {
        color: #4dc0b5;
    }
    .print\:hover\:text-teal-light:hover {
        color: #64d5ca;
    }
    .print\:hover\:text-teal-lighter:hover {
        color: #a0f0ed;
    }
    .print\:hover\:text-teal-lightest:hover {
        color: #e8fffe;
    }
    .print\:hover\:text-blue-darkest:hover {
        color: #12283a;
    }
    .print\:hover\:text-blue-darker:hover {
        color: #1c3d5a;
    }
    .print\:hover\:text-blue-dark:hover {
        color: #2779bd;
    }
    .print\:hover\:text-blue:hover {
        color: #3490dc;
    }
    .print\:hover\:text-blue-light:hover {
        color: #6cb2eb;
    }
    .print\:hover\:text-blue-lighter:hover {
        color: #bcdefa;
    }
    .print\:hover\:text-blue-lightest:hover {
        color: #eff8ff;
    }
    .print\:hover\:text-indigo-darkest:hover {
        color: #191e38;
    }
    .print\:hover\:text-indigo-darker:hover {
        color: #2f365f;
    }
    .print\:hover\:text-indigo-dark:hover {
        color: #5661b3;
    }
    .print\:hover\:text-indigo:hover {
        color: #6574cd;
    }
    .print\:hover\:text-indigo-light:hover {
        color: #7886d7;
    }
    .print\:hover\:text-indigo-lighter:hover {
        color: #b2b7ff;
    }
    .print\:hover\:text-indigo-lightest:hover {
        color: #e6e8ff;
    }
    .print\:hover\:text-purple-darkest:hover {
        color: #21183c;
    }
    .print\:hover\:text-purple-darker:hover {
        color: #382b5f;
    }
    .print\:hover\:text-purple-dark:hover {
        color: #794acf;
    }
    .print\:hover\:text-purple:hover {
        color: #9561e2;
    }
    .print\:hover\:text-purple-light:hover {
        color: #a779e9;
    }
    .print\:hover\:text-purple-lighter:hover {
        color: #d6bbfc;
    }
    .print\:hover\:text-purple-lightest:hover {
        color: #f3ebff;
    }
    .print\:hover\:text-pink-darkest:hover {
        color: #451225;
    }
    .print\:hover\:text-pink-darker:hover {
        color: #6f213f;
    }
    .print\:hover\:text-pink-dark:hover {
        color: #eb5286;
    }
    .print\:hover\:text-pink:hover {
        color: #f66d9b;
    }
    .print\:hover\:text-pink-light:hover {
        color: #fa7ea8;
    }
    .print\:hover\:text-pink-lighter:hover {
        color: #ffbbca;
    }
    .print\:hover\:text-pink-lightest:hover {
        color: #ffebef;
    }
    .print\:text-10 {
        font-size: 1rem;
    }
    .print\:text-11 {
        font-size: 1.1rem;
    }
    .print\:text-12 {
        font-size: 1.2rem;
    }
    .print\:text-13 {
        font-size: 1.3rem;
    }
    .print\:text-14 {
        font-size: 1.4rem;
    }
    .print\:text-15 {
        font-size: 1.5rem;
    }
    .print\:text-16 {
        font-size: 1.6rem;
    }
    .print\:text-17 {
        font-size: 1.7rem;
    }
    .print\:text-18 {
        font-size: 1.8rem;
    }
    .print\:text-19 {
        font-size: 1.9rem;
    }
    .print\:text-20 {
        font-size: 2rem;
    }
    .print\:text-24 {
        font-size: 2.4rem;
    }
    .print\:text-28 {
        font-size: 2.8rem;
    }
    .print\:text-32 {
        font-size: 3.2rem;
    }
    .print\:text-36 {
        font-size: 3.6rem;
    }
    .print\:text-40 {
        font-size: 4rem;
    }
    .print\:text-44 {
        font-size: 4.4rem;
    }
    .print\:text-48 {
        font-size: 4.8rem;
    }
    .print\:text-52 {
        font-size: 5.2rem;
    }
    .print\:text-56 {
        font-size: 5.6rem;
    }
    .print\:text-60 {
        font-size: 6rem;
    }
    .print\:text-64 {
        font-size: 6.4rem;
    }
    .print\:text-68 {
        font-size: 6.8rem;
    }
    .print\:text-72 {
        font-size: 7.2rem;
    }
    .print\:text-96 {
        font-size: 9.6rem;
    }
    .print\:text-128 {
        font-size: 12.8rem;
    }
    .print\:text-xs {
        font-size: 1.2rem;
    }
    .print\:text-sm {
        font-size: 2.4rem;
    }
    .print\:text-base {
        font-size: 1.6rem;
    }
    .print\:text-lg {
        font-size: 1.8rem;
    }
    .print\:text-xl {
        font-size: 2rem;
    }
    .print\:text-2xl {
        font-size: 2.4rem;
    }
    .print\:text-3xl {
        font-size: 3rem;
    }
    .print\:text-4xl {
        font-size: 3.6rem;
    }
    .print\:text-5xl {
        font-size: 4.8rem;
    }
    .print\:italic {
        font-style: italic;
    }
    .print\:roman {
        font-style: normal;
    }
    .print\:uppercase {
        text-transform: uppercase;
    }
    .print\:lowercase {
        text-transform: lowercase;
    }
    .print\:capitalize {
        text-transform: capitalize;
    }
    .print\:normal-case {
        text-transform: none;
    }
    .print\:underline {
        text-decoration: underline;
    }
    .print\:line-through {
        text-decoration: line-through;
    }
    .print\:no-underline {
        text-decoration: none;
    }
    .print\:antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .print\:subpixel-antialiased {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
    .print\:hover\:italic:hover {
        font-style: italic;
    }
    .print\:hover\:roman:hover {
        font-style: normal;
    }
    .print\:hover\:uppercase:hover {
        text-transform: uppercase;
    }
    .print\:hover\:lowercase:hover {
        text-transform: lowercase;
    }
    .print\:hover\:capitalize:hover {
        text-transform: capitalize;
    }
    .print\:hover\:normal-case:hover {
        text-transform: none;
    }
    .print\:hover\:underline:hover {
        text-decoration: underline;
    }
    .print\:hover\:line-through:hover {
        text-decoration: line-through;
    }
    .print\:hover\:no-underline:hover {
        text-decoration: none;
    }
    .print\:hover\:antialiased:hover {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .print\:hover\:subpixel-antialiased:hover {
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }
    .print\:tracking-tight {
        letter-spacing: -0.05em;
    }
    .print\:tracking-normal {
        letter-spacing: 0;
    }
    .print\:tracking-wide {
        letter-spacing: .05em;
    }
    .print\:select-none {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .print\:select-text {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }
    .print\:align-baseline {
        vertical-align: baseline;
    }
    .print\:align-top {
        vertical-align: top;
    }
    .print\:align-middle {
        vertical-align: middle;
    }
    .print\:align-bottom {
        vertical-align: bottom;
    }
    .print\:align-text-top {
        vertical-align: text-top;
    }
    .print\:align-text-bottom {
        vertical-align: text-bottom;
    }
    .print\:visible {
        visibility: visible;
    }
    .print\:invisible {
        visibility: hidden;
    }
    .print\:whitespace-normal {
        white-space: normal;
    }
    .print\:whitespace-no-wrap {
        white-space: nowrap;
    }
    .print\:whitespace-pre {
        white-space: pre;
    }
    .print\:whitespace-pre-line {
        white-space: pre-line;
    }
    .print\:whitespace-pre-wrap {
        white-space: pre-wrap;
    }
    .print\:break-words {
        word-wrap: break-word;
    }
    .print\:break-normal {
        word-wrap: normal;
    }
    .print\:truncate {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .print\:w-0 {
        width: 0;
    }
    .print\:w-4 {
        width: .4rem;
    }
    .print\:w-8 {
        width: .8rem;
    }
    .print\:w-12 {
        width: 1.2rem;
    }
    .print\:w-16 {
        width: 1.6rem;
    }
    .print\:w-20 {
        width: 2rem;
    }
    .print\:w-24 {
        width: 2.4rem;
    }
    .print\:w-28 {
        width: 2.8rem;
    }
    .print\:w-32 {
        width: 3.2rem;
    }
    .print\:w-36 {
        width: 3.6rem;
    }
    .print\:w-40 {
        width: 4rem;
    }
    .print\:w-44 {
        width: 4.4rem;
    }
    .print\:w-48 {
        width: 4.8rem;
    }
    .print\:w-52 {
        width: 5.2rem;
    }
    .print\:w-56 {
        width: 5.6rem;
    }
    .print\:w-60 {
        width: 6rem;
    }
    .print\:w-64 {
        width: 6.4rem;
    }
    .print\:w-68 {
        width: 6.8rem;
    }
    .print\:w-72 {
        width: 7.2rem;
    }
    .print\:w-76 {
        width: 7.6rem;
    }
    .print\:w-80 {
        width: 8rem;
    }
    .print\:w-84 {
        width: 8.4rem;
    }
    .print\:w-88 {
        width: 8.8rem;
    }
    .print\:w-92 {
        width: 9.2rem;
    }
    .print\:w-96 {
        width: 9.6rem;
    }
    .print\:w-128 {
        width: 12.8rem;
    }
    .print\:w-160 {
        width: 16rem;
    }
    .print\:w-192 {
        width: 19.2rem;
    }
    .print\:w-224 {
        width: 22.4rem;
    }
    .print\:w-256 {
        width: 25.6rem;
    }
    .print\:w-288 {
        width: 28.8rem;
    }
    .print\:w-320 {
        width: 32rem;
    }
    .print\:w-512 {
        width: 51.2rem;
    }
    .print\:w-auto {
        width: auto;
    }
    .print\:w-px {
        width: 1px;
    }
    .print\:w-1\/2 {
        width: 50%;
    }
    .print\:w-1\/3 {
        width: 33.33333%;
    }
    .print\:w-2\/3 {
        width: 66.66667%;
    }
    .print\:w-1\/4 {
        width: 25%;
    }
    .print\:w-3\/4 {
        width: 75%;
    }
    .print\:w-1\/5 {
        width: 20%;
    }
    .print\:w-2\/5 {
        width: 40%;
    }
    .print\:w-3\/5 {
        width: 60%;
    }
    .print\:w-4\/5 {
        width: 80%;
    }
    .print\:w-1\/6 {
        width: 16.66667%;
    }
    .print\:w-5\/6 {
        width: 83.33333%;
    }
    .print\:w-full {
        width: 100%;
    }
    .print\:w-screen {
        width: 100vw;
    }
    .print\:z-0 {
        z-index: 0;
    }
    .print\:z-10 {
        z-index: 10;
    }
    .print\:z-20 {
        z-index: 20;
    }
    .print\:z-30 {
        z-index: 30;
    }
    .print\:z-40 {
        z-index: 40;
    }
    .print\:z-50 {
        z-index: 50;
    }
    .print\:z-auto {
        z-index: auto;
    }
}

.MuiBadge-colorPrimary{
    margin-top: 13px !important;
    margin-right: 30px !important;
}