

.ag-theme-alpine .ag-column-hover {
    /*background-color: #dfffdf !important;*/
}

.ag-theme-alpine .ag-cell-hover {
    background-color: #dfdfff !important;
}


.ag-theme-alpine .ag-header-cell, .ag-theme-alpine .ag-header-group-cell {
    padding : 0px !important;
}

.ag-theme-alpine .ag-header {
    background-color: white !important;
}

.ag-theme-alpine .ag-header-cell-label {
    border-right-color: lightgray !important;
    border-right-width: 1px  !important;
    border-right-style: solid  !important;
    display: flex;
    align-items: flex-end;
}

.ag-theme-alpine .ag-header-cell-text {
    /*Force the width corresponding at how much width
      we need once the text is laid out vertically*/
    width: 105px;
    writing-mode: vertical-rl;
    transform: scale(-1);
    -ms-writing-mode: tb-lr;
    margin-top: 3px;
    margin-bottom: 3px;
    white-space: normal;
    vertical-align: middle;
    line-height: 3em;
    justify-content: center;
    width: 2em;
    color : #2f54eb;

}


.ag-theme-alpine .ag-cell
{
    border-right-color: lightgray !important;
    border-right-width: 1px  !important;
    border-right-style: solid  !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}