.mydisabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.3;
}
.fixed-width-127 > tbody > tr > td {
  /* width: 127px !important; */
  /* width: auto; */
  border: none;
}
tr.mystrip:nth-child(even) {
  background: #f8f8f8 0% 0% no-repeat padding-box !important;
}
tr.bax:first-child {
  background-color: #e0dddd;
}
tr.mystrip:nth-child(odd) {
  background: white 0% 0% no-repeat padding-box !important;
}

td.mystrip {
  border: none !important;
  /* padding-left: 30px !important;
  padding-right: 30px !important; */
}
.mymodalBtn {
  background-color: #673ab7 !important;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); */
  border-radius: 0;
  color: white !important;
  height: 100%;
}

.viewStudentButton {
  background-color: #673ab7 !important;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); */
  border-radius: 0;
  color: white !important;
  height: 100%;
  margin-top: 40px !important;
}
.MuiInput-underline.Mui-disabled:before {
  border-bottom-style: none !important;
}

.MuiInputBase-formControl.Mui-disabled {
  box-shadow: none !important;
}
textarea#comment.Mui-disabled {
  color: black;
  cursor: text;
}

.myDisableLogic {
  pointer-events: none;
  background-color: #bdbdbd !important;
}

.myDisableLogictext {
  pointer-events: none;
  color: #bdbdbd !important;
}

.checkbox .MuiCheckbox-colorSecondary.Mui-checked {
  color: #1890ff;
}