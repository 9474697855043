.main {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.MuiGrid-spacing-xs-6 > .MuiGrid-item {
    padding: 10px 24px !important;
}

.ant-upload.ant-upload-select-picture-card {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #cecece;
}

.ant-tag-cyan {
    font-size: 14px;
    padding: 3px 10px;
    background: #ffffff;
}

.student-profile-tags {
    font-size: 14px;
    padding: 0 10px;
    background: #ffffff;
    color: #212b9b;
    border-color: #212b9b;
}

.ant-input-number-input {
    width: 100% !important;
}

.add-button {
    background: #3cc89c;
    border-color: #3cc89c;
    color: white;
}

.review-button {
    background: #3c3989;
    border-color: #3c3989;
    color: white;
}

.review-button:hover {
    background: #3c3989;
    border-color: #3c3989;
    color: white;
}

.ant-calendar-picker-icon {
    color: #3cc89c;
    font-size: 16px;
}

.family-card {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    background: red;
    vertical-align: middle;
    font-size: 19px;
    text-align: center;
    color: white;
}

.ant-avatar {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 50%;
    background-color: #cecece;
}

.ant-card-body {
    padding: 18px;
}

.ant-card-meta-title {
    font-size: 17px;
}

.ant-card-meta-detail > div:not(:last-child) {
    margin-bottom: 0;
}

.ant-switch-checked {
    background-color: #3c3989;
}

.anticon-info-circle {
    color: #3cc89c;
}

.w-20-p {
    width: 20% !important;
}

.w-25-p {
    width: 25% !important;
}

.w-50-p {
    width: 50% !important;
}

.w-60-p {
    width: 60% !important;
}

.w-100-p {
    width: 100% !important;
}

.w-100-px {
    width: 120px !important;
}

.w-150 {
    width: 150px !important;
}

.present-panel {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background: #3cc89c;
    color: white;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
}

.absent-panel {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    background: #d12323;
    color: white;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
}

.main-dashboard .ant-list-item {
    padding: 5px 0 0 0;
}

.p-40 {
    padding: 40px !important;
}

.main-dashboard .ant-btn-round.ant-btn-lg {
    height: 55px;
    font-size: 20px;
    width: 265px;
}

.button-title-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-dashboard .ant-progress-circle .ant-progress-inner {
    width: 350px !important;
    height: 350px !important;
    font-size: 24px !important;
}

.margin-top-40 {
    margin-top: 40px !important;
}

.margin-bottom-40 {
    margin-bottom: 40px !important;
}

.mr-1rem {
    margin-right: 1rem !important;
}

.checkout-text {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: 100;
}

.color-active {
    color: #3cc89c;
}

.student-dashboard-infinite-container {
    /*border: 1px solid #e8e8e8;*/
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: calc(100vh - 340px);
}

.student-dashboard-infinite-container::-webkit-scrollbar {
    width: 6px;
}

.student-dashboard-infinite-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.student-dashboard-infinite-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.staff-dashboard-infinite-container::-webkit-scrollbar {
    width: 6px;
}

.staff-dashboard-infinite-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.staff-dashboard-infinite-container::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
}

.staff-dashboard-infinite-container {
    /*border: 1px solid #e8e8e8;*/
    border-radius: 4px;
    overflow: auto;
    padding: 8px 24px;
    height: calc(100vh - 360px);
}

.fs-25 {
    font-size: 25px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.chart-tags {
    height: 17px;
    width: 30px;
    border: 1px solid transparent;
    margin-right: 10px;
}

.bg-unmarked {
    background: #303998;
}

.bg-present {
    background: #3cc89c;
}

.bg-absent {
    background: #d12323;
}

.bg-tardy {
    background: orange;
}

.bg-virtual {
    background: #8787fb;
}

.bg-unmarked {
    background: #000000;
}

.ant-list-item:hover {
    background-color: #f7f7f7;
}

.student-color {
    color: #212b9b
}

.edit-icon {
    color: #3cc89c;
    font-size: 20px;
    cursor: pointer;
}

/*.student-table-header*/
.ant-list-item:hover {
    background-color: #ebeff4;
}

.ant-notification-notice-closable .ant-notification-notice-message {
    color: white;
}

.ant-notification-notice-close {
    color: white;
}

.color-red {
    color: red;
}

.color-white {
    color: white;
}

.color-black {
    color: black;
}

.color-green {
    color: green;
}

.fw-600 {
    font-weight: 600;
}

.align-item-center {
    align-items: center;
}

.ws-nowrap {
    white-space: nowrap;
}

.purple-bg {
    background-color: rgb(84, 99, 214) !important;
}